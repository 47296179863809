import { DocumentType, InvoiceType } from '@/models/Enums';

export class DocumentsFilter {
    customer: string;
    number: string | null;
    keyword: string;
    pageIndex: number;
    createDateStart: string | null;
    createDateEnd: string | null;
    type: number | null;
    orderNumber: string | null;
    orderBy: string;
    asc: boolean;
    providerName: string;
    providerId: string;

    constructor() {
        this.customer = '';
        this.number = null;
        this.keyword = '';
        this.pageIndex = 0;
        this.createDateStart = null;
        this.createDateEnd = null;
        this.type = null;
        this.orderNumber = null;
        this.orderBy = 'Number';
        this.asc = true;
        this.providerId = '';
        this.providerName = '';
    }
}

export class Document {
    id: string;
    documentType: DocumentType | null;
    invoiceType: InvoiceType | null;
    createDate: string;
    createdBy: string;
    documentNumber: number;
    creator: string;
    customer: string;
    pdfUrl: string;

    constructor() {
        this.id = '';
        this.documentType = null;
        this.invoiceType = null;
        this.createDate = '';
        this.createdBy = '';
        this.documentNumber = 0;
        this.creator = '';
        this.customer = '';
        this.pdfUrl = '';
    }
}
