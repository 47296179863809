export class Slot {
  id = 0;
  endTime = '';
  startTime = '';
  duration = '';
  quantity = 0;
}

export class ProductCustomer {
  id: string;
  name: string;
  price: string;
  priceForOne: string;
  quantity: string;
  priceCalculated: boolean;

  constructor() {
    this.id = '';
    this.name = '';
    this.price = '0';
    this.priceForOne = '';
    this.quantity = '';
    this.priceCalculated = true;
  }
}

export class Product {
  active: boolean;
  address: string;
  calendarColor: string;
  city: string;
  cityId: string;
  createdAt: string;
  fullDescription: string;
  id: string;
  latitude: number;
  longitude: number;
  mostPopular: false;
  name: string;
  productCustomerTypes: [];
  productDocuments: [];
  productExcludedTags: [];
  productImages: [];
  productIncludedTags: [];
  productPrices: [];
  productRegions: [];
  productTags: [];
  productTranslations: null;
  productTypeId: string;
  productTypes: null;
  productVideos: [];
  productWorkingDates: [];
  shortDescription: string;
  constructor() {
    this.active = true;
    this.address = '';
    this.calendarColor = '';
    this.city = '';
    this.cityId = '';
    this.createdAt = '';
    this.fullDescription = '';
    //this.id = 1102
    this.id = '';
    this.latitude = 0;
    this.longitude = 0;
    this.mostPopular = false;
    this.name = '';
    this.productCustomerTypes = [];
    this.productDocuments = [];
    this.productExcludedTags = [];
    this.productImages = [];
    this.productIncludedTags = [];
    this.productPrices = [];
    this.productRegions = [];
    this.productTags = [];
    this.productTranslations = null;
    this.productTypeId = '';
    this.productTypes = null;
    this.productVideos = [];
    this.productWorkingDates = [];
    this.shortDescription = '';
  }
}

export class OrderProduct {
  id: string;
  productId: string;
  name: string;
  date: string;
  calendarDate: Date | string | null;
  slotId: string;
  slot: Slot;
  customers: ProductCustomer[];
  price: number;
  priceForOne: number;
  currencySign: string;
  currency: string;
  opened: boolean;
  product: Product;
  covidInfo: string;
  arrivalInstructions: string;
  parkingInstructions: string;

  constructor() {
    this.id = '';
    this.productId = '';
    this.name = '';
    this.date = '';
    this.calendarDate = null;
    this.slotId = '';
    this.slot = new Slot();
    this.customers = [];
    this.price = 0;
    this.priceForOne = 0;
    this.currencySign = '';
    this.currency = '';
    this.opened = false;
    this.product = new Product();
    this.covidInfo = '';
    this.arrivalInstructions = '';
    this.parkingInstructions = '';
  }
}
export class Order {
  id: string;
  customerName: string;
  mobileNumber: string;
  email: string;
  priceTypeId: string;
  currency: string;
  currencySign: string;
  discountAmount: string;
  discountPercent: number;
  priceWithDiscount: number;
  priceTotal: number;
  products: OrderProduct[];
  newproduct: OrderProduct;
  constructor() {
    this.id = '';
    this.customerName = '';
    this.mobileNumber = '';
    this.email = '';
    this.priceTypeId = '';
    this.currency = '';
    this.currencySign = '';
    this.discountAmount = '0';
    this.discountPercent = 0;
    this.priceWithDiscount = 0;
    this.priceTotal = 0;
    this.products = [];
    this.newproduct = new OrderProduct();
  }
}
export class Currency {
  culture: string;
  name: string;
  symbol: string;
  constructor() {
    this.culture = '';
    this.name = '';
    this.symbol = '';
  }
}
export class Customer {
  email: string;
  mobile: string;
  name: string;
  pid: string;
  constructor() {
    this.email = '';
    this.name = '';
    this.mobile = '';
    this.pid = '';
  }
}

export class OrderProvider {
  active: boolean;
  address: '';
  email: string;
  id: string;
  mainImageUrl: string;
  name: string;
  type: number;
  validDate: string;
  constructor() {
    this.email = '';
    this.name = '';
    this.active = false;
    this.address = '';
    this.id = '';
    this.mainImageUrl = '';
    this.type = 0;
    this.validDate = '';
  }
}
export class MyOrderCustomer {
    qty = 0;
    typeName = '';
}
export class MyOrder {
  id: string;
  createDate: string;
  customerMobile: string;
  customerFullName: string;
  customersTickets: number;
  orderNumber: number;
  orderProductCreateDate: string;
  orderProductStartDate: string;
  productName: string;
  slotDuration: string;
  slotStartTime: string;
  total: number;
  currencySymbol: string;
  durationMinutes: number;
  haveRemarks: boolean;
  customers: MyOrderCustomer[];
  balance: number;
  constructor() {
    this.id = '';
    this.createDate = '';
    this.customerFullName = '';
    this.customerMobile = '';
    this.customersTickets = 0;
    this.orderNumber = 0;
    this.orderProductCreateDate = '';
    this.orderProductStartDate = '';
    this.productName = '';
    this.slotDuration = '';
    this.slotStartTime = '';
    this.total = 0;
    this.currencySymbol = '';
    this.durationMinutes = 0;
    this.haveRemarks = false;
    this.customers = [];
    this.balance = 0;
  }
}
export class OrderDetails {
  priceType: IdName;
  currency: Currency;
  currencyId: string;
  leadCustomer: Customer;
  orderProducts: any;
  provider: OrderProvider;
  id: string;
  invoiceTotal: number;
  orderNumber: number;
  discountAmount: number;
  discountPercent: number;
  priceWithDiscount: number;
  priceTotal: number;
  priceWithoutVat: number;
  receiptTotal: number;
  vatAmount: number;
  vatPercent: number;
  createDate: string;
  status: string;
  statusId: number;
  remarks: string;
  priceTotalWithoutDiscount: number;
  messages: OrderMessage[];
  commissionTotal: number;
  channelName: string;
  constructor() {
    this.currency = new Currency();
    this.currencyId = '';
    this.leadCustomer = new Customer();
    this.id = '';
    this.discountAmount = 0;
    this.discountPercent = 0;
    this.priceWithDiscount = 0;
    this.priceTotal = 0;
    this.invoiceTotal = 0;
    this.orderNumber = 0;
    this.priceWithoutVat = 0;
    this.receiptTotal = 0;
    this.vatAmount = 0;
    this.vatPercent = 0;
    this.createDate = '';
    this.status = '';
    this.statusId = 0;
    this.priceType = { id: '', name: '' };
    this.provider = new OrderProvider();
    this.orderProducts = [];
    this.remarks = '';
    this.priceTotalWithoutDiscount = 0;
    this.messages = [];
    this.commissionTotal = 0;
    this.channelName = '';
  }
}

export class OrderMessage {
  id = 0;
  createDate = '';
  from = '';
  message = '';
  type = 0;
}

export class OrdersFilter {
  providerId = 0;
  productId = 0;
  priceTypeId = 0;
  channelId = 0;
  orderNumber: string | null = null;
  status: number | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  pageIndex = 0;
  keyword = '';
  orderBy = 'ReservationDate';
  asc = false;
  arrivalStartDate: string | null = null;
  arrivalEndDate: string | null = null;
}

export class DateRange {
  fromDate: Date | null = null;
  toDate: Date | null = null;
}

export class RevenueByCurrency {
    currencyId = 0;
    total = '';
}
