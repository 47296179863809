
import { Options, Vue } from 'vue-class-component';
import swal from 'sweetalert2';
import Q from 'q';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import { ProvidersFilter } from '@/models/Provider';
import store from '@/store';
import router from '@/router';
import { mapState } from 'vuex';
import useExtensions from '@/useExtensions';
import { DateRange } from '@/models/Order';
import { toRaw } from 'vue';

var { dateRangeValidate, formatDate } = useExtensions();

@Options({
  components: {
    Multiselect,
    Calendar,
  },
  computed: {
    ...mapState(['subscriptions']),
  },
})
export default class ProvidersFilterModal extends Vue {
  public subscriptions!: SubsctiptionType[];
  public filter = new ProvidersFilter();
  public registrationDateRange = new DateRange();

  async mounted() {
    const sloterFilter = store.state.providersFilter;
    if (sloterFilter.active != null) sloterFilter.active = +sloterFilter.active;
    this.filter = JSON.parse(JSON.stringify(sloterFilter));

    this.registrationDateRange = new DateRange();

    if (this.filter.registrationStartDate) {
      this.registrationDateRange.fromDate = new Date(this.filter.registrationStartDate);
    }
    if (this.filter.registrationEndDate) {
      this.registrationDateRange.toDate = new Date(this.filter.registrationEndDate);
    }

    await Q.delay(400);
    swal.close();
  }

  //functions
  public ResetFilter() {
    router.push({ name: 'providers', params: { newSearch: '1' } });
  }

  public Close() {
    router.push({ name: 'providers' });
  }

  public Search() {
    let val = this.registrationDateRange.fromDate;
    this.filter.registrationStartDate = val ? formatDate(val, 'api') : null;

    val = this.registrationDateRange.toDate;
    this.filter.registrationEndDate = val ? formatDate(val, 'api') : null;

    store.commit('saveProvidersFilter', this.filter);
    router.push({ name: 'providers', params: { newSearch: '1', keepFilter: '1' } });
  }

  public dateChanged(dateRange: DateRange) {
    if (!dateRange.fromDate || !dateRange.toDate) return;
    dateRangeValidate(dateRange);
  }
}
