import api from '@/services/Api';
import { Product } from '@/models/Product';
import swal from 'sweetalert2';
import generalStore from '@/store';
import router from '@/router';
import Q from 'q';
import { useI18n } from 'vue-i18n';
import { ApiResult } from '@/services/BaseApi';
import moment from 'moment';
import { DateRange } from '@/models/Order';
import { OrderBlockTypes, OrderBlockOption } from '@/models/Enums';

export default function () {
  const { t } = useI18n();
  async function getProduct(id: string): Promise<Product | null> {
    if (id == '') return null;
    const product = generalStore.state.product;
    if (!product || product.id != id) {
      swal.showLoading();
      const response = await api.getProduct(id);
      if (response.data) {
        generalStore.commit('setProduct', response.data);
        swal.close();
      } else {
        swal
          .fire({
            icon: 'error',
            title: 'Such a product does not exist.',
            text: response.errorMessage,
            confirmButtonText: t('alert.backToCatalog'),
          })
          .then(() => {
            router.push({ name: 'catalog' });
          });
        return null;
      }
    }

    return generalStore.state.product;
  }

  function orderBlockTypesOpt() {
    return [
      { id: OrderBlockTypes.minutes, name: t('calendar.v3.modal.slot.minutes') },
      { id: OrderBlockTypes.hours, name: t('calendar.v3.modal.slot.hours') },
      { id: OrderBlockTypes.days, name: t('calendar.v3.modal.slot.days') },
    ];
  }

  function orderBlockOptionsOpt() {
    return [
      { id: OrderBlockOption.before, name: t('calendar.v3.modal.slot.before') },
      { id: OrderBlockOption.after, name: t('calendar.v3.modal.slot.after') },
      { id: OrderBlockOption.allow, name: t('calendar.v3.modal.slot.allow') },
    ];
  }

  function compareOrder(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  function getAverageDuration() {
    return [
      {
        id: 1,
        name: t('product.averageDuration.15min'),
        order: 1,
      },
      {
        id: 2,
        name: t('product.averageDuration.30min'),
        order: 2,
      },
      {
        id: 3,
        name: t('product.averageDuration.1hour'),
        order: 3,
      },
      {
        id: 4,
        name: t('product.averageDuration.2hours'),
        order: 5,
      },
      {
        id: 5,
        name: t('product.averageDuration.3hours'),
        order: 6,
      },
      {
        id: 6,
        name: t('product.averageDuration.over3Hours'),
        order: 7,
      },
      {
        id: 7,
        name: t('product.averageDuration.halfDay'),
        order: 8,
      },
      {
        id: 8,
        name: t('product.averageDuration.wholeDay'),
        order: 9,
      },
      {
        id: 9,
        name: t('product.averageDuration.1-5hour'),
        order: 4,
      },
    ].sort(compareOrder);
  }

  function getOperationStatus() {
    return [
      {
        id: 0,
        name: t('product.operationStatus.new'),
      },
      {
        id: 1,
        name: t('product.operationStatus.qaOk'),
      },
      {
        id: 2,
        name: t('product.operationStatus.qaErr'),
      },
    ];
  }
  async function saveProduct(product: Product): Promise<Product | undefined> {
    swal.showLoading();
    product.productPrices = [];
    const updateResponse = await api.saveProduct(product);
    await Q.delay(400);
    swal.close();
    if (updateResponse.errorMessage) {
      swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: updateResponse.errorMessage });
      return;
    }
    if (updateResponse.data) {
      if (updateResponse.data.warningCode == 588) {
        const swalResult = await swal.fire({
          icon: 'info',
          title: t('alert.updateProductSlotOrderBlockMin'),
          customClass: {
            confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
          cancelButtonText: t('editProductPage.cancelEdit.alertCancel'),
        });
        if (swalResult.isConfirmed) {
          api.updateProductSlotOrderBlockMin(updateResponse.data.product.id);
        }
      }
      generalStore.commit('setProduct', updateResponse.data.product);
      return updateResponse.data.product;
    }
  }

  function dateRangeValidate(dateRange: DateRange) {
    const isNotValidDates = moment(dateRange.fromDate) > moment(dateRange.toDate);
    if (isNotValidDates) {
      dateRange.toDate = dateRange.fromDate;
    }
  }

  async function saveProductRegions(
    productId: string,
    regionsId: string[],
  ): Promise<ApiResult<ChangeProductRegionsResponse>> {
    swal.showLoading();
    const updateResponse = await api.saveRegions(productId, regionsId);
    await Q.delay(400);
    swal.close();
    if (updateResponse.data) {
      generalStore.commit('setProductRegions', updateResponse.data.productRegions);
    }
    return updateResponse;
  }
  async function saveProductTags(
    productId: string,
    tagsId: string[],
    parentTagId: string,
  ): Promise<ApiResult<ChangeProductTagsResponse>> {
    swal.showLoading();
    const updateResponse = await api.saveTags(productId, tagsId, parentTagId);
    await Q.delay(400);
    swal.close();
    if (updateResponse.data) {
      generalStore.commit('setProductTags', updateResponse.data);
    }
    return updateResponse;
  }
  return {
    saveProduct,
    getProduct,
    saveProductRegions,
    saveProductTags,
    dateRangeValidate,
    getAverageDuration,
    getOperationStatus,
    orderBlockTypesOpt,
    orderBlockOptionsOpt,
  };
}
