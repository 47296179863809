
import { computed, defineComponent, reactive, ref, onMounted, inject, onBeforeUnmount } from 'vue';
import api from '@/services/Api';
import { DocumentsFilter, Document } from '@/models/Document';
import useExtensions from '@/useExtensions';
import swal from 'sweetalert2';
import Q from 'q';
import { DocumentType, InvoiceType } from '@/models/Enums';
import router from '@/router';
import generalStore from '@/store';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default defineComponent({
    name: 'Documents',
    async setup() {
        onBeforeRouteLeave(async to => {
            if (to.name != 'filterdocuments' && to.name != 'invoice' && to.name != 'receipt') {
                generalStore.commit('saveDocumentsFilter', new DocumentsFilter());
            }
            return true;
        });
        const searchInput = ref('');
        const { t } = useI18n();
        const selectedDocument = ref<Document | null>(null);
        const filter = ref<DocumentsFilter>(new DocumentsFilter());
        filter.value = generalStore.getters.getDocumentsFilter;
        if (filter.value) {
            filter.value.pageIndex = 0;
            searchInput.value = filter.value.keyword;
        }
        const total = ref(0);
        const busy = ref(false);
        const hasNext = ref(false);

        const emitter: any = inject('emitter');
        const { removemillis } = useExtensions();
        const documents = ref<Document[]>([]);
        const hasFilter = computed<boolean>(() => {
            if (filter.value.customer) {
                return true;
            }
            if (filter.value.number) {
                return true;
            }
            if (filter.value.createDateStart) {
                return true;
            }
            if (filter.value.createDateEnd) {
                return true;
            }
            if (filter.value.type) {
                return true;
            }
            if (filter.value.orderNumber) {
                return true;
            }
            if (filter.value.providerName) {
                return true;
            }
            if (filter.value.providerId) {
                return true;
            }
            return false;
        });

        function fixFilter() {
            if (!filter.value.number) {
                filter.value.number = null;
            }
            if (!filter.value.type) {
                filter.value.type = null;
            }
            if (!filter.value.orderNumber) {
                filter.value.orderNumber = null;
            }
        }

        async function getDocuments() {
            swal.showLoading();
            busy.value = true;
            fixFilter();
            const apiResult = await api.GetDocuments(filter.value);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (apiResult.data && filter.value.pageIndex == 0) {
                total.value = apiResult.data.total;
            }
            if (apiResult.data && documents.value && documents.value.length && apiResult.data.rows) {
                documents.value = [...documents.value, ...apiResult.data.rows];
            } else {
                documents.value = apiResult.data?.rows || [];
            }
            filter.value.pageIndex++;
            busy.value = false;
            swal.close();
        }

        async function ListenerGetDocuments() {
            if (hasNext.value && !busy.value) {
                await getDocuments();
            }
        }

        const rowsCount = computed<number>(() => {
            if (documents.value && documents.value.length) {
                return documents.value.length;
            }
            return 0;
        });

        async function getDocumentsOnClick() {
            filter.value.keyword = searchInput.value;
            documents.value = [];
            filter.value.pageIndex = 0;
            getDocuments();
        }

        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;

            await getDocumentsOnClick();
        }

        function onBeforeUnMountedCall() {
            emitter.off('isBottom', ListenerGetDocuments);
        }

        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetDocuments);
            await getDocuments();
        };

        function back() {
            router.go(-1);
        }

        function goOrders() {
            router.push({ name: 'orders' });
        }

        function goFilter() {
            generalStore.commit('saveDocumentsFilter', filter.value);
            router.push({ name: 'filterdocuments' });
        }

        async function showAll() {
            searchInput.value = '';
            filter.value = new DocumentsFilter();
            documents.value = [];
            generalStore.commit('saveDocumentsFilter', filter.value);
            await getDocuments();
        }

        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }

        function showMobileActions(doc: Document) {
            selectedDocument.value = doc;
            const element = document.getElementById('mobile-documents-actions');
            if (element) {
                element.style.display = 'block';
            }
        }

        function closeMobileActions() {
            const element = document.getElementById('mobile-documents-actions');
            if (element) {
                element.style.display = 'none';
            }
        }

        async function showPdf(doc: Document) {
            if (doc.pdfUrl) {
                window.open(doc.pdfUrl, '_blank');
            } else {
                swal.showLoading();
                const response = await api.GetDocumentPdfUrl(doc.id);
                await Q.delay(400);
                swal.close();
                if (response.errorMessage) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
                } else if (response.data) {
                    if (response.data.pdfUrl) {
                        doc.pdfUrl = response.data.pdfUrl;
                        window.open(response.data.pdfUrl, '_blank');
                    } else {
                        swal.fire({
                            icon: 'warning',
                            title: t('alert.pfgNotReady'),
                            confirmButtonText: t('button.close')
                        });
                    }
                }
            }
        }

        async function shareDocument(doc: Document) {
            router.push({ name: 'sharedocument', params: { documentId: doc.id }})
        }
        
        const getDocumentType = (doc: Document) => {
            switch (doc.documentType) {
                case DocumentType.OrderSummary:
                    return t('accounting.orderSummary');
                case DocumentType.Receipt:
                    return t('accounting.receipt');
                case DocumentType.Invoice:
                    switch (doc.invoiceType) {
                        case InvoiceType.Credit:
                            return t('accounting.creditInvoice');
                        case InvoiceType.Perfoma:
                            return t('accounting.perfomaInvoice');
                    }
                    return t('accounting.invoice');
                case DocumentType.InvoiceReceipt:
                    return t('accounting.invoice-receipt');
                default:
                    if (doc.documentType) return DocumentType[doc.documentType];
            }
        };

        onMounted(onMountedCall);
        onBeforeUnmount(onBeforeUnMountedCall);
        return {
            documents,
            filter,
            getDocumentsOnClick,
            hasFilter,
            rowsCount,
            total,
            DocumentType,
            getDocumentType,
            sotrBy,
            back,
            goOrders,
            goFilter,
            removemillis,
            showAll,
            getArrowClass,
            showMobileActions,
            closeMobileActions,
            showPdf,
            selectedDocument,
            shareDocument,
            moment,
            searchInput,
        };
    }
});
