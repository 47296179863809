
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        modelValue: [Boolean, Array],
        name: {
            type: String
        },
        value: [String, Number],
        label: String,
        singleLine: Boolean,
        tabIndex: String
    },
    emits: ['update:modelValue', 'click'],
    setup(props, { emit }) {
        function createGUID() {
            function random() {
                return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
            }
            return random() + random() + '-' + random() + '-' + random() + '-' +
                random() + '-' + random() + random() + random();
        }
        const guid = 'radio_' + createGUID();
        const checked = computed(() => {
            if (props.modelValue instanceof Array) {
                return props.modelValue.includes(props.value);
            }
            return props.modelValue === true;
        });
        function updateInput(isChecked: boolean) {
            if (props.modelValue instanceof Array) {
                const newValue = [...props.modelValue];
                if (isChecked) {
                    newValue.push(props.value);
                } else {
                    newValue.splice(newValue.indexOf(props.value), 1);
                }
                emit('update:modelValue', newValue);
            } else {
                emit('update:modelValue', isChecked);
            }
        }
        return {
            updateInput,
            checked,
            guid
        };
    }
});
