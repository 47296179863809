
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    itemToEdit: {
      type: Object,
      required: true,
    },
  },
  name: 'EditLangItem',
  inject: ['emitter'],
})
export default class EditLangItem extends Vue {
  protected readonly index!: number;
  protected readonly item!: ItemWithTranslation;
  protected readonly itemToEdit!: ItemWithTranslation | null;

  editItem() {
    this.$emit('editItem', this.item);
  }
  rejectChanges() {
    this.$emit('rejectChanges', this.index);
  }
  applyChanges() {
    this.$emit('applyChanges', this.index);
  }
  editLangs() {
      this.$emit('editLangs', this.item.id);
  }
  deleteItem() {
    this.$emit('deleteItem', this.item.id);
  }
  
}
