import { getTimezone } from '@/services/Api';
import moment from 'moment';
import {
  AccountingDocumentStatus,
  DocumentType,
  PaymentType,
  PaymentStatus,
  CreditCardPaymentType,
  InvoiceType,
} from './Enums';

export class BaseAccountingDocument {
  baseAccountingDocumentId = '';
  recipientName = '';
  orderId = '';
  documentNumber = 0;
  providerId = 0;
  remarks = '';
  total = 0;
  currencyId = '';
  currencyRate = 1;
  localCurrencyId = '';
  localCurrencyRate = 1;
  localCurrencyTotalAmount = 0;
  status: AccountingDocumentStatus = AccountingDocumentStatus.None;
  isOriginalPrinted = false;
  for = '';
  customerId = '';
  documentType: DocumentType | null = null;
  dateOfPrint: Date | null = null;
  issueDate: Date | null = null;
  cancelDate: Date | null = null;
}

export class ReceiptDocument extends BaseAccountingDocument {
  // baseAccountingDocumentId = '';
}

export class BasePayment {
  basePaymentId = '';
  totalAmount = 0;
  currencyId = '';
  localCurrencyId = 0;
  localCurrencyRate = 1;
  localCurrencyTotalAmount = 0;
  paymentDate: Date | null = null;
  orderId = '';
  receiptDocumentId = '';
  receiptDocument = new ReceiptDocument();
  paymentType: PaymentType | null = null;
  customerId = '';
  status: PaymentStatus = PaymentStatus.None;
}

export class BankTransferPayment {
  basePaymentId = '';
  basePayment = new BasePayment();
  bankId = '';
  bankBranchNumber: number | null = null;
  accountNumber = '';
  referenceNumber = 0;
}

export class CashPayment {
  basePaymentId = '';
  basePayment = new BasePayment();
}

export class CheckPayment {
  basePaymentId = '';
  basePayment = new BasePayment();
  bankId = '';
  bankBranchNumber: number | null = null;
  accountNumber = '';
  checkNumber = 0;
}

export class CreditCardPayment extends BasePayment {
  //basePaymentId = '';
  // basePayment = new BasePayment();
  numberOfPayments = 1;
  creditCardPaymentType = CreditCardPaymentType.Regular;
  holderFullName = '';
  holderIdNumber = '';
  lastFourDigits = '';
}

export class Bank {
  id = '';
  name = '';
  code = '';
  active = true;
}

export class CreditCardReceipt {
  orderId = '';
  total = 0;
  currencyId = '';
  localCurrencyRate = 1;
  for = '';
  recipientName = '';
  numberOfPayments = 1;
  holderFullName = '';
  holderIdNumber = '';
  lastFourDigits = '';
  timezone: number = getTimezone();
}

export class CashReceipt {
  orderId = '';
  total = 0;
  currencyId = '';
  localCurrencyRate = 1;
  for = '';
  recipientName = '';
  valueDate: Date = moment().toDate();
  timezone: number = getTimezone();
}

export class BankTransferReceipt {
  orderId = '';
  total = 0;
  currencyId = '';
  localCurrencyRate = 1;
  for = '';
  recipientName = '';
  valueDate: Date = moment().toDate();
  accountNumber = '';
  bankId = '';
  bankBranchNumber: number | null = null;
  referenceNumber = '';
  timezone: number = getTimezone();
}

export class CheckReceiptPayment {
  valueDate: Date = moment().toDate();
  accountNumber = '';
  bankId = '';
  bankBranchNumber: number | null = null;
  checkNumber = '';
  amount = 0;
  timezone: number = getTimezone();
}

export class CheckReceipt {
  orderId = '';
  total = 0;
  currencyId = '';
  localCurrencyRate = 1;
  for = '';
  recipientName = '';
  payments: CheckReceiptPayment[] = [new CheckReceiptPayment()];
  timezone: number = getTimezone();
}

export class Invoice {
  orderId = '';
  orderNumber = 0;
  documentNumber = '';
  extraAmount = 0;
  totalAmount = 0;
  vatPercent = 0;
  vatAmount = 0;
  discountAmount = 0;
  discountPercent = 0;
  productsTotalAmount = 0;
  currencyId = '';
  for = '';
  recipientName = '';
  products: InvoiceDocumentProduct[] = [];
  timezone: number = getTimezone();
  type: InvoiceType = 1;
  pdfUrl = '';
}

export class InvoiceDocumentProduct {
  id = '';
  total = 0;
  pricePerUnit = '';
  quantity = '';
  unitName = '';
  productId = null;
  invoiceDocumentId = '';
  name = '';
  currencyId = '';
}

export class Receipt {
  id = '';
  orderId = '';
  orderNumber = 0;
  documentNumber = '';
  for = '';
  recipientName = '';
  total = 0;
  currencyId = '';
  localCurrencyId = 1;
  localCurrencyRate = 1;
  localCurrencyTotalAmount = 0;
  creditCardReceipt: CreditCardReceipt | null = null;
  cashReceipt: CashReceipt | null = null;
  bankTransferReceipt: BankTransferReceipt | null = null;
  checkReceipt: CheckReceipt | null = null;
  pdfUrl = '';
}
