
import { computed, defineComponent, PropType, ref } from 'vue';
import api from '@/services/Api';
import swal from 'sweetalert2';

export default defineComponent({
    props: {
      providerId: {
        type: Number,
        required: true,
      },
      restrictions: {
        type: Object as PropType<NotificationsRestrictions>,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
    },
    setup(props: any) {

        const notificationsRestrictions = ref<NotificationsRestrictions>(props.restrictions);

        const basicNotificationsToggle = computed(() => {
            if (
                notificationsRestrictions.value.orderCreated == true ||
                notificationsRestrictions.value.orderConfirmed == true ||
                notificationsRestrictions.value.orderCanceled == true ||
                notificationsRestrictions.value.newMessageFromCustomer == true ||
                notificationsRestrictions.value.newMessageFromUser == true ||
                notificationsRestrictions.value.createReciept == true ||
                notificationsRestrictions.value.createInvoice == true ||
                notificationsRestrictions.value.orderChanged == true ||
                notificationsRestrictions.value.createInvoiceReceipt == true 
            ) {
                return true;
            }

            return false;
        });

        const unfinishedProductsNotificationsToggle = computed(() => {
            if (
                notificationsRestrictions.value.unfinishedProductSlotEmpty == true ||
                notificationsRestrictions.value.unfinishedProductPriceEmpty == true ||
                notificationsRestrictions.value.unfinishedProductSlotLess == true ||
                notificationsRestrictions.value.unfinishedProductPriceLess == true ||
                notificationsRestrictions.value.unfinishedProductNoPhoto == true
            ) {
                return true;
            }

            return false;
        });

        const aboutUpcomingOrdersNotificationsToggle = computed(() => {
            if (
                notificationsRestrictions.value.sendUpcomingOrdersBeforeTwoDays == true ||
                notificationsRestrictions.value.sendUpcomingOrdersToday == true
            ) {
                return true;
            }

            return false;
        });

        const productBecameLiveNotificationsToggle = computed(() => {
            if (
                notificationsRestrictions.value.productOnAir == true
            ) {
                return true;
            }

            return false;
        });


        const activateDeactivateGroupNotifications = async (notificationRestrictionGroupType: number, status: boolean) => {
            switch(notificationRestrictionGroupType) {
                case 1:
                    notificationsRestrictions.value.orderCreated = status;
                    notificationsRestrictions.value.orderConfirmed = status;
                    notificationsRestrictions.value.orderCanceled = status;
                    notificationsRestrictions.value.newMessageFromCustomer = status;
                    notificationsRestrictions.value.newMessageFromUser = status;
                    notificationsRestrictions.value.createReciept = status;
                    notificationsRestrictions.value.createInvoice = status;
                    notificationsRestrictions.value.orderChanged = status;
                    notificationsRestrictions.value.createInvoiceReceipt = status;
                    break;
                case 2:
                    notificationsRestrictions.value.unfinishedProductSlotEmpty = status;
                    notificationsRestrictions.value.unfinishedProductPriceEmpty = status;
                    notificationsRestrictions.value.unfinishedProductSlotLess = status;
                    notificationsRestrictions.value.unfinishedProductPriceLess = status;
                    notificationsRestrictions.value.unfinishedProductNoPhoto = status;
                    break;
                case 3:
                    notificationsRestrictions.value.sendUpcomingOrdersBeforeTwoDays = status;
                    notificationsRestrictions.value.sendUpcomingOrdersToday = status;
                    break;
                case 4:
                    notificationsRestrictions.value.productOnAir = status;
                    break;
            }

            await activateDeactivateNotificationRestrictions();
        }

        const activateDeactivateNotificationRestrictions = async () => {
            swal.showLoading();
            setTimeout(async () => {
                const response = await api.activateDeactivateNotificationRestriction(notificationsRestrictions.value);

                if (response.error) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                }
            } ,500);
            swal.close();
        }

        return {
            basicNotificationsToggle,
            unfinishedProductsNotificationsToggle,
            aboutUpcomingOrdersNotificationsToggle,
            productBecameLiveNotificationsToggle,
            notificationsRestrictions,
            activateDeactivateNotificationRestrictions,
            activateDeactivateGroupNotifications
        }
    }
});
