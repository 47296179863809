export enum ChatMessageType {
    IndexUser = 1,
    Provider = 2
}

export enum ChatType {
    Message = 1,
    OrderQuote = 2
}

export enum OrderQuoteRequestedTime {
    Morning = 1,
    Noon,
    Afternoon,
    Evening,
    Night
}

export class OrderQuote {
    id = 0;
    name = '';
    productId = '';
    date = '';
    requestedTime = '';
    totalGuests = 0;
    amount = 0;
    currencyId = '';
    comments = '';
    createdAt = null;
}

export class ChatMessage {
    id = '';
    createdAt = '';
    message = '';
    type: ChatMessageType = ChatMessageType.Provider;
    isToday = false;
    read = false;
    attachmentPath = '';
    attachmentName = '';
}

export class ChatItem {
    productId = 0;
    productName = '';
    providerId = 0;
    providerName = '';
    indexUserId = 0;
    indexUserName = '';
    agencyName = '';
    indexAgencyId = 0;
    lastMessageDate = '';
    groupName = '';
    hasUnreadedMessages = false;
    orderQuote: OrderQuote | null = null;
    messages: ChatMessage[] = [];
}

export class ChatFilter {
    chatType: ChatType | null = null;
    indexAgencyId = 0;
    indexUserId = 0;
    providerId = 0;
    productId = 0;
    keyword = '';
    pageIndex = 0;
    orderBy = 'Id';
    asc = false;

}

export class FilterData {
    products: IdName[] = [];
    providers: IdName[] = [];
    indexAgencies: IdName[] = [];
    indexUsers: IdName[] = [];
}