import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import '@vueform/multiselect/themes/default.css';
import '@/assets/accounting.scss';
import '@/assets/base.scss';
import '@/assets/icons.scss';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import Maska from 'maska';
import mitt from 'mitt';
import $ from 'jquery';
const emitter = mitt();
import PrimeVue from 'primevue/config';

import App from './App.vue';
import api from './services/Api';
import router from './router';
import store from './store';
import mixin from './mixin';

import en from '@/lang/en-US.json';
import ru from '@/lang/ru-RU.json';
import he from '@/lang/he-IL.json';

$(document).on('click', '.multiselect.is-open .multiselect-caret', function (this: HTMLElement) {
  const el = $(this).closest('.multiselect-input');
  const container = $(this).closest('.multiselect');
  el.trigger('blur');
});

function setDir(lang: string) {
  const body = document.querySelector('body');
  const cls = lang == 'he-IL' ? 'rtl' : 'ltr';
  body?.classList.add(cls);
}

(async (): Promise<void> => {
  const initResponse = await api.init();
  if (initResponse.data) {
    store.commit('init', initResponse.data);
    store.commit('updateLang', initResponse.data.lang);
  }
  setDir(initResponse.data?.lang || 'en-US');

  const i18n = createI18n({
    locale: initResponse.data?.lang,
    legacy: false,
    globalInjection: true,
    fallbackLocale: 'en-US',
    messages: {
      en,
      he,
      ru,
    },
  });
  const app = createApp(App);

  app.mixin({
    mixins: [mixin],
  });
  app.config.globalProperties.$filters = {
    formatLocalDateTime: mixin.methods.formatLocalDateTime,
  };

  // prettier-ignore
  app.provide('emitter', emitter);
  app.use(i18n);
  app.use(Maska);
  app.use(PrimeVue);

  app.use(store).use(router).mount('#app');
})();
