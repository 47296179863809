
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';
import router from '@/router';
import Q from 'q';
import swal from 'sweetalert2';
import api from '@/services/Api';

@Options({
  props: {
    items: {
      type: [],
      required: true,
    },
    providerId: {
      type: Number,
      required: true,
    },
  },
  name: 'Profiles',
  components: {},
  data() {
    return {
      wasSearch: false,
      itemsAfterSearch: [],
      searchText: '',
    };
  },
  computed: {
    itemsToShow() {
      if (this.wasSearch) {
        return this.itemsAfterSearch;
      }
      return this.items;
    },
  },
})
export default class Profiles extends Vue {
  protected readonly providerId!: number;
  public showMore = false;
  public wasSearch = false;
  public searchText = '';
  public itemsAfterSearch: ProviderProfile[] = [];

  getDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }
  EditProfile(profileId: number) {
    router.push({ name: 'EditProfile', params: { providerId: this.providerId, id: profileId } });
  }
  async searchProfile() {
    swal.showLoading();
    this.wasSearch = true;
    const apiResult = await api.getProviderProfilesData(this.providerId, this.searchText);
    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({
        icon: 'error',
        text: apiResult.errorMessage,
      });
      return;
    }
    if (apiResult.data) {
      this.itemsAfterSearch = apiResult.data || [];
    }
    swal.close();
  }
}
