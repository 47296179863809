import { DocumentsFilter } from '@/models/Document';
import { OrdersFilter } from '@/models/Order';
import { Product, ProductPrice, ProductsFilter, PromoFilter } from '@/models/Product';
import { ProvidersFilter } from '@/models/Provider';
import { AgenciesFilter } from '@/models/Agencies';

export default class StoreState {
  permissions: number[] = [];
  lang = '';
  isAuthenticated = false;
  user?: User | null = null;
  currencies: Currency[] = [];
  currencyRates: CurrencyRate[] = [];
  regions: Region[] = [];
  cities: City[] = [];
  subscriptions: SubsctiptionType[] = [];
  productTypes: ProductType[] = [];
  tags: Tag[] = [];
  subTags: SubTag[] = [];
  product: Product | null = null;
  languages: Language[] = [];
  predefinedDates: PredefinedDate[] = [];
  providersFilter: ProvidersFilter = new ProvidersFilter();
  agenciesFilter: AgenciesFilter = new AgenciesFilter();
  promoFilter: PromoFilter = new PromoFilter();
  orderFilter: OrdersFilter = new OrdersFilter();
  documentsFilter: DocumentsFilter = new DocumentsFilter();
  productsFilter: ProductsFilter = new ProductsFilter();
  defaultPriceTypeId = '';
  defaultChannelId = '';
  dateTimeFormatMoment = '';
  productLastOpenedBlock = '';
  selectedPriceTypeIdFilter = '';
  selectedProductIdFilter = '';
  isMobileView = false;
  refreshProviderSearch = false;
  refreshAgencySearch = false;
  countryCode = '';
  countryLatitude = 0;
  countryLongitude = 0;
  googleMapsApiKey = '';
  localCurrencyId = 0;

  channels: IdName[] = [];
  priceTypes: IdName[] = [];
  products: IdNameParent[] = [];
  indexProducts: IdNameParent[] = [];
  providers: IdName[] = [];
  profiles: IdName[] = [];
  currentPrice: ProductPrice = new ProductPrice();
  selectedWeekDays: string[] = [];
  banks: IdName[] = [];
  bankBranches: IdNameParent[] = [];
}
