
import { inject, defineComponent, ref, onMounted, onUnmounted } from 'vue';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import useClipboard from 'vue-clipboard3';
import moment from 'moment';

export default defineComponent({
  name: 'RegistrationAwaiting',
  async setup() {
    const items = ref<RegistrationAwaitingItem[]>([]);
    const emitter: any = inject('emitter');
    const busy = ref(false);
    const hasNext = ref(false);
    const pageIndex = ref(0);

    onMounted(async () => {
      emitter.on('isBottom', listenerGetRegistrationAwaiting);
      const apiResultPromise = api.getRegistrationAwaitingList(pageIndex.value);
      await Q.delay(400);
      const apiResult = await apiResultPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      pageIndex.value++;
      items.value = apiResult.data?.items || [];
      hasNext.value = apiResult?.data?.hasNext || false;
    });

    onUnmounted(async () => {
      emitter.off('isBottom');
    });

    function getCreatedAt(date: string) {
      return moment(date).format('DD/MM/YYYY HH:MM');
    }

    async function getRegistrationAwaiting() {
      if (busy.value) return;
      swal.showLoading();
      busy.value = true;
      const apiResultPromise = api.getRegistrationAwaitingList(pageIndex.value);
      await Q.delay(400);
      const apiResult = await apiResultPromise;
      if (apiResult.data?.error) {
        swal.fire({
          icon: 'error',
          text: apiResult.data?.error,
        });
        return;
      }
      if (apiResult.data) {
        pageIndex.value++;
        items.value = [...items.value, ...apiResult.data?.items];
        hasNext.value = apiResult?.data?.hasNext || false;
      }

      busy.value = false;
      swal.close();
    }

    function listenerGetRegistrationAwaiting() {
      if (hasNext.value && !busy.value) {
        getRegistrationAwaiting();
      }
    }

    function ShowLink(text: string) {
      swal
        .fire({
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Copy`,
          input: 'text',
          inputValue: text,
        })

        .then(async (result) => {
          if (result.isConfirmed) {
            const { toClipboard } = useClipboard();
            try {
              await toClipboard(text);
            } catch (e) {
              console.error(e);
            }
          }
        });
    }
    return { ShowLink, getCreatedAt, items };
  },
});
