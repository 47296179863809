
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import { mapState, Store, mapGetters } from 'vuex';
import * as ApiInterfaces from '@/models/ApiInterfaces';

@Options({
    name: 'EditPromoProductTranslation',
    props: {
        promoProductId: {
            type: Number,
            required: true,
        },
    },
    components: {
        Multiselect,
    },
    computed: {
        ...mapState(['languages', 'lang']),
    },
})
export default class EditPromoProductTranslation extends Vue {
    protected readonly promoProductId!: number;
    public promoProductItem: EditPromoProductLangs | null = null;
    public loaded = false;
    public mainLang: Language | null = null;
    public selectedLang = 0;

    protected readonly languages!: Language[];
    protected readonly lang!: string;

    async mounted() {
        swal.showLoading();
        this.mainLang = this.languages.find((x) => x.name == this.lang) || null;
        if (this.mainLang) {
            this.selectedLang = this.mainLang.id;
        }
        const getPromotedProductLangsResponse = await api.getPromotedProductLangs(this.promoProductId);
        await Q.delay(400);
        if (getPromotedProductLangsResponse.errorMessage) {
            swal.fire({ icon: 'error', title: 'Oops...', text: getPromotedProductLangsResponse.errorMessage });
            return;
        }
        if (getPromotedProductLangsResponse.data) {
            this.promoProductItem = {
                id: getPromotedProductLangsResponse.data.item.id,
                description: getPromotedProductLangsResponse.data.item.description,
                translations: getPromotedProductLangsResponse.data.item.translations,
            };

            this.languages.forEach((language) => {
                var lang = this.promoProductItem?.translations.find((x) => x.languageId == language.id);
                if (!lang) {
                    this.promoProductItem?.translations.push({
                        entityId: this.promoProductId,
                        id: 0,
                        languageId: language.id,
                        description: language.id == this.selectedLang ? this.promoProductItem?.description : '',
                    });
                }
            });
            this.loaded = true;
        }
        swal.close();
    }

    cancel() {
        this.$router.go(-1);
    }
    async save() {
        if (!this.promoProductItem) {
            return;
        }
        swal.showLoading();
        let toSave: EditPromoProductLangs = JSON.parse(JSON.stringify(this.promoProductItem));
        toSave.translations = toSave.translations.filter((x) => x.description);
        let request: ApiInterfaces.SavePromoProductTranslationRequest = {
            promotedProductId: toSave.id,
            promotedProductDescription: toSave.description,
            translations: toSave.translations,
        };

        const savePromoProductResponsePromise = api.savePromoProductTranslation(request);
        await Q.delay(400);
        const savePromoProductResponse = await savePromoProductResponsePromise;
        if (savePromoProductResponse.errorMessage) {
            swal.fire({ icon: 'error', title: 'Oops...', text: savePromoProductResponse.errorMessage });
            return;
        }
        swal.close();
        this.cancel();
    }
}
