import store from '@/store';
import api from './Api';

export async function changeLanguage(lang: string) {
  const body = document.querySelector('body');
  const html = document.querySelector('html');

  await api.changeLanguage(lang);
  if (html && body) {
    const dir = lang === 'he-IL' ? 'rtl' : 'ltr';
    body.setAttribute('dir', dir);
    html.setAttribute('lang', lang);
    window.location.reload();
    if (!body.classList.contains(dir)) {
      body.classList.add(dir);
      const removeClass = lang === 'he-IL' ? 'ltr' : 'rtl';
      body.classList.remove(removeClass);
    }
  }
  store.commit('updateLang', lang);
}
