
import { defineComponent, PropType, ref, computed } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'ProductFAQs',
  props: {
    productId: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    items: {
      type: [] as PropType<ProductFAQ[]>,
      default: () => [],
    },
  },
  async setup(props, { emit }: any) {
    const showAll = ref(false);

    const faqs = computed(() => {
      if (showAll.value || props.editMode) {
        return props.items;
      }
      return props.items.slice(0, 3);
    });

    function deleteFAQItem(index: number) {
      emit('deleteFAQItem', index);
    }
    function addTranslations(id: string) {
      router.push({
        name: 'edit-product-translation',
        params: {
          productId: props.productId,
          id: id,
          type: 'faq',
        },
      });
    }
    return { deleteFAQItem, showAll, faqs, addTranslations };
  },
});
