
import { defineComponent, ref, computed } from 'vue';
import api from '@/services/Api';
import * as apiInterfaces from '@/models/ApiInterfaces';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import router from '@/router';

export default defineComponent({
    name: 'ShareDocument',
    props: {
        documentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {};
    },
    async setup(props: any) {
        const { t } = useI18n();
        const smsReceiver = ref('');
        const emailSubject = ref('');
        const emailReceiver = ref('');
        const sendEmail = ref(false);
        const sendSms = ref(false);
        const message = ref('');
        const locale = ref('he-IL');
        const openedLocaleBlock = ref(false);

        async function getDocumentData() {
            const apiResult = await api.GetDocumentShareData(props.documentId);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return
            }
            const result = apiResult.data;
            
            if (result) {
                smsReceiver.value = result.smsReceiver;
                emailSubject.value = result.emailSubject;
                emailReceiver.value = result.emailReceiver;
                message.value = t('share.document.default.msg', [result.customerName, result.docType, result.docNumber]);
            }
        }

        await getDocumentData();
        

        function changeLang(lang: string) {
            locale.value = lang;
            openedLocaleBlock.value = false;
        }
        async function send() {
            if (!sendEmail.value && !sendSms.value) {
                return
            }
            if (!message.value) {
                return
            }
            if (sendEmail.value) {
                if (!emailSubject.value) {
                    return
                }
                if (!emailReceiver.value) {
                    return
                }
            }
            if (sendSms.value) {
                if (!smsReceiver.value) {
                    return
                }
            }

            const req: apiInterfaces.ShareDocumentRequest = {
                id: props.documentId,
                sendEmail: sendEmail.value,
                sendSms: sendSms.value,
                message: message.value,
                emailReceiver: emailReceiver.value,
                emailSubject: emailSubject.value,
                smsReceiver: smsReceiver.value.toString(),
                lang: locale.value,
                timezone: 0
            };

            const apiResult = await api.ShareDocument(req);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Document was shared',
                showConfirmButton: false,
                timer: 1500,
            });
        }

        function close() {
            router.go(-1);
        }
        return {
            locale,
            changeLang,
            openedLocaleBlock,
            sendEmail,
            sendSms,
            send,
            message,
            emailReceiver,
            emailSubject,
            smsReceiver,
            close
        };
    }
});
