
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import { mapState } from 'vuex';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';

@Options({
  props: {
    items: {
      type: [],
      required: true,
    },
  },
  name: 'CurrencyRates',
  components: {
    Multiselect,
    Calendar,
  },
  computed: {
    ...mapState(['currencies', 'localCurrencyId']),
  },
})
export default class CurrencyRates extends Vue {
  protected readonly items!: CurrencyRate[];
  protected readonly currencies!: Currency[];
  protected readonly localCurrencyId!: number;

  public getCurrencyRatesToShow: CurrencyRate[] = [];
  public fromDate: Date | null = null;
  public toDate: Date | null = null;
  public currencyId: number | null = null;
  public showSeeMore = true;

  ChangeRate(id: number) {
    this.$router.push({ name: 'EditCurrencyRate', params: { id } });
  }
  async getCurrRateByFilter() {
    swal.showLoading();
    const fromDate = this.fromDate ? moment(this.fromDate).format('DD/MM/YYYY') : '';
    const toDate = this.toDate ? moment(this.toDate).format('DD/MM/YYYY') : '';
    const currencyId = this.currencyId || 0;
    const apiResult = await api.filterCurrencyRate(currencyId, fromDate, toDate);
    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
    }
    if (apiResult.data) {
      this.getCurrencyRatesToShow = apiResult.data.items;
      this.showSeeMore = false;
      swal.close();
    }
  }
  getCurrDate(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }

  mounted() {
    this.getCurrencyRatesToShow = this.items;
  }
}
