import { BaseApi, ApiResult } from '@/services/BaseApi';
import * as ApiInterfaces from '@/models/ApiInterfaces';
import {
  Product,
  ProductTranslationItem,
  ProductWorkingDate,
  TimeSlot,
  ProductCustomerType,
  ProductPrice,
  ProductsFilter,
  ProductMoreInfo,
  PromotedInfo,
  PromotedProduct,
  PromotedCategory,
  UnfinishedNotificationsRestrictions
} from '@/models/Product';
import { ProviderDetails, ProvidersFilter, СashierStation } from '@/models/Provider';
import { OrdersFilter } from '@/models/Order';
import { DocumentsFilter } from '@/models/Document';
import { Receipt, Invoice } from '@/models/Accounting';
import { AgencyDetails, AgentDetails } from '@/models/Agencies';
import {
  GetPromotedProductLangsResponse,
  SavePromoProductTranslationRequest, SavePromoProductTranslationResponse
} from '@/models/ApiInterfaces';
import { ChatFilter, ChatMessage, FilterData } from '@/models/ChatQoutes';
import {PriceTypes} from "@/models/Enums";

export function getTimezone() {
  return -new Date().getTimezoneOffset();
}
class Api extends BaseApi {
  public async getRefProviders(): Promise<ApiResult<IdName[]>> {
    return this.get<IdName[]>('api/ref/providers');
  }
  public async getRefPriceTypes(): Promise<ApiResult<IdName[]>> {
    return this.get<IdName[]>('api/ref/price-types');
  }
  public async getChannels(): Promise<ApiResult<IdName[]>> {
    return this.get<IdName[]>('api/ref/channels');
  }
  public async getBankBranches(): Promise<ApiResult<IdNameParent[]>> {
    return this.get<IdNameParent[]>('api/ref/bankBranches');
  }
  public async getBanks(): Promise<ApiResult<IdName[]>> {
    return this.get<IdName[]>('api/ref/banks');
  }
  public async getRefProducts(): Promise<ApiResult<IdNameParent[]>> {
    return this.get<IdNameParent[]>('api/ref/products');
  }
  public async getRefIndexProducts(): Promise<ApiResult<IdNameParent[]>> {
    return this.get<IdNameParent[]>('api/ref/index-products');
  }
  public async getPredefinedDates(): Promise<ApiResult<PredefinedDate[]>> {
    return this.get<PredefinedDate[]>('api/ref/predefined-dates');
  }

  public async init(): Promise<ApiResult<ApiInterfaces.InitResponse>> {
    return this.get<ApiInterfaces.InitResponse>('api/init');
  }
  public async filterAgencies(
    request: ApiInterfaces.FilterAgenciesRequest,
  ): Promise<ApiResult<ApiInterfaces.FilterAgenciesResponse>> {
    return this.post<ApiInterfaces.FilterAgenciesResponse>('api/agencies', request);
  }
  public async filterProviders(
    request: ApiInterfaces.FilterProvidersRequest,
  ): Promise<ApiResult<ApiInterfaces.FilterProvidersResponse>> {
    return this.post<ApiInterfaces.FilterProvidersResponse>('api/providers', request);
  }
  public async exportProviders(filter: ProvidersFilter): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
    return this.post<ApiInterfaces.TaskResponse>('api/export/providers', {
      filter,
    });
  }
  public async exportProducts(filter: ProductsFilter): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
    return this.post<ApiInterfaces.TaskResponse>('api/export/products', filter);
  }
  public async exportOrders(filter: OrdersFilter): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
    return this.post<ApiInterfaces.TaskResponse>('api/export/orders', filter);
  }
  public async waitExportTask(taskId: string) {
    return this.get<ApiInterfaces.AwaitTaskResponse>('api/export/wait-task', {
      params: { id: taskId },
    });
  }
  public async getProviderDetails(id: number): Promise<ApiResult<ApiInterfaces.GetProviderResponse>> {
    return this.get<ApiInterfaces.GetProviderResponse>('api/providers/get', {
      params: { id },
    });
  }
  public async saveProviderDetails(provider: ProviderDetails): Promise<ApiResult<ApiInterfaces.SaveProviderResponse>> {
    return this.post<ApiInterfaces.SaveProviderResponse>('api/providers/save', {
      provider,
    });
  }
  public async activateDeactivateNotificationRestriction(
    notificationsRestrictions: NotificationsRestrictions,
  ): Promise<ApiResult<boolean>> {
    return this.post<boolean>('api/notification-restrictions/save', JSON.stringify({ notificationsRestrictions }));
  }
  public async activateDeactivateProductNotificationRestriction(
      notificationsRestrictions: UnfinishedNotificationsRestrictions,
  ): Promise<ApiResult<boolean>> {
    return this.post<boolean>('api/product-notification-restrictions/save', JSON.stringify(notificationsRestrictions));
  }
  public async changeLanguage(lang: string): Promise<ApiResult<ApiInterfaces.ChangeLanguageResponse>> {
    return this.post<ApiInterfaces.ChangeLanguageResponse>('api/changelanguage', JSON.stringify({ lang: lang }));
  }
  public async login(Username: string, Password: string): Promise<ApiResult<ApiInterfaces.LoginResponse>> {
    return this.post<ApiInterfaces.LoginResponse>('api/authenticate', JSON.stringify({ Username, Password }));
  }
  public async logout(): Promise<ApiResult<void>> {
    return this.get<void>('api/logout');
  }
  public async productHasOrders(productId: number): Promise<ApiResult<boolean>> {
    return this.get<boolean>('api/product/has-orders', {
      params: { productId },
    });
  }
  public async getOrders(filter: OrdersFilter): Promise<ApiResult<ApiInterfaces.GetMyOrderResponse>> {
    return this.post<ApiInterfaces.GetMyOrderResponse>('api/orders', JSON.stringify({ filter }));
  }
  public async GetOrder(orderId: string): Promise<ApiResult<ApiInterfaces.GetOrderResponse>> {
    return this.get<ApiInterfaces.GetOrderResponse>('api/order/get', {
      params: { id: orderId },
    });
  }

  public async GetOrderMessages(orderId: string, type: string, fromId = 0, showOld = false) {
    if (!fromId) fromId = 0;
    return this.post<ApiInterfaces.GetOrderMessagesResponse>(
      'api/order/messages/get',
      JSON.stringify({ orderId, type, fromId, showOld }),
    );
  }

  public async getSettings(): Promise<ApiResult<ApiInterfaces.GetSettingsResponse>> {
    return this.get<ApiInterfaces.GetSettingsResponse>('api/settings/get');
  }
  public async getProducts(
    filter: ApiInterfaces.FilterProductsRequest,
  ): Promise<ApiResult<ApiInterfaces.GetProductsResponse>> {
    return this.post<ApiInterfaces.GetProductsResponse>('api/products', filter);
  }
  public async getProduct(id: string): Promise<ApiResult<Product>> {
    return this.get<Product>('api/product/' + id);
  }
  public async saveProduct(product: Product): Promise<ApiResult<ApiInterfaces.SaveProductResponse>> {
    return this.post<ApiInterfaces.SaveProductResponse>('api/product', JSON.stringify(product));
  }
  public async updateProductSlotOrderBlockMin(productId: string) {
    return this.post<ApiInterfaces.ApiResponseBase[]>(
      `api/product/${productId}/updateSlotsOrderBlockMin`,
      JSON.stringify({}),
    );
  }
  public async deleteActivity(id: string): Promise<ApiResult<Product>> {
    return this.delete<Product>(`api/product/${id}`);
  }
  public async saveProductAddress(
    productAddress: ApiInterfaces.SaveProductAddressRequest,
  ): Promise<ApiResult<boolean>> {
    return this.post<boolean>('api/save-product-address', JSON.stringify(productAddress));
  }
  public async uploadFile(file: FormData, projectId: string): Promise<ApiResult<ProductImage>> {
    return this.postFormData<ProductImage>(`api/product/${projectId}/file`, file);
  }
  public async uploadTicketImage(file: FormData, projectId: string): Promise<ApiResult<ApiInterfaces.TicketImageResponse>> {
      return this.postFormData<ApiInterfaces.TicketImageResponse>(`api/product-ticket-image/${projectId}`, file);
  }
  public async uploadDocument(file: FormData, projectId: string): Promise<ApiResult<ProductDocument>> {
    return this.postFormData<ProductDocument>(`api/product/${projectId}/document`, file);
  }
  public async uploadResposiblePersonFile(
    file: FormData,
    projectId: string,
  ): Promise<ApiResult<ResponsiblePersonImage>> {
    return this.postFormData<ResponsiblePersonImage>(`api/product/${projectId}/upload-responsible-person-image`, file);
  }
  public async deleteResposiblePersonImage(productId: string): Promise<ApiResult<boolean>> {
    return this.delete<boolean>(`api/product/${productId}/delete-responsible-person-image`);
  }
  public async ActivateOrDisactivate(id: string): Promise<ApiResult<boolean>> {
    return this.delete<boolean>(`api/product/activate-or-disactivate-product/`, { params: { productId: id } });
  }
  public async ActivateOrDisactivateProductPriceType( productId: string, priceTypeId: string): Promise<ApiResult<boolean>> {
    return this.post<boolean>(`api/product/activate-or-disactivate-product-price-type/`, { productId, priceTypeId });
  }
  public async getTag(id: number): Promise<ApiResult<ApiInterfaces.GetTagResponse>> {
    return this.get<ApiInterfaces.GetTagResponse>('api/settings/tags', {
      params: { id },
    });
  }
  public async getSettingsProductTypes(id: number): Promise<ApiResult<ApiInterfaces.GetTagResponse>> {
    return this.get<ApiInterfaces.GetTagResponse>('api/settings/product-types', { params: { id } });
  }
  public async saveProductType(
    request: ApiInterfaces.SaveTagRequest,
  ): Promise<ApiResult<ApiInterfaces.SaveTagResponse>> {
    return this.post<ApiInterfaces.SaveTagResponse>('api/settings/product-type/save', JSON.stringify(request));
  }
  public async saveTag(request: ApiInterfaces.SaveTagRequest): Promise<ApiResult<ApiInterfaces.SaveTagResponse>> {
    return this.post<ApiInterfaces.SaveTagResponse>('api/settings/tag/save', JSON.stringify(request));
  }
  public async savePromoProductTranslation(request: ApiInterfaces.SavePromoProductTranslationRequest): Promise<ApiResult<ApiInterfaces.SavePromoProductTranslationResponse>> {
    return this.post<ApiInterfaces.SavePromoProductTranslationResponse>('api/promo-product-translation/save', JSON.stringify(request));
  }
  public async productTypeIsUsed(id: number): Promise<ApiResult<ApiInterfaces.TagIsUsedResponse>> {
    return this.get<ApiInterfaces.TagIsUsedResponse>('api/settings/product-type/used', { params: { id } });
  }
  public async tagIsUsed(id: number): Promise<ApiResult<ApiInterfaces.TagIsUsedResponse>> {
    return this.get<ApiInterfaces.TagIsUsedResponse>('api/settings/tag/used', {
      params: { id },
    });
  }
  public async deleteSubTag(id: number): Promise<ApiResult<ApiInterfaces.DeleteSubTagResponse>> {
    return this.post<ApiInterfaces.DeleteSubTagResponse>('api/settings/tag/delete', JSON.stringify({ id }));
  }
  public async deleteProductType(id: number): Promise<ApiResult<ApiInterfaces.DeleteSubTagResponse>> {
    return this.post<ApiInterfaces.DeleteSubTagResponse>('api/settings/product-type/delete', JSON.stringify({ id }));
  }
  public async getTagLangs(id: number, type: string): Promise<ApiResult<ApiInterfaces.GetTagLangsResponse>> {
    return this.get<ApiInterfaces.GetTagLangsResponse>('api/settings/tag/langs', { params: { id, type } });
  }
  public async getPromotedProductLangs(id: number): Promise<ApiResult<ApiInterfaces.GetPromotedProductLangsResponse>> {
    return this.get<ApiInterfaces.GetPromotedProductLangsResponse>('api/promoted-product/langs/' + id);
  }
  public async getRegions(pageIndex: number, filter: string): Promise<ApiResult<ApiInterfaces.GetLocationsResponse>> {
    return this.get<ApiInterfaces.GetLocationsResponse>('api/settings/regions/get', { params: { pageIndex, filter } });
  }
  public async getCities(pageIndex: number, filter: string): Promise<ApiResult<ApiInterfaces.GetLocationsResponse>> {
    return this.get<ApiInterfaces.GetLocationsResponse>('api/settings/cities/get', { params: { pageIndex, filter } });
  }
  public async getAllCities(): Promise<ApiResult<City[]>> {
    return this.get<City[]>('api/ref/cities');
  }
  public async getAllRegions(): Promise<ApiResult<Region[]>> {
    return this.get<Region[]>('api/ref/regions');
  }
  public async regionIsUsed(id: number): Promise<ApiResult<ApiInterfaces.LocationItemIsUsedResponse>> {
    return this.get<ApiInterfaces.LocationItemIsUsedResponse>('api/settings/region/used', { params: { id } });
  }
  public async cityIsUsed(id: number): Promise<ApiResult<ApiInterfaces.LocationItemIsUsedResponse>> {
    return this.get<ApiInterfaces.LocationItemIsUsedResponse>('api/settings/city/used', { params: { id } });
  }
  public async deleteRegionItem(id: number): Promise<ApiResult<ApiInterfaces.DeleteLocationItemResponse>> {
    return this.post<ApiInterfaces.DeleteLocationItemResponse>('api/settings/region/delete', JSON.stringify({ id }));
  }
  public async deleteCityItem(id: number): Promise<ApiResult<ApiInterfaces.DeleteLocationItemResponse>> {
    return this.post<ApiInterfaces.DeleteLocationItemResponse>('api/settings/city/delete', JSON.stringify({ id }));
  }
  public async getRegionTranslations(id: number): Promise<ApiResult<ApiInterfaces.GetLocationTranslationsResponse>> {
    return this.get<ApiInterfaces.GetLocationTranslationsResponse>('api/settings/region/translations', {
      params: { id },
    });
  }
  public async getCityTranslations(id: number): Promise<ApiResult<ApiInterfaces.GetLocationTranslationsResponse>> {
    return this.get<ApiInterfaces.GetLocationTranslationsResponse>('api/settings/city/translations', {
      params: { id },
    });
  }
  public async saveRegionTranslation(
    request: ApiInterfaces.SaveItemWithTranslationRequest,
  ): Promise<ApiResult<ApiInterfaces.SaveItemWithTranslationResponse>> {
    return this.post<ApiInterfaces.SaveItemWithTranslationResponse>(
      `api/settings/region/save`,
      JSON.stringify(request),
    );
  }
  public async saveCityTranslation(
    request: ApiInterfaces.SaveItemWithTranslationRequest,
  ): Promise<ApiResult<ApiInterfaces.SaveItemWithTranslationResponse>> {
    return this.post<ApiInterfaces.SaveItemWithTranslationResponse>(`api/settings/city/save`, JSON.stringify(request));
  }
  public async filterCurrencyRate(
    currencyId: number,
    dateFrom: string,
    dateTo: string,
  ): Promise<ApiResult<ApiInterfaces.FilterCurrencyRateResponse>> {
    return this.post<ApiInterfaces.FilterCurrencyRateResponse>(
      'api/settings/currency-rate/filter',
      JSON.stringify({ currencyId, dateFrom, dateTo }),
    );
  }
  public async GetDocumentPdfUrl(id: string): Promise<ApiResult<ApiInterfaces.GetDocumentPdfUrlResponse>> {
    return this.get<ApiInterfaces.GetDocumentPdfUrlResponse>('api/document/pdf', { params: { id } });
  }
  public async GetDocuments(filter: DocumentsFilter): Promise<ApiResult<ApiInterfaces.GetDocumentsResponse>> {
    return this.post<ApiInterfaces.GetDocumentsResponse>('api/documents', JSON.stringify({ filter }));
  }
  public async GetDocumentShareData(id: string): Promise<ApiResult<ApiInterfaces.GetDocumentShareDataResponse>> {
    return this.get<ApiInterfaces.GetDocumentShareDataResponse>('api/document/sharedata', { params: { id } });
  }
  public async ShareDocument(
    shareItem: ApiInterfaces.ShareDocumentRequest,
  ): Promise<ApiResult<ApiInterfaces.ShareDocumentResponse>> {
    shareItem.timezone = getTimezone();
    return this.post<ApiInterfaces.ShareDocumentResponse>('api/document/share', JSON.stringify(shareItem));
  }
  public async getTags(): Promise<ApiResult<Tag[]>> {
    return this.get<Tag[]>('api/ref/tags');
  }
  public async getSubTags(): Promise<ApiResult<SubTag[]>> {
    return this.get<SubTag[]>('api/ref/sub-tags');
  }
  public async getProductTypes(): Promise<ApiResult<ProductType[]>> {
    return this.get<ProductType[]>('api/ref/product-types');
  }
  public async removeDocument(id: string): Promise<ApiResult<ProductDocument>> {
    return this.delete<ProductDocument>(`api/product/delete-document`, {
      params: { documentId: id },
    });
  }
  public async deleteProductImage(id: string): Promise<ApiResult<boolean>> {
    return this.delete<boolean>('api/product/delete-product-image', {
      params: { productImageId: id },
    });
  }
  public async setMainProductImage(id: string): Promise<ApiResult<boolean>> {
    return this.get<boolean>('api/product/main-product-image', {
      params: { productImageId: id },
    });
  }
  public async getProductTranslations(projectId: string): Promise<ApiResult<ProductTranslation[]>> {
    return this.get<ProductTranslation[]>(`api/product/${projectId}/translations`);
  }
  public async saveProductImage(imageId: string, newLocalization: FileTranslation[], keywords: string) {
    return this.post<FileTranslation[]>(
      `api/product/image/${imageId}/save`,
      JSON.stringify({ newLocalization, keywords }),
    );
  }
  public async saveProductDocumentTranslations(documentId: string, newLocalization: FileTranslation[]) {
    return this.post<FileTranslation[]>(
      `api/product/document/${documentId}/save-translation`,
      JSON.stringify(newLocalization),
    );
  }
  public async saveNewProductNameTranslation(productId: string, Translations: ProductTranslationItem[]) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/save-name-translation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async saveNewProductShortDescriptionTranslation(productId: string, Translations: ProductTranslationItem[]) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/save-short-description-translation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async saveNewProductLongDescriptionTranslation(productId: string, Translations: ProductTranslationItem[]) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/save-long-description-translation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async saveNewProductLongDescriptionIndexTranslation(productId: string, Translations: ProductTranslationItem[]) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/save-long-description-index-translation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async saveupdateProductArrivalIstructionsTranslation(
    productId: string,
    Translations: ProductTranslationItem[],
  ) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/arrivalIstructionsTranslation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async saveupdateProductParkingIstructionsTranslation(
    productId: string,
    Translations: ProductTranslationItem[],
  ) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/parkingIstructionsTranslation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async getCurrencyRate(id: number): Promise<ApiResult<ApiInterfaces.GetCurrencyRateResponse>> {
    return this.get<ApiInterfaces.GetCurrencyRateResponse>('api/settings/currency-rate/get', { params: { id } });
  }
  public async saveCurrencyRate(
    request: ApiInterfaces.SaveCurrencyRateRequest,
  ): Promise<ApiResult<ApiInterfaces.SaveCurrencyRateResponse>> {
    return this.post<ApiInterfaces.SaveCurrencyRateResponse>(
      `api/settings/currency-rate/save`,
      JSON.stringify(request),
    );
  }
  public async deleteCurrencyRate(id: number): Promise<ApiResult<ApiInterfaces.DeleteCurrencyRateResponse>> {
    return this.post<ApiInterfaces.DeleteCurrencyRateResponse>('api/settings/currency-rate/delete', JSON.stringify(id));
  }
  public async getReceipt(id: string): Promise<ApiResult<Receipt>> {
    return this.get<Receipt>('api/bo/receipt', { params: { id } });
  }
  public async getInvoice(id: string): Promise<ApiResult<Invoice>> {
    return this.get<Invoice>('api/bo/get-invoice', { params: { id } });
  }
  public async saveProductWorkingDate(
    productWorkingDate: ProductWorkingDate,
    skipValidate: boolean,
  ): Promise<ApiResult<ApiInterfaces.GetProductWorkingDateResponse>> {
    return this.post<ApiInterfaces.GetProductWorkingDateResponse>(
      'api/product-working-date',
      JSON.stringify({ productWorkingDate, skipValidate }),
    );
  }
  public async changeActiveProductWorkingTimeSlot(timeSlot: TimeSlot): Promise<ApiResult<TimeSlot>> {
    return this.post<TimeSlot>('api/change-active-product-working-time-slot', JSON.stringify(timeSlot));
  }
  public async removeProductWorkingTimeSlot(timeSlot: TimeSlot): Promise<ApiResult<boolean>> {
    return this.post<boolean>('api/remove-product-working-time-slot', JSON.stringify(timeSlot));
  }
  public async deleteProductWorkingDate(
    productWorkingDate: ProductWorkingDate,
  ): Promise<ApiResult<ApiInterfaces.GetProductWorkingDateResponse>> {
    return this.post<ApiInterfaces.GetProductWorkingDateResponse>(
      'api/delete-product-working-date',
      JSON.stringify(productWorkingDate),
    );
  }
  public async getProductWorkingDate(id: string): Promise<ApiResult<ProductWorkingDate>> {
    return this.get<ProductWorkingDate>(`api/product-working-date/${id}`);
  }
  public async getProductCustomerTypes(productId: string): Promise<ApiResult<ProductCustomerType[]>> {
    return this.get<ProductCustomerType[]>(`api/pricetypes/${productId}`);
  }
  public async removeOrRestoreProductPrice(prodId: string, id: number): Promise<ApiResult<ProductPrice>> {
    return this.post<ProductPrice>(
      'api/remove-or-restore-price-list/',
      JSON.stringify({ productId: prodId, priceListId: id }),
    );
  }
  public async saveProductPrice(productPrice: ProductPrice): Promise<ApiResult<ApiInterfaces.GetProductPriceResponse>> {
    return this.post<ApiInterfaces.GetProductPriceResponse>('api/product-price', JSON.stringify(productPrice));
  }
  public async removeCustomer(customerId: number): Promise<ApiResult<boolean>> {
    return this.delete<boolean>(`api/product/deleteCustomerType/`, {
      params: { customerId: customerId },
    });
  }
  public async updateOrCreateCustomerType(newCustomer: ProductCustomerType): Promise<ApiResult<ProductCustomerType>> {
    return this.post<ProductCustomerType>('api/product/updateCustomerType', JSON.stringify(newCustomer));
  }
  public async getUserData(id: number): Promise<ApiResult<ApiInterfaces.getUserDataResponse>> {
    return this.get<ApiInterfaces.getUserDataResponse>(`api/provider/user/get`, { params: { id } });
  }
  public async saveUser(user: ProviderUsers): Promise<ApiResult<ApiInterfaces.saveUserResponse>> {
    return this.post<ApiInterfaces.saveUserResponse>('api/provider/user/save', JSON.stringify({ user }));
  }
  public async getUsersByFilter(
    searchText: string,
    providerId: number,
  ): Promise<ApiResult<ApiInterfaces.getUsersByFilterResponse>> {
    return this.post<ApiInterfaces.getUsersByFilterResponse>(
      'api/provider/users/search',
      JSON.stringify({ searchText, providerId }),
    );
  }
  public async exportUsers(filterText: string, providerId: number): Promise<ApiResult<ApiInterfaces.TaskResponse>> {
    return this.post<ApiInterfaces.TaskResponse>('api/export/users', {
      filterText,
      providerId,
    });
  }
  public async getProviderProfiles(providerId: number): Promise<ApiResult<IdName[]>> {
    return this.get<IdName[]>('api/provider/profiles', {
      params: { providerId },
    });
  }
  public async getUserProfileData(id: number, providerId: number): Promise<ApiResult<ProviderProfile>> {
    return this.get<ProviderProfile>('api/provider/profile/get', {
      params: { profileId: id, providerId },
    });
  }
  public async saveUserProfileData(profile: ProviderProfile): Promise<ApiResult<ProviderProfile>> {
    return this.post<ProviderProfile>('api/provider/profile/save', { profile });
  }
  public async getProviderProfilesData(providerId: number, searchText: string): Promise<ApiResult<ProviderProfile[]>> {
    return this.get<ProviderProfile[]>('api/provider/profiles/get', {
      params: { providerId, searchText },
    });
  }
  public async saveRegions(productId: string, regionsId: string[]): Promise<ApiResult<ChangeProductRegionsResponse>> {
    return this.post<ChangeProductRegionsResponse>('api/product-regions', {
      ProductId: productId,
      ProductRegions: regionsId,
    });
  }
  public async saveTags(
    productId: string,
    regionsId: string[],
    tagType: string,
  ): Promise<ApiResult<ChangeProductTagsResponse>> {
    return this.post<ChangeProductTagsResponse>('api/product-tags', {
      ProductId: productId,
      ProductTagsId: regionsId,
      ProductTagsType: tagType,
    });
  }
  public async getBoProfileData(id: number): Promise<ApiResult<BoProfile>> {
    return this.get<BoProfile>('api/settings/profile/get', { params: { id } });
  }
  public async saveBoProfileData(profile: BoProfile): Promise<ApiResult<BoProfile>> {
    return this.post<BoProfile>('api/settings/profile/save', { profile });
  }
  public async getBoProfiles(): Promise<ApiResult<IdName[]>> {
    return this.get<IdName[]>('api/settings/bo-profiles/get');
  }
  public async saveBoUser(user: SettingUsers): Promise<ApiResult<ApiInterfaces.saveBoUserResponse>> {
    return this.post<ApiInterfaces.saveBoUserResponse>('api/settings/bo-user/save', JSON.stringify({ user }));
  }
  public async getBoUserData(id: number): Promise<ApiResult<ApiInterfaces.getBoUserDataResponse>> {
    return this.get<ApiInterfaces.getBoUserDataResponse>(`api/settings/bo-user/get`, { params: { id } });
  }
  public async getBoUsersByFilter(searchText: string): Promise<ApiResult<ApiInterfaces.getBoUsersByFilterResponse>> {
    return this.post<ApiInterfaces.getBoUsersByFilterResponse>(
      'api/settings/bo-users/search',
      JSON.stringify({ searchText }),
    );
  }
  public async getBoProfilesData(searchText: string): Promise<ApiResult<ApiInterfaces.getBoProfilesByFilterResponse>> {
    return this.post<ApiInterfaces.getBoProfilesByFilterResponse>(
      'api/settings/bo-profiles/search',
      JSON.stringify({ searchText }),
    );
  }
  public async changeProductAllowMultiplePriceLists(
    productRequest: ApiInterfaces.ProductAllowMultiplePriceListsRequest,
  ): Promise<ApiResult<boolean>> {
    return this.post<boolean>('api/product/change-multiple-pricelists-status', JSON.stringify(productRequest));
  }
  public async changeProductAllowAssignEmployees(productId: number): Promise<ApiResult<boolean>> {
    return this.post<boolean>(`api/product/change-assign-employees/${productId}`);
  }
  public async changeProductIsRegistrationRequired(
    productRequest: ApiInterfaces.ProductIsRegistrationRequiredRequest,
  ): Promise<ApiResult<boolean>> {
    return this.post<boolean>('api/product/change-registration-required', JSON.stringify(productRequest));
  }
  public async getProductsAlert(): Promise<ApiResult<boolean>> {
    return this.get<boolean>('api/product/alert');
  }
  public async getRegistrationAwaitingList(
    pageIndex: number,
  ): Promise<ApiResult<ApiInterfaces.GetRegistrationAwaitingListResponse>> {
    return this.get<ApiInterfaces.GetRegistrationAwaitingListResponse>('api/registration-awaiting/list/get', {
      params: { pageIndex },
    });
  }
  public async changeAllowOrdersOnRequest(
    productId: string,
    canChangeOrderConfirmation: boolean,
    changeProductPricesOnRequest = false,
  ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
    return this.post<ApiInterfaces.ApiResponseBase>(
      'api/product/changeProductAllowOrdersOnRequest',
      JSON.stringify({
        productId,
        canChangeOrderConfirmation,
        changeProductPricesOnRequest,
      }),
    );
  }
  public async SetOrderStatus(
    id: string,
    status: string,
    confirmed: boolean,
  ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
    return this.get<ApiInterfaces.ApiResponseBase>('api/order/edit/status/' + status, { params: { id, confirmed } });
  }
  public async GetOrderRemarks(orderId: string): Promise<ApiResult<ApiInterfaces.GetOrderRemarksResponse>> {
    return this.get<ApiInterfaces.GetOrderRemarksResponse>('api/order/get/remarks', { params: { id: orderId } });
  }
  public async SaveOrderRemarks(
    orderId: string,
    remarks: string,
  ): Promise<ApiResult<ApiInterfaces.SaveOrderRemarksResponse>> {
    return this.post<ApiInterfaces.SaveOrderRemarksResponse>(
      'api/order/save/remarks',
      JSON.stringify({ orderId: orderId, remarks: remarks }),
    );
  }
  public async qtyReportGet(filter: OrdersFilter): Promise<ApiResult<ApiInterfaces.QtyReportGetResponse>> {
    return this.post<ApiInterfaces.QtyReportGetResponse>('api/orders/qty-report', JSON.stringify({ filter }));
  }
  public async saveupdateProductResponsiblePersonDescriptionTranslation(
    productId: string,
    Translations: ProductTranslationItem[],
  ) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/saveResponsiblePersonDescriptionTranlation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async saveupdateProductPolicyTranslation(productId: string, Translations: ProductTranslationItem[]) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/saveProductPolicyTranlation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async saveupdateProductExtraInfoTranslation(productId: string, Translations: ProductTranslationItem[]) {
    return this.post<ProductTranslation[]>(
      `api/product/${productId}/saveProductExtraInfoTranlation`,
      JSON.stringify({ productId, Translations }),
    );
  }
  public async GetProductFAQItem(productId: string, faqId: string) {
    return this.get<ProductFAQ>('api/product/faq/get', {
      params: { productId, faqId },
    });
  }

  public async GetProductInfoItem(productId: string, infoId: string) {
    return this.get<ProductMoreInfo>('api/product/moreinfo/get', {
      params: { productId, infoId },
    });
  }

  public async SaveFAQTranslations(
    productId: string,
    faqId: string,
    translations: Translation[],
  ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
    return this.post<ApiInterfaces.ApiResponseBase>('api/product/faq-translations/save', {
      productId,
      faqId,
      translations,
    });
  }

  public async SaveMoreInfoTranslations(
    productId: string,
    infoId: string,
    translations: Translation[],
  ): Promise<ApiResult<ApiInterfaces.ApiResponseBase>> {
    return this.post<ApiInterfaces.ApiResponseBase>('api/product/info-translations/save', {
      productId,
      infoId,
      translations,
    });
  }

  public async addStation(providerId: number) {
    return this.post<СashierStation>('api/provider/add-station', { providerId });
  }

  public async deleteStation(stationId: number) {
    return this.delete<СashierStation>('api/provider/delete-station', {params: {
      stationId
    }});
  }

  public async changeSellingSetting(
    productId: string,
    newValue: boolean,
    field: string,
  ): Promise<ApiResult<ApiInterfaces.ChangeSellingSettingResponse>> {
    return this.post<ApiInterfaces.ChangeSellingSettingResponse>(
      'api/product/changeSellingSetting/' + field,
      JSON.stringify({ productId, newValue }),
    );
  }
  public async getProviderProducts(providerId: number): Promise<ApiResult<IdText[]>> {
    return this.get<IdText[]>('api/provider/products', {
      params: { providerId },
    });
  }

  public async GetPromotedInfo(request: ApiInterfaces.FilterPromoRequest) {
    return this.post<ApiInterfaces.PromotedInfoResponse>('api/promoted-info', request);
  }

  public async GetPromotedProduct(id: number) {
    return this.get<PromotedProduct>('api/promoted-product', { params: { id } });
  }

  public async DeletePromotedProduct(id: number) {
    return this.delete<PromotedProduct>('api/promoted-product', { params: { id } });
  }

  public async SavePromotedProduct(product: PromotedProduct) {
    return this.post<PromotedProduct>('api/promoted-product', product);
  }

  public async GetPromotedProductsCount(id: number) {
    return this.get<number>('api/promoted-products-count', { params: { id } });
  }

  public async OrderPromotedProducts(products: PromotedProduct[]) {
    return this.post<boolean>('api/order-promoted-products', products);
  }
  public async OrderPromotedCategories(categories: PromotedCategory[]) {
    return this.post<boolean>('api/order-promoted-categories', categories);
  }
  public async GetPromotedCategory(id: number) {
    return this.get<PromotedCategory>('api/promoted-category', { params: { id } });
  }

  public async DeletePromotedCategory(id: number) {
    return this.delete<PromotedCategory>('api/promoted-category', { params: { id } });
  }

  public async SavePromotedCategory(product: PromotedCategory) {
    return this.post<PromotedCategory>('api/promoted-category', product);
  }

  public async GetAgencyDetails(id: number): Promise<ApiResult<ApiInterfaces.GetAgencyResponse>> {
    return this.get<ApiInterfaces.GetAgencyResponse>('api/agencies/get', { params: { id } });
  }

  public async SaveAgencyDetails(agency: AgencyDetails): Promise<ApiResult<ApiInterfaces.SaveAgencyResponse>> {
    return this.post<ApiInterfaces.SaveAgencyResponse>('api/agencies/save', { agency });
  }

  public async GetAgencyUserData(id: number): Promise<ApiResult<ApiInterfaces.GetAgencyUserDataResponse>> {
    return this.get<ApiInterfaces.GetAgencyUserDataResponse>(`api/agency/user/get`, { params: { id } });
  }
  public async SaveAgencyUser(user: AgencyUser): Promise<ApiResult<ApiInterfaces.saveUserResponse>> {
    return this.post<ApiInterfaces.saveUserResponse>('api/agency/user/save', JSON.stringify({ user }));
  }
  public async SearchAgencyUsers(
    searchText: string,
    agencyId: number,
  ): Promise<ApiResult<ApiInterfaces.SearchAgencyUsersResponse>> {
    return this.post<ApiInterfaces.SearchAgencyUsersResponse>(
      'api/agency/users/search',
      JSON.stringify({ searchText, agencyId }),
    );
  }
  public async DisactivateOrActivateProvider(providerId: number): Promise<ApiResult<boolean>> {
    return this.delete<boolean>(`api/provider/activate-deactivate-provider`, { params: { providerId } });
  }
  public async GetProductImg(productId: number): Promise<ApiResult<ProductImage>> {
    return this.get<ProductImage>(`api/product/product-covered-img`, { params: { productId } });
  }

  public async GetIndexStatistics(filter: { productId: string | null; fromDate: string | null; toDate: string | null }): Promise<ApiResult<ApiInterfaces.IndexStatisticsResult>> {
    return this.get<ApiInterfaces.IndexStatisticsResult>('api/index-statistics', { params: filter });
  }

  public async GetChatsAndQuotes(filter: ChatFilter): Promise<ApiResult<ApiInterfaces.GetChatsAndQuotesResponse>> {
    return this.post<ApiInterfaces.GetChatsAndQuotesResponse>('api/chat-messages', JSON.stringify(filter));
  }

  public async MarkChatAsRead(productId: string, orderQuoteId: number, indexUserId: string, providerId: string): Promise<ApiResult<boolean>> {
    return this.post<boolean>('api/chat/mark-as-read', { productId, orderQuoteId, indexUserId, providerId });
  }

  public async ProductChatMessages(
      productId: string,
      indexUserId: string,
      orderQuoteId: number,
      fromId: string | undefined
  ): Promise<ApiResult<ChatMessage[]>> {
    return this.post<ChatMessage[]>('api/product-chat-messages', { productId, indexUserId, orderQuoteId, fromId });
  }

  public async GetAgentDetails(id: string): Promise<ApiResult<AgentDetails>> {
    return this.get<AgentDetails>('api/agent-details/' + id);
  }

  public async GetAllFilterData(): Promise<ApiResult<FilterData>> {
    return this.get<FilterData>('api/all-filter-data');
  }

  public async changeAutoPrintReceiptProviderSetting(
    autoPrintReceipt: boolean, providerId: number
): Promise<ApiResult<ApiInterfaces.AutoPrintReceiptProviderSettingResponse>> {
    return this.post<ApiInterfaces.AutoPrintReceiptProviderSettingResponse>(
        'api/provider/autoPrintReceipt',
        JSON.stringify({ autoPrintReceipt, providerId })
    );
}
}

const api = new Api();

export default api;
