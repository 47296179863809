
import api from '@/services/Api';
import swal from 'sweetalert2';
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import Q from 'q';

@Options({
  name: 'EditAgencyUser',
  props: {
    id: {
      type: Number,
      required: true,
    },
    agencyId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    userNameTitle() {
      if (this.id && this.id > 0) {
        return this.user.userName;
      }
      if (this.user.userName) {
        return this.user.userName;
      }
      return 'New User';
    },
    passwordIsValid() {
      if (!this.user.password) {
        return false;
      }
      if (this.user.password.length < 8) {
        return false;
      }
      if (!/[a-z]/.test(this.user.password)) {
        return false;
      }
      if (!/[A-Z]/.test(this.user.password)) {
        return false;
      }
      if (!/\d/.test(this.user.password)) {
        return false;
      }
      return true;
    },
  },
})
export default class EditAgencyUser extends Vue {
  protected readonly id!: number;
  protected readonly agencyId!: number;
  protected readonly passwordIsValid!: boolean;
  public user: AgencyUser = {
    id: 0,
    active: true,
    fullName: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    userName: '',
    password: '',
    createdAt: null,
    agencyId: this.agencyId,
  };
  public passwordFieldType = 'password';
  public profiles: IdName[] = [];
  public wrongEmail = false;
  public wrongMobile = false;
  public wrongUsername = false;
  public usernameExist = false;
  public emailExist = false;
  public saveWas = false;
  public wrongProfile = false;

  getCreateDateFormat(date: string) {
    var stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local().format('DD/MM/YYYY HH:mm');
  }

  async mounted() {
    if (this.id && this.id > 0) {
      swal.showLoading();
      const apiResult = await api.GetAgencyUserData(this.id);
      if (apiResult.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
        return;
      }
      swal.close();
      if (apiResult.data) {
        this.user = apiResult.data.user;
      }
    }
  }
  cancel() {
    this.$router.go(-1);
  }
  async save() {
    this.saveWas = true;
    var wasError = false;
    if (!this.user.firstName) {
      wasError = true;
    }
    if (!this.user.lastName) {
      wasError = true;
    }
    if (!this.user.userName) {
      this.wrongUsername = true;
      wasError = true;
    }
    if (!this.passwordIsValid) {
      wasError = true;
    }
    if (!this.user.email) {
      this.wrongEmail = true;
      wasError = true;
    }
    if (wasError) {
      return;
    }
    swal.showLoading();
    this.wrongProfile = false;
    this.wrongEmail = false;
    this.wrongMobile = false;
    this.wrongUsername = false;
    this.usernameExist = false;
    this.emailExist = false;
    const apiResult = await api.SaveAgencyUser(this.user);
    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
      return;
    }
    if (apiResult.data?.validationErrors && apiResult.data?.validationErrors.length > 0) {
      var errorList: string[] = [];
      apiResult.data?.validationErrors.forEach((element) => {
        switch (element) {
          case 'email':
            this.wrongEmail = true;
            errorList.push(this.$t('wrong.incorrect-email'));
            break;
          case 'mobile':
            this.wrongMobile = true;
            errorList.push(this.$t('wrong.incorrect-mobile'));
            break;
          case 'username':
            this.usernameExist = true;
            errorList.push(this.$t('wrong.incorrect-username'));
            break;
          case 'emailExist':
            this.emailExist = true;
            errorList.push(this.$t('wrong.email-exist'));
            break;
        }
      });
      swal.fire({ icon: 'error', title: 'Oops...', html: errorList.join('<br/>') });
      return;
    }
    swal.fire({
      position: 'center',
      text: this.id > 0 ? this.$t('confirm.user-updated') : this.$t('confirm.user-created'),
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
    this.cancel();
  }
  async toggleActivation(evt: Event) {
    if (this.user.active) {
      evt.preventDefault();
      const swalResult = await swal.fire({
        icon: "question",
        text: this.$t("agencies.agency.toggleActivationTitle"),
        customClass: {
        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no'),
      });
      if (swalResult.isConfirmed) {
        this.user.active = false;
      }
    } else {
      this.user.active = true;
    }
  }
  checkLength(descriptionType: string) {
    if (descriptionType == 'fname') {
      if (this.user.firstName.length > 30) {
        this.user.firstName = this.user.firstName.substring(0, 30);
      }
    }
    if (descriptionType == 'lname') {
      if (this.user.lastName.length > 30) {
        this.user.lastName = this.user.lastName.substring(0, 30);
      }
    }
  }
  additionalValidation() {
    this.wrongMobile = false;
    this.user.mobile = this.user.mobile.replace(/ /g, '');
    this.user.mobile = this.user.mobile.replace(/-/g, '');
  }
}
