
/* eslint-disable no-undef */
import api from '@/services/Api';
import { defineComponent, ref } from 'vue';
import router from '@/router';
import generalStore from '@/store';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { changeLanguage } from '@/services/i18n-utils';

export default defineComponent({
  computed: {
    currentPagename() {
      return this.$route.name;
    },
  },
  name: 'NavBar',
  async setup() {
    const isExpandMenu = ref(false);
    const isExpandIndexMenu = ref(false);
    const isMobile = window.innerWidth < 991;
    const { locale } = useI18n();
    const scrolled = ref(false);
    const logout = async () => {
      const response = await api.logout();
      if (response.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
      } else {
        generalStore.commit('logout');
        router.push('/login');
      }
    };

    async function changeLang(lang: string): Promise<void> {
      isExpandMenu.value = false;
      changeLanguage(lang);
      locale.value = lang;
    }
    const reloadCurrentPage = (route: string) => {
      if (router.currentRoute.value.name == route) {
          router.go(0);
          return;
      }
    };

    return {
      reloadCurrentPage,
      scrolled,
      isExpandMenu,
      isExpandIndexMenu,
      isMobile,
      logout,
      changeLang,
      generalStore,
      locale
    };
  },
});
