
import api from '@/services/Api';
import { defineComponent, ref, computed } from 'vue';
import swal from 'sweetalert2';
import Q from 'q';
import generalStore from '@/store';
import * as ApiInterfaces from '@/models/ApiInterfaces';
import { useI18n } from 'vue-i18n';
import router from '@/router';

export default defineComponent({
  name: 'EditTag',
  props: {
    tagId: {
      type: String,
      required: true,
    },
  },
  components: {},
  async setup(props: any) {
    const isProductType = computed<boolean>(() => props.tagId == -1);
    const filterText = ref('');
    const { t } = useI18n();
    const toEditItem = ref<SubTag | null>();
    const tagItem = ref<Tag>();
    const langs = generalStore.state.languages;
    const currentLangName = generalStore.state.lang;
    const currentLang = langs.find((x) => x.name == currentLangName);
    const mainEnLang = langs.find((x) => x.name == 'en-US');

    async function getTagDate() {
      const getTagResponse =
        props.tagId.toString() == '-1' ? await api.getSettingsProductTypes(props.tagId) : await api.getTag(props.tagId);
      if (getTagResponse.data) {
        tagItem.value = getTagResponse.data.tag;
        if (!currentLang) {
          return;
        }
        const mainTagLang = tagItem.value.translations.find((x) => x.languageId == currentLang.id);
        if (mainTagLang) {
          tagItem.value.nameToDisplay = mainTagLang.name;
        } else {
          var mainTagLangEn = tagItem.value.translations.find((x) => x.languageId == mainEnLang?.id);
          if (mainTagLangEn) {
            tagItem.value.nameToDisplay = mainTagLangEn.name;
          } else {
            tagItem.value.nameToDisplay = tagItem.value.translations[0].name;
          }
        }

        tagItem.value.subTags.forEach((subTag) => {
          if (!subTag.translations.length) {
            subTag.mainLang = {
              id: 0,
              name: subTag.name,
              entityId: props.tagId,
              languageId: currentLang.id,
            };
            return;
          }

          var lang = subTag.translations.find((x) => x.languageId == currentLang.id);
          if (lang) {
            subTag.mainLang = lang;
            return;
          }

          lang = subTag.translations.find((x) => x.languageId == mainEnLang?.id);
          if (lang) {
            subTag.mainLang = lang;
            return;
          }

          var anyLang = subTag.translations[0];
          subTag.mainLang = anyLang;
        });
      }
    }

    await getTagDate();

    function clearEmptySubTags() {
      if (tagItem.value) {
        tagItem.value.subTags = tagItem.value.subTags.filter((x) => x.id);
      }
    }

    function editSubTag(tag: SubTag) {
      clearEmptySubTags();
      toEditItem.value = JSON.parse(JSON.stringify(tag));
    }
    async function deleteEntity(id: number) {
      if (isProductType.value) {
        await deleteProductType(id);
      } else {
        await deleteSubTag(id);
      }
    }

    async function deleteProductType(id: number) {
      swal.showLoading();
      const productTypeIsUsedPromise = api.productTypeIsUsed(id);
      await Q.delay(400);
      const tagIsUsedResponse = await productTypeIsUsedPromise;
      if (tagIsUsedResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: tagIsUsedResponse.errorMessage });
        return;
      }

      if (!tagIsUsedResponse.data) {
        return;
      }

      const productTypeUsed = tagIsUsedResponse.data.used;

      const swalResult = await swal.fire({
        icon: 'info',
        title: t('alert.confirm-remove-this-product-type'),
        text: productTypeUsed ? t('alert.product-type-used') : '',
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('alert.yesDeleteIt'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalResult.isConfirmed) {
        return;
      }
      if (!tagItem.value) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const deleteProductTypePromise = api.deleteProductType(id);
      await Q.delay(400);
      const deleteProductTypeResponse = await deleteProductTypePromise;
      if (deleteProductTypeResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: deleteProductTypeResponse.errorMessage });
        return;
      }

      if (!deleteProductTypeResponse.data || !deleteProductTypeResponse.data.deleted) {
        return;
      }

      var indexToDelete = tagItem.value.subTags.findIndex((x) => x.id == id);
      if (indexToDelete >= 0) {
        tagItem.value.subTags.splice(indexToDelete, 1);
      }
      swal.close();
    }

    async function deleteSubTag(tagId: number) {
      swal.showLoading();
      const tagIsUsedResponsePromise = api.tagIsUsed(tagId);
      await Q.delay(400);
      const tagIsUsedResponse = await tagIsUsedResponsePromise;
      if (tagIsUsedResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: tagIsUsedResponse.errorMessage });
        return;
      }

      if (!tagIsUsedResponse.data) {
        return;
      }

      const tagUsed = tagIsUsedResponse.data.used;

      const swalResult = await swal.fire({
        icon: 'info',
        title: t('alert.confirm-remove-this-tag'),
        text: tagUsed ? t('alert.tag-used') : '',
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('alert.yesDeleteIt'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalResult.isConfirmed) {
        return;
      }
      if (!tagItem.value) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const deleteSubTagResponsePromise = api.deleteSubTag(tagId);
      await Q.delay(400);
      const deleteSubTagResponse = await deleteSubTagResponsePromise;
      if (deleteSubTagResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: deleteSubTagResponse.errorMessage });
        return;
      }

      if (!deleteSubTagResponse.data || !deleteSubTagResponse.data.deleted) {
        return;
      }

      var indexToDelete = tagItem.value.subTags.findIndex((x) => x.id == tagId);
      if (indexToDelete >= 0) {
        tagItem.value.subTags.splice(indexToDelete, 1);
      }
      swal.close();
    }

    function selTranslateFromMain(tag: SubTag) {
      var lang = tag.translations.find((x) => x.languageId == tag.mainLang.languageId);
      if (lang && tag.mainLang) {
        lang.name = tag.mainLang.name;
      }
    }

    async function applyChanges() {
      if (!tagItem.value) {
        return;
      }
      if (!toEditItem.value || !toEditItem.value.mainLang) {
        return;
      }
      if (!currentLang) {
        return;
      }

      var index = tagItem.value.subTags.findIndex((x) => x.id == toEditItem.value?.id);
      if (index < 0) {
        return;
      }

      swal.showLoading();
      let request: ApiInterfaces.SaveTagRequest | null = null;

      if (toEditItem.value.id) {
        if (toEditItem.value.translations.length && currentLang.id == toEditItem.value.mainLang.languageId) {
          selTranslateFromMain(toEditItem.value);
        } else {
          toEditItem.value.translations.push({
            id: 0,
            entityId: props.tagId,
            languageId: currentLang.id || 0,
            name: toEditItem.value.mainLang.name || '',
          });
        }

        request = {
          tagId: toEditItem.value.id,
          tagName: toEditItem.value.mainLang.name || '',
          translations: toEditItem.value.translations,
          parentTagId: Number(props.tagId),
          tagType: isProductType.value ? 'productType' : 'subtag',
        };
        const saveTagResponse = isProductType.value ? await api.saveProductType(request) : await api.saveTag(request);
        if (saveTagResponse.errorMessage) {
          swal.fire({ icon: 'error', title: 'Oops...', text: saveTagResponse.errorMessage });
          return;
        }
        if (saveTagResponse.data && saveTagResponse.data.subTag) {
          var editIndex = tagItem.value.subTags.findIndex((x) => x.id == toEditItem.value?.id);
          if (editIndex >= 0) {
            let subTagToChange: SubTag = JSON.parse(JSON.stringify(saveTagResponse.data.subTag));
            const mainLang = subTagToChange.translations.find((x) => x.languageId == currentLang?.id);
            if (mainLang) {
              subTagToChange.mainLang = mainLang;
            }
            tagItem.value.subTags[index] = subTagToChange;
          }
        }
      } else {
        //Add New
        selTranslateFromMain(toEditItem.value);
        request = {
          tagId: toEditItem.value.id,
          tagName: toEditItem.value.mainLang.name || '',
          translations: toEditItem.value.translations,
          parentTagId: Number(props.tagId),
          tagType: isProductType.value ? 'productType' : 'subtag',
        };
        const saveTagResponse = isProductType.value ? await api.saveProductType(request) : await api.saveTag(request);
        if (saveTagResponse.errorMessage) {
          swal.fire({ icon: 'error', title: 'Oops...', text: saveTagResponse.errorMessage });
          return;
        }

        if (saveTagResponse.data && saveTagResponse.data.subTag) {
          tagItem.value.subTags = tagItem.value.subTags.filter((x) => x.id);
          var itemToPush: SubTag = JSON.parse(JSON.stringify(saveTagResponse.data.subTag));
          var mainLang = itemToPush.translations.find((x) => x.languageId == currentLang?.id);
          itemToPush.mainLang = JSON.parse(JSON.stringify(mainLang));
          tagItem.value.subTags.unshift(itemToPush);
        }
      }
      swal.close();
      toEditItem.value = null;
    }

    function rejectChanges(itemInxed: number) {
      if (tagItem.value && toEditItem.value && toEditItem.value.id == 0) {
        tagItem.value.subTags.splice(itemInxed, 1);
      }
      toEditItem.value = null;
    }
    function addSubTag() {
      const tag: SubTag = {
        id: 0,
        name: '',
        parentTagId: props.tagId,
        mainLang: {
          name: '',
          languageId: currentLang?.id || 0,
          id: 0,
          entityId: props.tagId,
        },
        translations: [
          {
            name: '',
            languageId: currentLang?.id || 0,
            id: 0,
            entityId: props.tagId,
          },
        ],
      };
      tagItem.value?.subTags.unshift(JSON.parse(JSON.stringify(tag)));
      toEditItem.value = tag;
    }

    function close() {
      router.push({ name: 'settings' });
    }

    function compare(a: SubTag, b: SubTag) {
      if (a.mainLang.name < b.mainLang.name) return -1;
      if (a.mainLang.name > b.mainLang.name) return 1;
      return 0;
    }

    const subTagsItems = computed<SubTag[]>(() => {
      let result: SubTag[] = [];
      if (!tagItem.value) {
        return result;
      }
      const sortItems = tagItem.value.subTags;

      if (!filterText.value || filterText.value.length < 2) {
        result = sortItems.sort(compare);
      } else {
        result = sortItems
          .filter((x) => x.mainLang.name.toLowerCase().includes(filterText.value.toLowerCase()) || x.id == 0 || x.id == toEditItem.value?.id )
          .sort(compare);
      }

      if (props.tagId == 2) {
        //id 2 is Languages tag;
        var hebrewGuidanceIndex = result.findIndex((x) => x.id == 10); //id 10 is Hebrew Guidance subtag;
        if (hebrewGuidanceIndex >= 0) {
          const toPush = JSON.parse(JSON.stringify(result[hebrewGuidanceIndex]));
          result.splice(hebrewGuidanceIndex, 1);
          result.unshift(toPush);
        }
      }
      return result;
    });

    function editTagLang() {
      if (isProductType.value) {
        return;
      }
      router.push({ name: 'editTagLang', params: { tagId: props.tagId, tagType: 'tag' } });
    }

    function editSubTagLang(id: number) {
      router.push({
        name: 'editTagLang',
        params: { tagId: id, tagType: isProductType.value ? 'productType' : 'subtag' },
      });
    }

    return {
      tagItem,
      editSubTag,
      deleteEntity,
      toEditItem,
      applyChanges,
      rejectChanges,
      addSubTag,
      currentLang,
      close,
      subTagsItems,
      filterText,
      editTagLang,
      editSubTagLang,
      isProductType,
    };
  },
});
