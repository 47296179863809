
import api from '@/services/Api';
import swal from 'sweetalert2';
import Calendar from 'primevue/calendar';
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import { mapState, mapGetters } from 'vuex';
import { Currency } from '@/models/Order';
import moment from 'moment';
import * as ApiInterfaces from '@/models/ApiInterfaces';
import Q from 'q';

@Options({
  name: 'EditCurrencyRate',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    Multiselect,
    Calendar,
  },
  computed: {
    ...mapGetters({ currenciesWithoutLocal: 'currenciesWithoutLocal' }),
  },
})
export default class EditCurrencyRate extends Vue {
  protected readonly currenciesWithoutLocal!: Currency[];
  protected readonly id!: number;
  public rate: CurrencyRate = { id: 0, currencyId: 0, rate: '', startDate: '', createDate: '' };
  public startDate: Date | null = null;

  async mounted() {
    swal.showLoading();
    if (this.id && this.id > 0) {
      const apiResult = await api.getCurrencyRate(this.id);
      if (apiResult.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
      }
      if (apiResult.data) {
        this.rate = apiResult.data.item;
        this.startDate = new Date(apiResult.data.item.startDate);
      }
    }
    swal.close();
  }

  dateChanged() {
    this.rate.startDate = new Date(moment(this.startDate).utcOffset(0, true).format()).toUTCString();
  }

  async deleteRate() {
    const swalResult = await swal.fire({
      icon: 'info',
      title: this.$t('alert.confirm-remove-rate'),
      customClass: {
        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
      },
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: this.$t('alert.yesDeleteIt'),
      cancelButtonText: this.$t('button.cancel'),
    });
    if (!swalResult.isConfirmed) {
      return;
    }
    await Q.delay(200);
    swal.showLoading();
    const apiResult = await api.deleteCurrencyRate(this.id);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
    }
    if (apiResult.data && apiResult.data.deleted) {
      await swal.fire({
        title: this.$t('msg.currency-deleted'),
        position: 'center',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
      this.cancel();
    }
  }

  async saveRate() {
    swal.showLoading();
    const request: ApiInterfaces.SaveCurrencyRateRequest = {
      item: {
        id: this.rate.id,
        currencyId: this.rate.currencyId,
        rate: this.rate.rate,
        startDate: this.rate.startDate,
        createDate: null,
      },
    };
    const apiResult = await api.saveCurrencyRate(request);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
      return;
    }
    if (apiResult.data) {
      if (apiResult.data.validationError) {
        swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.data.validationError });
      } else {
        const title = this.rate.id ? this.$t('msg.currency-updates') : this.$t('msg.currency-created');
        await swal.fire({
          title: title,
          position: 'center',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
        this.cancel();
      }
    }
  }
  cancel() {
    this.$router.push({ name: 'settings' });
  }
}
