
import { Options, Vue } from 'vue-class-component';
import swal from 'sweetalert2';
import Q from 'q';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import { PromoFilter } from '@/models/Product';
import store from '@/store';
import router from '@/router';
import useExtensions from '@/useExtensions';
import { DateRange } from '@/models/Order';
import { PromotedType } from '@/models/Enums';

var { dateRangeValidate, formatDate } = useExtensions();

@Options({
  components: {
    Multiselect,
    Calendar,
  },
  computed: {
    filteredProducts() {
      return this.filter.providerId
        ? this.products.filter((p: IdNameParent) => p.parentId == this.filter.providerId)
        : this.products;
    },
  },
})
export default class PromoFilterModal extends Vue {
  public filter = new PromoFilter();
  public promoDateRange = new DateRange();
  public products: IdNameParent[] = [];
  public providers: IdName[] = [];

  async mounted() {
    const sloterFilter = store.state.promoFilter;
    if (sloterFilter.active != null) sloterFilter.active = +sloterFilter.active;
    this.filter = JSON.parse(JSON.stringify(sloterFilter));

    this.products = await store.dispatch('getProducts');
    this.providers = await store.dispatch('getProviders');

    this.promoDateRange = new DateRange();

    if (this.filter.fromDate) {
      this.promoDateRange.fromDate = new Date(this.filter.fromDate);
    }
    if (this.filter.toDate) {
      this.promoDateRange.toDate = new Date(this.filter.toDate);
    }

    await Q.delay(400);
    swal.close();
  }

  //functions
  public ResetFilter() {
    let newFilter = new PromoFilter();
    newFilter.type = this.filter.type;
    store.commit('savePromoFilter', newFilter);
    router.push({ name: 'PromoManagement', params: { type: PromotedType[this.filter.type ?? PromotedType.Carousel] } });
  }

  public Close() {
    router.push({ name: 'PromoManagement', params: { type: PromotedType[this.filter.type ?? PromotedType.Carousel] } });
  }

  public Search() {
    let val = this.promoDateRange.fromDate;
    this.filter.fromDate = val ? formatDate(val, 'api') : null;

    val = this.promoDateRange.toDate;
    this.filter.toDate = val ? formatDate(val, 'api') : null;

    store.commit('savePromoFilter', this.filter);
    router.push({ name: 'PromoManagement', params: { type: PromotedType[this.filter.type ?? PromotedType.Carousel] } });
  }

  public dateChanged(dateRange: DateRange) {
    if (!dateRange.fromDate || !dateRange.toDate) return;
    dateRangeValidate(dateRange);
  }

  public providerSelect = {
    value: 0,
    valueProp: 'id',
    trackBy: 'name',
    label: 'name',
    required: true,
    searchable: true,
    'close-on-select': true,
  };

  public productSelect = {
    value: 0,
    valueProp: 'id',
    trackBy: 'name',
    label: 'name',
    required: true,
    searchable: true,
    'close-on-select': true,
  };

  public providerChanged = async () => {
    this.filter.productId = null;
  };
}
