
import api from '@/services/Api';
import { defineComponent, ref } from 'vue';
import swal from 'sweetalert2';
import router from '@/router';
import { useRoute } from 'vue-router';
import { AgencyDetails } from '@/models/Agencies';
import AgencyDetailsComponent from '@/components/Index/AgencyDetailsComponent.vue';

export default defineComponent({
  components: {
    AgencyDetailsComponent,
  },
  name: 'ViewAgency',
  async setup() {
    const agency = ref<AgencyDetails>(new AgencyDetails());

    const route = useRoute();

    async function loadAgency() {
      var agencyId = (route.params.id && Number(route.params.id)) || null;
      if (!agencyId) {
        router.replace({ name: 'agencies' });
        return;
      }

      const response = (await api.GetAgencyDetails(agencyId))?.data;
      if (!response || response.error) {
        swal.fire({
          icon: 'error',
          text: response?.error || 'No data',
        });
      }
      agency.value = response?.agency || new AgencyDetails();
    }

    loadAgency();

    return {
      agency,
    };
  },
});
