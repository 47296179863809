
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import router from '@/router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';

@Options({
  props: {
    items: {
      type: [],
      required: true,
    },
  },
  name: 'BoUsers',
  components: {
    Multiselect,
  },
  data() {
    return {
      filteredUsers: [],
    };
  },
  computed: {
    showUsers() {
      if (this.ShowDefaultUsers) {
        return this.items;
      }
      return this.users;
    },
  },
})
export default class BoUsers extends Vue {
  public showMore = false;
  public users: SettingUsers[] = [];
  public ShowDefaultUsers = true;
  public searchText = '';
  editUser(userId: number) {
    router.push({ name: 'EditBoUser', params: { id: userId } });
  }
  async showMoreUsers() {
    await this.getUsersByFilter();
  }
  async getUsersByFilter() {
    this.ShowDefaultUsers = false;
    swal.showLoading();
    const apiResult = await api.getBoUsersByFilter(this.searchText);
    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
      return;
    }
    this.users = apiResult.data?.users || [];
    swal.close();
  }
}
