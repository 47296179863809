
import api from '@/services/Api';
import { CalendarSlot, PriceType, Product, ProductPrice, ProductRegion, ProductTags, TimeSlot } from '@/models/Product';
import { computed, defineComponent, ref } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import router from '@/router';
import Multiselect from '@vueform/multiselect';
import generalStore from '@/store';
import moment from 'moment';
import {
  WeekNames,
  DayOfWeekKendo,
  ProductInfoType,
  ProductRegistrationType,
  OrderBlockOption,
  OrderBlockTypes,
  PrintingType,
  ToOptions
} from '@/models/Enums';
import swal from 'sweetalert2';
import useProduct from '@/components/Product/useProduct';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { useI18n } from 'vue-i18n';
import { onMounted } from 'vue';
import Q from 'q';
import ProductSlotsTable from '@/components/Product/ProductSlotsTable.vue';
import { OrdersFilter } from '@/models/Order';
import _ from 'lodash';
import InputNumber from 'primevue/inputnumber';
import {} from 'google-maps';
import { Loader } from '@googlemaps/js-api-loader';

import ProductFAQs from '@/components/ProductFAQs.vue';
import ProductMoreInfo from '@/components/Product/ProductMoreInfo.vue';
import ProductSellingSettings from '@/components/Product/ProductSellingSettings.vue';
import NotificationsRestrictions from '@/components/Product/NotificationsRestrictions.vue';
import draggable from 'vuedraggable';

export default defineComponent({
  props: {
    currentTab: {
      type: String,
    },
  },
  components: {
    Multiselect,
    Cropper,
    ProductSlotsTable,
    InputNumber,
    ProductFAQs,
    ProductMoreInfo,
    ProductSellingSettings,
    draggable,
    NotificationsRestrictions,
  },
  name: 'Product',
  async setup(props: any) {
    let productWasSaved = false;
    const unchangedProduct = ref<Product>(new Product());
    const submitButtonRef = ref();
    const showDocumentsHelp = ref(false);
    const showproductActivityTimeDurationInfo = ref(false);
    const showExtraInfoAbout = ref(false);
    const showProductPhotosVideoInfo = ref(false);
    const showPage = ref(true);
    const showAllPrices = ref(false);
    const { t } = useI18n();
    const registrationTypes = ToOptions(ProductRegistrationType, 'registrationType.');
    const route = useRoute();
    const {
      getProduct,
      saveProduct,
      getAverageDuration,
      getOperationStatus,
      orderBlockTypesOpt,
      orderBlockOptionsOpt,
    } = useProduct();
    const id = ref(route.params.id.toString());
    const background = ref(false);
    const product = ref(new Product());
    const selectedOrderBlockOption = ref<string>('after');
    const drag = ref(false);
    const orderBlockTypes = orderBlockTypesOpt();
    const orderBlockOptions = orderBlockOptionsOpt();
    const routeDataTemp: { routeName: string; paramId: string } = {
      routeName: 'product',
      paramId: id.value,
    };
    const map = ref();
    const googleMap = ref();
    const marker = ref();
    const mapOptions = {
      center: { lat: generalStore.getters.countryLatitude, lng: generalStore.getters.countryLongitude }, // By default Israel coordinates
      zoom: 10,
    };
    const loader = new Loader({
      apiKey: generalStore.getters.googleMapsApiKey,
      version: 'weekly',
      language: generalStore.getters.currentLanguage,
      libraries: ['places'],
    });

    onBeforeRouteLeave(async (to, from, next) => {
      const items = document.getElementsByClassName('show');
      if (items.length) {
        generalStore.commit('saveProductLastOpenedBlock', items[0].id);
      } else {
        generalStore.commit('saveProductLastOpenedBlock', '');
      }

      switch (to.name) {
        case 'edit-product-desc':
        case 'modal-regions':
        case 'location-map':
        case 'modal-tags':
        case 'product-price-list':
        case 'product-photo':
          next();
          break;

        default: {
          if (productWasSaved || product.value.deletedAt != null) {
            next();
            break;
          }
          const areObjectEqual = _.isEqual(unchangedProduct.value, product.value);
          if (!areObjectEqual) {
            const swalResult = await swal.fire({
              icon: 'info',
              title: t('editProductPage.cancelEdit.alertTitle'),
              customClass: {
                confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
              },
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
              cancelButtonText: t('editProductPage.cancelEdit.alertCancel'),
            });
            if (swalResult.isConfirmed) {
              swal.fire({ willOpen: () => swal.showLoading() });
              routeDataTemp.routeName = to.name ? to.name.toString() : '';
              routeDataTemp.paramId = to.params.id ? to.params.id.toString() : '';
              next(false);
              submitButtonRef.value.click();
            } else generalStore.commit('setProduct', JSON.parse(JSON.stringify(unchangedProduct.value)) as Product);
          }
          next();
          break;
        }
      }
    });
    async function AccordionSettings() {
      const lastOpenedBlock: string = await generalStore.dispatch('getProductLastOpenedBlock');
      if (!lastOpenedBlock) {
        return;
      }
      const body = document.getElementById(lastOpenedBlock);
      if (!body) {
        return;
      }
      const bodyId = lastOpenedBlock.charAt(0).toUpperCase() + lastOpenedBlock.slice(1);
      const header = document.getElementById('heading' + bodyId);
      if (!header) {
        return;
      }
      header.setAttribute('data-toggle', 'collapse');
      header.setAttribute('aria-expanded', 'true');
      header.classList.remove('collapsed');
      body.classList.add('show');
    }
    async function onMountedAction() {
      await AccordionSettings();
      if (route.params.fromCatalog == 'yes') {
        showPage.value = false;
        const app = document.getElementById('main-content');
        if (app) {
          app?.scrollTo(0, 0);
        }
        await Q.delay(10);
        showPage.value = true;
      }

      const newId = route.params.id.toString();

      if (!product.value || !product.value.id) {
        const response = await api.getProduct(newId);
        if (response.data) {
          generalStore.commit('setProduct', response.data);
          product.value = response.data;
          swal.close();
        }
      }

      if (!product.value.id) {
        id.value = newId;
      }
      if (product.value.longitude !== 0 && product.value.latitude !== 0) {
        await loader.load();
        googleMap.value = new window.google.maps.Map(map.value, mapOptions);
        if (product.value.latitude && product.value.longitude) {
          const position = { lat: product.value.latitude, lng: product.value.longitude };
          marker.value = new window.google.maps.Marker({
            position: position,
            map: googleMap.value,
          });
          googleMap.value.setCenter(position);
          googleMap.value.setZoom(15);
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              googleMap.value.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
              googleMap.value.setZoom(15);
            },
            (error) => {
              console.log(error.message);
            },
          );
        }
      }
      ValidateMoreProductInfo();
      // this line should be last in this mounted action
      unchangedProduct.value = JSON.parse(JSON.stringify(product.value));
    }

    function ValidateMoreProductInfo() {
      if (!product.value.productInfos.find((x) => x.type == ProductInfoType.General)) {
        product.value.productInfos.push({
          id: 0,
          title: t('product-info.title.important'),
          description: '',
          type: ProductInfoType.General,
          translations: [],
        });
      }
      if (!product.value.productInfos.find((x) => x.type == ProductInfoType.Covid19)) {
        product.value.productInfos.push({
          id: 0,
          title: t('product-info.title.covid19'),
          description: '',
          type: ProductInfoType.Covid19,
          translations: [],
        });
      }
    }

    onMounted(onMountedAction);

    const currentPricing = computed<ProductPrice>(() => generalStore.getters.currentPricing);
    if (!generalStore.state.tags.length) await generalStore.dispatch('loadAllTags');
    const tags = generalStore.state.tags;

    if (!generalStore.state.productTypes.length) await generalStore.dispatch('loadAllProductTypes');
    const productTypes = generalStore.state.productTypes;

    if (!generalStore.state.regions.length) {
      await generalStore.dispatch('loadAllRegions');
    }
    const allRegions = generalStore.state.regions;

    if (!generalStore.state.cities.length) await generalStore.dispatch('loadAllCities');
    const cities = generalStore.state.cities;

    const weekNames = WeekNames;

    const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
    const priceTypes: IdOriginName[] = await generalStore.dispatch('getPriceTypes');

    const citySelect = {
      value: 0,
      placeholder: t('placeholder.selectCity'),
      valueProp: 'id',
      trackBy: 'name',
      label: 'name',
      required: true,
      searchable: true,
      options: cities,
    };
    const addressRegionSelect = {
      value: 0,
      placeholder: t('address.region'),
      valueProp: 'id',
      trackBy: 'name',
      label: 'name',
      required: true,
      searchable: true,
      options: allRegions,
    };
    const productAverageDuration = {
      value: 0,
      placeholder: t('placeholder.selectAverageActivityDuration'),
      valueProp: 'id',
      label: 'name',
      searchable: false,
      options: getAverageDuration(),
    };
    const productOperationStatus = {
      value: 0,
      placeholder: t('placeholder.operationStatus.title'),
      valueProp: 'id',
      label: 'name',
      searchable: false,
      options: getOperationStatus(),
      canDeselect: false,
    };
    function momentFormat(data: Date, formatType: string) {
      if (formatType === 'date') {
        return moment(data).format('DD/MM/YYYY');
      }
      if (formatType === 'datetime') {
        return moment(data, moment.ISO_8601).format('DD/MM/YYYY HH:mm');
      } else return moment(data, 'HH:mm:ss').format('HH:mm');
    }
    function findCurrencySymbol(prodPriceId: number) {
      const found = product.value.productPrices.find((pp) => pp.id === prodPriceId);
      return generalStore.getters.getCurrencySymbol(found?.currencyId);
    }
    function findPriceTypeName(prodPriceId: number) {
      let result;
      const found = product.value.productPrices.find(pp => pp.id === prodPriceId);
      if (priceTypes && found)
        priceTypes.forEach((priceType) => {
          if (priceType.id === found?.priceTypeId) {
            result = priceType.name;
          }
        });
      return result;
    }
    function callUploadingFunction(): void {
      document.getElementById('file-upload-input')?.click();
    }

    async function disactivateOrActivateProductPriceType(e: any, priceTypeId: string) {
      e.preventDefault();
      swal.showLoading();
      const apiPromise = api.ActivateOrDisactivateProductPriceType(product.value.id, priceTypeId);
      await Q.delay(400);
      const response = await apiPromise;
      if (response.errorMessage) {
        swal.fire({
          icon: 'error',
          text: response.errorMessage
        });
        return;
      }
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Product updated',
        showConfirmButton: false,
        timer: 1500
      });
      if (response.data) {
        product.value.availableProductPriceTypes.push(priceTypeId);
        unchangedProduct.value.availableProductPriceTypes.push(priceTypeId);
      } else {
        const index = product.value.availableProductPriceTypes.indexOf(priceTypeId);
        if (index >= 0) {
          product.value.availableProductPriceTypes.splice(index, 1);
          unchangedProduct.value.availableProductPriceTypes.splice(index, 1);
        }
      }
    }

    async function disactivateOrActivateProduct(evt: Event) {
      evt.preventDefault();
      const isActive = product.value.active;
      let isProductHasOrder = false;
      if (isActive) {
        const productId = parseInt(product.value.id);
        const apiResult = await api.productHasOrders(productId);
        if (apiResult.errorMessage) {
          swal.fire({
            icon: 'error',
            text: apiResult.errorMessage,
          });
          return;
        }
        if (apiResult.data != null && apiResult.data == true) {
          isProductHasOrder = true;
        }
      }
      let productActivateOrDisactivateMessage = isActive ? t('product.deactivate') : t('product.activate');
      if (isProductHasOrder) {
        productActivateOrDisactivateMessage = t('product.warning.hasOrders');
      }
      const swalAction = await swal.fire({
        icon: 'info',
        text: productActivateOrDisactivateMessage,
        customClass: {
          confirmButton: isActive
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: isActive ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      const response = await api.ActivateOrDisactivate(product.value.id);
      if (response.errorMessage) {
        swal.fire({
          icon: 'error',
          text: response.errorMessage,
        });
        return;
      }
      product.value.active = !product.value.active;
      unchangedProduct.value.active = product.value.active;
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Product updated',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    const imgSrc = ref('');
    const ticketImgSrc = ref('');
    const responsiblePersonImgSrc = ref('');
    const cropperLib = ref();
    const circleCropperLib = ref();
    const circleStencil = CircleStencil;
    const newSavedImage = ref('');

    function uploadFile(e: any, imageInputId: string): void {
      e.target.value = '';
      newSavedImage.value = '';
      document.getElementById(imageInputId)?.click();
    }

    function clearImage() {
      newSavedImage.value = '';
      imgSrc.value = '';
      responsiblePersonImgSrc.value = '';
    }
    function clearTicketImage() {
        newSavedImage.value = '';
        ticketImgSrc.value = '';
    }
    async function uploadingFileValidation(e: any): Promise<void> {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      swal.showLoading();
      const fileExt = file.name.split('.').pop();
      const fileTypes: string[] = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf'];

      if (!fileTypes.includes(fileExt)) {
        swal.fire({
          icon: 'error',
          text: 'Error file type',
        });
        file = null;
        return;
      }

      if (file.size > 5242880) {
        swal.fire({
          icon: 'error',
          text: 'File size should be less or equal to 5 MB',
        });
        file = null;
        return;
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const fd = new FormData();
      fd.append('file', file, file.name);

      const uploadResponse = await api.uploadDocument(fd, product.value.id);
      if (uploadResponse.data) {
        if (!product.value.productDocuments) {
          product.value.productDocuments = [];
        }
        product.value.productDocuments.push(uploadResponse.data);
      }

      swal.close();
    }
    function addYoutubeInput() {
      if (!product.value.productVideos) {
        product.value.productVideos = [];
      }
      product.value.productVideos.push({ id: 0, productId: id.value, path: '' });
    }
    function removeYouTubeLink(videoIndex: number) {
      if (product.value.productVideos) {
        product.value.productVideos.splice(videoIndex, 1);
      }
    }
    async function uploadingImageValidation(e: any, isResponsiblePersonImage: boolean) {
      swal.showLoading();
      let file = e.target.files[0];
      const fileTypes: string[] = ['image/jpeg', 'image/png'];
      if (file) {
        if (file.size > 5242880) {
          swal.fire({
            icon: 'error',
            text: 'File size should be less or equal to 5 MB',
          });
          file = null;
          return;
        }
        if (!fileTypes.find((t) => t === file.type)) {
          swal.fire({
            icon: 'error',
            text: 'Allowing file types: jpeg, jpg, png',
          });
          file = null;
          return;
        }
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            if (isResponsiblePersonImage) responsiblePersonImgSrc.value = event.target.result;
            else imgSrc.value = event.target.result;
          };
          reader.readAsDataURL(file);
        }
      }
      swal.close();
      e.target.value = '';
    }
    async function uploadingTicketImageValidation(e: any) {
        swal.showLoading();
        let file = e.target.files[0];
        const fileTypes: string[] = ['image/jpeg', 'image/png'];
        if (file) {
            if (file.size > 5242880) {
                swal.fire({
                    icon: 'error',
                    text: 'File size should be less or equal to 5 MB'
                });
                file = null;
                return;
            }
            if (!fileTypes.find(t => t === file.type)) {
                swal.fire({
                    icon: 'error',
                    text: 'Allowing file types: jpeg, jpg, png'
                });
                file = null;
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    ticketImgSrc.value = event.target.result;
                };
                reader.readAsDataURL(file);
            }
        }
        swal.close();
        e.target.value = '';
    }
    const saveImage = async () => {
      swal.showLoading();
      const { canvas } = cropperLib.value.getResult();
      if (canvas) {
        const fd = new FormData();
        canvas.toBlob(
          async (blob: any) => {
            fd.append('file', blob);
            const uploadResponse = await api.uploadFile(fd, product.value.id);
            if (uploadResponse.errorMessage)
              swal.fire({ icon: 'error', title: 'Oops...', text: uploadResponse.errorMessage });
            if (uploadResponse.data) {
              imgSrc.value = '';
              newSavedImage.value = uploadResponse.data.pathMedium;
              product.value.productImages?.push(uploadResponse.data);
              unchangedProduct.value.productImages = JSON.parse(JSON.stringify(product.value.productImages));
              swal.close();
            }
          },
          'image/jpeg',
          0.9,
        );
      }
    };
    const saveTicketImage = async () => {
        swal.showLoading();
        const { canvas } = cropperLib.value.getResult();
        if (canvas) {
            const fd = new FormData();
            canvas.toBlob(
                async (blob: any) => {
                    fd.append('file', blob);
                    const uploadResponse = await api.uploadTicketImage(fd, product.value.id);
                    if (uploadResponse.data && uploadResponse.data.errorCode == 802) {
                        await swal.fire({
                            icon: 'error',
                            title: t('error-pop-up.oops'),
                            text: t('edit-product.photos-videos.upload-image-error.small-resolution')
                        });
                        return;
                    }

                    if (uploadResponse.errorMessage) {
                        await swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: uploadResponse.errorMessage });
                        return;
                    }

                    if (uploadResponse.data?.image) {
                        const productTicketImage = uploadResponse.data.image;

                        ticketImgSrc.value = '';
                        newSavedImage.value = productTicketImage.pathMedium;
                        product.value.ticketImagePath = uploadResponse.data?.image.path;
                        product.value.ticketImagePathSmall = uploadResponse.data?.image.pathSmall;
                        unchangedProduct.value.ticketImagePath = uploadResponse.data?.image.path;
                        unchangedProduct.value.ticketImagePathSmall = uploadResponse.data?.image.pathSmall;
                        swal.close();
                    }
                },
                'image/jpeg',
                0.9
            );
        }
    };
    const saveResponsiblePersonImage = async () => {
      swal.showLoading();
      const { canvas } = circleCropperLib.value.getResult();
      if (canvas) {
        const fd = new FormData();
        canvas.toBlob(
          async (blob: any) => {
            if (blob == null) {
              swal.fire({
                icon: 'error',
                title: t('error-pop-up.oops'),
                text: t('edit-product.photos-videos.upload-image-error.small-resolution'),
              });
              return;
            }
            fd.append('file', blob);
            const uploadResponse = await api.uploadResposiblePersonFile(fd, product.value.id);
            if (uploadResponse.errorMessage)
              await swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: uploadResponse.errorMessage });
            if (uploadResponse.data) {
              const responsiblePersonImage = uploadResponse.data;
              responsiblePersonImgSrc.value = '';
              product.value.responsiblePersonPhotoUrl = responsiblePersonImage.path;
              unchangedProduct.value.responsiblePersonPhotoUrl = responsiblePersonImage.path;
              swal.close();
            }
          },
          'image/jpeg',
          0.9,
        );
      }
    };
    function showImageSettings(i: number) {
      background.value = true;
      if (!document.getElementById(i + '-image-dropdown-settings')?.classList.contains('display-block')) {
        Array.prototype.forEach.call(document.getElementsByClassName('dropdown-menu'), (element) => {
          element.classList.remove('display-block');
        });
        document.getElementById(i + '-image-dropdown-settings')?.classList.add('display-block');
      } else document.getElementById(i + '-image-dropdown-settings')?.classList.remove('display-block');
    }
    async function showDocumentSettings(i: number) {
      background.value = true;
      if (!document.getElementById(i + '-document-dropdown-settings')?.classList.contains('display-block')) {
        Array.prototype.forEach.call(document.getElementsByClassName('dropdown-menu'), (element) => {
          element.classList.remove('display-block');
        });
        document.getElementById(i + '-document-dropdown-settings')?.classList.add('display-block');
      } else document.getElementById(i + '-document-dropdown-settings')?.classList.remove('display-block');
    }
    function hideSettings() {
      background.value = false;
      Array.prototype.forEach.call(document.getElementsByClassName('dropdown-menu'), (element) => {
        element.classList.remove('display-block');
      });
    }
    function routeToEdit(fieldOfProduct: string) {
      if (id.value && fieldOfProduct) {
        if (fieldOfProduct === 'fulldescription') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'long-description',
              value: product.value.fullDescription,
            },
          });
        }
        if (fieldOfProduct === 'fulldescriptionIndex') {
            router.push({
                name: 'edit-product-desc',
                params: {
                    type: 'long-description-index',
                    value: product.value.fullDescriptionIndex
                }
            });
        }
        if (fieldOfProduct === 'shortdescription') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'short-description',
              value: product.value.shortDescription,
            },
          });
        }
        if (fieldOfProduct === 'name') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'name',
              value: product.value.name,
            },
          });
        }
        if (fieldOfProduct === 'parkingInstructions') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'parkingInstructions',
              value: product.value.parkingInstructions,
            },
          });
        }
        if (fieldOfProduct === 'arrivalInstructions') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'arrivalInstructions',
              value: product.value.arrivalInstructions,
            },
          });
        }
        if (fieldOfProduct === 'name') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'name',
              value: product.value.name,
            },
          });
        }
        if (fieldOfProduct === 'responsible-person-description') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'responsible-person-description',
              value: product.value.responsiblePersonDescription || '',
            },
          });
        }

        if (fieldOfProduct === 'policy') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'policy',
              value: product.value.policy,
            },
          });
        }

        if (fieldOfProduct === 'extraInfo') {
          router.push({
            name: 'edit-product-desc',
            params: {
              type: 'extraInfo',
              value: product.value.extraInfo,
            },
          });
        }
      }
    }

    document.addEventListener('click', (event: any) => {
      const colorPickerElement: HTMLElement = document
        .getElementsByClassName('p-colorpicker-preview')
        .item(0) as HTMLElement;
      if (document.getElementById('productPhotosVideo')?.classList.contains('show')) {
        if (!event.target.closest('.photo-wrapper')) {
          Array.prototype.forEach.call(document.getElementsByClassName('dropdown-menu'), (element) => {
            element.classList.remove('display-block');
          });
        }
      }
    });

    function deleteTag(tag: ProductTags) {
      product.value.productTags.splice(product.value.productTags.indexOf(tag), 1);
    }
    function deleteIncludedTag(tag: ProductTags) {
      product.value.productIncludedTags.splice(product.value.productIncludedTags.indexOf(tag), 1);
    }
    function deleteExcludedTag(tag: ProductTags) {
      product.value.productExcludedTags.splice(product.value.productExcludedTags.indexOf(tag), 1);
    }
    function deleteRegion(region: ProductRegion) {
      product.value.productRegions.splice(product.value.productRegions.indexOf(region), 1);
    }
    const transportAndParkingId = tags.find((t) => t.name == 'Transport and Parking')?.id;
    const transportAndParkingTags = () =>
      product.value.productTags.filter((t) => t.parentTagId == transportAndParkingId);

    const languagesId = tags.find((t) => t.name == 'Languages')?.id;
    const languagesTags = () => product.value.productTags.filter((t) => t.parentTagId == languagesId);

    const religionId = tags.find((t) => t.name == 'Religion')?.id;
    const religionTags = () => product.value.productTags.filter((t) => t.parentTagId == religionId);

    const foodDrinkId = tags.find((t) => t.name == 'Food and Drink')?.id;
    const foodDrinkTags = () => product.value.productTags.filter((t) => t.parentTagId == foodDrinkId);

    const entranceId = tags.find((t) => t.name == 'Entrance')?.id;
    const entranceTags = () => product.value.productTags.filter((t) => t.parentTagId == entranceId);

    const targetAudiencesId = tags.find((t) => t.name == 'Target Audiences')?.id;
    const targetAudiencesTags = () => product.value.productTags.filter((t) => t.parentTagId == targetAudiencesId);

    const includedNotIncludedId = tags.find((t) => t.name == 'Included - Not Included')?.id;
    const includedNotIncludedTags = () =>
      product.value.productIncludedTags.filter((t) => t.parentTagId == includedNotIncludedId);

    const subTypeId = tags.find((t) => t.name == 'Sub Type')?.id;
    const subTypeTags = () => product.value.productTags.filter((t) => t.parentTagId == subTypeId);

    const includedId =
      product.value.productIncludedTags && product.value.productIncludedTags.length > 0
        ? product.value.productIncludedTags[0].parentTagId
        : 7;
    const excludedId =
      product.value.productExcludedTags && product.value.productExcludedTags.length > 0
        ? product.value.productExcludedTags[0].parentTagId
        : 7;

    const difficultyLevelId = tags.find((t) => t.name == 'Difficulty Level')?.id;
    const difficultyLevelTags = () => product.value.productTags.filter((t) => t.parentTagId == difficultyLevelId);
    const productTypeSelect = {
      placeholder: 'Select product type',
      valueProp: 'id',
      trackBy: 'name',
      label: 'name',
      searchable: true,
      options: productTypes,
    };
    function validateURL(url: string) {
      //eslint-disable-next-line
      const regex =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const result = regex.test(url);
      return result;
    }
    async function updateProduct() {
      let newColor = product.value.calendarColor;
      if (!product.value.calendarColor.includes('#')) {
        product.value.calendarColor = '#' + newColor;
      }
      let validatedLinks = true;

      if (product.value.productVideos && product.value.productVideos.length > 0) {
        product.value.productVideos = product.value.productVideos.filter((x) => x.path);
        for (const video of product.value.productVideos) {
          const validatedLink = validateURL(video.path);

          if (!validatedLink) {
            swal.fire({
              icon: 'error',
              title: video.path,
              text: 'Video link is incorrect',
            });
            validatedLinks = false;
            break;
          }
        }
      }
      if (validatedLinks) {
        product.value.name = product.value.name.trim();
        if (!product.value.name) return;
        const productToSave: Product = JSON.parse(JSON.stringify(product.value));

        const savedProduct = await saveProduct(productToSave);
        if (savedProduct) {
          newColor = '';
          swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Product updated',
            showConfirmButton: false,
            timer: 1500,
          });
          productWasSaved = true;
          router.push({ name: routeDataTemp.routeName, params: { id: routeDataTemp.paramId } });
        }
      }
    }
    function routeToEditFile(imageId: string, fileType: string) {
      router.push({
        name: 'product-photo',
        params: { productId: product.value.id, fileId: imageId, type: fileType },
      });
    }

    async function makeImageAsMain(imageId: string) {
      swal.showLoading();
      const response = await api.setMainProductImage(imageId);
      if (response.errorMessage) {
        swal.fire({
          icon: 'error',
          title: response.errorMessage,
        });
      } else {
        product.value.productImages?.forEach((image) => {
          if (image.id == imageId) image.isMainImage = true;
          else image.isMainImage = false;
        });
        swal.close();
        hideSettings();
      }
    }
    function removeImageFromProduct(imageId: string) {
      swal
        .fire({
          icon: 'info',
          title: t('alert.photoDelete'),
          customClass: {
            confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-4',
            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t('alert.yesDeleteIt'),
          cancelButtonText: t('button.cancel'),
        })
        .then(async (result) => {
          background.value = false;
          if (result.isConfirmed) {
            swal.showLoading();
            const response = await api.deleteProductImage(imageId);
            if (response.errorMessage) {
              swal.fire({
                icon: 'error',
                title: response.errorMessage,
                showConfirmButton: true,
              });
            } else {
              product.value.productImages = product.value.productImages?.filter((i) => i.id !== imageId);
              unchangedProduct.value.productImages = JSON.parse(JSON.stringify(product.value.productImages));
              swal.close();
            }
          }
        });
    }
    function removeResponsiblePersonImage() {
      swal
        .fire({
          icon: 'info',
          title: t('alert.photoDelete'),
          customClass: {
            confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-4',
            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t('alert.yesDeleteIt'),
          cancelButtonText: t('button.cancel'),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            swal.showLoading();
            const response = await api.deleteResposiblePersonImage(product.value.id);
            if (response.errorMessage) {
              swal.fire({
                icon: 'error',
                title: response.errorMessage,
                showConfirmButton: true,
              });
            } else {
              product.value.responsiblePersonPhotoUrl = null;
              swal.close();
            }
          }
        });
    }
    function routeToPriceListactions(prodId: string, pricingId: number) {
      const found = product.value.productPrices.find((pp) => pp.id === pricingId);
      generalStore.commit('setCurrentPricing', found);
      router.push({ name: 'product-price-list', params: { productId: prodId, priceId: pricingId } });
    }
    async function deleteProduct() {
      swal
        .fire({
          icon: 'info',
          title: t('alert.doYouWantToDeleteProduct', { productName: product.value.name }),
          customClass: {
            confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t('alert.yesDeleteIt'),
          cancelButtonText: t('button.cancel'),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            swal.showLoading();
            const response = await api.deleteActivity(id.value);
            if (response.errorMessage) {
              swal.fire({
                icon: 'error',
                title: response.errorMessage,
                showConfirmButton: true,
              });
            }
            if (!response.error) {
              const timeNow = moment().toISOString();
              product.value.deletedAt = timeNow;
              product.value.active = false;
              swal
                .fire({
                  icon: 'success',
                  title: t('alert.productWasDeleted'),
                  text: response.errorMessage,
                  confirmButtonText: t('alert.backToCatalog'),
                })
                .then(() => {
                  router.push({ name: 'catalog' });
                });
              return;
            }
          }
        });
    }

    const getEndTime = (timeSlot: TimeSlot) => {
      const duration = moment.duration(timeSlot.startTime).add(timeSlot.duration);
      return moment.utc(duration.asMilliseconds()).format('HH:mm');
    };
    function createNewPricing() {
      generalStore.commit('setCurrentPricing', new ProductPrice());
      router.push({ name: 'product-price-list', params: { productId: product.value.id } });
    }
    async function deleteDocument(document: ProductDocument) {
      swal
        .fire({
          icon: 'info',
          title: t('alert.documentDelete', {
            docName: document.name,
          }),
          customClass: {
            confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-4',
            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t('alert.yesDeleteIt'),
          cancelButtonText: t('button.cancel'),
        })
        .then(async (result) => {
          background.value = false;
          if (result.isConfirmed) {
            swal.showLoading();
            const response = await api.removeDocument(document.id);
            if (response && response.data) {
              product.value.productDocuments = product.value.productDocuments?.filter(
                (d) => d.id !== response.data?.id,
              );
            }
          }
        });
    }

    function back() {
      router.go(-1);
    }

    function getDocIcon(path: string) {
      if (path.endsWith('.txt')) {
        return 'icon-txt-document';
      }
      if (path.endsWith('.pdf')) {
        return 'icon-pdf';
      }
      if (path.endsWith('.xlsx') || path.endsWith('.xls')) {
        return 'icon-xls';
      }
      if (path.endsWith('.docx') || path.endsWith('.doc')) {
        return 'icon-docx';
      }
      return '';
    }
    const submitForm = async (event: any) => {
      event.target.classList.add('was-validated');
      if (event.target.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        swal.fire({
          icon: 'warning',
          title: t('alert.mandatoryfields'),
          confirmButtonText: t('button.close'),
        });
      } else {
        updateProduct();
      }
    };
    async function changeMultiplePriceLists() {
      product.value.allowMultiplePriceLists = !product.value.allowMultiplePriceLists;
      const payload = {
        productId: parseInt(product.value.id),
        allowMultiplePriceLists: product.value.allowMultiplePriceLists,
      };
      const response = await api.changeProductAllowMultiplePriceLists(payload);
      if (response.errorMessage) {
        swal.fire({
          icon: 'error',
          title: response.errorMessage,
          showConfirmButton: true,
        });
        product.value.allowMultiplePriceLists = true;
      }
      return;
    }

    async function changeAllowAssignEmployees() {
      product.value.allowAssignEmployees = !product.value.allowAssignEmployees;
      const response = await api.changeProductAllowAssignEmployees(parseInt(product.value.id));
      if (response.errorMessage) {
        swal.fire({
          icon: 'error',
          title: response.errorMessage,
          showConfirmButton: true,
        });
        product.value.allowAssignEmployees = true;
      }
      return;
    }

    async function OnChangeOrderConfirmation() {
      if (product.value.canChangeOrderConfirmation) {
        swal.showLoading();
        const apiResultPromise = api.changeAllowOrdersOnRequest(
          product.value.id,
          product.value.canChangeOrderConfirmation,
        );
        await Q.delay(400);
        const response = await apiResultPromise;
        swal.close();
        if (response.errorMessage) {
          swal.fire({
            icon: 'error',
            title: response.errorMessage,
            showConfirmButton: true,
          });
          product.value.canChangeOrderConfirmation = !product.value.canChangeOrderConfirmation;
          return;
        }
      } else {
        swal.showLoading();
        const apiResultPromise = api.changeAllowOrdersOnRequest(
          product.value.id,
          product.value.canChangeOrderConfirmation,
        );
        await Q.delay(400);
        const response = await apiResultPromise;
        swal.close();
        if (response.errorMessage) {
          swal.fire({
            icon: 'error',
            title: response.errorMessage,
            showConfirmButton: true,
          });
          product.value.canChangeOrderConfirmation = !product.value.canChangeOrderConfirmation;
          return;
        }
        if (response.data?.errorCode == 450) {
          swal.fire({
            icon: 'error',
            title: response.errorMessage,
            showConfirmButton: true,
          });
          const swalAction = await swal.fire({
            icon: 'info',
            text: t('swalAction.productHasPriceOnRequest'),
            customClass: {
              confirmButton: 'stop-sale-button popup-bookit-button my-1 px-4',
              cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('alert.btn.yes-confirm'),
            cancelButtonText: t('button.cancel'),
          });
          if (swalAction.isConfirmed) {
            await Q.delay(200);
            swal.showLoading();
            const responsePromise = api.changeAllowOrdersOnRequest(
              product.value.id,
              product.value.canChangeOrderConfirmation,
              true,
            );
            await Q.delay(400);
            const apiResult = await responsePromise;
            swal.close();
            if (apiResult.errorMessage) {
              swal.fire({
                icon: 'error',
                title: response.errorMessage,
                showConfirmButton: true,
              });
              product.value.canChangeOrderConfirmation = !product.value.canChangeOrderConfirmation;
            }
            product.value.productPrices.forEach((productPrice) => {
              productPrice.autoConfirm = true;
            });
          } else {
            product.value.canChangeOrderConfirmation = !product.value.canChangeOrderConfirmation;
          }
        }
      }
    }
    function checkDescription(descriptionType: string) {
      if (descriptionType == 'parking') {
        if (product.value.parkingInstructions.length > 300) {
          product.value.parkingInstructions = product.value.parkingInstructions.substring(0, 300);
        }
      }
      if (descriptionType == 'arrival') {
        if (product.value.arrivalInstructions.length > 300) {
          product.value.arrivalInstructions = product.value.arrivalInstructions.substring(0, 300);
        }
      }
      if (descriptionType == 'extraInfo') {
        if (product.value.extraInfo.length > 255) {
          product.value.extraInfo = product.value.extraInfo.substring(0, 255);
        }
      }
      if (descriptionType == 'address') {
        if (product.value.address.length > 100) {
          product.value.address = product.value.address.substring(0, 100);
        }
      }
    }
    function routeToSelectLocation() {
      router.push({
        name: 'location-map',
        params: { productId: id.value },
      });
    }
    function addFAQ() {
      const newFaq: ProductFAQ = {
        id: 0,
        question: '',
        answer: '',
        translations: [],
      };
      product.value.productFaqs.unshift(newFaq);
    }
    async function deleteFAQItem(index: number) {
      const toDeleteFAQ = product.value.productFaqs[index];
      if (!toDeleteFAQ.question && !toDeleteFAQ.answer) {
        product.value.productFaqs.splice(index, 1);
        return;
      }
      const resultSwal = await swal.fire({
        title: t('alert.confirm-delete-faq'),
        icon: 'warning',
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('alert.yesDeleteIt'),
        cancelButtonText: t('button.cancel'),
      });
      if (!resultSwal.isConfirmed) return;
      product.value.productFaqs.splice(index, 1);
    }
    const showFilter = ref(false);

    function addCustomInfoType() {
      product.value.productInfos.push({
        id: 0,
        title: '',
        description: '',
        type: ProductInfoType.Custom,
        translations: [],
      });
    }
    function showWarning(index: number) {
      document.getElementById('product-image-' + index)?.classList.remove('dp-none');
      document.getElementById('product-image-' + index)?.classList.add('dp-block');
    }
    function hideWarning(index: number) {
      document.getElementById('product-image-' + index)?.classList.remove('dp-block');
      document.getElementById('product-image-' + index)?.classList.add('dp-none');
    }
    async function removeCustomInfoType() {
      const info = product.value.productInfos.find((x) => x.type == ProductInfoType.Custom);
      if (info?.description || info?.title) {
        const resultSwal = await swal.fire({
          title: t('alert.confirm-delete-custom-info'),
          icon: 'warning',
          customClass: {
            confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: t('alert.yesDeleteIt'),
          cancelButtonText: t('button.cancel'),
        });
        if (!resultSwal.isConfirmed) return;
      }
      product.value.productInfos = product.value.productInfos.filter((x) => x.type != ProductInfoType.Custom);
    }

    function startUploadImage(e: any, imageInputId: string) {
      if (imageInputId !== 'ticket-image-upload-input') {
          if (product.value.productImages && product.value.productImages.length >= 20) {
              swal.fire({
                  title: t('product-page-edit.photos-videos.limitReached'),
                  icon: 'error'
              });
              return;
          }
      }
      uploadFile(e, imageInputId);
    }
    function watchAtOption(event: OrderBlockOption) {
      if (event === OrderBlockOption.before) {
        product.value.blockCreatingNewOrdersBefore = true;
      } else {
        product.value.blockCreatingNewOrdersBefore = false;
      }
      product.value.orderBlockMinutes = null;
    }
    function deactivatePrices(priceIds: number[]) {
      product.value.productPrices.forEach((productPrice) => {
        if (priceIds.includes(productPrice.id)) {
          productPrice.deletedAt = moment().toISOString();
        }
      });
      unchangedProduct.value.productPrices.forEach((productPrice) => {
        if (priceIds.includes(productPrice.id)) {
          productPrice.deletedAt = moment().toISOString();
        }
      });
    }
    function changeSellingSetting(newValue: boolean, field: string, priceIds: number[]) {
      switch (field) {
        case 'cashier':
          product.value.useCashier = newValue;
          unchangedProduct.value.useCashier = newValue;
          break;
        case 'locals':
          product.value.useLocals = newValue;
          unchangedProduct.value.useLocals = newValue;
          if (!newValue) {
            deactivatePrices(priceIds);
          }
          break;
        case 'groups':
          product.value.useGroups = newValue;
          unchangedProduct.value.useGroups = newValue;
          if (!newValue) {
            deactivatePrices(priceIds);
          }
          break;
        case 'b2cChannels':
          product.value.useB2cChannels = newValue;
          unchangedProduct.value.useB2cChannels = newValue;
          break;
        case 'index':
          product.value.useIndex = newValue;
          unchangedProduct.value.useIndex = newValue;
          break;
      }
    }
    function changeSellingSettingPrintingType(newValue: PrintingType | null) {
      product.value.printingType = newValue;
    }

    return {
      disactivateOrActivateProduct,
      disactivateOrActivateProductPriceType,
      background,
      hideSettings,
      showDocumentSettings,
      deleteDocument,
      createNewPricing,
      currentPricing,
      routeToPriceListactions,
      priceTypes,
      currencies,
      findPriceTypeName,
      findCurrencySymbol,
      citySelect,
      validateURL,
      getEndTime,
      deleteProduct,
      removeYouTubeLink,
      addYoutubeInput,
      removeImageFromProduct,
      includedId,
      excludedId,
      deleteIncludedTag,
      deleteExcludedTag,
      deleteRegion,
      makeImageAsMain,
      routeToEditFile,
      updateProduct,
      product,
      momentFormat,
      weekNames,
      callUploadingFunction,
      uploadingFileValidation,
      uploadingImageValidation,
      uploadFile,
      showImageSettings,
      tags,
      routeToEdit,
      deleteTag,
      transportAndParkingId,
      languagesId,
      religionId,
      foodDrinkId,
      entranceId,
      targetAudiencesId,
      includedNotIncludedId,
      difficultyLevelId,
      subTypeId,
      transportAndParkingTags,
      languagesTags,
      religionTags,
      foodDrinkTags,
      entranceTags,
      targetAudiencesTags,
      includedNotIncludedTags,
      difficultyLevelTags,
      subTypeTags,
      productTypeSelect,
      imgSrc,
      newSavedImage,
      saveImage,
      cropperLib,
      circleCropperLib,
      showPage,
      back,
      getDocIcon,
      showproductActivityTimeDurationInfo,
      showProductPhotosVideoInfo,
      id,
      submitForm,
      showDocumentsHelp,
      weekDays: DayOfWeekKendo,
      submitButtonRef,
      showAllPrices,
      changeMultiplePriceLists,
      changeAllowAssignEmployees,
      productAverageDuration,
      productOperationStatus,
      showFilter,
      selectedOrderBlockOption,
      orderBlockTypes,
      orderBlockOptions,
      OnChangeOrderConfirmation,
      addressRegionSelect,
      clearImage,
      routeToSelectLocation,
      checkDescription,
      map,
      addFAQ,
      deleteFAQItem,
      addCustomInfoType,
      responsiblePersonImgSrc,
      circleStencil,
      saveResponsiblePersonImage,
      removeResponsiblePersonImage,
      removeCustomInfoType,
      showWarning,
      hideWarning,
      drag,
      startUploadImage,
      showExtraInfoAbout,
      watchAtOption,
      changeSellingSetting,
      registrationTypes,
      changeSellingSettingPrintingType,
      uploadingTicketImageValidation,
      saveTicketImage,
      ticketImgSrc,
      clearTicketImage
    };
  },
});
