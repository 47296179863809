import { InitResponse } from '@/models/ApiInterfaces';
import { DocumentsFilter } from '@/models/Document';
import { OrdersFilter } from '@/models/Order';
import { Product, ProductPrice, ProductsFilter, ProductRegion, ProductAllTags, PromoFilter } from '@/models/Product';
import { ProvidersFilter } from '@/models/Provider';
import { AgenciesFilter } from '@/models/Agencies';
import apiRef from '@/services/Api';
import { createStore } from 'vuex';
import StoreState from './State';
import { useI18n } from 'vue-i18n';

export default createStore({
  state: new StoreState(),
  getters: {
    GetPermissions: (state) => state.user?.permissions || [],
    currentLanguage: (state): string => state.lang,
    getNewProduct: (state): Product => {
      if (state.product == null) state.product = new Product();
      return state.product;
    },
    product: (state): Product | null => state.product,
    languages: (state): Language[] => state.languages,
    priceTypes: (state): IdName[] => state.priceTypes,
    getCurrencySymbol:
      (state) =>
      (id: number): string =>
        state.currencies.filter((c) => c.id == id)?.[0]?.symbol,
    getSelectedPriceTypeId: (state): string => state.selectedPriceTypeIdFilter,
    getSelectedProductId: (state): string => state.selectedProductIdFilter,
    getSubscription: (state) => (id: number) => id ? state.subscriptions.find((x) => x.id == id) : {},
    getCurrentLangId: (state): number => {
      const lang = state.languages.find((x) => x.name == state.lang);
      return lang?.id || 0;
    },
    getEnLangId: (state): number => {
      const lang = state.languages.find((x) => x.name == 'en-US');
      return lang?.id || 0;
    },
    countryCode: (state): string => state.countryCode,
    countryLatitude: (state): number => state.countryLatitude,
    countryLongitude: (state): number => state.countryLongitude,
    googleMapsApiKey: (state): string => state.googleMapsApiKey,
    getCurrencyRate: (state): CurrencyRate[] => {
      return state.currencyRates;
    },
    getDocumentsFilter: (state): DocumentsFilter | null => state.documentsFilter,
    getProductsFilter: (state): ProductsFilter | null => state.productsFilter,
    getPromoFilter: (state): PromoFilter | null => state.promoFilter,
    currentPricing: (state): ProductPrice => state.currentPrice,
    currencies: (state): Currency[] => state.currencies,
    currenciesWithoutLocal: (state): Currency[] => state.currencies.filter(x => x.id != state.localCurrencyId),
    getDefaultPriceType: (state): string => state.defaultPriceTypeId,
    getDefaultChannel: (state): string => state.defaultChannelId,
    getDateTimeFormatMoment: (state) => state.dateTimeFormatMoment,
  },
  actions: {
    async getProviders(context) {
      if (!context.state.providers.length) {
        const response = await apiRef.getRefProviders();
        context.state.providers = response.data || [];
      }

      return context.state.providers;
    },
    async getPriceTypes(context) {
      if (!context.state.priceTypes.length) {
        const response = await apiRef.getRefPriceTypes();
        context.state.priceTypes = response.data || [];
      }

      return context.state.priceTypes;
    },
    async getChannels(context) {
      if (!context.state.channels.length) {
        const response = await apiRef.getChannels();
        context.state.channels = response.data || [];
      }

      return context.state.channels;
    },
    async getBanks(context) {
      if (!context.state.banks.length) {
        const response = await apiRef.getBanks();
        context.state.banks = response.data || [];
      }

      return context.state.banks;
    },
    async getBankBranches(context) {
      if (!context.state.bankBranches.length) {
        const response = await apiRef.getBankBranches();
        context.state.bankBranches = response.data || [];
      }

      return context.state.bankBranches;
    },

    async getProducts(context) {
      if (!context.state.products.length) {
        const response = await apiRef.getRefProducts();
        context.state.products = response.data || [];
      }
      return context.state.products;
    },

    async getIndexProducts(context) {
        if (!context.state.indexProducts.length) {
            const response = await apiRef.getRefIndexProducts();
            context.state.indexProducts = response.data || [];
        }
        return context.state.indexProducts;
    },

    async getProductLastOpenedBlock(context) {
      const productLastOpenedBlock = context.state.productLastOpenedBlock;
      context.state.productLastOpenedBlock = '';
      return productLastOpenedBlock;
    },
    async loadAllTags(context) {
      const response = await apiRef.getTags();
      if (response.data) context.state.tags = response.data;
    },
    async loadAllSubTags(context) {
      const response = await apiRef.getSubTags();
      if (response.data) context.state.subTags = response.data;
    },
    async loadAllProductTypes(context) {
      const response = await apiRef.getProductTypes();
      if (response.data) context.state.productTypes = response.data;
    },
    async loadAllCities(context) {
      const response = await apiRef.getAllCities();
      if (response.data) context.state.cities = response.data;
    },
    async loadAllRegions(context) {
      const response = await apiRef.getAllRegions();
      if (response.data) context.state.regions = response.data;
    },
    async getSelectedWeekDays(context) {
      const selectedWeekDays = context.state.selectedWeekDays;
      context.state.selectedWeekDays = [];
      return selectedWeekDays;
    },
  },
  mutations: {
    init(state, data: InitResponse): void {
      state.lang = data.lang;
      state.isAuthenticated = data.isAuthenticated;
      state.currencies = data.currencies;
      state.currencyRates = data.currencyRates;
      state.subscriptions = data.subscriptions || [];
      state.languages = data.languages;
      state.googleMapsApiKey = data.googleMapsApiKey;
      state.countryCode = data.countryCode;
      state.countryLatitude = data.countryLatitude;
      state.countryLongitude = data.countryLongitude;
      state.defaultPriceTypeId = data.defaultPriceTypeId;
      state.defaultChannelId = data.defaultChannelId;
      state.dateTimeFormatMoment = data.dateTimeFormatMoment;
      state.localCurrencyId = data.localCurrencyId;
      if (data.user) state.user = data.user;
    },
    updateLang(state, lang: string): void {
      state.lang = lang;
    },
    logout(state): void {
      state.isAuthenticated = false;
      state.user = null;
    },
    setRefreshProviderSearch(state, val: boolean) {
      state.refreshProviderSearch = val || false;
    },
    setRefreshAgencySearch(state, val: boolean) {
      state.refreshAgencySearch = val || false;
    },
    setIsMobileView(state, isMobile: boolean): void {
      state.isMobileView = isMobile;
    },
    saveProvidersFilter(state, filter: ProvidersFilter) {
      state.providersFilter = filter;
    },
    saveAgenciesFilter(state, filter: AgenciesFilter) {
      state.agenciesFilter = filter;
    },
    savePromoFilter(state, filter: PromoFilter) {
      state.promoFilter = filter;
    },
    saveOrdersFilter(state, filter: OrdersFilter) {
      state.orderFilter = filter;
    },
    saveDocumentsFilter(state, filter: DocumentsFilter) {
      state.documentsFilter = filter;
    },
    saveProductsFilter(state, filter: ProductsFilter) {
      state.productsFilter = filter;
    },
    saveProductLastOpenedBlock(state, blockName: string) {
      state.productLastOpenedBlock = blockName;
    },
    setPriceTypeIdFilter(state, newPriceTypeId) {
      state.selectedPriceTypeIdFilter = newPriceTypeId;
    },
    setProductIdFilter(state, newProductId) {
      state.selectedProductIdFilter = newProductId;
    },
    setProduct(state, product: Product): void {
      state.product = product;
    },
    setCurrentPricing(state, newPricing: ProductPrice) {
      const np = newPricing;
      np.productPriceLines = np.productPriceLines.filter((value) => value.amount != null);
      state.currentPrice = np;
    },
    saveSelectedWeekDays(state, selectedWeekDays: string[]) {
      state.selectedWeekDays = selectedWeekDays;
    },
    setProductRegions(state, regions: ProductRegion[]): void {
      if (state.product && state.product.productRegions) {
        state.product.productRegions = regions;
      }
    },
    setProductTags(state, tags: ProductAllTags): void {
      if (state.product && state.product.productTags) {
        state.product.productTags = tags.productTags;
      }
      if (state.product && state.product.productIncludedTags) {
        state.product.productIncludedTags = tags.productIncludedTags;
      }
      if (state.product && state.product.productExcludedTags) {
        state.product.productExcludedTags = tags.productExcludedTags;
      }
    },
    updateUserData(state, user: User) {
      if (state.user) {
        state.user.fullName = user.fullName;
      }
    },
  },
});
