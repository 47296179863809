import mixins from './mixin';
import swal from 'sweetalert2';
import { AwaitTaskResponse } from './models/ApiInterfaces';
import { ApiResult } from './services/BaseApi';
import Q from 'q';
import moment from 'moment';
import { DateRange } from './models/Order';
import { ProductWorkingDate, TimeSlot } from './models/Product';
import { useI18n } from 'vue-i18n';

function isNumber(evt: any) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  else return true;
}

function isTelephone(evt: any) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if ((charCode < 48 || charCode > 57) && charCode != 43) evt.preventDefault();
  else return true;
}
function isDecimalNumber(evt: any) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) evt.preventDefault();
  else return true;
}

function isNegativeNumber(evt: any) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45) evt.preventDefault();
  else return true;
}

function isNegativeDecimalNumber(evt: any) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) evt.preventDefault();
  else return true;
}

function formatAmount(item: number | string): string {
  if (!item) {
    return '0';
  }
  let result = (Math.round((Number(item) + Number.EPSILON) * 100) / 100).toString();
  const arrRes = result.split('.');
  if (arrRes.length > 1) {
    const first = arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    result = first + '.' + arrRes[1];
  } else {
    result = arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    result += '.00';
  }
  return result;
}

function formatAmountNoDecimal(item: number | string): string {
    if (!item) {
        return '0';
    }
    const result = (Math.round((Number(item) + Number.EPSILON) * 100) / 100).toString();
    const arrRes = result.split('.');

    return arrRes[0].replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatDate(date: string | Date | null, format = 'DD/MM/YYYY') {
  if (!date) return '';
  if (format == 'api') format = 'YYYY-MM-DD';
  return moment(date).format(format);
}

function getOrderProductStartDate(date: string) {
  return moment(date.replace('Z', '')).format('DD/MM/YYYY');
}

function formatLocalDate(date: string | Date | null, format = 'DD/MM/YYYY') {
  if (!date) return '';
  if (format == 'api') format = 'YYYY-MM-DD';
  return moment.utc(date).local().format(format);
}
function formatDateIgnoreZone(date: string | Date | null, format = 'DD/MM/YYYY') {
  if (!date) return '';
  return moment.utc(date).format(format);
}

function formatTime(incomeData: string | Date | null) {
  return moment(incomeData, 'HH:mm:ss').format('HH:mm');
}

function formatLocalTime(incomeData: string | Date | null) {
  return moment.utc(incomeData, 'HH:mm:ss').local().format('HH:mm');
}

function formatTimeIgnoreZone(incomeData: string | Date | null) {
  if (!incomeData) return '';
  return moment.utc(incomeData, 'HH:mm:ss').format('HH:mm');
}

function formatLocalDateTime(incomeData: string | null, format = 'DD/MM/YYYY HH:mm') {
  if (format == 'api') format = 'YYYY-MM-DDTHH:mm';
  if (!incomeData) return '';
  return moment.utc(incomeData).local().format(format);
}

function removemillis(time: string) {
  if (!time) {
    return '';
  }
  const arr = time.split(':');
  const result = arr[0] + ':' + arr[1];
  return result;
}

function getMinutes(time: string) {
  const [hours, mins] = time.split(':');
  return parseInt(hours) * 60 + parseInt(mins);
}

function dateRangeValidate(dateRange: DateRange) {
  const isNotValidDates = moment(dateRange.fromDate) > moment(dateRange.toDate);
  if (isNotValidDates) {
    dateRange.toDate = dateRange.fromDate;
  }
}

function addZero(number: number) {
  const str = number.toString();
  if (str.length == 1) {
    return '0' + str;
  }
  return str;
}

function compare(a: TimeSlot, b: TimeSlot) {
  if (a.startTime < b.startTime) {
    return -1;
  }
  if (a.startTime > b.startTime) {
    return 1;
  }
  return 0;
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  document.getElementById('main-content')?.scrollTo({ top: 0, behavior: 'smooth' });
}

function getUniqueListByWorkingDay(workingday: ProductWorkingDate) {
  const checkedDays = workingday?.timeSlots.map((m) => m.dayOfWeek).filter((x, i, a) => a.indexOf(x) === i);
  return workingday.timeSlots
    ?.filter(
      (thing, index, self) =>
        self.findIndex(
          (t) =>
            formatTime(t.startTime) == formatTime(thing.startTime) &&
            formatTime(t.duration) == formatTime(thing.duration) &&
            formatTime(t.endTime) == formatTime(thing.endTime) &&
            t.quantity == thing.quantity,
        ) === index && checkedDays.includes(thing.dayOfWeek),
    )
    .sort(compare);
}

async function awaitTask(
  action: () => Promise<ApiResult<AwaitTaskResponse>>,
  onSuccess: (url: string) => void,
  t: (key: string) => string,
  waitTime = 3000,
) {
  swal.fire(t('swalAction.processing'));
  swal.showLoading();
  await Q.delay(waitTime);

  const resp = await action();
  if (resp.errorMessage) {
    await swal.fire({
      icon: 'error',
      text: resp.errorMessage,
    });
    return;
  }
  if (resp.data?.url) {
    onSuccess(resp.data.url);
    return;
  }

  if (waitTime > 0) {
    awaitTask(action, onSuccess, t, waitTime);
  }
}

export default function () {
  return {
    isNumber,
    isDecimalNumber,
    isNegativeNumber,
    isNegativeDecimalNumber,
    formatAmount,
    formatAmountNoDecimal,
    formatDate,
    formatLocalDate,
    formatTime,
    formatLocalTime,
    formatLocalDateTime,
    removemillis,
    getMinutes,
    dateRangeValidate,
    addZero,
    compare,
    scrollToTop,
    getUniqueListByWorkingDay,
    awaitTask,
    isTelephone,
    formatDateIgnoreZone,
    formatTimeIgnoreZone,
    getOrderProductStartDate,
  };
}
