
import { Product, ProductPrice, ProductTags, TimeSlot } from '@/models/Product';
import { computed, defineComponent, ref } from 'vue';
import generalStore from '@/store';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import swal from 'sweetalert2';
import moment from 'moment';
import { WeekNames, DayOfWeekKendo, ProductInfoType, ProductRegistrationType, OrderBlockTypes } from '@/models/Enums';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { onMounted } from 'vue';
import Q from 'q';
import api from '@/services/Api';
import useProduct from '@/components/Product/useProduct';
import ProductSlotsTable from '@/components/Product/ProductSlotsTable.vue';
import {} from 'google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import ProductFAQs from '@/components/ProductFAQs.vue';
import ProductMoreInfo from '@/components/Product/ProductMoreInfo.vue';
import ProductSellingSettings from '@/components/Product/ProductSellingSettings.vue';
import NotificationsRestrictions from '@/components/Product/NotificationsRestrictions.vue';


const groupBy = function (incomeArray: any[], key: string) {
    return incomeArray.reduce(function (arr, x) {
        (arr[x[key]] = arr[x[key]] || []).push(x);
        return arr;
    }, {});
};

export default defineComponent({
    name: 'Product',
    data() {
        return {
            longDescriptionExpanded: false,
            longDescriptioIndexExpanded: false,
            arrivalInstructionsExpanded: false,
            parkingInstructionsExpanded: false
        };
    },
    components:{
        ProductSlotsTable, ProductFAQs, ProductMoreInfo, ProductSellingSettings, NotificationsRestrictions
    },
    async setup() {
        onBeforeRouteLeave(async () => {
            const items = document.getElementsByClassName('show');
            if (items.length) {
                generalStore.commit('saveProductLastOpenedBlock', items[0].id);
            } else {
                generalStore.commit('saveProductLastOpenedBlock', '');
            }
            return true;
        });
        const { getAverageDuration, getOperationStatus } = useProduct();
        const showPage = ref(true);
        const showAllPrices = ref(false);
        const { t } = useI18n();
        const route = useRoute();
        const id = ref(route.params.id.toString());
        const productItem = ref(new Product());
        const map = ref();
        const googleMap = ref();
        const marker = ref();
        const mapOptions = {
            center: { lat: generalStore.getters.countryLatitude, lng: generalStore.getters.countryLongitude }, // By default Israel coordinates
            zoom: 10
        };
        const loader = new Loader({
            apiKey: generalStore.getters.googleMapsApiKey,
            version: 'weekly',
            language: generalStore.getters.currentLanguage,
            libraries: ['places']
        });
        const product = ref<Product>(new Product());

        async function onMountedAction() {
            if (route.params.fromCatalog == 'yes') {
                showPage.value = false;
                const app = document.getElementById('main-content');
                if (app) {
                    app?.scrollTo(0, 0);
                }
                await Q.delay(10);
                showPage.value = true;
            }

            swal.showLoading();
            const response = await api.getProduct(id.value);
            if (response.data) {
                product.value = response.data;
                productItem.value = product.value;
                swal.close();
            } else {
                swal.fire({
                    icon: 'error',
                    title: 'Such a product does not exist.',
                    text: response.errorMessage,
                    confirmButtonText: t('alert.backToCatalog')
                }).then(() => {
                    router.push({ name: 'catalog' });
                });
                return null;
            }

            if (product.value.longitude !== 0 && product.value.latitude !== 0) {
                await loader.load();
                googleMap.value = new window.google.maps.Map(map.value, mapOptions);
                const position = { lat: product.value.latitude, lng: product.value.longitude };
                marker.value = new window.google.maps.Marker({
                    position: position,
                    map: googleMap.value
                });
                googleMap.value.setCenter(position);
                googleMap.value.setZoom(15);
            } else {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        googleMap.value.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
                        googleMap.value.setZoom(15);
                    },
                    error => {
                        console.log(error.message);
                    }
                );
            }
          if (!product.value.productInfos.find(x => x.type == ProductInfoType.General)) {
            product.value.productInfos.push({
              id: 0,
              title: t('product-info.title.important'),
              description: '',
              type: ProductInfoType.General,
              translations: []
            });
          }
          if (!product.value.productInfos.find(x => x.type == ProductInfoType.Covid19)) {
            product.value.productInfos.push({
              id: 0,
              title: t('product-info.title.covid19'),
              description: '',
              type: ProductInfoType.Covid19,
              translations: []
            });
          }
        }
        onMounted(onMountedAction);
        function getAverageTime() {
            return getAverageDuration().find((x: AverageDurationTime) => x.id == product.value.averageDurationTime)?.name || t('product.averageDuration.0min');
        }

        function getCurrentOperationStatus() {
            return (
                getOperationStatus().find((x: ProductOperationStatus) => x.id == product.value.operationStatus)?.name ||
                t('product.operationStatus.new')
            );
        }

        if (!generalStore.state.tags.length) await generalStore.dispatch('loadAllTags');
        const tags = generalStore.state.tags;

        const weekNames = WeekNames;
        const grouppedTags = computed(() => groupBy(productItem.value.productTags, 'parentTagId'));

        if (!generalStore.state.productTypes.length) await generalStore.dispatch('loadAllProductTypes');
        const productTypes = generalStore.state.productTypes;

        function momentFormat(data: Date, formatType: string) {
            if (formatType === 'date') {
                return moment(data, moment.ISO_8601).format('DD/MM/YYYY');
            }
            if (formatType === 'datetime') {
                return moment(data, moment.ISO_8601).format('DD/MM/YYYY HH:mm');
            } else return moment(data, 'HH:mm:ss').format('HH:mm');
        }
        const getEndTime = (timeSlot: TimeSlot) => {
            const duration = moment.duration(timeSlot.startTime).add(timeSlot.duration);
            return moment.utc(duration.asMilliseconds()).format('HH:mm');
        };
        function routeToEditActivity(prodWorkingDayId: string) {
            router.push({
                name: 'edit-activity',
                params: { productId: id.value, productWorkingDayId: prodWorkingDayId },
            });
        }

        const priceTypes: IdOriginName[] = await generalStore.dispatch('getPriceTypes');
        function findCurrencySymbol(prodPriceId: number) {
            const found = productItem.value.productPrices.find((pp) => pp.id === prodPriceId);
            return generalStore.getters.getCurrencySymbol(found?.currencyId)
        }

        function createNewPricing() {
            generalStore.commit('setCurrentPricing', new ProductPrice());
            router.push({ name: 'product-price-list', params: { productId: productItem.value.id } });
        }

        const languagesId = tags.find((t) => t.name == 'Languages')?.id;
        const languagesTags = () => productItem.value.productTags.filter((t) => t.parentTagId == languagesId);

        const religionId = tags.find((t) => t.name == 'Religion')?.id;
        const religionTags = () => productItem.value.productTags.filter((t) => t.parentTagId == religionId);

        const foodDrinkId = tags.find((t) => t.name == 'Food and Drink')?.id;
        const foodDrinkTags = () => productItem.value.productTags.filter((t) => t.parentTagId == foodDrinkId);

        const entranceId = tags.find((t) => t.name == 'Entrance')?.id;
        const entranceTags = () => productItem.value.productTags.filter((t) => t.parentTagId == entranceId);

        const targetAudiencesId = tags.find((t) => t.name == 'Target Audiences')?.id;
        const targetAudiencesTags = () => productItem.value.productTags.filter((t) => t.parentTagId == targetAudiencesId);

        const includedNotIncludedId = tags.find((t) => t.name == 'Included - Not Included')?.id;
        const includedNotIncludedTags = () => productItem.value.productIncludedTags.filter((t) => t.parentTagId == includedNotIncludedId);

        const subTypeId = tags.find((t) => t.name == 'Sub Type')?.id;
        const subTypeTags = () => productItem.value.productTags.filter((t) => t.parentTagId == subTypeId);

        const difficultyLevelId = tags.find((t) => t.name == 'Difficulty Level')?.id;
        const difficultyLevelTags = computed<ProductTags[]>(() =>
            productItem.value.productTags.filter((t) => t.parentTagId == difficultyLevelId)
        );
        const transportAndParkingId = tags.find((t) => t.name == 'Transport and Parking')?.id;
        const transportAndParkingTags = () => productItem.value.productTags.filter((t) => t.parentTagId == transportAndParkingId);

        function findPriceTypeName(prodPriceId: number) {
            let result;
            const found = product.value.productPrices.find(pp => pp.id === prodPriceId);
            if (priceTypes && found)
                priceTypes.forEach((priceType) => {
                    if (priceType.id === found?.priceTypeId) {
                        result = priceType.name;
                    }
                });
            return result;
        }

        function CreateWorkingDates() {
            router.push({ name: 'create-working-dates', params: { id: productItem.value.id } });
        }

        function back() {
            router.go(-1);
        }
        const showFilter = ref(false);
        function showWarning(index: number) {
            document.getElementById('product-image-' + index)?.classList.remove('dp-none');
            document.getElementById('product-image-' + index)?.classList.add('dp-block');
        }
        function hideWarning(index: number) {
            document.getElementById('product-image-' + index)?.classList.remove('dp-block');
            document.getElementById('product-image-' + index)?.classList.add('dp-none');
        }

        function getRegistrationTypeName(type: number) {
            const result = ProductRegistrationType[type];
            return t("registrationType." + result);
        }
        return {
            createNewPricing,
            productTypes,
            transportAndParkingTags,
            languagesTags,
            religionTags,
            foodDrinkTags,
            entranceTags,
            targetAudiencesTags,
            includedNotIncludedTags,
            subTypeTags,
            findCurrencySymbol,
            findPriceTypeName,
            routeToEditActivity,
            id,
            productItem,
            momentFormat,
            weekNames,
            tags,
            grouppedTags,
            getEndTime,
            CreateWorkingDates,
            difficultyLevelTags,
            weekDays: DayOfWeekKendo,
            back,
            showAllPrices,
            getAverageTime,
            getCurrentOperationStatus,
            showFilter,
            map,
            showWarning,
            hideWarning,
            getRegistrationTypeName,
            OrderBlockTypes,
            priceTypes
        };
    },
});
