
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import { mapState, Store, mapGetters } from 'vuex';
import * as ApiInterfaces from '@/models/ApiInterfaces';

@Options({
  name: 'EditTranslations',
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState(['languages', 'lang']),
  },
})
export default class EditTranslations extends Vue {
  protected readonly type!: string;
  protected readonly id!: number;
  public editItem: ItemWithTranslation | null = null;
  public loaded = false;
  public mainLang: Language | null = null;
  public selectedLang = 0;

  protected readonly languages!: Language[];
  protected readonly lang!: string;

  async mounted() {
    swal.showLoading();
    this.mainLang = this.languages.find((x) => x.name == this.lang) || null;
    if (this.mainLang) {
      this.selectedLang = this.mainLang.id;
    }
    let getTagLangsResponse;
    if (this.type == 'regions') {
      getTagLangsResponse = await api.getRegionTranslations(this.id);
    } else {
      getTagLangsResponse = await api.getCityTranslations(this.id);
    }
    await Q.delay(400);
    if (getTagLangsResponse.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: getTagLangsResponse.errorMessage });
      return;
    }
    if (getTagLangsResponse.data) {
      this.editItem = {
        id: getTagLangsResponse.data.item.id,
        name: getTagLangsResponse.data.item.name,
        translations: getTagLangsResponse.data.item.translations,
        mainLang: { id: 0, entityId: 0, languageId: 0, name: '' },
      };
      this.languages.forEach((language) => {
        var lang = this.editItem?.translations.find((x) => x.languageId == language.id);
        if (!lang) {
          this.editItem?.translations.push({
            entityId: this.id,
            id: 0,
            languageId: language.id,
            name: ''
          });
        }
      });
      this.loaded = true;
    }
    swal.close();
  }

  cancel() {
    this.$router.go(-1);
  }
  async save() {
    if (!this.editItem) {
      return;
    }
    swal.showLoading();
    let toSave: ItemWithTranslation = JSON.parse(JSON.stringify(this.editItem));
    toSave.translations = toSave.translations.filter((x) => x.name);
    let request: ApiInterfaces.SaveItemWithTranslationRequest = {
      id: toSave.id,
      name: toSave.name,
      translations: toSave.translations,
      parentId: 0,
    };
    let apiResponse;
    if (this.type == 'regions') {
      apiResponse = await api.saveRegionTranslation(request);
    } else {
      apiResponse = await api.saveCityTranslation(request);
    }
    await Q.delay(400);
    if (apiResponse.errorMessage) {
      if (apiResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: apiResponse.errorMessage });
        return;
      }
    }
    swal.close();
    this.cancel();
  }
}
