
import api from '@/services/Api';
import swal from 'sweetalert2';
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import Q from 'q';
import { Permissions } from '@/models/Enums';

@Options({
  name: 'EditBoProfile',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  computed: {},
})
export default class EditBoProfile extends Vue {
  protected readonly id!: number;
  public permissions = Permissions;

  public profile: BoProfile = {
    id: 0,
    active: true,
    description: '',
    createdAt: '',
    permissions: [],
  };

  async mounted() {
    if (this.id > 0) {
      swal.showLoading();
      const apiResult = await api.getBoProfileData(this.id);
      await Q.delay(400);
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
      }
      if (apiResult.data) {
        this.profile = apiResult.data;
      }
      swal.close();
    }
  }
  cancel() {
    this.$router.go(-1);
  }
  async save() {
    swal.showLoading();
    const profileClone = JSON.parse(JSON.stringify(this.profile)) as BoProfile;
    profileClone.permissions = [];
    if (this.profile.permissions) {
      this.profile.permissions.forEach((element) => {
        profileClone.permissions.push(parseInt(element.toString()));
      });
    }
    const apiResult = await api.saveBoProfileData(profileClone);
    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({
        icon: 'error',
        text: apiResult.errorMessage,
      });
      return;
    }
    swal.close();
    this.$router.go(-1);
  }
}
