
import { Options, Vue } from 'vue-class-component';
import swal from 'sweetalert2';
import Q from 'q';
import Multiselect from '@vueform/multiselect';
import { ProductsFilter } from '@/models/Product';
import store from '@/store';
import router from '@/router';
import useProduct from '@/components/Product/useProduct';
import { ProductFilterSlot, ProductFilterPrice, ProductFilterPhoto } from '@/models/Enums';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import InputNumber from 'primevue/inputnumber';

@Options({
  components: {
    Multiselect,
    CheckBoxInput,
    InputNumber
  },
})
export default class ProductsFilterModal extends Vue {
  public filter = new ProductsFilter();
  public productTypes: ProductType[] = [];
  public cities: City[] = [];
  public subTags: SubTag[] = [];
  public allRegions: Region[] = [];
  public subTypeId = '';
  public targetAudiencesId = '';
  public entranceId = '';
  public foodDrinkId = '';
  public religionId = '';
  public languagesId = '';
  public transportAndParkingId = '';
  public includedNotIncludedId = '';

  getOperationStatus = useProduct().getOperationStatus;

  get subTypeOptions(): SubTag[] {
    return this.subTags.filter((t) => t.parentTagId == this.subTypeId);
  }
  get targetAudiencesOptions(): SubTag[] {
    return this.subTags.filter((t) => t.parentTagId == this.targetAudiencesId);
  }
  get entranceOptions(): SubTag[] {
    return this.subTags.filter((t) => t.parentTagId == this.entranceId);
  }
  get foodDrinkOptions(): SubTag[] {
    return this.subTags.filter((t) => t.parentTagId == this.foodDrinkId);
  }
  get religionOptions(): SubTag[] {
    return this.subTags.filter((t) => t.parentTagId == this.religionId);
  }
  get languageOptions(): SubTag[] {
    const options = this.subTags.filter((t) => t.parentTagId == this.languagesId);
    const hebrewIndex = options.findIndex((x) => x.id == 10);
    if (hebrewIndex > -1) {
      const item = options[hebrewIndex];
      options.splice(hebrewIndex, 1);
      options.unshift(item);
    }
    return options;
  }
  get transportAndParkingOptions(): SubTag[] {
    return this.subTags.filter((t) => t.parentTagId == this.transportAndParkingId);
  }
  get includedNotIncludedOptions(): SubTag[] {
    return this.subTags.filter((t) => t.parentTagId == this.includedNotIncludedId);
  }

  async mounted() {
    const sloterFilter = store.state.productsFilter;
    if (sloterFilter.active != null) sloterFilter.active = +sloterFilter.active;
    this.filter = JSON.parse(JSON.stringify(sloterFilter));

    if (!store.state.productTypes.length) await store.dispatch('loadAllProductTypes');
    this.productTypes = store.state.productTypes;

    if (!store.state.cities.length) await store.dispatch('loadAllCities');
    this.cities = store.state.cities;

    if (!store.state.subTags.length) await store.dispatch('loadAllSubTags');
    this.subTags = store.state.subTags;

    if (!store.state.tags.length) await store.dispatch('loadAllTags');
    const tags = store.state.tags;

    if (!store.state.regions.length) await store.dispatch('loadAllRegions');
    this.allRegions = store.state.regions;

    this.subTypeId = tags.find((t) => t.name == 'Sub Type')?.id ?? '';
    this.targetAudiencesId = tags.find((t) => t.name == 'Target Audiences')?.id ?? '';
    this.entranceId = tags.find((t) => t.name == 'Entrance')?.id ?? '';
    this.foodDrinkId = tags.find((t) => t.name == 'Food and Drink')?.id ?? '';
    this.religionId = tags.find((t) => t.name == 'Religion')?.id ?? '';
    this.languagesId = tags.find((t) => t.name == 'Languages')?.id ?? '';
    this.transportAndParkingId = tags.find((t) => t.name == 'Transport and Parking')?.id ?? '';
    this.includedNotIncludedId = tags.find((t) => t.name == 'Included - Not Included')?.id ?? '';

    await Q.delay(400);
    swal.close();
  }

  //functions
  public ResetFilter() {
    store.commit('saveProductsFilter', new ProductsFilter());
    router.push({ name: 'catalog' });
  }

  public Close() {
    router.push({ name: 'catalog' });
  }

  public GetSlotFilter() {
    const arrayObjects = [];
    for (const [propertyKey, propertyValue] of Object.entries(ProductFilterSlot)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      arrayObjects.push({ id: propertyValue, name: this.$t('product.filter.slot.' + propertyKey) });
    }
    return arrayObjects;
  }
  public GetPriceFilter() {
    const arrayObjects = [];
    for (const [propertyKey, propertyValue] of Object.entries(ProductFilterPrice)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      arrayObjects.push({ id: propertyValue, name: this.$t('product.filter.price.' + propertyKey) });
    }
    return arrayObjects;
  }
  public GetPhotoFilter() {
    const arrayObjects = [];
    for (const [propertyKey, propertyValue] of Object.entries(ProductFilterPhoto)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      arrayObjects.push({ id: propertyValue, name: this.$t('product.filter.photo.' + propertyKey) });
    }
    return arrayObjects;
  }

  public Search() {
    if (!this.filter.idNoHash) this.filter.idNoHash = null;
    if (!this.filter.providerIdNoHash) this.filter.providerIdNoHash = null;
    if (this.filter.name?.trim() === '') this.filter.name = null;
    if (this.filter.providerName?.trim() === '') this.filter.providerName = null;
    if (this.filter.id?.trim() === '') this.filter.id = null;
    if (this.filter.providerId?.trim() === '') this.filter.providerId = null;
    if (this.filter.email?.trim() === '') this.filter.email = null;
    if (this.filter.mobile?.trim() === '') this.filter.mobile = null;
    if (this.filter.keyword?.trim() === '') this.filter.keyword = null;
    if (this.filter.operationStatus === null) this.filter.operationStatus = null;

    store.commit('saveProductsFilter', this.filter);
    router.push({ name: 'catalog' });
  }
}
