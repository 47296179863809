
import { ProductRowResult, ProductsFilter } from '@/models/Product';
import { inject, defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';
import store from '@/store';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import router from '@/router';
import { onBeforeRouteLeave } from 'vue-router';
import useExtensions from '@/useExtensions';
import { useI18n } from 'vue-i18n';
import { ProductFilterPhoto, ProductFilterPrice, ProductFilterSlot } from '@/models/Enums'

export default defineComponent({
    name: 'Catalog',
    async setup() {
        const emitter: any = inject('emitter');

        onMounted(async() => {
            emitter.on('isBottom', listenerGetMyProducts);
        })

        onUnmounted( async() => {
            emitter.off('isBottom');
        })

        onBeforeRouteLeave(async to => {
            if (to.name != 'modal-products-filter' && to.name != 'product' && to.name != 'edit-product' && to.name != 'provider-view') {
                store.commit('saveProductsFilter', new ProductsFilter());
            }
            return true;
        });

        var { awaitTask } = useExtensions();
        const { t } = useI18n();

        const products = ref<ProductRowResult[]>([]);
        const hasFilter = computed<boolean>(() => {
            if (filter.value.productCity) return true;
            if (filter.value.name) return true;
            if (filter.value.id) return true;
            if (filter.value.idNoHash) return true;
            if (filter.value.typeId) return true;
            if (filter.value.cityId) return true;
            if (filter.value.subTypes.length) return true;
            if (filter.value.targetAudiences.length) return true;
            if (filter.value.entrances.length) return true;
            if (filter.value.foodAndDrinks.length) return true;
            if (filter.value.religions.length) return true;
            if (filter.value.languages.length) return true;
            if (filter.value.transportAndParking.length) return true;
            if (filter.value.included.length) return true;
            if (filter.value.excluded.length) return true;
            if (filter.value.regions.length) return true;
            if (filter.value.active !== null) return true;
            if (filter.value.providerName) return true;
            if (filter.value.providerId) return true;
            if (filter.value.providerIdNoHash) return true;
            if (filter.value.email) return true;
            if (filter.value.mobile) return true;
            if (filter.value.slotFilter != ProductFilterSlot.All) return true;
            if (filter.value.priceFilter != ProductFilterPrice.All) return true;
            if (filter.value.photoFilter != ProductFilterPhoto.All) return true;
            if (filter.value.operationStatus) return true;
            return false;
        });

        const filter = ref<ProductsFilter>(new ProductsFilter());
        filter.value = store.getters.getProductsFilter
        
        const total = ref(0);
        const totalFiltered = ref(0);
        const busy = ref(false);
        const hasNext = ref(false);
        const sortParam = ref('');

        async function getProducts(pageIndex?: number) {
            if (busy.value) return;
            if (!pageIndex) pageIndex = 0;
            const submitSearchButton = document.getElementById('submit-search-button');
            submitSearchButton?.blur();

            swal.showLoading();

            busy.value = true;
            filter.value.pageIndex = pageIndex;
            if (filter.value.active != null) 
                filter.value.active = Boolean(filter.value.active);
                
            if (!filter.value.keyword) filter.value.keyword = null;
            if (!filter.value.useExpiredSlots) {
                filter.value.filterExpiredSlots = null;
            }
            const apiResult = await api.getProducts({
                filter: filter.value
            });
            await Q.delay(400);
            if (apiResult.data?.error) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error
                });
                return;
            }
            if (apiResult.data) {
                if (filter.value.pageIndex == 0) {
                    products.value = apiResult.data.rows;
                    total.value = apiResult.data.total;
                    totalFiltered.value = apiResult.data.totalFiltered;
                } else 
                    products.value = [...products.value, ...apiResult.data.rows];
            }

            busy.value = false;
            swal.close();
            filter.value.pageIndex = pageIndex;

            hasNext.value = apiResult?.data?.hasNext || false;
        }

        function listenerGetMyProducts() {
            if (hasNext.value && !busy.value) {
                getProducts(filter.value.pageIndex + 1);
            }
        }
        
        await getProducts();

        const getArrowClass = (sort: string) => {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }
        const sortBy = async (sortBy: string) => {
            if (filter.value.orderBy != sortBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sortBy;
            await getProducts();
        }

        const goProductsFilter = () => {
            if (filter.value.keyword) store.commit('saveProductsFilter', filter);
            router.push({ name: 'modal-products-filter' });
        }
        const showAll = async () => {
            filter.value = new ProductsFilter();
            products.value = [];
            store.commit('saveProductsFilter', filter);
            await getProducts();
        }

        const exportProducts = async () => {
            swal.showLoading();
            if (filter.value && filter.value.active != null) 
                filter.value.active = Boolean(filter.value.active);

            const apiResult = await api.exportProducts(filter.value);
            await Q.delay(400);
            if (apiResult.data?.error || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error || 'No data',
                });
                return;
            }
            const taskId = apiResult.data.taskId;
            awaitTask(() => api.waitExportTask(taskId),
                (url) => {
                    swal.fire({
                    icon: 'success',
                    title: t('alert.exportReady.products'),
                    html: `
                        <div class="text-center my-4">
                            <a href="${url}" target="_blank">
                                <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                            </a>
                        </div>`,
                    showConfirmButton: false,
                    showCloseButton: true,
                    });
                },
                t
            );
        }
        async function setNotConfirmedFilter() {
            filter.value.photoFilter = ProductFilterPhoto.NoConfirmedPhoto;
            store.commit('saveProductsFilter', filter);
            await getProducts();
        }
        return { products, getProducts, filter, sortParam, getArrowClass, sortBy, goProductsFilter, total, totalFiltered, showAll, hasFilter, exportProducts, setNotConfirmedFilter };
    }
});
