
import { Invoice } from '@/models/Accounting';
import { computed, reactive } from 'vue';
import generalStore from '@/store';
// import { Currency } from '@/models/Interfaces';
import api from '@/services/Api';
import swal from 'sweetalert2';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import Q from 'q';

export default {
    components: {},
    props: {
        invoiceId: {
            type: String
        }
    },
    async setup(props: any) {
        const { t } = useI18n();

        const currencies = computed<Currency[]>(() => generalStore.state.currencies);
        const nisCurrencyId = currencies.value.find(c => c.name == 'NIS')?.id;

        const apiResult = await api.getInvoice(props.invoiceId);
        if (apiResult.errorMessage) {
            swal.fire({
                icon: 'error',
                text: apiResult.errorMessage
            });
        }
        const invoice = reactive<Invoice>(apiResult.data || new Invoice());

        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        const close = () => router.go(-1);

        async function showPdf() {
            if (invoice.pdfUrl) {
                window.open(invoice.pdfUrl, '_blank');
            } else {
                swal.showLoading();
                const response = await api.GetDocumentPdfUrl(props.invoiceId);
                await Q.delay(400);
                swal.close();
                if (response.errorMessage) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
                } else if (response.data) {
                    if (response.data.pdfUrl) {
                        invoice.pdfUrl = response.data.pdfUrl;
                        window.open(response.data.pdfUrl, '_blank');
                    } else {
                        swal.fire({
                            icon: 'warning',
                            title: t('alert.pfgNotReady'),
                            confirmButtonText: t('button.close')
                        });
                    }
                }
            }
        }

        return {
            invoice,
            currencies,
            getCurrencySymbol,
            close,
            nisCurrencyId,
            showPdf
        };
    }
};
