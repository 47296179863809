
import { defineComponent, PropType, computed } from 'vue';
import { ProductInfoType } from '@/models/Enums';
import { ProductMoreInfo } from '@/models/Product';
import router from '@/router';

export default defineComponent({
  name: 'ProductMoreInfo',
  props: {
    productId: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    items: {
      type: [] as PropType<ProductMoreInfo[]>,
      default: () => [],
    },
  },
  async setup(props, { emit }: any) {
    const infos = computed(() => props.items?.map((x) => x).sort((a, b) => a.type - b.type));
    const productInfoType = ProductInfoType;
    const existCustomInfoType = computed<boolean>(
      () => (props.items as ProductMoreInfo[]).find((x) => x.type == productInfoType.Custom) != null,
    );

    function addCustomInfoType() {
      emit('addCustomInfoType');
    }

    function removeCustomInfoType() {
      emit('removeCustomInfoType');
    }
    function addTranslations(id: string, type: ProductInfoType) {
      router.push({
        name: 'edit-product-translation',
        params: {
          productId: props.productId,
          id: id,
          type: 'moreinfo',
          infoType: type,
        },
      });
    }

    return { productInfoType, existCustomInfoType, removeCustomInfoType, addCustomInfoType, infos, addTranslations };
  },
});
