
import api from '@/services/Api';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import router from '@/router';
import Tags from '@/components/Settings/Tags.vue';
import CurrencyRates from '@/components/Settings/CurrencyRates.vue';
import BoUsers from '@/components/Settings/BoUsers.vue';
import BoProfiles from '@/components/Settings/BoProfiles.vue';
import { Options, Vue } from 'vue-class-component';
import swal from 'sweetalert2';
import generalStore from '@/store';

@Options({
  name: 'Settings',
  props: {
    tagType: {
      type: String,
      required: true,
    },
    tagId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Multiselect,
    Tags,
    Calendar,
    CurrencyRates,
    BoUsers,
    BoProfiles,
  },
  computed: {},
})
export default class Settings extends Vue {
  public tags: IdName[] = [];
  public profiles: SettingProfile[] = [];
  public users: ProviderUsers[] = [];
  public currencyFilter = 0;
  public currencyRatesItems: CurrencyRate[] = [];
  public loaded = false;

  async mounted() {
    const getSettingsResponse = await api.getSettings();
    if (getSettingsResponse.errorMessage) {
      swal.fire({
        icon: 'error',
        text: getSettingsResponse.errorMessage,
      });
      return;
    }
    if (getSettingsResponse.data) {
      this.tags = getSettingsResponse.data.tags;
      this.users = getSettingsResponse.data.users;
      generalStore.commit('updateUserData', getSettingsResponse.data.users.find(x => x.id == generalStore.state.user?.id));
      this.currencyRatesItems = getSettingsResponse.data.currencies;
      this.profiles = getSettingsResponse.data.profiles;

      this.loaded = true;
    }
  }

  editLocation(locationName: string) {
    router.push({ name: 'Locations', params: { type: locationName } });
  }

  getCurrDate(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }
}
