
import api from '@/services/Api';
import { defineComponent, ref, reactive } from 'vue';
import swal from 'sweetalert2';
import router from '@/router';
import { useRoute } from 'vue-router';
import { PromotedCategory, PromotedCategoryRegion } from '@/models/Product';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';
import { DateRange } from '@/models/Order';
import moment from 'moment';
import { PromotedType } from '@/models/Enums';
import store from '@/store';

export default defineComponent({
  name: 'EditPromoCategory',
  components: {
    Multiselect,
  },
  async setup() {
    const promoCategory = ref<PromotedCategory>(new PromotedCategory());
    var productTypes: ProductType[] = [];
    var subTags: SubTag[] = [];
    var regions: Region[] = [];
    const dateRange = reactive(new DateRange());
    const promoCategorySubTags = ref<string[]>([]);
    const promotedCategoryRegions = ref<string[]>([]);
    const route = useRoute();
    const validated = ref(false);
    var { t } = useI18n();

    const promoCounter = ref(0);
    if (!store.state.productTypes.length) await store.dispatch('loadAllProductTypes');
    productTypes = store.state.productTypes;
    if (!store.state.subTags.length) await store.dispatch('loadAllSubTags');
    subTags = store.state.subTags;
    if (!store.state.regions.length) await store.dispatch('loadAllRegions');
    regions = store.state.regions;

    async function loadPromo() {
      var promoId = (route.params.id && Number(route.params.id)) || null;
      if (!promoId) {
        promoCategory.value = new PromotedCategory();
        return;
      } else {
        const response = await api.GetPromotedCategory(promoId);
        if (!response || response.error) {
          swal.fire({
            icon: 'error',
            text: response.errorMessage || 'No data',
          });
        } else if (response.data) {
          promoCategory.value = response.data;
          if (promoCategory.value.imagePath) {
            const str = promoCategory.value.imagePath + "?v=" + new Date();
            promoCategory.value.imageToShow = str;
          }

          if (promoCategory.value.fromDate) {
            dateRange.fromDate = new Date(promoCategory.value.fromDate);
          }
          if (promoCategory.value.toDate) {
            dateRange.toDate = new Date(promoCategory.value.toDate);
          }
          if (promoCategory.value.subTagIds) {
            promoCategorySubTags.value = promoCategory.value.subTagIds.split(',');
          }
          promoCategory.value.promotedCategoryRegions.forEach(r => {
            const foundRegion = regions.find(x => x.id === r.regionId);
            if (foundRegion) {
              promotedCategoryRegions.value.push(foundRegion.id);
            }
          });
        }
      }
    }

    async function GetPromotedProductsCount() {
      var response = await api.GetPromotedProductsCount(4);
      if (!response || response.error) {
        swal.fire({
          icon: 'error',
          text: response.errorMessage || 'No data',
        });
      }
      if (response.data) {
        promoCounter.value = response.data;
      }
    }
    await loadPromo();
    await GetPromotedProductsCount();

    function submitForm(event: any) {
      validated.value = true;
      event.target.classList.add('was-validated');
      if (event.target.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        swal.fire({
          icon: 'warning',
          title: t('alert.mandatoryfields'),
          confirmButtonText: t('button.close'),
        });
      } else {
        onSave();
      }
    }

    async function onSave() {
      swal.showLoading();
      const tempPromoCatRegions: PromotedCategoryRegion[] = JSON.parse(JSON.stringify(promoCategory.value.promotedCategoryRegions));
      promoCategory.value.promotedCategoryRegions = [];
      promotedCategoryRegions.value.forEach(regionId => {
        const foundRegion = regions.find(x => x.id === regionId);
        if (foundRegion) {
          const promoRegion = new PromotedCategoryRegion();
          promoRegion.regionId = foundRegion.id;
          promoRegion.regionName = foundRegion.name;
          promoCategory.value.promotedCategoryRegions.push(promoRegion);
        }
      });

      if (tempPromoCatRegions && tempPromoCatRegions.length > 0) {
        for (let index = 0; index < tempPromoCatRegions.length; index++) {
          if (promoCategory.value.promotedCategoryRegions[index]) {
            const idIsNullOrZero = !promoCategory.value.promotedCategoryRegions[index].id || promoCategory.value.promotedCategoryRegions[index].id === "0";
            const promoCatRegIdNullOrZero = !promoCategory.value.promotedCategoryRegions[index].id || promoCategory.value.promotedCategoryRegions[index].id === "0";

            if (idIsNullOrZero && tempPromoCatRegions[index].id) {
              promoCategory.value.promotedCategoryRegions[index].id = tempPromoCatRegions[index].id;
            }
            if (promoCatRegIdNullOrZero && tempPromoCatRegions[index].promotedCategoryId) {
              promoCategory.value.promotedCategoryRegions[index].promotedCategoryId = tempPromoCatRegions[index].promotedCategoryId;
            }
          }
        }
      }

      if (promoCategorySubTags.value && promoCategorySubTags.value.length) {
        promoCategory.value.subTagIds = promoCategorySubTags.value.join();
      } else {
        promoCategory.value.subTagIds = '';
      }
      if (!promoCategory.value.imagePath && !promoCategory.value.image) {
        swal.fire({
          icon: 'warning',
          title: t('alert.mandatoryfields'),
          confirmButtonText: t('button.close'),
        });
        return;
      }

      if (dateRange.fromDate) {
        promoCategory.value.fromDate = moment(dateRange.fromDate).format('YYYY-MM-DD');
      }

      if (dateRange.toDate) {
        promoCategory.value.toDate = moment(dateRange.toDate).format('YYYY-MM-DD');
      }

      var apiResponse = await api.SavePromotedCategory(promoCategory.value);
      validated.value = false;
      if (apiResponse.errorMessage || !apiResponse.data) {
        swal.fire({
          icon: 'error',
          text: apiResponse.errorMessage || 'No data',
        });
        return false;
      }

      await swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Promo ' + (promoCategory.value.id ? 'updated' : 'created'),
        showConfirmButton: false,
        timer: 1500,
      });
      router.replace({ name: 'PromoManagement', params: { type: PromotedType[PromotedType.Category] } });
      return true;
    }

    async function onCancel() {
      router.replace({ name: 'PromoManagement', params: { type: PromotedType[PromotedType.Category] } });
      return;
    }

    function clearImage() {
      promoImage.value = null;
      promoCategory.value.imagePath = '';
      promoCategory.value.image = '';
      promoCategory.value.imageToShow = '';
    }

    const promoImage = ref();
    const uploadingImageValidation = async (e: any) => {
      swal.showLoading();
      let file = e.target.files[0];
      const fileTypes: string[] = ['image/jpeg', 'image/png'];
      if (file) {
        if (file.size > 5242880) {
          swal.fire({
            icon: 'error',
            text: 'File size should be less or equal to 5 MB',
          });
          file = null;
          swal.close();
          return;
        }
        if (!fileTypes.find((t) => t === file.type)) {
          swal.fire({
            icon: 'error',
            text: 'Allowing file types: png, jpeg',
          });
          file = null;
          return;
        }
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            promoImage.value = event.target.result;
            promoCategory.value.image = event.target.result
              .replace('data:image/jpeg;base64,', '')
              .replace('data:image/png;base64,', '');
          };
          reader.readAsDataURL(file);
        }
      }
      swal.close();
      validated.value = false;
    };

    function dateRangeValidate(dateRange: DateRange) {
      const isNotValidDates = moment(dateRange.fromDate) > moment(dateRange.toDate);
      if (isNotValidDates) {
        dateRange.toDate = dateRange.fromDate;
      }
    }

    const dateChanged = () => {
      if (!dateRange.fromDate || !dateRange.toDate) {
        return;
      }
      dateRangeValidate(dateRange);
    };
    return {
      submitForm,
      promoCategory,
      onSave,
      onCancel,
      uploadingImageValidation,
      clearImage,
      promoImage,
      dateChanged,
      dateRange,
      promoCategorySubTags,
      productTypes,
      subTags,
      regions,
      promoCounter,
      promotedCategoryRegions,
      validated
    };
  },
});
