
import { reactive } from 'vue';
import { Receipt } from '@/models/Accounting';
import generalStore from '@/store';
import router from '@/router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import moment from 'moment';
import Q from 'q';
import { useI18n } from 'vue-i18n';

export default {
    props: {
        receiptId: {
            type: String,
            required: true
        }
    },
    async setup(props: any) {
        const { t } = useI18n();
        const apiResult = await api.getReceipt(props.receiptId);
        if (apiResult.errorMessage) {
            swal.fire({
                icon: 'error',
                text: apiResult.errorMessage
            }).then(() => router.go(-1));
            return;
        }
        const receipt = reactive<Receipt>(apiResult.data || new Receipt());
        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        const close = () => router.go(-1);

        async function showPdf() {
            if (receipt.pdfUrl) {
                window.open(receipt.pdfUrl, '_blank');
            } else {
                swal.showLoading();
                const response = await api.GetDocumentPdfUrl(props.receiptId);
                await Q.delay(400);
                swal.close();
                if (response.errorMessage) { 
                    swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
                }
                else if (response.data) {
                    if (response.data.pdfUrl) {
                        receipt.pdfUrl = response.data.pdfUrl;
                        window.open(response.data.pdfUrl, '_blank');
                    } else {
                        swal.fire({
                            icon: 'warning',
                            title: t('alert.pfgNotReady'),
                            confirmButtonText: t('button.close'),
                        });
                    }
                }
            }
        }

        return {
            getCurrencySymbol,
            receipt,
            close,
            moment,
            showPdf
        };
    }
};
