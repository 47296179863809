
import NavBar from '@/components/NavBar.vue';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { Emitter, EventType } from 'mitt';

function parseDimension(val: string | number) {
  if (!val) return undefined;
  if (Number(val)) return val + 'px';
  return val;
}

@Options({
  components: {
    NavBar,
  },
  inject: ['emitter'],
})
export default class AppLayout extends Vue {
  public emitter!: Emitter<Record<EventType, unknown>>;

  public get routeMeta() {
    let meta = this.$route.meta;
    return meta;
  }
  public get height() {
    return this.routeMeta.height as string | number;
  }
  public get heightMobile() {
    return this.routeMeta.heightMobile as string | number;
  }
  public get routeAdditionalClass() {
    return this.routeMeta.class as string;
  }
  public get routeName() {
    return this.routeMeta.header as string;
  }
  public get isAuthenticated() {
    return store.state.isAuthenticated;
  }
  get headerStyle() {
    const defaultHeight = parseDimension(this.height) || '200px';
    return {
      '--desktop-height': defaultHeight,
      '--mobile-height': parseDimension(this.heightMobile) || defaultHeight,
    };
  }

  mounted() {
    this.$el.addEventListener('scroll', this.handleScroll);
    window.onresize = () => this.handleResize();
    this.handleResize();
  }

  beforeUnmount() {
    window.onresize = null;
  }

  public handleScroll() {
    const app = document.getElementById('main-content');
    const layoutHeader = document.getElementById('layout-header');
    const NavBar = document.getElementById('main-navbar');
    if (app && layoutHeader && NavBar) {
      if (app.scrollTop + app.clientHeight >= app.scrollHeight) {
        this.emitter.emit('isBottom');
      }
      // const limit = layoutHeader.clientHeight - 160;
      if (app.scrollTop > 5) {
        if (!NavBar.classList.contains('not-transition')) {
          NavBar.classList.add('not-transition');
        }
      } else {
        if (NavBar.classList.contains('not-transition')) {
          NavBar.classList.remove('not-transition');
        }
      }
    }
  }

  public handleResize() {
    const app = document.getElementById('app');
    var isMobile = (app?.offsetWidth || 0) < 992;
    if (store.state.isMobileView != isMobile) {
      store.commit('setIsMobileView', isMobile);
    }
  }
}
