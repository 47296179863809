
import { AgencyDetails } from '@/models/Agencies';
import { defineComponent, PropType, ref, watch, toRaw, computed } from 'vue';
import AgencyUsers from '@/components/Index/AgencyUsers.vue';

export default defineComponent({
  name: 'AgencyDetailsComponent',
  components: {
    AgencyUsers,
  },
  props: {
    modelValue: {
      type: Object as PropType<AgencyDetails>,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  async setup(props) {
    function copyModel(value: AgencyDetails) {
      return JSON.parse(JSON.stringify(value)) as AgencyDetails;
    }
    const agency = ref<AgencyDetails>(copyModel(props.modelValue));

    function getAgency() {
      return toRaw(agency.value);
    }

    watch(
      () => props.modelValue,
      (newValue) => {
        agency.value = copyModel(newValue);
      },
    );
    function checkInputLength(inputName: string) {
      if (inputName == 'name') {
          if (agency.value.name.length > 30) {
            agency.value.name = agency.value.name.substring(0, 30);
          }
      }
      if (inputName == 'description') {
          if (agency.value.description.length > 120) {
            agency.value.description = agency.value.description.substring(0, 120);
          }
      }
    }
    const validateWebsite = computed(() => {
      if (agency.value.website.includes('https')) {
        return agency.value.website
      } else {
        return "https://" + agency.value.website
      }
    })
    function additionalValidation() {
      agency.value.mobile = agency.value.mobile.replace(/ /g, '');
      agency.value.mobile = agency.value.mobile.replace(/-/g, '');
    }
    return {
      agency,
      getAgency,
      checkInputLength,
      validateWebsite,
      additionalValidation
    };
  },
});
