
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    tags: {
      type: [],
      required: true,
    }
  },
  name: 'Tags',
})
export default class Tags extends Vue {
  protected readonly tags!: IdName[];
  editTag(Id: string) {
    this.$router.push({ name: 'editTag', params: { tagId: Id } });
  }

  CreateNewTag() {
    this.$router.push({ name: 'CreateNewTag' });
  }
}
