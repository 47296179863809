
import { AgencyRow, AgenciesFilter } from '@/models/Agencies';
import api from '@/services/Api';
import store from '@/store';
import swal from 'sweetalert2';
import Q from 'q';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import { Emitter, EventType } from 'mitt';
import Calendar from 'primevue/calendar';
import moment from 'moment';

@Options({
  name: 'AgenciesTable',
  components: {
    Calendar,
  },
  computed: {
    ...mapState({
      locale: 'lang',
      isMobileView: 'isMobileView',
    }),
  },
  inject: ['emitter'],
})
export default class Agencies extends Vue {
  public emitter!: Emitter<Record<EventType, unknown>>;

  public busy = false;
  public hasNext = false;
  public total = 0;
  public totalFiltered = 0;
  public filter: AgenciesFilter = new AgenciesFilter();
  public rows: AgencyRow[] = [];

  mounted() {
    this.$route.params.newSearch = '1';

    this.search();
  }

  activated() {
    this.emitter.on('isBottom', this.ListenerGetNextPage);
    this.filter = JSON.parse(JSON.stringify(store.state.agenciesFilter));

    const refreshAgencySearch = store.state.refreshAgencySearch;

    if (this.$route.params.newSearch || refreshAgencySearch) {
      if (!this.$route.params.keepFilter) {
        store.commit('saveAgenciesFilter', new AgenciesFilter());
        this.filter = new AgenciesFilter();
      }
      this.filter.pageIndex = 0;
      this.rows = [];
      this.search();
    }
  }
  deactivated() {
    this.emitter.off('isBottom', this.ListenerGetNextPage);
  }

  goToDetails(agencyId: string) {
    this.$router.push({ name: 'agency-view', params: { id: agencyId } });
  }

  async search(pageIndex?: number) {
    store.commit('setRefreshAgencySearch', false);

    if (this.busy) return;
    if (!pageIndex) pageIndex = 0;

    swal.showLoading();
    this.busy = true;
    this.filter.pageIndex = pageIndex;
    if (this.filter.active != null) this.filter.active = this.filter.active = Boolean(this.filter.active);

    const reqFilter = JSON.parse(JSON.stringify(this.filter));
    if (reqFilter.createDate) {
      reqFilter.createDate = moment.utc(reqFilter.createDate).local().format('YYYY-MM-DD');
    }

    const apiResult = await api.filterAgencies({
      filter: reqFilter,
    });
    await Q.delay(400);
    if (apiResult.data?.error || !apiResult.data) {
      swal.fire({
        icon: 'error',
        text: apiResult.data?.error || 'No data',
      });
      this.busy = false;
      return;
    }

    this.hasNext = apiResult.data.hasNext || false;

    if (this.filter.pageIndex == 0) {
      this.total = apiResult.data.total;
      this.totalFiltered = apiResult.data.totalFiltered;
      this.rows = apiResult.data.rows || [];
    } else {
      this.rows = [...this.rows, ...apiResult.data.rows];
    }
    this.filter.pageIndex = pageIndex;
    this.busy = false;
    swal.close();
  }

  ListenerGetNextPage() {
    if (this.hasNext && !this.busy) {
      this.search(this.filter.pageIndex + 1);
    }
  }

  async showAll() {
    this.filter = new AgenciesFilter();
    this.rows = [];
    await this.search();
  }

  async sortBy(sotrBy: string) {
    if (this.filter.orderBy != sotrBy) {
      this.filter.asc = true;
    } else {
      this.filter.asc = !this.filter.asc;
    }
    this.filter.orderBy = sotrBy;
    await this.search();
  }

  getArrowClass(sort: string) {
    if (sort == this.filter.orderBy) {
      if (this.filter.asc) {
        return 'top-sort-arrow';
      } else {
        return 'bottom-sort-arrow';
      }
    }
    return 'standart-sort-arrow';
  }
  trimDate() {
    setTimeout(() => {
      if (this.filter.createDate) {
        this.filter.createDate = this.filter.createDate.trim();
      }
    }, 0);
  }
  additionalValidation() {
    this.filter.mobile = this.filter.mobile.replace(/ /g, '');
    this.filter.mobile = this.filter.mobile.replace(/-/g, '');
  }
}
