
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/views/Modals/OverlayModal.vue';
import { QtyReportItem } from '@/models/ApiInterfaces';

export default defineComponent({
  components: { OverlayModal },
  name: 'OrderQtyReportModal',
  async setup() {
    const isOpen = ref(false);
    const Items = ref<QtyReportItem[]>([]);
    function open(items: QtyReportItem[]) {
      isOpen.value = true;
      Items.value = items;
    }
    return {
      open,
      isOpen,
      Items,
    };
  },
});
