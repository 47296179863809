
import { defineComponent, computed, ref, onBeforeUnmount, nextTick, watch } from 'vue';
import api from '@/services/Api';
import store from '@/store';
import { ChatFilter, ChatItem, ChatType, OrderQuoteRequestedTime } from '@/models/ChatQoutes';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ProductChat from '@/components/Index/ProductChat.vue';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
    components: { ProductChat, Multiselect },
    name: 'ChatQuotes',
    async setup() {
        const chatTimerId = ref();
        const filter = ref(new ChatFilter());
        const { t } = useI18n();
        const route = useRoute();
        const messageId = computed(() => route.params.messageId?.toString());

        const products = ref<IdName[]>([]);
        const providers = ref<IdName[]>([]);
        const indexAgencies = ref<IdName[]>([]);
        const indexUsers = ref<IdName[]>([]);
        const chatTypes: IdName[] = [
            { id: '1', name: t('chat.chatType.chat') },
            { id: '2', name: t('chat.chatType.quote') }
        ];

        onBeforeUnmount(() => {
            clearTimeout(chatTimerId.value);
        });

        const selectedChat = ref<ChatItem | null>(null);
        const selectedChatIndex = ref<number | null>(null);
        const selectedOrderQuoteId = computed(() => selectedChat.value?.orderQuote?.id);
        const selectedIndexUserId = computed(() => selectedChat.value?.indexUserId);
        const getCurrencySymbol = (id: string) => store.getters.getCurrencySymbol(id);

        const chats = ref<ChatItem[]>([]);

        const filteredChats = computed(() => {
            let results = chats.value;
            if (filter.value.productId) results = results?.filter(c => c.productId === filter.value.productId);
            if (filter.value.chatType) {
                if (filter.value.chatType == ChatType.Message) results = results?.filter(c => c.orderQuote === null);
                else results = results?.filter(c => c.orderQuote);
            }
            if (filter.value.providerId) results = results?.filter(c => c.providerId === filter.value.providerId);
            if (filter.value.indexAgencyId) results = results?.filter(c => c.indexAgencyId === filter.value.indexAgencyId)
            if (filter.value.indexUserId) results = results?.filter(c => c.indexUserId === filter.value.indexUserId);
            if (filter.value.keyword)
                results = results?.filter(
                    c =>
                        c.groupName?.toLowerCase().includes(filter.value.keyword.toLowerCase()) ||
                        c.agencyName.toLowerCase().includes(filter.value.keyword.toLowerCase()) ||
                        c.indexUserName.toLowerCase().includes(filter.value.keyword.toLowerCase()) ||
                        c.providerName.toLowerCase().includes(filter.value.keyword.toLowerCase())
                );
            return results;
        });

        const allFilterData = async () => {
            const filterDataResults = await api.GetAllFilterData();
            if (filterDataResults.data) {
                products.value = filterDataResults.data.products;
                providers.value = filterDataResults.data.providers;
                indexAgencies.value = filterDataResults.data.indexAgencies;
                indexUsers.value = filterDataResults.data.indexUsers;
            }
        };

        allFilterData();

        const refreshSelectedChatIndex = () => {
            if (selectedChat.value) {
                selectedChatIndex.value = filteredChats.value.findIndex(
                    c =>
                        c.productId === selectedChat.value?.productId &&
                        c.providerId === selectedChat.value.providerId &&
                        c.orderQuote?.id === selectedChat.value.orderQuote?.id &&
                        c.indexUserId === selectedChat.value.indexUserId
                );
            }
        };

        const chooseChat = async (chat: ChatItem, index: number) => {
            selectedChat.value = null;
            selectedChatIndex.value = null;
            await nextTick();
            selectedChat.value = chat;
            selectedChatIndex.value = index;
            if (chat.hasUnreadedMessages) chat.hasUnreadedMessages = false;
        };

        const chooseChatByMessageId = () => {
            if (filteredChats.value && messageId.value) {
                for (let index = 0; index < filteredChats.value.length; index++) {
                    const chat = filteredChats.value[index];
                    if (chat.messages.some(m => m.id == messageId.value)) {
                        chooseChat(chat, index);
                        break;
                    }
                }
            }
        };

        const getChats = async () => {
            const chatResult = await api.GetChatsAndQuotes(filter.value);
            if (chatResult.data) {
                chats.value = chatResult.data.chats;
                refreshSelectedChatIndex();
            }

            clearTimeout(chatTimerId.value);
            chatTimerId.value = setTimeout(getChats, 30 * 1000);
        };

        watch(messageId, () => {
            chooseChatByMessageId();
        });

        await getChats();
        chooseChatByMessageId();

        const formatChatDate = (date: string) => {
            const d = moment(date).utc();
            return moment()
                .utc()
                .isSame(d, 'day')
                ? d.format('HH:mm')
                : d.format('DD/MM/YYYY');
        };

        const sortBy = async (sortBy: string) => {
            if (filter.value.orderBy != sortBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sortBy;
            filter.value.pageIndex = 0;
            await getChats();
        };

        const getArrowClass = (sort: string) => {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        };

        const getRequestedTime = (requestedTimeType: number) => OrderQuoteRequestedTime[requestedTimeType];

        return {
            sortBy,
            getArrowClass,
            filter,
            products,
            providers,
            indexAgencies,
            indexUsers,
            formatChatDate,
            selectedChat,
            selectedChatIndex,
            chooseChat,
            selectedOrderQuoteId,
            selectedIndexUserId,
            moment,
            filteredChats,
            getCurrencySymbol,
            chatTypes,
            getRequestedTime
        };
    }
});
