import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AppLayout from '../AppLayout.vue';
import Home from '../views/Home.vue';
import Login from '@/views/Login.vue';
import Orders from '@/views/Orders.vue';
import Order from '@/views/Order.vue';
import OrdersFilterModal from '@/views/Modals/OrdersFilterModal.vue';
import Providers from '@/views/Provider/ProviderTable.vue';
import ViewProvider from '@/views/Provider/ViewProvider.vue';
import EditProvider from '@/views/Provider/EditProvider.vue';
import ProvidersFilterModal from '@/views/Modals/ProvidersFilterModal.vue';
import PromoProductsFilterModal from '@/views/Modals/PromoProductsFilterModal.vue';
import ProductsFilterModal from '@/views/Modals/ProductsFilterModal.vue';
import Settings from '@/views/Settings.vue';
import EditTag from '@/components/Settings/EditTag.vue';
import EditTagLang from '@/views/Modals/EditTagTranslation.vue';
import EditPromoProductLang from '@/views/Modals/EditPromoProductTranslation.vue';
import CreateNewTag from '@/components/Settings/CreateNewTag.vue';
import EditTranslations from '@/views/Modals/EditTranslations.vue';
import Locations from '@/components/Settings/Locations.vue';
import Documents from '@/components/Documents/Documents.vue';
import FilterDocuments from '@/components/Documents/FilterDocuments.vue';
import ShareDocument from '@/components/Documents/ShareDocument.vue';
import AccountingReceipt from '@/components/Accounting/Receipt.vue';
import EditCurrencyRate from '@/views/Modals/EditCurrencyRate.vue';
import EditUser from '@/views/Modals/EditUser.vue';
import EditBoUser from '@/views/Modals/EditBoUser.vue';
import EditProfile from '@/views/Modals/EditProfile.vue';
import EditBoProfile from '@/views/Modals/EditBoProfile.vue';
import AccountingInvoice from '@/components/Accounting/Invoice.vue';
import swal from 'sweetalert2';
import { Permissions } from '@/models/Enums';
import RegistrationAwaiting from '@/views/RegistrationAwaiting.vue';
import PromoManagement from '@/views/Index/PromoManagement.vue';
import EditPromoProduct from '@/views/Modals/EditPromoProduct.vue';
import EditPromoCategory from '@/views/Modals/EditPromoCategory.vue';
import Agencies from '@/views/Index/AgenciesTable.vue';
import EditAgency from '@/views/Index/EditAgency.vue';
import ViewAgency from '@/views/Index/ViewAgency.vue';
import EditAgencyUser from '@/views/Modals/EditAgencyUser.vue';
import IndexStatistics from '@/views/Index/IndexStatistics.vue';
import IndexChatQoutes from '@/views/Index/ChatsQuotes.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: { name: 'home' },
    component: AppLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          title: 'Bookit BO - Login',
          AllowAnonymous: true,
          height: 300,
          heightMobile: 200,
        },
      },
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          title: 'Bookit BO - Home',
          height: 210,
        },
      },
      {
        path: '/providers',
        name: 'providers',
        component: Providers,
        meta: {
          title: 'Bookit BO - Providers',
          height: 210,
          class: 'reverselayout',
          permission: Permissions.BoViewProviders,
        },
      },
      {
        path: '/providers/filter',
        name: 'modal-providers-filter',
        component: ProvidersFilterModal,
        meta: {
          title: 'Bookit BO - Provider details',
          class: 'popuplayout',
          height: 0,
          permission: Permissions.BoViewProviders,
        },
        props: true,
      },
      {
        path: '/providers/view/:id',
        name: 'provider-view',
        component: ViewProvider,
        meta: {
          title: 'Bookit BO - Provider details',
          height: 210,
          permission: Permissions.BoViewProviders,
        },
        props: true,
      },
      {
        path: '/providers/edit/:id',
        name: 'provider-edit',
        component: EditProvider,
        meta: {
          title: 'Bookit BO - Provider details',
          height: 210,
          permission: Permissions.BoEditProviders,
        },
        props: true,
      },
      {
        path: '/providers/new',
        name: 'provider-new',
        component: EditProvider,
        meta: {
          title: 'Bookit BO - Provider details',
          height: 210,
          permission: Permissions.BoCreateProviders,
        },
        props: {
          id: 0,
        },
      },
      {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
          title: 'Bookit BO - Orders',
          height: 210,
          class: 'reverselayout',
          permission: Permissions.ViewOrders,
        },
      },
      {
        path: '/orders/filter',
        name: 'modal-orders-filter',
        component: OrdersFilterModal,
        meta: {
          title: 'Bookit BO - Orders Filter',
          class: 'popuplayout',
          permission: Permissions.ViewOrders,
        },
        props: true,
      },
      {
        path: '/orders/view/:id',
        name: 'order',
        component: Order,
        meta: {
          title: 'Bookit BO - Orders Filter',
          height: 325,
          permission: Permissions.ViewOrders,
        },
        props: true,
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
          title: 'Bookit BO - Settings',
          height: 210,
          permission: Permissions.BoSettingsView,
        },
      },
      {
        path: '/settings/edit-tag/:tagId',
        name: 'editTag',
        component: EditTag,
        meta: {
          title: 'Bookit BO - EditTag',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/settings/edit-lang/:tagType/:tagId',
        name: 'editTagLang',
        component: EditTagLang,
        meta: {
          title: 'Bookit BO - EditTagLang',
          class: 'popuplayout',
          permission: Permissions.BoTagsEditCreateDelete,
        },
        props: true,
      },
      {
        path: '/promo/product/:promoProductId/edit-lang',
        name: 'editPromoProductLang',
        component: EditPromoProductLang,
        meta: {
          title: 'Bookit BO - EditPromoProductLang',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/settings/create/tag',
        name: 'CreateNewTag',
        component: CreateNewTag,
        meta: {
          title: 'Bookit BO - CreateNewTag',
          class: 'popuplayout',
          permission: Permissions.BoTagsEditCreateDelete,
        },
      },
      {
        path: '/registration-awaiting',
        name: 'RegistrationAwaiting',
        component: RegistrationAwaiting,
        meta: {
          title: 'Bookit BO - Registration Awaiting',
          height: 210,
          class: 'reverselayout',
        },
      },
      {
        path: '/catalog',
        name: 'catalog',
        component: () => import('@/components/Catalog.vue'),
        meta: {
          title: 'Bookit BO - Products',
          class: 'reverselayout',
          height: 210,
          permission: Permissions.ProductsViewSearch,
        },
      },
      {
        path: '/product/:id',
        name: 'product',
        component: () => import('@/components/Product/Product.vue'),
        meta: {
          title: 'Bookit BO - Product details',
          height: 210,
          permission: Permissions.ProductsViewSearch,
        },
      },
      {
        path: '/product/:id/edit',
        name: 'edit-product',
        component: () => import('@/components/Product/EditProduct.vue'),
        meta: {
          title: 'Bookit BO - Edit Product',
          height: 200,
          permission: Permissions.EditProducts,
        },
      },
      {
        path: '/products/filter',
        name: 'modal-products-filter',
        component: ProductsFilterModal,
        meta: {
          title: 'Bookit BO - Products filter',
          class: 'popuplayout',
          height: 0,
          permission: Permissions.ProductsViewSearch,
        },
        props: true,
      },
      {
        path: '/modal-regions',
        name: 'modal-regions',
        component: () => import('@/views/Modals/RegionsModal.vue'),
        meta: {
          title: 'Bookit BO - Regions',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/modal-tags/:tagId/:productId/:type?',
        name: 'modal-tags',
        component: () => import('@/views/Modals/TagsModal.vue'),
        meta: {
          title: 'Bookit BO - Tags',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/product/:id/edit/:type',
        name: 'edit-product-desc',
        component: () => import('@/views/Modals/EditProductNameDescriptions.vue'),
        meta: {
          title: 'Bookit BO - Product edit',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/product/:productId/translate/:id/:type/:infoType?',
        name: 'edit-product-translation',
        component: () => import('@/views/Modals/EditProductTranslationsTitleContent.vue'),
        meta: {
          title: 'Bookit - Product edit',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/location-map/:productId',
        name: 'location-map',
        component: () => import('@/views/Modals/LocationMap.vue'),
        meta: {
          title: 'Bookit BO - Location map',
          height: 90,
        },
        props: true,
      },
      {
        path: '/settings/location/:type',
        name: 'Locations',
        component: Locations,
        meta: {
          title: 'Bookit BO - Location',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/settings/location/:type/:id',
        name: 'EditTranslations',
        component: EditTranslations,
        meta: {
          title: 'Bookit BO - Location',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/settings/currency/:id',
        name: 'EditCurrencyRate',
        component: EditCurrencyRate,
        meta: {
          title: 'Bookit BO - Currency',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/providers/:providerId/user/:id',
        name: 'EditUser',
        component: EditUser,
        meta: {
          title: 'Bookit BO - EditUser',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/providers/:providerId/profile/:id',
        name: 'EditProfile',
        component: EditProfile,
        meta: {
          title: 'Bookit BO - EditProfile',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/documents',
        name: 'documents',
        component: Documents,
        meta: {
          title: 'Bookit BO - Documents',
          height: 210,
          class: 'reverselayout',
          permission: Permissions.DocumentViewSearch,
        },
      },
      {
        path: '/order/:id/remarks',
        name: 'modal-order-edit-remarks',
        component: () => import('@/views/Modals/OrderRemarksEdit.vue'),
        meta: {
          title: 'Bookit BO - Edit Order Remarks',
          class: 'popuplayout',
        },
      },
      {
        path: '/documents/filter',
        name: 'filterdocuments',
        component: FilterDocuments,
        meta: {
          title: 'Bookit BO - FilterDocuments',
          class: 'popuplayout',
          permission: Permissions.DocumentViewSearch,
        },
      },
      {
        path: '/document/:documentId',
        name: 'sharedocument',
        component: ShareDocument,
        meta: {
          title: 'Bookit BO - ShareDocument',
          class: 'popuplayout',
          permission: Permissions.DocumentShare,
        },
        props: true,
      },
      {
        path: '/accounting/receipt/:receiptId',
        name: 'receipt',
        component: AccountingReceipt,
        meta: {
          title: 'Bookit BO - Receipt',
          height: 90,
          permission: Permissions.DocumentViewSearch,
        },
        props: true,
      },
      {
        path: '/accounting/invoice/:invoiceId',
        name: 'invoice',
        component: AccountingInvoice,
        meta: {
          title: 'Bookit BO - Invoice',
          height: 90,
          permission: Permissions.DocumentViewSearch,
        },
        props: true,
      },
      {
        path: '/edit-time-slot/:productId/:productWorkingDayId/',
        name: 'edit-activity',
        component: () => import('@/views/Modals/EditActivityTimes.vue'),
        meta: {
          title: 'Bookit BO - Activity time',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/edit-working-dates/:workingDayId',
        name: 'edit-working-dates',
        component: () => import('@/views/Modals/AddWorkingDaysMoreOptions.vue'),
        meta: {
          title: 'Bookit BO - Working days',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/create-working-date/:id',
        name: 'create-working-dates',
        component: () => import('@/views/Modals/CreateWorkingDates.vue'),
        meta: {
          title: 'Bookit BO - Create working dates',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/modal-predefined-dates',
        name: 'modal-predefined-dates',
        component: () => import('@/views/Modals/PredefinedDatesModal.vue'),
        meta: {
          title: 'Bookit BO - Predefined dates',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/product/:id/edit/price-list/:priceId?/:action?',
        name: 'product-price-list',
        component: () => import('@/views/Modals/ProductPriceList.vue'),
        meta: {
          title: 'Bookit BO - Product price list',
          class: 'popuplayout',
        },
      },
      {
        path: '/product/:id/edit/file/:type?',
        name: 'product-photo',
        component: () => import('@/components/Product/EditProductFiles.vue'),
        meta: {
          title: 'Bookit BO - Product details',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/time-duration/:productId',
        name: 'edit-product-time',
        component: () => import('@/views/Modals/AddTimeDuration.vue'),
        meta: {
          title: 'Bookit BO - Time duration',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/settings/bo-profile/:id',
        name: 'EditBoProfile',
        component: EditBoProfile,
        meta: {
          title: 'Bookit BO - EditBOProfile',
          class: 'popuplayout',
          permission: Permissions.BoProfilesEditCreateDelete,
        },
        props: true,
      },
      {
        path: '/settings/bo-user/:id',
        name: 'EditBoUser',
        component: EditBoUser,
        meta: {
          title: 'Bookit BO - EditBoUser',
          class: 'popuplayout',
          permission: Permissions.BoUsersEditCreateDelete,
        },
        props: true,
      },
      {
        path: '/promo-management/:type?',
        name: 'PromoManagement',
        component: PromoManagement,
        meta: {
          title: 'Bookit BO - Promo Management',
          height: 240,
        },
        props: true,
      },
      {
        path: '/index-statistics',
        name: 'IndexStatistics',
        component: IndexStatistics,
        meta: {
          title: 'Bookit BO - Index Statistics',
          height: 180,
        }
      },
      {
        path: '/agent-details/:id',
        name: 'agent-details',
        component: () => import('@/components/Index/AgentDetailsComponent.vue'),
        meta: {
          title: 'Bookit - Agent Details',
          class: 'popuplayout'
        }
      },
      {
        path: '/index-chat-quotes',
        name: 'IndexChatQuotes',
        component: IndexChatQoutes,
        meta: {
          title: 'Bookit BO - Index Chat Qoutes',
          height: 160,
        }
      },
      {
        path: '/promo/product/:id?/edit/:type?',
        name: 'editPromoProduct',
        component: EditPromoProduct,
        meta: {
          title: 'Bookit BO - Edit Promo Product',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/promo/filter',
        name: 'modal-promo-filter',
        component: PromoProductsFilterModal,
        meta: {
          title: 'Bookit BO - Promo filter',
          class: 'popuplayout',
          height: 0,
        },
        props: true,
      },
      {
        path: '/promo/category/:id?',
        name: 'editPromoCategory',
        component: EditPromoCategory,
        meta: {
          title: 'Bookit BO - Edit Promo Category',
          class: 'popuplayout',
        },
        props: true,
      },
      {
        path: '/agencies',
        name: 'agencies',
        component: Agencies,
        meta: {
          title: 'Bookit BO - Agencies',
          height: 240,
        },
      },
      {
        path: '/agencies/view/:id',
        name: 'agency-view',
        component: ViewAgency,
        meta: {
          title: 'Bookit BO - Agency details',
          height: 337,
          class: 'reverselayout',
        },
        props: true,
      },
      {
        path: '/agencies/edit/:id',
        name: 'agency-edit',
        component: EditAgency,
        meta: {
          title: 'Bookit BO - Edit agency',
          height: 337,
          class: 'reverselayout',
        },
        props: true,
      },
      {
        path: '/agencies/new',
        name: 'agency-new',
        component: EditAgency,
        meta: {
          title: 'Bookit BO - New agency',
          height: 337,
          class: 'reverselayout',
        },
        props: {
          id: 0,
        },
      },
      {
        path: '/agencies/:agencyId/user/:id',
        name: 'EditAgencyUser',
        component: EditAgencyUser,
        meta: {
          title: 'Bookit BO - Edit Agency User',
          class: 'popuplayout',
        },
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function gotPermission(permId: number): boolean {
  if (store.getters.GetPermissions == null || store.getters.GetPermissions.length == 0) {
    return false;
  }

  return store.getters.GetPermissions.includes(permId);
}

router.beforeEach((to: any, from, next) => {
  const isAuthenticated = store?.state?.isAuthenticated;
  document.title = (to.meta.title as string) || 'Bookit BO';
  if (!to.meta?.AllowAnonymous && to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
  } else if (isAuthenticated && to.meta?.AllowAnonymous) {
    next({ name: 'home' });
  } else if (to.meta.permission) {
    if (!gotPermission(to.meta.permission)) {
      swal.fire({
        icon: 'error',
        text: 'No permission',
      });
      if (!from.name) {
        next({ name: 'home' });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
