
import api from '@/services/Api';
import { defineComponent, ref, toRaw } from 'vue';
import swal from 'sweetalert2';
import router from '@/router';
import { useRoute } from 'vue-router';
import { ProviderDetails } from '@/models/Provider';
import ProviderDetailsComponent from '@/components/ProviderDetailsComponent.vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';

export default defineComponent({
  components: {
    ProviderDetailsComponent,
  },
  name: 'ViewProvider',
  async setup() {
    const provider = ref<ProviderDetails>(new ProviderDetails());

    const details = ref<InstanceType<typeof ProviderDetailsComponent>>();

    const route = useRoute();

    const saveWas = ref(false);

    var { t } = useI18n();

    async function loadProvider() {
      var providerId = (route.params.id && Number(route.params.id)) || null;
      if (!providerId) {
        provider.value = new ProviderDetails();
        return;
      }

      const response = (await api.getProviderDetails(providerId))?.data;
      if (!response || response.error) {
        swal.fire({
          icon: 'error',
          text: response?.error || 'No data',
        });
      }
      provider.value = response?.provider || new ProviderDetails();
      addMissingAccountingSettings();
    }

    function addMissingAccountingSettings() {
      if (!provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_username')) {
        provider.value.settings.push({
          id: 0,
          providerId: provider.value.id,
          key: 'ccard_payment_username',
          value: '',
        });
      }

      if (!provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_password')) {
        provider.value.settings.push({
          id: 0,
          providerId: provider.value.id,
          key: 'ccard_payment_password',
          value: '',
        });
      }

      if (!provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_url')) {
        provider.value.settings.push({
          id: 0,
          providerId: provider.value.id,
          key: 'ccard_payment_url',
          value: '',
        });
      }

      if (!provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_terminal')) {
        provider.value.settings.push({
          id: 0,
          providerId: provider.value.id,
          key: 'ccard_payment_terminal',
          value: '',
        });
      }

      if (!provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_max_count_payments')) {
        provider.value.settings.push({
          id: 0,
          providerId: provider.value.id,
          key: 'ccard_max_count_payments',
          value: '',
        });
      }

      if (!provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_system')) {
        provider.value.settings.push({
          id: 0,
          providerId: provider.value.id,
          key: 'ccard_payment_system',
          value: '',
        });
      }

      if (!provider.value.settings.find((s: ProviderSetting) => s.key == 'quantity_payments_credit')) {
        provider.value.settings.push({
          id: 0,
          providerId: provider.value.id,
          key: 'quantity_payments_credit',
          value: '',
        });
      }
    }

    loadProvider();

    function submitForm(event: any) {
      saveWas.value = true;
      details.value?.updateInvalidMobileValue(false);
      event.target.classList.add('was-validated');
      if (event.target.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        swal.fire({
          icon: 'warning',
          title: t('alert.mandatoryfields'),
          confirmButtonText: t('button.close'),
        });
      } else {
        onSave();
      }
    }

    async function onSave() {
      const providerChanged = details.value?.getProvider();
      if (!providerChanged) return false;
      const providerOld = toRaw(provider.value);
      providerOld.active = providerChanged.active;
      const isSame = JSON.stringify(providerOld) == JSON.stringify(providerChanged);
      let providerId = providerChanged.id;
      if (!isSame || !providerId) {
        swal.showLoading();
        var maxCountPayments = providerChanged.settings.find(
          (x: ProviderSetting) => x.key == 'ccard_max_count_payments',
        );
        if (maxCountPayments) {
          maxCountPayments.value = maxCountPayments.value == null ? '' : maxCountPayments.value.toString();
        }
        var cCardPaymentSystem = providerChanged.settings.find((x: ProviderSetting) => x.key == 'ccard_payment_system');
        if (cCardPaymentSystem) {
          cCardPaymentSystem.value = cCardPaymentSystem.value == null ? '' : cCardPaymentSystem.value;
        }
        var ccardMaxCountPayments = providerChanged.settings.find((x: ProviderSetting) => x.key == 'ccard_max_count_payments');
        if (ccardMaxCountPayments) {
          ccardMaxCountPayments.value = ccardMaxCountPayments.value == null ? '' : ccardMaxCountPayments.value.toString();
        }
        var quantityPaymentsCredit = providerChanged.settings.find((x: ProviderSetting) => x.key == 'quantity_payments_credit');
        if (quantityPaymentsCredit) {
          quantityPaymentsCredit.value = quantityPaymentsCredit.value == null ? '' : quantityPaymentsCredit.value.toString();
        }
        var apiResponse = await api.saveProviderDetails(providerChanged);
        if (apiResponse.data?.errorCode == 595) {
          details.value?.updateInvalidMobileValue(true);
        }
        if (apiResponse.errorMessage || !apiResponse.data) {
          swal.fire({
            icon: 'error',
            text: apiResponse.errorMessage || 'No data',
          });
          return false;
        }
        providerId = apiResponse.data.providerId;
      }

      if (!isSame) {
        store.commit('setRefreshProviderSearch', true);
      }
      await swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Provider ' + (providerId ? 'updated' : 'created'),
        showConfirmButton: false,
        timer: 1500,
      });
      if (providerId) {
        router.go(-1);
      } else {
        router.replace({ name: 'provider-view', params: { id: providerId } });
      }
      return true;
    }

    async function onCancel() {
      const providerChanged = details.value?.getProvider();
      if (!providerChanged) return;
      const providerOld = toRaw(provider.value);
      const isSame = JSON.stringify(providerOld) == JSON.stringify(providerChanged);

      if (isSame && providerChanged.id) {
        router.go(-1);
        return;
      }

      const confirmSave = await swal.fire({
        icon: 'info',
        title: t('providers.confirmBeforeCancel'),
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
          denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonText: t('button.yes'),
        denyButtonText: t('button.no'),
      });
      if (confirmSave.isConfirmed) {
        onSave();
        return;
      }
      if (confirmSave.isDenied) {
        router.go(-1);
        return;
      }
    }

    return {
      submitForm,
      provider,
      onSave,
      onCancel,
      details,
      saveWas
    };
  },
});
