
import { defineComponent, ref, computed, nextTick, onMounted, onUnmounted } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import swal from 'sweetalert2';
import { ChatMessage, ChatMessageType } from '@/models/ChatQoutes';
import api from '@/services/Api';
import { callback } from 'chart.js/helpers';

export default defineComponent({
    name: 'ProductChat',
    props: {
        productId: {
            type: String,
            required: true
        },
        indexUserId: {
            type: String,
            required: true,
            default: () => null
        },
        providerId: {
          type: String,
          required: true
        },
        orderQuoteId: {
            type: Number,
            required: false,
            default: () => null
        }
    },
    setup(props, { emit }) {
        const chatTimerId = ref();
        const chatMessages = ref<ChatMessage[]>([]);
        const { t } = useI18n();

        const messagedMarked = ref(false);
        const productId = computed(() => props.productId);
        const indexUserId = computed(() => props.indexUserId);
        const providerId = computed(() => props.providerId);
        const orderQuoteId = computed(() => props.orderQuoteId);
        let observer: IntersectionObserver | null = null;

        const isToday = (date: string) => {
            const d = moment(date).utc();
            return moment()
                .utc()
                .isSame(d, 'day');
        };

        const filteredChatMessages = computed(() => {
            const results = chatMessages.value.filter(m => m.message || m.attachmentPath);
            const index = results.findIndex(f => isToday(f.createdAt));
            if (index >= 0) results[index].isToday = true;
            return results;
        });

        const trackLastMessage = async () => {
            await nextTick();
            const messagesContainer = document.getElementById('messages-container');
            const lastMessage = messagesContainer?.lastElementChild;

            if (lastMessage) {
                observer?.observe(lastMessage);
            }
        };

        const markQuoteAsRead = async () => {
            await api.MarkChatAsRead(productId.value, orderQuoteId.value, indexUserId.value, providerId.value);
        };

        const markMessagesAsRead = async () => {
            if (filteredChatMessages.value && filteredChatMessages.value.length > 0 && messagedMarked.value === false) {
                try {
                    await api.MarkChatAsRead(productId.value, orderQuoteId.value, indexUserId.value, providerId.value);
                } catch (error) {
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: error as any });
                    return;
                }
                messagedMarked.value = true;
            }
        };

        const scrollMessagesToEnd = async () => {
            await nextTick();
            const container = document?.getElementById('messages-container');

            const lastUnreadedMessage = container?.lastElementChild;
            lastUnreadedMessage?.scrollIntoView({behavior: 'smooth'})
        };

        let getNextMessages: () => void = () => {
            'funtion template for stupid composition api';
        };

        const getMessages = async (fromId?: string, showLoading = false) => {
            if (showLoading) swal.showLoading();
            const chatResult = await api.ProductChatMessages(productId.value, indexUserId.value, orderQuoteId.value, fromId);
            if (chatResult.data) {
                messagedMarked.value = false;
                if (!fromId) {
                    chatMessages.value = chatResult.data;
                    if (chatResult.data.length == 1 && chatResult.data[0].message == null) {
                        markQuoteAsRead();
                    }
                } else {
                    chatMessages.value.push(...chatResult.data);
                }

                if (chatResult.data.length > 0) {

                    trackLastMessage();
                    scrollMessagesToEnd();
                }
            }
            if (showLoading) swal.close();

            if (chatMessages.value.length) {
                clearTimeout(chatTimerId.value);
                chatTimerId.value = setTimeout(getNextMessages, 30 * 1000);
            }
        };

        getNextMessages = async () => {
            let fromId: string | undefined;

            if (chatMessages.value.length > 0) {
                fromId = chatMessages.value[chatMessages.value.length - 1].id;
            }
            await getMessages(fromId);
        };

        getMessages();

        const formatChatDate = (date: string) => {
            const d = moment(date).utc();
            return moment()
                .utc()
                .isSame(d, 'day')
                ? d.format('hh:mm A')
                : d.format('DD/MM/YYYY hh:mm A');
        };

        const callback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    markMessagesAsRead();
                }
            });
        };

        onMounted(() => {
            observer = new IntersectionObserver(callback, {
                root: document.getElementById('messages-container'),
                threshold: [1] // If 100% of the element is in the screen, we count it!
                // Can change the thresholds based on your needs. The default is 0 - it'll run only when the element first comes into view
            });
        });

        onUnmounted(() => {
            clearTimeout(chatTimerId.value);
        });

        return {
            formatChatDate,
            chatMessages,
            ChatMessageType,
            filteredChatMessages,
            isToday,
        };
    }
});
