
import { ref, computed, reactive } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import { Product, ProductWorkingDate } from '@/models/Product';
import generalStore from '@/store';
import useProduct from '@/components/Product/useProduct';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { DateRange } from '@/models/Order';
import useExtensions from '@/useExtensions';

export default {
    components: {
        Calendar
    },
    async setup() {
        const { t } = useI18n();
        const calendar = ref();

        const showHelp = ref(false);
        const route = useRoute();
        const id = route.params.id.toString();

        const close = () => {
            router.go(-1);
        };
        if (!id) {
            close();
        }
        const { saveProduct, getProduct } = useProduct();
        const { dateRangeValidate } = useExtensions();
        const isTypeSelected = ref(false);
        const product = computed<Product>(() => generalStore.getters.product);
        if (!product.value || !product.value.id) await getProduct(id);

        const newCreatedWorkingDate = ref<ProductWorkingDate>();
        const workingDate = ref<ProductWorkingDate>();
        const dateRange = reactive(new DateRange());
        const createWorkingDates = async (isStrictHours: boolean) => {
            const foundIndex = product.value.productWorkingDates.findIndex(w => w.id == 0);
            if (foundIndex > -1) {
                newCreatedWorkingDate.value = product.value.productWorkingDates[foundIndex];
                dateRange.fromDate = new Date(newCreatedWorkingDate.value.fromDate);
                dateRange.toDate = new Date(newCreatedWorkingDate.value.toDate);
                product.value.productWorkingDates.splice(foundIndex, 1);
                return;
            }

            const dates = new ProductWorkingDate();
            dates.strictHours = isStrictHours;
            newCreatedWorkingDate.value = dates;
            isTypeSelected.value = true;
            dateRange.fromDate = new Date(newCreatedWorkingDate.value.fromDate);
            dateRange.toDate = moment(`${moment().year() + 1}-12-31`).toDate();
            product.value.productWorkingDates.push(newCreatedWorkingDate.value);
        };
        if (product.value.productWorkingDates && product.value.productWorkingDates.length > 0) {
            isTypeSelected.value = true;
            const found = product.value.productWorkingDates[0];
            if (found && found.strictHours === false) {
                createWorkingDates(false);
            } else {
                createWorkingDates(true);
            }
        }
        const save = async () => {
            const existWorkingDates = product.value.productWorkingDates.map(wk => wk.id);
            if (newCreatedWorkingDate.value && newCreatedWorkingDate.value.name) {
                swal.showLoading();
                newCreatedWorkingDate.value.fromDate = moment(dateRange.fromDate)
                    .toDate()
                    .toDateString();
                newCreatedWorkingDate.value.toDate = moment(dateRange.toDate)
                    .toDate()
                    .toDateString();
                newCreatedWorkingDate.value.productId = id;

                const response = await api.saveProductWorkingDate(newCreatedWorkingDate.value, false);
                if (response.data?.error) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: response.data.error });
                } else {
                    const newWorkingDateId = response.data?.productWorkingDate.id || '';
                    if (response.data?.productWorkingDate) {
                        product.value.productWorkingDates.push(response.data.productWorkingDate);
                        product.value.productWorkingDates = product.value.productWorkingDates.filter(x => x.id);
                    }
                    router.push({
                        name: 'edit-activity',
                        params: { productId: id, productWorkingDayId: newWorkingDateId }
                    });
                    swal.close();
                }
            } else {
                swal.fire({
                    icon: 'error',
                    text: 'Enter name'
                });
            }
        };
        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                product.value.productWorkingDates?.forEach(pwd =>
                    pwd.timeSlots?.forEach(t => {
                        t.active = pwd.timeSlots[0].active;
                        t.endTime = pwd.timeSlots[0].endTime;
                        t.duration = pwd.timeSlots[0].duration;
                        t.quantity = pwd.timeSlots[0].quantity;
                        t.startTime = pwd.timeSlots[0].startTime;
                    })
                );
                const savedProduct = await saveProduct(product.value);
                if (savedProduct) {
                    if (product.value.productWorkingDates[0].timeSlots.length == 0) {
                        const swalResult = await swal.fire({
                            title: t('alert.areYouSure'),
                            text: t('alert.youDidNotCreateAnySlots'),
                            icon: 'warning',
                            customClass: {
                                confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4 ',
                                cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                            },
                            buttonsStyling: false,
                            showCancelButton: true,
                            confirmButtonText: t('alert.yesContinue'),
                            cancelButtonText: t('button.cancel')
                        });
                        if (!swalResult.isConfirmed) return;
                    }
                    router.push({ name: 'edit-product', params: { id: id } });
                }
            }
        };
        function goBack() {
            product.value.productWorkingDates = product.value.productWorkingDates.filter(p => p.id != 0);
            router.push({ name: 'edit-product', params: { id: id } });
        }
        const dateChanged = () => {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
        };
        return {
            dateRange,
            newCreatedWorkingDate,
            isTypeSelected,
            createWorkingDates,
            submitForm,
            product,
            showHelp,
            goBack,
            workingDate,
            save,
            close,
            calendar,
            dateChanged
        };
    }
};
