
import api from '@/services/Api';
import { defineComponent, ref } from 'vue';
import swal from 'sweetalert2';
import router from '@/router';
import { useRoute } from 'vue-router';
import { ProviderDetails } from '@/models/Provider';
import ProviderDetailsComponent from '@/components/ProviderDetailsComponent.vue';

export default defineComponent({
  components: {
    ProviderDetailsComponent,
  },
  name: 'ViewProvider',
  async setup() {
    const provider = ref<ProviderDetails>(new ProviderDetails());

    const route = useRoute();

    async function loadProvider() {
      var providerId = (route.params.id && Number(route.params.id)) || null;
      if (!providerId) {
        router.replace({ name: 'providers' });
        return;
      }

      const response = (await api.getProviderDetails(providerId))?.data;
      if (!response || response.error) {
        swal.fire({
          icon: 'error',
          text: response?.error || 'No data',
        });
      }
      provider.value = response?.provider || new ProviderDetails();
    }

    loadProvider();

    return {
      provider,
    };
  },
});
