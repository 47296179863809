
import store from '@/store';

export default {
  setup() {
    const body = document.querySelector('body');
    body?.setAttribute('dir', store.getters.currentLanguage === 'he-IL' ? 'rtl' : 'ltr');

    return {
      store,
    };
  },
};
