
import { computed, reactive, ref, nextTick } from 'vue';
import { Product, ProductWorkingDate, TimeSlot, DateRange } from '@/models/Product';
import useProduct from '@/components/Product/useProduct';
import { useRoute } from 'vue-router';
import generalStore from '@/store';
import { WeekNames } from '@/models/Enums';
import router from '@/router';
import moment from 'moment';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import api from '@/services/Api';
import mixin from '@/mixin';
import Calendar from 'primevue/calendar';
import useExtensions from '@/useExtensions';

export default {
    props: {
        productId: {
            type: String
        },
        productWorkingDayId: {
            type: Number
        }
    },
    components: {
        Calendar
    },
    async setup() {
        const editDateMode = ref(false);
        const showTimeSlotActions = ref(false);
        const choosenTimeSlotIdActions = ref('');
        const calendar = ref();
        const { t } = useI18n();
        const showHelp = ref(false);

        const { dateRangeValidate } = useExtensions();

        const route = useRoute();
        const { getProduct } = useProduct();
        const id = route.params.productId.toString();

        if (!route.params.productWorkingDayId) {
            router.push({ name: 'edit-product', params: { id: id } });
            return;
        }
        const prodWorkingDayId = route.params.productWorkingDayId;
        let productItem: Product = reactive(new Product());
        const product = computed<Product>(() => generalStore.getters.product);
        const weekNames = WeekNames;

        if (!product.value || !product.value.id) {
            await getProduct(id);
        }
        if (product.value !== null) {
            productItem = product.value;
        }

        const tempData = JSON.stringify(product.value.productWorkingDates.find(w => w.id == parseInt(prodWorkingDayId.toString())));
        const foundedProductWorkingDay: ProductWorkingDate = reactive(JSON.parse(tempData));
        const foundedProductTimeSlot = ref(new TimeSlot());
        const dateRange = reactive(new DateRange());
        dateRange.fromDate = new Date(foundedProductWorkingDay.fromDate);
        dateRange.toDate = new Date(foundedProductWorkingDay.toDate);

        const priceDateChanged = () => {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
            foundedProductWorkingDay.fromDate = dateRange.fromDate.toDateString();
            foundedProductWorkingDay.toDate = dateRange.toDate.toDateString();
        };

        if (foundedProductWorkingDay) {
            if (foundedProductWorkingDay.strictHours === false) {
                if (foundedProductWorkingDay.timeSlots && foundedProductWorkingDay.timeSlots.length > 0) {
                    foundedProductTimeSlot.value = foundedProductWorkingDay.timeSlots[0];
                    foundedProductTimeSlot.value.duration = mixin.methods.formatTime(foundedProductTimeSlot.value.duration);
                    foundedProductTimeSlot.value.startTime = mixin.methods.formatTime(foundedProductTimeSlot.value.startTime);
                    foundedProductTimeSlot.value.endTime = mixin.methods.formatTime(foundedProductTimeSlot.value.endTime);
                } else {
                    foundedProductTimeSlot.value = new TimeSlot();
                }
            }
        }

        const deleteCalendar = async () => {
            const index = product.value.productWorkingDates.findIndex(d => d.id == foundedProductWorkingDay.id);
            if (index > -1) {
                const timeNow = moment().toISOString();
                product.value.productWorkingDates[index].deletedAt = timeNow;
                product.value.productWorkingDates[index].timeSlots.forEach(s => {
                    s.deletedAt = timeNow;
                });
            }
            const response = await api.saveProductWorkingDate(product.value.productWorkingDates[index], false);
            if (response.data?.error) {
                swal.fire({ icon: 'error', title: 'Oops...', text: response.data?.error });
                return false;
            } else {
                product.value.productWorkingDates.splice(index, 1);
                return true;
            }
        };


        const deleteWorkingDate = () => {
            swal.fire({
                title: t('alert.areYouSure'),
                text: t('alert.youWontBeAbleToRevertThis'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            }).then(async result => {
                if (result.isConfirmed) {
                    const deleted = await deleteCalendar();
                    if (deleted) {
                        router.push({ name: 'edit-product', params: { id: id } });
                    }
                }
            });
        };

        const removeTimeSlot = (timeSlotId: number) => {
            swal.fire({
                title: t('alert.areYouSure'),
                text: t('alert.youWontBeAbleToRevertThis'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            }).then(async result => {
                if (result.isConfirmed) {
                    const foundSlot = foundedProductWorkingDay.timeSlots.find(s => s.id == timeSlotId);
                    if (foundSlot) {
                        const response = await api.removeProductWorkingTimeSlot(foundSlot);
                        if (!response.data) {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text:
                                    'This slot can not be deleted because it has orders connected to. You can either cancel the orders or you can create a new slot with the same Start time and week day and move them to this new slot.'
                            });
                            foundSlot.active = false;
                        } else {
                            const indexWD = product.value.productWorkingDates.findIndex(d => d.id == foundedProductWorkingDay.id);
                            const filterDeleted = foundedProductWorkingDay.timeSlots.filter(t => t.startTime != foundSlot.startTime);
                            foundedProductWorkingDay.timeSlots = filterDeleted;
                            product.value.productWorkingDates[indexWD].timeSlots = filterDeleted;
                            generalStore.commit('setProduct', product.value);
                            showTimeSlotActions.value = false;
                        }
                    }
                }
            });
        };

        const changeActiveSlot = async (timeSlot: TimeSlot) => {
            timeSlot.active = true;
            const response = await api.changeActiveProductWorkingTimeSlot(timeSlot);
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
                timeSlot.active = false;
            }
            foundedProductWorkingDay.timeSlots.filter(t => t.startTime == timeSlot.startTime).forEach(t => (t.active = timeSlot.active));
        };

        function momentFormat(data: Date, formatType: string) {
            if (formatType === 'date') {
                return moment(data, moment.ISO_8601).format('DD/MM/YYYY');
            }
            if (formatType === 'datetime') {
                return moment(data, moment.ISO_8601).format('DD/MM/YYYY HH:mm');
            } else return moment(data, 'HH:mm:ss').format('HH:mm');
        }

        function findActiveWeekDay(weekEnum: WeekNames, weekSlots: TimeSlot[]) {
            let newwBool = false;
            weekSlots.forEach(week => {
                if (weekEnum === week.dayOfWeek) {
                    newwBool = true;
                }
            });
            return newwBool;
        }

        const checkedDays = ref(foundedProductWorkingDay?.timeSlots.map(m => m.dayOfWeek).filter((x, i, a) => a.indexOf(x) === i));
        let isEmpty = false;
        if (checkedDays.value.length == 0 || foundedProductWorkingDay.timeSlots.length == 0) {
            isEmpty = true;
        }

        const copyOrEditTimeSlot = (timeSlotId: number, isCopyAction: boolean ) => {
            const foundSlot = foundedProductWorkingDay.timeSlots.find(ts => ts.id == timeSlotId);
            if (foundSlot) {
                let timeSlot = new TimeSlot();
                timeSlot = JSON.parse(JSON.stringify(foundSlot));
                if (isCopyAction) {
                    timeSlot.id = 0;
                    timeSlot.startTime = '';
                    timeSlot.endTime = '';
                }
                router.push({ name: 'edit-product-time', params: {productId: id, workingDateId: foundedProductWorkingDay.id, selectedWeekDays: checkedDays.value, timeSlotObject: JSON.stringify(timeSlot as TimeSlot) }});
            }
        }

        const checkedDaysForExistSlots = ref(JSON.parse(JSON.stringify(foundedProductWorkingDay?.timeSlots.map(m => m.dayOfWeek).filter((x, i, a) => a.indexOf(x) === i))));
        const slots = computed<TimeSlot[]>(() => {
            return foundedProductWorkingDay.timeSlots?.filter(
                (thing, index, self) =>
                    self.findIndex(
                        t =>
                            mixin.methods.formatTime(t.startTime) == mixin.methods.formatTime(thing.startTime) &&
                            mixin.methods.formatTime(t.duration) == mixin.methods.formatTime(thing.duration) &&
                            mixin.methods.formatTime(t.endTime) == mixin.methods.formatTime(thing.endTime) &&
                            t.quantity == thing.quantity
                    ) === index && checkedDaysForExistSlots.value.includes(thing.dayOfWeek)
            );
        });
        const isNotActiveDay = (weekname: string) => {
            let isNotActive = false;
            if (foundedProductWorkingDay.timeSlots.length > 0) {
                isNotActive =
                    !foundedProductWorkingDay.timeSlots.some(t => t.dayOfWeek == weekname && t.active) &&
                    checkedDays.value.includes(weekname);
            }
            return isNotActive;
        };
        const updateTimeSlots = async (weekname: string, event: any) => {
            await nextTick();
            if (isNotActiveDay(weekname)) {
                swal.fire({ icon: 'warning', title: 'Warning', text: t('alert.thisWeekDayIsSwitchedOff') });
                event.preventDefault();
                event.stopPropagation();
                return;
            } else {
                if (!checkedDays.value.includes(weekname)) {
                    checkedDays.value.push(weekname);
                    checkedDaysForExistSlots.value.push(weekname);
                } else {
                    checkedDays.value.splice(
                        checkedDays.value.findIndex(d => d == weekname),
                        1
                    );
                }
            }
            const timeNow = moment().toISOString();
            if (checkedDays.value.includes(weekname)) {
                if (foundedProductWorkingDay?.strictHours == false && foundedProductWorkingDay.timeSlots.length == 0) {
                    const slot = new TimeSlot();
                    slot.dayOfWeek = weekname;
                    foundedProductWorkingDay.timeSlots = [slot];
                } else {
                    const newSlots = slots.value.map(
                        s =>
                            ({
                                active: true,
                                endTime: moment(s.endTime, 'HH:mm').format('HH:mm:ss'),
                                dayOfWeek: weekname,
                                duration: s.duration,
                                startTime: moment(s.startTime, 'HH:mm').format('HH:mm:ss'),
                                quantity: s.quantity,
                                usedQuantity: 0
                            } as TimeSlot)
                    );
                    foundedProductWorkingDay?.timeSlots?.push(...newSlots);
                }
            } else {
                foundedProductWorkingDay.timeSlots.filter(t => t.dayOfWeek == weekname).forEach(t => (t.deletedAt = timeNow));
            }
        };

        async function confirmToSaveEmptyCalendar(): Promise<boolean | null> {
            return await swal
                .fire({
                    icon: 'error',
                    title: t('alert.saveEmptyCalendar'),
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('alert.noDelete'),
                    cancelButtonText: t('button.cancel'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'popup-bookit-button'
                    }
                })
                .then(async result => {
                    if (result.isConfirmed) {
                        return true;
                    } else if (result.isDenied) {
                        await deleteCalendar();
                        return false;
                    }
                    return null;
                });
        }
        function saveNewParamsOfWorkingDates() {
            foundedProductWorkingDay.timeSlots = foundedProductWorkingDay.timeSlots.filter(x => checkedDays.value.includes(x.dayOfWeek));
            const found = product.value.productWorkingDates.find(x => x.id === foundedProductWorkingDay.id);
            if (found) {
                found.timeSlots = foundedProductWorkingDay.timeSlots;
                router.push({ name: 'edit-working-dates', params: { workingDayId: foundedProductWorkingDay.id } });
            }
        }
        const save = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                if (checkedDays.value.length == 0 || foundedProductWorkingDay.timeSlots.length == 0) {
                    if (foundedProductWorkingDay && foundedProductWorkingDay.timeSlots && foundedProductWorkingDay.timeSlots.length > 0) {
                        swal.fire({
                            icon: 'error',
                            text: t('product.timeSlots.noWeekDays')
                        });
                        return;
                    }
                    const res = await confirmToSaveEmptyCalendar();
                    if (res == null) {
                        return;
                    }
                    if (!res) {
                        router.push({ name: 'edit-product', params: { id: id } });
                        return;
                    }
                }

                if (foundedProductWorkingDay) {
                    if (foundedProductWorkingDay.strictHours) {
                        const found = productItem.productWorkingDates.findIndex(pw => pw.id === foundedProductWorkingDay.id);
                        productItem.productWorkingDates[found].timeSlots = foundedProductWorkingDay.timeSlots;
                    } else {
                        const found = productItem.productWorkingDates.find(pw => pw.id === foundedProductWorkingDay.id);
                        if (found) found.timeSlots = foundedProductWorkingDay.timeSlots;
                        if (found?.timeSlots && found?.timeSlots.length > 0) {
                            found?.timeSlots.forEach(timeSlot => {
                                timeSlot.startTime = moment(foundedProductTimeSlot.value.startTime, 'HH:mm').format('HH:mm:ss');
                                timeSlot.endTime = moment(foundedProductTimeSlot.value.endTime, 'HH:mm').format('HH:mm:ss');
                                timeSlot.duration = foundedProductTimeSlot.value.duration;
                                timeSlot.quantity = foundedProductTimeSlot.value.quantity;
                            });
                        }
                    }
                }

                const response = await api.saveProductWorkingDate(foundedProductWorkingDay, false);
                if (response.data?.errorCode && response.data?.errorCode == 101) {
                    const newResult = await swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: response.data?.error,
                        customClass: {
                            confirmButton: 'green-button popup-bookit-button my-1 px-4 fixed-size',
                            cancelButton: 'stop-sale-button popup-bookit-button my-1 px-4 fixed-size'
                        },
                        buttonsStyling: false,
                        showCancelButton: true,
                        confirmButtonText: t('button.yes'),
                        cancelButtonText: t('button.no')
                    });
                    if (newResult.isConfirmed) {
                        const saveAnywayResponse = await api.saveProductWorkingDate(foundedProductWorkingDay, true);
                        if (product.value && saveAnywayResponse.data && saveAnywayResponse.data.productWorkingDate) {
                            const found = product.value.productWorkingDates?.find(d => d.id == saveAnywayResponse.data?.productWorkingDate.id);
                            if (found) {
                                found.timeSlots = saveAnywayResponse.data.productWorkingDate.timeSlots;
                            }
                        }
                    } else return;
                }
                if (response.data?.error && response.data?.errorCode !== 101) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: response.data?.error });
                } else if (response.data?.errorCode == 1003) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        text: 'Please take in account that there are already sold tickets for this slot.'
                    });
                } else if (response.data?.errorCode == 1002) {
                    let workDaySlotQuantity = 0;
                    if (foundedProductWorkingDay.strictHours) {
                        workDaySlotQuantity = foundedProductWorkingDay.timeSlots[0].quantity || 0;
                    } else {
                        workDaySlotQuantity = foundedProductTimeSlot.value.quantity || 0;
                    }
                    const title = `In some dates, the qty of already sold tickets which relevant to this slot start time and dates is ${response.data?.maxUsedTicketsQuantity} and it is more than the new slot qty you just set (${workDaySlotQuantity}). Do you want to increase the new slot qty?`;
                    const oneValue = response.data?.maxUsedTicketsQuantity;
                    const twoValue = response.data?.maxUsedTicketsQuantity - (foundedProductTimeSlot.value.quantity || 0);
                    const inputOptions = {
                        1: `Add another ${oneValue}. The availability will remain ${foundedProductTimeSlot.value.quantity} after increasing.`,
                        2: `Add only ${twoValue}, so there will be no available tickets for the new slot on those dates`
                    };
                    const { value: formValues } = await swal.fire({
                        title: title,
                        customClass: {
                            title: 'creating-slot-pop-up-title',
                            input: 'creating-slot-pop-up-input-label'
                        },
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: `Increase`,
                        denyButtonText: `Don't increase`,
                        focusConfirm: false,
                        input: 'radio',
                        inputOptions: inputOptions,
                        inputValidator: value => {
                            return new Promise(resolve => {
                                if (!value) {
                                    resolve('You need to choose something!');
                                } else {
                                    resolve('');
                                }
                            });
                        }
                    });
                    if (formValues) {
                        let newQuantity = 0;

                        if (formValues == 1) {
                            newQuantity = Number(workDaySlotQuantity) + oneValue;
                        } else {
                            newQuantity = Number(workDaySlotQuantity) + twoValue;
                        }

                        if (foundedProductWorkingDay.strictHours) {
                            foundedProductWorkingDay.timeSlots.forEach(t => {
                                t.quantity = newQuantity;
                            });
                        } else {
                            foundedProductTimeSlot.value.quantity = newQuantity;
                        }
                        save(event);
                    } else {
                        swal.close();
                    }
                }
                if (response.data?.errorCode !== 101) {
                    const indexWD = product.value.productWorkingDates.findIndex(w => w.id == foundedProductWorkingDay.id);
                    if (indexWD > -1) {
                        product.value.productWorkingDates[indexWD] = response.data!.productWorkingDate;
                    }
                }
                router.push({ name: 'edit-product', params: { id: id } });
                close();
            }
        };

        async function goBack() {
            if (isEmpty) {
                const res = await confirmToSaveEmptyCalendar();
                if (res == null) {
                    return;
                }
            }
            router.push({ name: 'edit-product', params: { id: id } });
        }

        const getEndTime = (timeSlot: TimeSlot) => {
            const duration = moment.duration(timeSlot.startTime).add(timeSlot.duration);
            return moment.utc(duration.asMilliseconds()).format('HH:mm');
        };
        function validateDate(field: string) {
            const splitter = ':';
            let timeArray = [];
            if (field === 'duration') {
                if (foundedProductTimeSlot.value.duration?.includes(splitter)) {
                    timeArray = foundedProductTimeSlot.value.duration.split(splitter);
                    const parsedHours = parseInt(timeArray[0]);
                    const parsedMin = parseInt(timeArray[1]);
                    let newHours = parsedHours.toString();
                    if (parsedHours > 24) {
                        newHours = '23';
                        foundedProductTimeSlot.value.duration = newHours;
                    }
                    if (parsedHours == 24) {
                        newHours = '00';
                        foundedProductTimeSlot.value.duration = newHours;
                    }
                    if (parsedHours == 0 && parsedMin > 0 && parsedMin < 60) {
                        foundedProductTimeSlot.value.duration = '00' + ':' + parsedMin.toString();
                    }
                    if (parsedMin >= 60) {
                        foundedProductTimeSlot.value.duration = newHours + ':' + '59';
                    }
                }
            }
            if (field === 'open') {
                if (foundedProductTimeSlot.value.startTime.includes(splitter)) {
                    timeArray = foundedProductTimeSlot.value.startTime.split(splitter);
                    const parsedHours = parseInt(timeArray[0]);
                    const parsedMin = parseInt(timeArray[1]);
                    let newHours = parsedHours.toString();
                    if (parsedHours > 24) {
                        newHours = '23';
                        foundedProductTimeSlot.value.startTime = newHours;
                    }
                    if (parsedHours == 24) {
                        newHours = '00';
                        foundedProductTimeSlot.value.startTime = newHours;
                    }
                    if (parsedHours == 0 && parsedMin > 0 && parsedMin < 60) {
                        foundedProductTimeSlot.value.startTime = '00' + ':' + parsedMin.toString();
                    }
                    if (parsedMin >= 60) {
                        foundedProductTimeSlot.value.startTime = newHours + ':' + '59';
                    }
                }
            }
            if (field === 'close') {
                if (foundedProductTimeSlot.value.endTime.includes(splitter)) {
                    timeArray = foundedProductTimeSlot.value.endTime.split(splitter);
                    const parsedHours = parseInt(timeArray[0]);
                    const parsedMin = parseInt(timeArray[1]);
                    let newHours = parsedHours.toString();
                    if (parsedHours > 24) {
                        newHours = '23';
                        foundedProductTimeSlot.value.endTime = newHours;
                    }
                    if (parsedHours == 24) {
                        newHours = '00';
                        foundedProductTimeSlot.value.endTime = newHours;
                    }
                    if (parsedHours == 0 && parsedMin > 0 && parsedMin < 60) {
                        foundedProductTimeSlot.value.endTime = '00' + ':' + parsedMin.toString();
                    }
                    if (parsedMin >= 60) {
                        foundedProductTimeSlot.value.endTime = newHours + ':' + '59';
                    }
                }
            }
        }
        return {
            checkedDaysForExistSlots,
            validateDate,
            slots,
            updateTimeSlots,
            save,
            removeTimeSlot,
            copyOrEditTimeSlot,
            id,
            checkedDays,
            weekNames,
            findActiveWeekDay,
            momentFormat,
            foundedProductWorkingDay,
            foundedProductTimeSlot,
            productItem,
            goBack,
            getEndTime,
            deleteWorkingDate,
            showHelp,
            changeActiveSlot,
            isNotActiveDay,
            calendar,
            dateRange,
            editDateMode,
            priceDateChanged,
            showTimeSlotActions,
            choosenTimeSlotIdActions,
            saveNewParamsOfWorkingDates
        };
    }
};
