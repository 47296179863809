
import { defineComponent, toRef, PropType } from 'vue';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import api from '@/services/Api';
import Q from 'q';
import Multiselect from '@vueform/multiselect';
import { PrintingType as PrintingTypeEnum, ToOptions } from '@/models/Enums';

export default defineComponent({
  name: 'ProductSellingSettings',
  props: {
    productId: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    useCashierProp: {
      type: Boolean,
      default: false,
    },
    useB2cChannelsProp: {
      type: Boolean,
      default: false,
    },
    useLocalsProp: {
      type: Boolean,
      default: false,
    },
    useIndexProp: {
      type: Boolean,
      default: false,
    },
    useTouristsProp: {
      type: Boolean,
      default: false,
    },
    useGroupsProp: {
      type: Boolean,
      default: false,
    },
    printingTypeProp: {
      type: Object as PropType<PrintingTypeEnum> | null,
      default: null,
    },
  },
  components: { Multiselect },
  async setup(props, { emit }: any) {
    const { t } = useI18n();
    const useCashier = toRef(props, 'useCashierProp');
    const useB2cChannels = toRef(props, 'useB2cChannelsProp');
    const useLocals = toRef(props, 'useLocalsProp');
    const useIndex = toRef(props, 'useIndexProp');
    const useTourists = toRef(props, 'useTouristsProp');
    const useGroups = toRef(props, 'useGroupsProp');
    const printingType = toRef(props, 'printingTypeProp');
    const printingTypes = ToOptions(PrintingTypeEnum, 'printing-type.');
    async function ChangeSellingSettingCashier(evt: Event) {
      evt.preventDefault();
      const swalAction = await swal.fire({
        icon: 'info',
        text: useCashier.value ? t('selling-settings.alert.disableCashier') : t('selling-settings.alert.enableCashier'),
        customClass: {
          confirmButton: useCashier.value
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: useCashier.value ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const apiPromise = api.changeSellingSetting(props.productId, !useCashier.value, 'cashier');
      await Q.delay(400);
      const apiResult = await apiPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      if (apiResult.data) {
        emit('changeSellingSetting', apiResult.data.newValue, 'cashier');
      }
      swal.close();
    }

    async function ChangeSellingSettingLocals(evt: Event) {
      evt.preventDefault();
      const swalAction = await swal.fire({
        icon: 'info',
        text: useLocals.value ? t('selling-settings.alert.disableLocal') : t('selling-settings.alert.enableLocal'),
        customClass: {
          confirmButton: useLocals.value
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: useLocals.value ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const apiPromise = api.changeSellingSetting(props.productId, !useLocals.value, 'locals');
      await Q.delay(400);
      const apiResult = await apiPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      if (apiResult.data) {
        emit('changeSellingSetting', apiResult.data.newValue, 'locals', apiResult.data.ids);
        if (apiResult.data.newValue) {
          await swal.fire({
            icon: 'info',
            text: t('selling-settings.alert.infoEnableLocals'),
            customClass: {
              confirmButton: 'green-button popup-bookit-button my-1 px-4',
            },
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        }
      }
      swal.close();
    }
    async function ChangeSellingSettingGroups(evt: Event) {
      evt.preventDefault();
      const swalAction = await swal.fire({
        icon: 'info',
        text: useGroups.value ? t('selling-settings.alert.disableGroups') : t('selling-settings.alert.enableGroups'),
        customClass: {
          confirmButton: useGroups.value
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: useGroups.value ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const apiPromise = api.changeSellingSetting(props.productId, !useGroups.value, 'groups');
      await Q.delay(400);
      const apiResult = await apiPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      if (apiResult.data) {
        emit('changeSellingSetting', apiResult.data.newValue, 'groups', apiResult.data.ids);
        if (apiResult.data.newValue) {
          await swal.fire({
            icon: 'info',
            text: t('selling-settings.alert.infoInableGroups'),
            customClass: {
              confirmButton: 'green-button popup-bookit-button my-1 px-4',
            },
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        }
      }
      swal.close();
    }
    async function ChangeSellingSettingB2cChannels(evt: Event) {
      evt.preventDefault();
      const swalAction = await swal.fire({
        icon: 'info',
        text: useB2cChannels.value
          ? t('selling-settings.alert.disableB2cChannels')
          : t('selling-settings.alert.enableB2cChannels'),
        customClass: {
          confirmButton: useB2cChannels.value
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: useB2cChannels.value ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const apiPromise = api.changeSellingSetting(props.productId, !useB2cChannels.value, 'b2cChannels');
      await Q.delay(400);
      const apiResult = await apiPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      if (apiResult.data) {
        emit('changeSellingSetting', apiResult.data.newValue, 'b2cChannels');
        await swal.fire({
          position: 'center',
          icon: 'success',
          showConfirmButton: true,
        });
      }
    }

    async function ChangeSellingSettingIndex(evt: Event) {
      evt.preventDefault();
      const swalAction = await swal.fire({
        icon: 'info',
        text: useIndex.value ? t('selling-settings.alert.disableIndex') : t('selling-settings.alert.enableIndex'),
        customClass: {
          confirmButton: useIndex.value
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: useIndex.value ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const apiPromise = api.changeSellingSetting(props.productId, !useIndex.value, 'index');
      await Q.delay(400);
      const apiResult = await apiPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      if (apiResult.data) {
        emit('changeSellingSetting', apiResult.data.newValue, 'index');
        await swal.fire({
          position: 'center',
          icon: 'success',
          showConfirmButton: true,
        });
      }
    }
    function ChangeSellingSettingPrintingType(evt: any) {
      emit('changeSellingSettingPrintingType', evt, 'printing-type');
    }

    return {
      useCashier,
      useB2cChannels,
      useLocals,
      useIndex,
      useTourists,
      useGroups,
      ChangeSellingSettingCashier,
      ChangeSellingSettingLocals,
      ChangeSellingSettingGroups,
      ChangeSellingSettingB2cChannels,
      ChangeSellingSettingIndex,
      ChangeSellingSettingPrintingType,
      printingType,
      printingTypes,
      PrintingTypeEnum,
    };
  },
});
