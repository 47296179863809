
import { computed, ref, toRef } from 'vue';
import { Product } from '@/models/Product';
import generalStore from '@/store';
import router from '@/router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Multiselect from '@vueform/multiselect';
import { ProductInfoType } from '@/models/Enums';
import Q from 'q';
import useProduct from '@/components/Product/useProduct';

export default {
    name: 'EditProductTranslationsTitleContent',
    props: {
        productId: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        infoType: {
            type: String,
            required: false
        }
    },
    components: { Multiselect },
    async setup(props: any) {
        const discMaxLength = ref();
        const selectedOption = ref();
        const currLangFullInfo = ref();
        const translations = ref<TranslationDouble[]>([]);
        const productInfoType = ProductInfoType;
        const itemInfoType = toRef(props, 'infoType');
        const currentLanguage = computed(() => generalStore.getters.currentLanguage);
        const langs = computed<Language[]>(() => generalStore.getters.languages);
        const currentLanguageId = computed(() => langs.value.find(x => x.name == currentLanguage.value)?.id || '');
        if (langs.value) {
            selectedOption.value = langs.value.find(l => l.name == currentLanguage.value)?.id;
            currLangFullInfo.value = langs.value.find(l => l.id == selectedOption.value);
        }
        const {  getProduct } = useProduct();
        discMaxLength.value = props.type == 'moreinfo' ? 300 : 500;
        const product = computed<Product>(() => generalStore.getters.product);
        if (!product.value || !product.value.id) await getProduct(props.productId);
        function addMissionLangs() {
            langs.value.forEach(lang => {
                const index = translations.value.findIndex(x => x.languageId == lang.id);
                if (index && index < 0) {
                    const translation: TranslationDouble = {
                        id: 0,
                        name: '',
                        description: '',
                        entityId: props.id,
                        languageId: lang.id
                    };
                    translations.value.push(translation);
                }
            });
        }

        async function InitFaqType() {
            const faqIndex = product.value.productFaqs.findIndex(x => x.id == props.id);
            if (faqIndex >= 0) {
                translations.value = product.value.productFaqs[faqIndex].translations;
            } else {
                swal.showLoading();
                const apiPromise = api.GetProductFAQItem(props.productId, props.id);
                await Q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (apiResult.data) {
                    translations.value = apiResult.data.translations;
                    swal.close();
                }
            }
        }
        async function InitMoreInfoType() {
            const faqIndex = product.value.productInfos.findIndex(x => x.id == props.id);
            if (faqIndex >= 0) {
                translations.value = product.value.productInfos[faqIndex].translations;
                itemInfoType.value = product.value.productInfos[faqIndex].type;
            } else {
                swal.showLoading();
                const apiPromise = api.GetProductInfoItem(props.productId, props.id);
                await Q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (apiResult.data) {
                    itemInfoType.value = apiResult.data.type;
                    translations.value = apiResult.data.translations;
                    swal.close();
                }
            }
        }
        if (props.type == 'faq') {
            await InitFaqType();
        } else if (props.type == 'moreinfo') {
            await InitMoreInfoType();
        } else {
            router.push({ name: 'edit-product', params: { id: props.productId } });
            return;
        }
        addMissionLangs();
        function goBack() {
            window.history.back();
        }
        async function onSaveFaq() {
            swal.showLoading();
            const apiPromise = api.SaveFAQTranslations(props.productId, props.id, translations.value);
            await Q.delay(400);
            const apiResult = await apiPromise;
            swal.close();
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                const faq = product.value.productFaqs.find(x => x.id == props.id);
                if (faq) {
                    faq.translations = translations.value;
                    const currnetLangTranslation = translations.value.find(x => x.languageId == currentLanguageId.value);
                    faq.question = currnetLangTranslation?.name || '';
                    faq.answer = currnetLangTranslation?.description || '';
                }
                router.push({ name: 'edit-product', params: { id: props.productId } });
            }
        }
        async function onSaveMoreInfo() {
            swal.showLoading();
            const apiPromise = api.SaveMoreInfoTranslations(props.productId, props.id, translations.value);
            await Q.delay(400);
            const apiResult = await apiPromise;
            swal.close();
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                const faq = product.value.productInfos.find(x => x.id == props.id);
                if (faq) {
                    faq.translations = translations.value;
                    const currnetLangTranslation = translations.value.find(x => x.languageId == currentLanguageId.value);
                    faq.title = currnetLangTranslation?.name || '';
                    faq.description = currnetLangTranslation?.description || '';
                }
                router.push({ name: 'edit-product', params: { id: props.productId } });
            }
        }
        async function save() {
            if (!translations.value) {
                return;
            }
            if (props.type == 'faq') {
                onSaveFaq();
            } else if (props.type == 'moreinfo') {
                onSaveMoreInfo();
            }
        }
        return {
            langs,
            selectedOption,
            save,
            product,
            goBack,
            translations,
            currentLanguage,
            itemInfoType,
            productInfoType,
            discMaxLength
        };
    }
};
