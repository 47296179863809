
import { defineComponent, PropType, toRef } from 'vue';
import swal from 'sweetalert2';
import q from 'q';
import api from '@/services/Api';
import { useI18n } from 'vue-i18n';
import { СashierStation } from '@/models/Provider';

export default defineComponent({
  name: 'СashierStations',
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    providerId: {
      type: Number,
      required: true,
    },
    items: {
      type: [] as PropType<СashierStation[]>,
      default: () => [],
    },
    autoPrintReceiptProp: {
      type: Boolean,
      default: false,
    },
  },
  async setup(props, { emit }) {
    const { t } = useI18n();
    const autoPrintReceipt = toRef(props, 'autoPrintReceiptProp');

    async function addStation() {
      const swalResult = await swal.fire({
        icon: 'info',
        title: t('createStation'),
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
        cancelButtonText: t('editProductPage.cancelEdit.alertCancel'),
      });
      await q.delay(200);
      if (swalResult.isConfirmed) {
        swal.showLoading();
        const apiPromise = api.addStation(props.providerId);
        await q.delay(400);
        const apiResult = await apiPromise;
        if (apiResult.errorMessage) {
          swal.fire({
            icon: 'error',
            text: apiResult.errorMessage,
          });
          return;
        }
        if (!apiResult.data) {
          return;
        }
        emit('addStation', apiResult.data);
        await swal.fire({
          position: 'center',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }

    async function deleteStation(stationId: number){
      const swalResult = await swal.fire({
        icon: 'info',
        title: t('cashier.station.deleteStationMessage'),
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
        cancelButtonText: t('editProductPage.cancelEdit.alertCancel'),
      });

      await q.delay(200);

      if (swalResult.isConfirmed) {
        swal.showLoading();
        const apiPromise = api.deleteStation(stationId);
        await q.delay(400);
        const apiResult = await apiPromise;

        if (apiResult.errorMessage) {
          swal.fire({
            icon: 'error',
            text: apiResult.errorMessage,
          });
          return;
        }

        if (!apiResult.data) {
          return;
        }

        emit('deleteStation', apiResult.data);

        await swal.fire({
          position: 'center',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }

    async function ChangeSellingSettingAutoPrintReceipt(evt: Event) {
      evt.preventDefault();
      const swalAction = await swal.fire({
        icon: 'info',
        text: autoPrintReceipt.value
          ? t('selling-settings.alert.disableAutoPrintReceipt')
          : t('selling-settings.alert.enableAutoPrintReceipt'),
        customClass: {
          confirmButton: autoPrintReceipt.value
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: autoPrintReceipt.value ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      await q.delay(200);
      swal.showLoading();
      const apiPromise = api.changeAutoPrintReceiptProviderSetting(!autoPrintReceipt.value, props.providerId);
      await q.delay(400);
      const apiResult = await apiPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      if (apiResult.data) {
        emit('changeReceiptProviderSetting', apiResult.data.newValue);
        await swal.fire({
          position: 'center',
          icon: 'success',
          showConfirmButton: true,
        });
      }
    }
    return { 
      addStation, 
      autoPrintReceipt, 
      ChangeSellingSettingAutoPrintReceipt,
      deleteStation
    };
  },
});
