export class AgencyRow {
  id = 0;
  name = '';
  email = '';
  mobile = '';
  createDate = '';
  isActive = false;
}

export class AgenciesFilter {
  pageIndex = 0;
  keyword = '';
  orderBy = 'Name';
  asc = true;

  active: boolean | number | null = null;
  email = '';
  mobile = '';
  name = '';
  createDate: string | null = null;
}

export class AgencyDetails {
  id = 0;
  name = '';
  address = '';
  email = '';
  mobile = '';
  description = '';
  website = '';
  active = true;
  createDate = null;

  users: User[] = [];
}

export interface AgentDetails {
  id: number;
  agencyName: string;
  fullName: string;
  email: string;
  mobile: string;
}
