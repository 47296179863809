
import api from '@/services/Api';
import { defineComponent, ref, toRaw } from 'vue';
import swal from 'sweetalert2';
import router from '@/router';
import { useRoute } from 'vue-router';
import { AgencyDetails } from '@/models/Agencies';
import AgencyDetailsComponent from '@/components/Index/AgencyDetailsComponent.vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';

export default defineComponent({
  components: {
    AgencyDetailsComponent,
  },
  name: 'EditAgency',
  async setup() {
    const agency = ref<AgencyDetails>(new AgencyDetails());

    const details = ref<InstanceType<typeof AgencyDetailsComponent>>();

    const route = useRoute();

    var { t } = useI18n();

    async function loadAgency() {
      var agencyId = (route.params.id && Number(route.params.id)) || null;
      if (!agencyId) {
        agency.value = new AgencyDetails();
        return;
      }

      const response = (await api.GetAgencyDetails(agencyId))?.data;
      if (!response || response.error) {
        swal.fire({
          icon: 'error',
          text: response?.error || 'No data',
        });
      }
      agency.value = response?.agency || new AgencyDetails();
    }

    loadAgency();

    function submitForm(event: any) {
      event.target.classList.add('was-validated');
      if (event.target.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        swal.fire({
          icon: 'warning',
          title: t('alert.mandatoryfields'),
          confirmButtonText: t('button.close'),
        });
      } else {
        onSave();
      }
    }

    async function onSave() {
      const agencyChanged = details.value?.getAgency();
      if (!agencyChanged) return false;
      const providerOld = toRaw(agency.value);
      const isSame = JSON.stringify(providerOld) == JSON.stringify(agencyChanged);
      let agencyId = agencyChanged.id;
      if (!isSame || !agencyId) {
        swal.showLoading();
        var apiResponse = await api.SaveAgencyDetails(agencyChanged);
        if (apiResponse.errorMessage || !apiResponse.data) {
          swal.fire({
            icon: 'error',
            text: apiResponse.errorMessage || 'No data',
          });
          return false;
        }
        agencyId = apiResponse.data.agencyId;
      }

      if (!isSame) {
        store.commit('setRefreshAgencySearch', true);
      }
      await swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Agency ' + (agencyId ? 'updated' : 'created'),
        showConfirmButton: false,
        timer: 1500,
      });
      if (agencyId) {
        router.go(-1);
      } else {
        router.replace({ name: 'agency-view', params: { id: agencyId } });
      }
      return true;
    }

    async function onCancel() {
      const agencyChanged = details.value?.getAgency();
      if (!agencyChanged) return;
      const agencyOld = toRaw(agency.value);
      const isSame = JSON.stringify(agencyOld) == JSON.stringify(agencyChanged);

      if (isSame && agencyChanged.id) {
        router.go(-1);
        return;
      }

      const confirmSave = await swal.fire({
        icon: 'info',
        title: t('providers.confirmBeforeCancel'),
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
          denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonText: t('button.yes'),
        denyButtonText: t('button.no'),
      });
      if (confirmSave.isConfirmed) {
        onSave();
        return;
      }
      if (confirmSave.isDenied) {
        router.go(-1);
        return;
      }
    }

    return {
      submitForm,
      agency,
      onSave,
      onCancel,
      details,
    };
  },
});
