
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import router from '@/router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import useExtensions from '@/useExtensions';

var { formatDate, awaitTask } = useExtensions();

@Options({
  props: {
    items: {
      type: [],
      required: true,
    },
    providerId: {
      type: Number,
      required: true,
    },
  },
  name: 'Users',
  components: {
    Multiselect,
  },
  data() {
    return {
      filteredUsers: [],
    };
  },
  computed: {
    showUsers() {
      if (this.ShowDefaultUsers) {
        return this.items;
      }
      return this.users;
    },
  },
})
export default class Users extends Vue {
  protected readonly providerId!: number;
  public showMore = false;
  public users: ProviderUsers[] = [];
  public ShowDefaultUsers = true;
  public searchText = '';
  editUser(userId: number) {
    router.push({ name: 'EditUser', params: { providerId: this.providerId, id: userId } });
  }
  async showMoreUsers() {
    await this.getUsersByFilter();
  }
  async getUsersByFilter() {
    this.ShowDefaultUsers = false;
    swal.showLoading();
    const apiResult = await api.getUsersByFilter(this.searchText, this.providerId);
    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
      return;
    }
    this.users = apiResult.data?.users || [];
    swal.close();
  }
  async Export() {
    swal.showLoading();
    const apiResult = await api.exportUsers(this.searchText, this.providerId);
    await Q.delay(400);
    if (apiResult.data?.error || !apiResult.data) {
      swal.fire({
        icon: 'error',
        text: apiResult.data?.error || 'No data',
      });
      return;
    }
    const taskId = apiResult.data.taskId;
    awaitTask(
      () => api.waitExportTask(taskId),
      (url) => {
        swal.fire({
          icon: 'success',
          title: this.$t('alert.exportReady.users'),
          html: `
              <div class="text-center my-4">
                  <a href="${url}" target="_blank">
                      <button role="button" class="popup-bookit-button my-1 px-4">${this.$t('report.download')}</button>
                  </a>
              </div>`,
          showConfirmButton: false,
          showCloseButton: true,
        });
      },
      this.$t
    );
  }
}
