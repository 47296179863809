
import { computed, defineComponent, ref } from "vue";
import Calendar from 'primevue/calendar';
import { DateRange } from '@/models/Order';
import swal from "sweetalert2";
import api from '@/services/Api';
import Q from 'q';
import {IndexStatisticsResult, IndexUser} from "@/models/ApiInterfaces";
import TimelineBlock from '@/components/Index/TimelineBlock.vue';
import Multiselect from '@vueform/multiselect';
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: 'IndexStatistics',
    components: {
        Calendar,
        TimelineBlock,
        Multiselect
    },

    async setup() {
        const { t } = useI18n();

        const dateRange = ref<DateRange>(new DateRange());
        dateRange.value.fromDate = new Date(new Date().setDate(new Date().getDate() - 30))
        dateRange.value.toDate = new Date();

        const isProductsTablesChosed = ref(true);

        const providerName = ref<string | null>(null);
        const productName = ref<string | null>(null);
        const agencyName = ref<string | null>(null);
        const username = ref<string | null>(null);
        const userActive = ref<boolean | number | null>(null);
        const lastUserActionOnWebsiteRange = ref<DateRange>(new DateRange());
        const indexStatistics = ref<IndexStatisticsResult>();

        const addZero = (number: number) => {
            const str = number.toString();
            if (str.length == 1) {
                return '0' + str;
            }
            return str;
        }

        const convertDate = (data: Date) => {
            const day = data.getDate();
            const month = data.getMonth() + 1;
            const year = data.getFullYear();
            return year + '-' + addZero(month) + '-' + addZero(day);
        }

        async function loadData() {
            swal.showLoading();
            const filter = {
                productId: null as string | null,
                fromDate: null as string | null,
                toDate: null as string | null
            };
            if (dateRange.value.fromDate) {
                filter.fromDate = convertDate(dateRange.value.fromDate);
            }
            if (dateRange.value.toDate) {
                filter.toDate = convertDate(dateRange.value.toDate);
            }
            productName.value = null;

            const apiResult = await api.GetIndexStatistics(filter);
            await Q.delay(400);
            if (apiResult.errorMessage || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            indexStatistics.value = apiResult.data;
            swal.close();
        }

        function createSet(data: any[], labels: string[], label: string, fill: boolean, backgroundColor: string, pointRadius: number, borderWidth: number) {
            return {
                datasets: [
                    {
                        backgroundColor,
                        data,
                        fill: fill,
                        label,
                        pointRadius,
                        borderWidth
                    }
                ],
                labels
            };
        }

        const timeline = computed(() => {
            const datasets = createSet(
                indexStatistics.value?.timelineIndexUsers.map(x => ({ x: x.date, y: x.indexUsersQty })) ?? [],
                [],
                t('index-statistics.timeline-label.new-users'),
                false,
                '#155878',
                5,
                3
            );

            datasets.datasets.push({
                data: indexStatistics.value?.timelineIndexStatistics?.map(x => ({x: x.date, y: x.productPageVisitsQty})) ?? [],
                fill: false,
                label: t('index-statistics.timeline-label.products-opens'),
                backgroundColor: '#FFBD43',
                pointRadius: 5,
                borderWidth: 3
            });
            datasets.datasets.push({
                data: indexStatistics.value?.timelineIndexStatistics?.map(x => ({x: x.date, y: x.showContactsClicksQty})) ?? [],
                fill: false,
                label: t('index-statistics.timeline-label.show-contacts-presses'),
                backgroundColor: '#1FB6FF',
                pointRadius: 5,
                borderWidth: 3
            });
            datasets.datasets.push({
                data: indexStatistics.value?.timelineIndexQuotes?.map(x => ({x: x.date, y: x.quotesQty})) ?? [],
                fill: false,
                label: t('index-statistics.timeline-label.quotes-qty'),
                backgroundColor: '#811F59',
                pointRadius: 5,
                borderWidth: 3
            });
            datasets.datasets.push({
                data: indexStatistics.value?.timelineIndexChats?.map(x => ({x: x.date, y: x.chatsQty})) ?? [],
                fill: false,
                label: t('index-statistics.timeline-label.new-chats'),
                backgroundColor: '#D65D05',
                pointRadius: 5,
                borderWidth: 3

            });
            datasets.datasets.push({
                data: indexStatistics.value?.timelineIndexUsersAuthorizations?.map(x => ({x: x.date, y: x.authorizationsQty})) ?? [],
                fill: false,
                label: t('index-statistics.timeline-label.users-authorizations'),
                backgroundColor: '#F1354C',
                pointRadius: 5,
                borderWidth: 3
            })

            return {
                data: datasets
            };
        });

        const getIndexProducts = () => {
            let indexProducts = JSON.parse(JSON.stringify(indexStatistics.value?.indexProductsTable));
            if (providerName.value) {
                indexProducts = indexStatistics.value?.indexProductsTable?.filter(x => x.providerName === providerName.value);
            }
            if (productName.value) {
                indexProducts = indexStatistics.value?.indexProductsTable?.filter(x => x.productName === productName.value);
            }

            return indexProducts;
        }

        const getIndexUsers = () => {
            let indexUsers = JSON.parse(JSON.stringify(indexStatistics.value?.indexUsersTable)) as IndexUser[];
            if (agencyName.value) {
                indexUsers = indexUsers.filter(x => x.agencyname === agencyName.value);
            }
            if (username.value) {
                indexUsers = indexUsers.filter(x => x.username === username.value);
            }
            if (userActive.value === 0 || userActive.value) {
                indexUsers = indexUsers.filter(x => x.active === Boolean(userActive.value))
            }
            if (lastUserActionOnWebsiteRange.value.fromDate) {
                indexUsers = indexUsers.filter(x => x.lastActionOnWebsite && setHours(new Date(x.lastActionOnWebsite)) >= setHours(lastUserActionOnWebsiteRange.value.fromDate!));
            }
            if (lastUserActionOnWebsiteRange.value.toDate) {
                indexUsers = indexUsers.filter(x => x.lastActionOnWebsite && setHours(new Date(x.lastActionOnWebsite)) <= setHours(lastUserActionOnWebsiteRange.value.toDate!));
            }

            return indexUsers;
        }

        const setHours = (date: Date) => {
            date.setHours(0);
            return date;
        }

        const userActiveOptions = ():{ id: number; name: string }[] => {
            return [
                {
                    id: 1,
                    name: t('label.active').toString()
                },
                {
                    id: 0,
                    name: t('label.notactive').toString()
                }
            ]
        }

        function showAll() {
            dateRange.value = new DateRange();
            loadData();
        }

        await loadData();

        return {
            dateRange,
            lastUserActionOnWebsiteRange,
            loadData,
            indexStatistics,
            showAll,
            userActiveOptions,
            timeline,
            isProductsTablesChosed,
            providerName,
            productName,
            agencyName,
            username,
            userActive,
            getIndexProducts,
            getIndexUsers,
        }
    }
})
