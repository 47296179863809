
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { AgentDetails } from '@/models/Agencies';

export default defineComponent({
    components: {},
    name: 'AgentDetails',
    async setup() {
        const route = useRoute();
        const id = route.params.id.toString();
        const agent = ref<AgentDetails>();

        async function getAgentDetails() {
            if (id) {
                swal.showLoading();
                const apiResult = await api.GetAgentDetails(id);
                await Q.delay(400);
                if (!apiResult.data) {
                    swal.fire({
                        icon: 'error',
                        text: 'Error'
                    });
                    swal.close();
                    return '';
                } else {
                    agent.value = apiResult.data;
                }
                swal.close();
                return apiResult.data;
            }
            return null;
        }

        await getAgentDetails();

        function Cancel() {
            router.push({ name: 'IndexChatQuotes' });
        }

        return { agent, Cancel };
    }
});
