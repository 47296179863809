
import { computed, reactive, ref } from 'vue';
import { Product, ProductTranslationItem, Translation } from '@/models/Product';
import useProduct from '@/components/Product/useProduct';
import { useRoute } from 'vue-router';
import generalStore from '@/store';
import Multiselect from '@vueform/multiselect';
import api from '@/services/Api';
import router from '@/router';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

export default {
    props: {
        type: {
            type: String
        }
    },
    components: {
        Multiselect
    },
    async setup() {
        const showInfo = ref(false);
        const route = useRoute();
        const { t } = useI18n();
        const { getProduct } = useProduct();

        const id = route.params.id.toString();

        const selectedOption = ref();
        const currentType = ref(route.params.type);
        if (!route.params.type) {
            router.push({ name: 'edit-product', params: { id: id } });
        }

        const currentLanguage = computed(() => generalStore.getters.currentLanguage);
        const currLangFullInfo = ref();
        const langs = computed<Language[]>(() => generalStore.getters.languages);
        const product = computed<Product>(() => generalStore.getters.product);
        const apiProductTranslations = await api.getProductTranslations(id);

        if (langs.value) {
            selectedOption.value = langs.value.find(l => l.name == currentLanguage.value)?.id;
            currLangFullInfo.value = langs.value.find(l => l.id == selectedOption.value);
        }
        if (!product.value || !product.value.id) {
            if (route.params.type) {
                await getProduct(id);
            }
        }

        const currTranslations = apiProductTranslations.data;
        langs.value.forEach(lang => {
            if (!currTranslations?.find(x => x.language.id == lang.id)) {
                currTranslations?.push({
                    name: '',
                    shortDescription: '',
                    fullDescription: '',
                    fullDescriptionIndex: '',
                    arrivalInstructions: '',
                    parkingInstructions: '',
                    language: lang,
                    responsiblePersonDescription: '',
                    policy: '',
                    extraInfo: ''
                });
            }
        });
        const tempTranslation = JSON.stringify(currTranslations);
        const currentTranslation: ProductTranslation[] = reactive(JSON.parse(tempTranslation));

        function goBack() {
            router.push({ name: 'edit-product', params: { id: id } });
        }
        function addCurrentDefaultTranslation() {
            if (langs.value) {
                currLangFullInfo.value = langs.value.find(l => l.id == selectedOption.value);
            }
            const found = currentTranslation?.find(t => t.language.id === selectedOption.value);
            if (!found) {
                const newTranslation = new Translation();
                if (langs.value) {
                    newTranslation.language = currLangFullInfo.value;
                }
                currentTranslation?.push(newTranslation);
            }
        }
        if (currentTranslation && currentTranslation.length === 0) {
            addCurrentDefaultTranslation();
        } else {
            const found = currentTranslation.find(t => t.language.id === selectedOption.value);
            if (!found) {
                addCurrentDefaultTranslation();
            }
        }
        if (route.params.value) {
            const translationIndex = currentTranslation.findIndex(t => t.language.name == currentLanguage.value);
            if (currentType.value == 'name') {
                currentTranslation[translationIndex].name = route.params.value.toString();
            } else if (currentType.value == 'long-description') {
                currentTranslation[translationIndex].fullDescription = route.params.value.toString();
            } else if (currentType.value == 'short-description') {
                currentTranslation[translationIndex].shortDescription = route.params.value.toString();
            } else if (currentType.value == 'long-description-index') {
                currentTranslation[translationIndex].fullDescriptionIndex = route.params.value.toString();
            } else if (currentType.value == 'arrivalInstructions') {
                currentTranslation[translationIndex].arrivalInstructions = route.params.value.toString();
            } else if (currentType.value == 'parkingInstructions') {
                currentTranslation[translationIndex].parkingInstructions = route.params.value.toString();
            } else if (currentType.value == 'extraInfo') {
                currentTranslation[translationIndex].extraInfo = route.params.value.toString();
            }
        }
        function selectLanguage() {
            if (!currentTranslation) {
                router.push({ name: 'edit-product', params: { id: route.params.id } });
                return;
            }
            addCurrentDefaultTranslation();
        }
        async function updateProductName() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.name });
            });
            const newTranslation = await api.saveNewProductNameTranslation(id, reqArray);
            if (newTranslation.data) {
                product.value.name = newTranslation.data?.find(ct => ct.language.name === currentLanguage.value)
                    ?.name as string;
            } else {
                await swal.fire({
                    icon: 'warning',
                    title: newTranslation.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            swal.close();
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            return;
        }
        async function updateProductShortDescription() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.shortDescription });
            });
            const newTranslation = await api.saveNewProductShortDescriptionTranslation(id, reqArray);
            if (newTranslation.data) {
                product.value.shortDescription = newTranslation.data?.find(
                    ct => ct.language.name === currentLanguage.value
                )?.shortDescription as string;
            }
            swal.close();
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            return;
        }
        async function updateProductLongDescription() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.fullDescription });
            });
            const newTranslation = await api.saveNewProductLongDescriptionTranslation(id, reqArray);
            if (newTranslation.data) {
                product.value.fullDescription = newTranslation.data?.find(
                    ct => ct.language.name === currentLanguage.value
                )?.fullDescription as string;
            }
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            swal.close();
            return;
        }
        async function updateProductLongDescriptionIndex() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.fullDescriptionIndex });
            });
            const newTranslation = await api.saveNewProductLongDescriptionIndexTranslation(id, reqArray);
            if (newTranslation.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: newTranslation.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (newTranslation.data) {
                product.value.fullDescriptionIndex = newTranslation.data?.find(ct => ct.language.name === currentLanguage.value)
                    ?.fullDescriptionIndex as string;
            }
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            swal.close();
            return;
        }
        async function updateProductArrivalIstructionsTranslation() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.arrivalInstructions });
            });
            const newTranslation = await api.saveupdateProductArrivalIstructionsTranslation(id, reqArray);
            if (newTranslation.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: newTranslation.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (newTranslation.data) {
                product.value.arrivalInstructions = newTranslation.data?.find(ct => ct.language.name === currentLanguage.value)?.arrivalInstructions as string;
            }
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            swal.close();
            return;
        }
        async function updateProductParkingIstructionsTranslation() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.parkingInstructions });
            });
            const newTranslation = await api.saveupdateProductParkingIstructionsTranslation(id, reqArray);
            if (newTranslation.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: newTranslation.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (newTranslation.data) {
                product.value.parkingInstructions = newTranslation.data?.find(ct => ct.language.name === currentLanguage.value)?.parkingInstructions as string;
            }
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            swal.close();
            return;
        }
        async function updateResponsiblePersonDescriptionTranslation() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.responsiblePersonDescription });
            });
            const newTranslation = await api.saveupdateProductResponsiblePersonDescriptionTranslation(id, reqArray);
            if (newTranslation.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: newTranslation.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (newTranslation.data) {
                product.value.responsiblePersonDescription = newTranslation.data?.find(ct => ct.language.name === currentLanguage.value)
                    ?.responsiblePersonDescription as string;
            }
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            swal.close();
            return;
        }

        async function updateProductPolicyTranslation() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.policy });
            });
            const newTranslation = await api.saveupdateProductPolicyTranslation(id, reqArray);
            if (newTranslation.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: newTranslation.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (newTranslation.data) {
                product.value.policy = newTranslation.data?.find(ct => ct.language.name === currentLanguage.value)?.policy as string;
            }
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            swal.close();
            return;
        }
         async function updateProductExtraInfoTranslation() {
            swal.showLoading();
            const reqArray: ProductTranslationItem[] = [];
            currentTranslation?.forEach(translation => {
                reqArray.push({ languageId: translation.language.id, newTranslation: translation.extraInfo });
            });
            const newTranslation = await api.saveupdateProductExtraInfoTranslation(id, reqArray);
            if (newTranslation.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    title: newTranslation.errorMessage,
                    confirmButtonText: t('button.close')
                });
                return;
            }
            if (newTranslation.data) {
                product.value.extraInfo = newTranslation.data?.find(ct => ct.language.name === currentLanguage.value)?.extraInfo as string;
            }
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            swal.close();
            return;
        }

        function saveTranslation() {
            if (currentType.value === 'name') {
                updateProductName();
            }
            if (currentType.value === 'short-description') {
                updateProductShortDescription();
            }
            if (currentType.value === 'long-description') {
                updateProductLongDescription();
            }
            if (currentType.value === 'long-description-index') {
                updateProductLongDescriptionIndex();
            }
            if (currentType.value === 'arrivalInstructions') {
                updateProductArrivalIstructionsTranslation();
            }
            if (currentType.value === 'parkingInstructions') {
                updateProductParkingIstructionsTranslation();
            }
            if (currentType.value === 'responsible-person-description') {
                updateResponsiblePersonDescriptionTranslation();
            }
            if (currentType.value === 'policy') {
                updateProductPolicyTranslation();
            }
            if (currentType.value === 'extraInfo') {
                updateProductExtraInfoTranslation();
            }
        }
        function checkDescription(descriptionType: string) {
            const selectedTranslation = currentTranslation.find(x => x.language.id === selectedOption.value);
            if (descriptionType == 'parking') {
                if (selectedTranslation && selectedTranslation?.parkingInstructions.length > 300) {
                    selectedTranslation.parkingInstructions = selectedTranslation.parkingInstructions.substring(0, 300);
                }
            }
            if (descriptionType == 'arrival') {
                if (selectedTranslation && selectedTranslation?.arrivalInstructions.length > 300) {
                    selectedTranslation.arrivalInstructions = selectedTranslation.arrivalInstructions.substring(0, 300);
                }
            }
            if (descriptionType == 'name') {
                if (selectedTranslation && selectedTranslation?.name.length > 30) {
                    selectedTranslation.name = selectedTranslation.name.substring(0, 30);
                }
            }
            if (descriptionType == 'longdescription') {
                if (selectedTranslation && selectedTranslation?.fullDescription.length > 1200) {
                    selectedTranslation.fullDescription = selectedTranslation.fullDescription.substring(0, 1200);
                }
            }
            if (descriptionType == 'longdescriptionindex') {
                if (selectedTranslation && selectedTranslation?.fullDescriptionIndex.length > 1200) {
                    selectedTranslation.fullDescriptionIndex = selectedTranslation.fullDescriptionIndex.substring(0, 1200);
                }
            }
            if (descriptionType == 'shortdescription') {
                if (selectedTranslation && selectedTranslation?.shortDescription.length > 120) {
                    selectedTranslation.shortDescription = selectedTranslation.shortDescription.substring(0, 120);
                }
            }
            if (descriptionType == 'responsiblepersondescription') {
                if (selectedTranslation && selectedTranslation?.responsiblePersonDescription.length > 1200) {
                    selectedTranslation.responsiblePersonDescription = selectedTranslation.responsiblePersonDescription.substring(0, 1200);
                }
            }
            if (descriptionType == 'policy') {
                if (selectedTranslation && selectedTranslation?.policy.length > 255) {
                    selectedTranslation.policy = selectedTranslation.policy.substring(0, 255);
                }
            }
            if (descriptionType == 'extraInfo') {
                if (selectedTranslation && selectedTranslation?.extraInfo.length > 255) {
                    selectedTranslation.extraInfo = selectedTranslation.extraInfo.substring(0, 255);
                }
            }
        }
        return {
            saveTranslation,
            currLangFullInfo,
            currentTranslation,
            currentLanguage,
            currentType,
            selectLanguage,
            langs,
            goBack,
            selectedOption,
            showInfo,
            checkDescription
        };
    }
};
