
import router from '@/router';
import generalStore from '@/store';
import { ref, computed } from 'vue';
import useProduct from '@/components/Product/useProduct';
import { Product } from '@/models/Product';
import moment from 'moment';
import api from '@/services/Api';

export default {
    props: {
        productId: {
            type: String,
            required: true
        },
        isFromWizard: {
            type: Boolean,
            required: true
        }
    },
    async setup(props: any) {
        const { getProduct } = useProduct();
        if (props.productId) await getProduct(props.productId);

        const product = computed<Product>(() => generalStore.getters.product);
        const isFromWizard = props.isFromWizard === 'true';

        const allPredefinedDates = ref<PredefinedDate[]>();
        const predefinedDatesResponse =  await api.getPredefinedDates();
        if (predefinedDatesResponse.data) {
            allPredefinedDates.value = predefinedDatesResponse.data;
        }

        const addPredefinedDate = (predefinedDate: PredefinedDate) => {
            if (product.value && isFromWizard) {
                product.value.productWorkingDates[0].name = predefinedDate.name;
                product.value.productWorkingDates[0].fromDate = moment(predefinedDate.fromDate).toDate().toDateString();
                product.value.productWorkingDates[0].toDate = moment(predefinedDate.toDate).toDate().toDateString();
            } else {
                const found = product.value.productWorkingDates.find(w => w.id == 0);
                if(found){
                    found.name = predefinedDate.name;
                    found.fromDate = moment(predefinedDate.fromDate).toDate().toDateString();
                    found.toDate = moment(predefinedDate.toDate).toDate().toDateString();
                }
            }
            router.go(-1);
        };

        const close = () => {
            router.go(-1);
        };

        const search = ref('');
        const filteredPredefinedDates = computed(() => {
            return allPredefinedDates.value?.filter((predefinedDate: any) => {
                return predefinedDate.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
            });
        });

        return {
            close,
            filteredPredefinedDates,
            search,
            allPredefinedDates,
            addPredefinedDate
        };
    }
};
