import { useI18n } from 'vue-i18n';
import useExtensions from './useExtensions';

export default {
  methods: {
    ...useExtensions(),

    activityStatuses(): { id: number; name: string }[] {
      return [
        {
          id: 1,
          name: useI18n().t('label.active'),
        },
        {
          id: 0,
          name: useI18n().t('label.notactive'),
        },
      ]
    },
    filterKeyOnlyNumbersDotComma(event: KeyboardEvent) {
      if (event.key) {
        if (event.key.trim() === '' || /^[0-9,.]+$/.test(event.key.toString()) === false) {
          event.preventDefault();
        }
      }
    },
    filterKeyOnlyNumbersDot(event: KeyboardEvent) {
        if (event.key) {
            if (event.key.trim() === '' || /^[0-9.]+$/.test(event.key.toString()) === false) {
                event.preventDefault();
            }
        }
    },
    filterPastedOnlyNumbersDotComma(event: any) {
      const value = event.clipboardData.getData('text/plain');

      if (/^[0-9,.]+$/.test(value.toString()) === false) {
        event.preventDefault();
      }
    },
    filterPastedOnlyNumber(event: any) {
      const value = event.clipboardData.getData('text/plain');

      if (/^\d+$/.test(value.toString()) === false) {
        event.preventDefault();
      }
    },
  },
};
