
import { defineComponent, ref, onMounted, provide, computed } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import api from '@/services/Api';
import { OrderDetails, Customer } from '@/models/Order';
import swal from 'sweetalert2';
import router from '@/router';
import Q from 'q';
import { OrderStatus, OrderStatusColors } from '@/models/Enums';
import moment from 'moment';
import store from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Order',
  data() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == 'modal-order-edit-remarks') {
      to.params.needScrollBottom = '1';
    }
    next();
  },
  async setup() {
    const { t } = useI18n();
    const isMobileView = computed(() => store.state.isMobileView);
    let chatTimerId = 0;
    const route = useRoute();
    onBeforeRouteLeave(async () => {
      clearTimeout(chatTimerId);
      return true;
    });
    onMounted(() => {
      const result = document?.getElementById('order-details-title-client');
      const height = result?.offsetHeight;
      if (height && height > 80) {
        const title = document?.getElementById('order-details-title');
        title?.classList.add('client-name-not-fit');
      }
      const result2 = document?.getElementById('order-details-title-client');
      const height2 = result2?.offsetHeight;
      if (!height2) {
        return;
      }
      if (height2 > 110) {
        const title = document?.getElementById('order-details-title');
        title?.classList.remove('client-name-not-fit');
        title?.classList.add('client-name-not-fit2');
      }
      if (route.params.needScrollBottom) {
        const container = document.getElementById('main-content');
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }
    });
    swal.showLoading();
    const message = ref('');
    const isActionsExpand = ref(false);
    const actionsMenu = ref('');
    provide('isActionsExpand', isActionsExpand);
    const id = route.params.id.toString();
    const getOrderTotalToPay = computed(() => {
      if (order.value.channelName.toLowerCase() == 'direct') {
        return order.value.priceTotalWithoutDiscount - order.value.discountAmount;
      } else {
        return order.value.priceTotalWithoutDiscount - order.value.commissionTotal;
      }
    });
    const filterType = ref<string>('ALL');
    const order = ref<OrderDetails>(new OrderDetails());
    const apiResult = await api.GetOrder(id);
    await Q.delay(400);
    if (apiResult.data?.error) {
      swal.fire({
        icon: 'error',
        text: apiResult.data?.error,
      });
      return;
    }
    order.value = apiResult.data?.order || new OrderDetails();

    let getNextMessages: (showOld?: boolean) => void = () => {
      'funtion template for stupid composition api';
    };

    async function getMessages(showOld = false, fromId?: number, showLoading = false) {
      if (showLoading) swal.showLoading();
      const chatResult = await api.GetOrderMessages(id, filterType.value, fromId, showOld);
      if (chatResult.data && !chatResult.errorMessage) {
        if (!fromId) order.value.messages = chatResult.data.messages;
        else if (showOld) order.value.messages.push(...chatResult.data.messages);
        else order.value.messages.unshift(...chatResult.data.messages);
      }
      if (showLoading) {
        if (chatResult.errorMessage) {
          swal.fire({
            icon: 'error',
            text: chatResult.errorMessage,
          });
        } else swal.close();
      }

      if (!showOld) {
        clearTimeout(chatTimerId);
        chatTimerId = setTimeout(getNextMessages, 60 * 1000);
      }
    }

    getNextMessages = async (showOld = false) => {
      let fromId: number | undefined = undefined;

      if (order.value.messages.length > 0)
        fromId = showOld ? order.value.messages[order.value.messages.length - 1].id : order.value.messages[0].id;
      await getMessages(showOld, fromId);
    };

    getMessages();

    function changeChatType(type: string) {
      filterType.value = type;
      getMessages(false, undefined, true);
    }

    function formatChatDate(date: string) {
      const d = moment(date);
      return moment().isSame(d, 'day') ? d.format('HH:mm:ss') : d.format('DD/MM/YYYY HH:mm');
    }

    function EditOrder() {
      router.push({ name: 'modal-order-edit', params: { customer: JSON.stringify(order.value.leadCustomer ?? new Customer()) } });
    }
    swal.close();

    function changeRemarks() {
      router.push({ name: 'modal-order-edit-remarks', params: { id: id } });
    }

    function changeOrderProductDate(productId: string) {
      router.push({ name: 'modal-order-edit-productdate', params: { id: id, productid: productId } });
    }

    function actions() {
      isActionsExpand.value = true;
      if (window.innerWidth < 991) {
        actionsMenu.value = 'mobile';
        return;
      }
      actionsMenu.value = 'desktop';
    }
    async function cancelOrder() {
      const resultSwal = await swal.fire({
        title: t('alert.order-to-cancel-sure'),
        icon: 'warning',
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('alert.yesContinue'),
        cancelButtonText: t('button.cancel'),
      });
      if (!resultSwal.isConfirmed) return;
      await Q.delay(200);
      swal.showLoading();
      const status = OrderStatus[3];
      const apiResult = await api.SetOrderStatus(order.value.id, status, false);
      await Q.delay(400);
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      order.value.status = status;
      order.value.statusId = 3;
      swal.close();
    }
    function getHoursMinutesSlotDuration(time: string) {
      const startTime = moment.duration(time);
      const hours = startTime.hours();
      const minutes = startTime.minutes();
      if (hours == 0) {
        return t('orders.duration-minutes', { minutes: minutes });
      }
      if (minutes == 0) {
        return t('orders.duration-hours', { hours: hours });
      }
      return t('orders.duration-hours-minutes', { hours: hours, minutes: minutes });
    }

    return {
      order,
      EditOrder,
      changeRemarks,
      message,
      changeOrderProductDate,
      OrderStatusColors,
      formatChatDate,
      chatTimerId,
      filterType,
      changeChatType,
      getNextMessages,
      isMobileView,
      isActionsExpand,
      id,
      cancelOrder,
      actions,
      actionsMenu,
      getHoursMinutesSlotDuration,
      getOrderTotalToPay,
    };
  },
});
