
import { defineComponent, PropType, computed, toRef } from 'vue';
import { PromotedCategory, PromoFilter, PromotedCategoryRegion } from '@/models/Product';
import useExtensions from '@/useExtensions';
import draggable from 'vuedraggable';
import store from '@/store';

export default defineComponent({
  name: 'PromoCategories',
  props: {
    items: {
      type: [] as PropType<PromotedCategory[]>,
      default: () => [],
    },
    filter: {
      type: [] as PropType<PromoFilter>,
      required: true,
    },
  },
  components: {
    draggable,
  },
  async setup(props, { emit }) {
    const promoted = toRef(props, 'items');
    const filter = toRef(props, 'filter');
    const promotedCategories = computed({
      get() {
        return promoted.value;
      },
      set(newValue) {
        emit('on-order', newValue);
      },
    });
    var subTags: SubTag[] = [];
    if (!store.state.subTags.length) await store.dispatch('loadAllSubTags');
    subTags = store.state.subTags;

    const { formatLocalDate } = useExtensions();
    const getArrowClass = (sort: string) => {
      if (sort == filter.value.orderBy) {
        if (filter.value.asc) {
          return 'top-sort-arrow';
        } else {
          return 'bottom-sort-arrow';
        }
      }
      return 'standart-sort-arrow';
    };

    const getTagsName = (subTagIds: string) => {
      const tagArr = subTagIds?.split(',');
      const tags = subTags?.filter((s) => tagArr?.includes(s.id.toString())).map((t) => t.name);
      return tags?.join(', ');
    };
    const getRegionsName = (regions: PromotedCategoryRegion[]) => {
      return regions.map(x => x.regionName).join(", ");
    };

    return {
      promotedCategories,
      props,
      formatLocalDate,
      getArrowClass,
      getTagsName,
      getRegionsName
    };
  },
});
