
import api from '@/services/Api';
import { defineComponent, ref, computed, reactive } from 'vue';
import swal from 'sweetalert2';
import router from '@/router';
import { useRoute } from 'vue-router';
import { PromotedProduct } from '@/models/Product';
import { useI18n } from 'vue-i18n';
import generalStore from '@/store';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import { PromotedType } from '@/models/Enums';
import moment from 'moment';
import { DateRange } from '@/models/Order';

export default defineComponent({
  name: 'EditPromoProduct',
  components: {
    Multiselect,
    Calendar,
  },
  async setup() {
    const route = useRoute();
    var { t } = useI18n();

    const promoProduct = ref<PromotedProduct>(new PromotedProduct());
    const products = await generalStore.dispatch('getIndexProducts');
    const providers = await generalStore.dispatch('getProviders');
    const filteredProducts = computed<IdNameParent[]>(() => {
      return promoProduct.value.providerId
        ? products.filter((p: any) => p.parentId == promoProduct.value.providerId)
        : products;
    });
    const promoCounter = ref(0);
    const validated = ref(false);
    const mobileValidated = ref(false);
    const promotedType = ref(PromotedType[route.params.type as keyof typeof PromotedType]);
    if (route.params.type && promotedType.value == undefined) {
      swal
        .fire({
          icon: 'error',
          text: 'Not valid Promoted type',
        })
        .then(() =>
          router.replace({
            name: 'PromoManagement',
            params: { type: PromotedType[promotedType.value ?? PromotedType.Carousel] },
          }),
        );
    }
    const validDate = computed(() => {
      if (dateRange && dateRange.fromDate) {
        if (dateRange.fromDate > new Date()) {
          return dateRange.fromDate;
        } else {
          return new Date();
        }
      } else {
        return new Date();
      }
    })
    async function getProductImg(productId: number) {
      const response = await api.GetProductImg(productId);
      if (!response || response.error) {
        swal.fire({
          icon: 'error',
          text: response.errorMessage || 'No data',
        });
      }
      if (response.data) {
        promoProduct.value.imagePath = response.data.path;
        promoProduct.value.mobileImagePath = response.data.mobilePath;
        promoImage.value = response.data.path;
        promoImageMobile.value = response.data.mobilePath;
        promoProduct.value.imageToShow = response.data.path;
        promoProduct.value.mobileImageToShow = response.data.mobilePath;
      } else {
        promoProduct.value.imagePath = "";
        promoProduct.value.mobileImagePath = "";
        promoImage.value = null;
        promoImageMobile.value = null;
        promoProduct.value.imageToShow = "";
        promoProduct.value.mobileImageToShow = "";
      }
    }
    async function loadPromo() {
      var promoId = (route.params.id && Number(route.params.id)) || null;
      if (!promoId) {
        promoProduct.value = new PromotedProduct();
        if (route.params.type) {
          promoProduct.value.type = promotedType.value;
        }
        return;
      } else {
        const response = await api.GetPromotedProduct(promoId);
        if (!response || response.error) {
          swal.fire({
            icon: 'error',
            text: response.errorMessage || 'No data',
          });
        } else if (response.data) {
          promoProduct.value = response.data;
          if (promoProduct.value.type) promotedType.value = promoProduct.value.type;
          if (promoProduct.value.imagePath) {
            const str = promoProduct.value.imagePath + "?v=" + new Date();
            promoProduct.value.imageToShow = str;
          }
          if (promoProduct.value.mobileImagePath) {
            const str = promoProduct.value.mobileImagePath + "?v=" + new Date();
            promoProduct.value.mobileImageToShow = str;
          }
        }
      }
    }
    async function GetPromotedProductsCount() {
      var response = await api.GetPromotedProductsCount(promotedType.value);
      if (!response || response.error) {
        swal.fire({
          icon: 'error',
          text: response.errorMessage || 'No data',
        });
      }
      if (response.data) {
        promoCounter.value = response.data;
      }
    }
    await loadPromo();
    await GetPromotedProductsCount();
    const dateRange = reactive(new DateRange());
    dateRange.fromDate = promoProduct.value.fromDate ? new Date(promoProduct.value.fromDate) : null;
    dateRange.toDate = promoProduct.value.toDate ? new Date(promoProduct.value.toDate) : null;

    function submitForm(event: any) {
      validated.value = mobileValidated.value = true;
      event.target.classList.add('was-validated');
      if (event.target.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        swal.fire({
          icon: 'warning',
          title: t('alert.mandatoryfields'),
          confirmButtonText: t('button.close'),
        });
      } else {
        onSave();
      }
    }

    async function onSave() {
      swal.showLoading();
      promoProduct.value.fromDate = moment(dateRange.fromDate).format('YYYY-MM-DD');
      promoProduct.value.toDate = moment(dateRange.toDate).format('YYYY-MM-DD');
      if (
        (!promoProduct.value.imagePath && !promoImage.value) ||
        ((PromotedType[promotedType.value] == 'TopBanner' || PromotedType[promotedType.value] == 'BottomBanner') && !promoProduct.value.mobileImagePath && !promoImageMobile.value)
    ) {
        swal.fire({
          icon: 'warning',
          title: t('alert.mandatoryfields'),
          confirmButtonText: t('button.close'),
        });
        return;
      }
      if (!promoProduct.value.price) promoProduct.value.price = 0;
      var apiResponse = await api.SavePromotedProduct(promoProduct.value);
      validated.value = mobileValidated.value = false;
      if (apiResponse.errorMessage || !apiResponse.data) {
        swal.fire({
          icon: 'error',
          text: apiResponse.errorMessage || 'No data',
        });
        return false;
      }

      await swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Promo ' + (promoProduct.value.id ? 'updated' : 'created'),
        showConfirmButton: false,
        timer: 1500,
      });
      router.replace({
        name: 'PromoManagement',
        params: { type: PromotedType[promotedType.value ?? PromotedType.Carousel] },
      });
      return true;
    }

    async function onCancel() {
      router.replace({
        name: 'PromoManagement',
        params: { type: PromotedType[promotedType.value ?? PromotedType.Carousel] },
      });
      return;
    }

    const currencies = computed<Currency[]>(() => generalStore.getters.currencies);

    const currenciesSelect = {
      valueProp: 'id',
      trackBy: 'name',
      label: 'symbol',
      options: currencies,
      canDeselect: false,
    };

    const providerSelect = {
      value: 0,
      valueProp: 'id',
      trackBy: 'name',
      label: 'name',
      required: true,
      searchable: true,
      options: providers,
      'close-on-select': true,
    };

    const productSelect = {
      value: 0,
      valueProp: 'id',
      trackBy: 'name',
      label: 'name',
      searchable: true,
      'close-on-select': true,
    };

    function clearImage(isMobile: boolean) {
      switch(isMobile) {
        case true:
          promoImageMobile.value = null;
          promoProduct.value.mobileImagePath = '';
          promoProduct.value.mobileImage = '';
          promoProduct.value.mobileImageToShow = '';
          break;

        case false:
          promoImage.value = null;
          promoProduct.value.imagePath = '';
          promoProduct.value.image = '';
          promoProduct.value.imageToShow = '';
          break;
      }
    }

    const promoImage = ref();
    const promoImageMobile = ref();
    const uploadingImageValidation = async (e: any, isMobile: boolean) => {
      swal.showLoading();
      let file = e.target.files[0];
      const fileTypes: string[] = ['image/jpeg', 'image/png'];
      if (file) {
        if (file.size > 5242880) {
          swal.fire({
            icon: 'error',
            text: 'File size should be less or equal to 5 MB',
          });
          file = null;
          swal.close();
          return;
        }
        if (!fileTypes.find((t) => t === file.type)) {
          swal.fire({
            icon: 'error',
            text: 'Allowing file types: png, jpeg',
          });
          file = null;
          return;
        }
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            switch(isMobile) {
              case true:
                promoImageMobile.value = event.target.result;
                promoProduct.value.mobileImage = event.target.result
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '');
                break;
              case false:
                promoImage.value = event.target.result;
                promoProduct.value.image = event.target.result
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/png;base64,', '');
                break;
            }
          };
          reader.readAsDataURL(file);
        }
      }
      swal.close();
      validated.value = mobileValidated.value = false;
    };

    function routeToEdit() {
      if (promoProduct.value.id)
        router.push({ name: 'editPromoProductLang', params: { promoProductId: promoProduct.value.id } });
    }

    const substringLength = (model: string, maxlength: number) => {
      model = model.substring(0, maxlength);
    };

    const dateChanged = () => {
      if (!dateRange.fromDate || !dateRange.toDate) {
        return;
      }
      const isNotValidDates = moment(dateRange.fromDate) > moment(dateRange.toDate);
      if (isNotValidDates) {
        dateRange.toDate = dateRange.fromDate;
      }
    };

    const providerChanged = async () => {
      promoProduct.value.productId = 0;
    };
    const checkPriceLength = () => {
      if (promoProduct.value.price.toString().length > 10) {
        promoProduct.value.price = parseInt(promoProduct.value.price.toString().substring(0, 10));
      }
    }
    return {
      submitForm,
      promoProduct,
      onSave,
      onCancel,
      currenciesSelect,
      providerSelect,
      productSelect,
      uploadingImageValidation,
      clearImage,
      promoImage,
      promoImageMobile,
      promotedType,
      PromotedType,
      substringLength,
      dateChanged,
      dateRange,
      filteredProducts,
      providerChanged,
      promoCounter,
      checkPriceLength,
      getProductImg,
      validated,
      mobileValidated,
      validDate,
      routeToEdit
    };
  },
});
