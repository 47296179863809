import { isNumber } from 'lodash';
import { useI18n } from 'vue-i18n';

export enum StepNumber {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}
export enum WeekNames {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}
export const DayOfWeekKendo = {
  Sunday: 'SU',
  Monday: 'MO',
  Tuesday: 'TU',
  Wednesday: 'WE',
  Thursday: 'TH',
  Friday: 'FR',
  Saturday: 'SA',
};
export enum OrderStatus {
  OK = 1,
  RQ = 2,
  CN = 3,
  ERR = 4,
}
export enum OrderStatusColors {
  '#79D217' = 1,
  '#FD695A' = 2,
  '#625C82' = 3,
  '' = 4,
}

export enum AccountingDocumentStatus {
  None = 0,
  Approved = 1,
  Canceled = 2,
  Request = 3,
  Paid = 4,
}

export enum PaymentType {
  Cash = 1,
  Check = 2,
  CreditCard = 3,
  BankTransfer = 4,
}

export enum PaymentStatus {
  None = 0,
  Ok = 1,
  Error = 2,
  Cancel = 3,
}

export enum CreditCardPaymentType {
  Regular = 1,
  Credit = 2,
  Payments = 3,
}

export enum DocumentType {
  Invoice = 1,
  Receipt = 2,
  OrderSummary = 3,
  Voucher = 4,
  InvoiceReceipt = 5,
}

export enum NotificationType {
  NewOrder = 1,
  NewChatMsg = 2,
}

export enum InvoiceType {
  Debit = 1,
  Credit = 2,
  Perfoma = 3,
}

export enum VatType {
  IncVat = 0,
  NoVat = 1,
  VatZero = 2,
}

export enum Permissions {
  ViewOrders = 1,
  EditOrders = 2,
  ChangeOrderStatus = 3,
  CreateReceipts = 4,
  CreateInvoices = 5,
  CreateCreditInvoices = 6,
  GenerateLinkOfPersonalAreaToOrder = 7,
  DocumentViewSearch = 8,
  DocumentShare = 9,
  ReportsView = 10,
  ProductsViewSearch = 11,
  CalendarViewFilter = 12,
  CalendarStopSale = 13,
  EditProducts = 14,
  CreateProducts = 15,
  ViewProviderSettings = 16,
  EditProviderSettings = 17,
  BoViewProviders = 18,
  BoEditProviders = 19,
  BoCreateProviders = 20,
  BoSettingsView = 21,
  BoTagsEditCreateDelete = 22,
  BoCitiesEditCreateDelete = 23,
  BoCurrencyEditCreateDelete = 24,
  BoUsersEditCreateDelete = 25,
  BoProfilesEditCreateDelete = 26,
  CreateOrders = 27,
  Cashier = 28,
  CashierReservedOrders = 29,
  CashierSellToPrivate = 30,
  CashierSellToBusiness = 31,
  CashierClubsVouchers = 32,
  CashierChangeRate = 33,
  ChangeUnitPrice = 34,
  CashierChangeDiscount = 35,
  CashierEditSlot = 36,
  CreatePerfomaInvoices = 37,
  CreateBulkInvoices = 39,
  CreateChangeOrderInPast = 40,
  CreateChangeSlotInPast = 41
}

export enum ProductInfoType {
  General = 1,
  Covid19 = 2,
  Custom = 3,
}

export enum PromotedType {
  Carousel = 1,
  MiddleBanner = 2,
  BottomBanner = 3,
  Category = 4,
  UpperBanner = 5,
}

export enum PaymentSystem {
  Pelecard = 1,
  Credit2000 = 2,
  CreditGuard = 3,
}

export enum ProductFilterSlot {
  All = 0,
  NoSlotsFuture = 1,
  LeastOneSlotFuture = 2,
}

export enum ProductFilterPrice {
  All = 0,
  NoPriceFuture = 1,
  LeastOnePriceFuture = 2,
}
export enum ProductFilterPhoto {
  All = 0,
  NoPhoto = 1,
  LeastOnePhoto = 2,
  NoConfirmedPhoto = 3,
}
export enum ProductRegistrationType {
  RegistrationIsRequired = 1,
  RegistrationIsNotRequired = 2,
  NotForSale = 3,
}
export enum OrderBlockTypes {
  minutes = 0,
  hours = 1,
  days = 2,
}

export enum OrderBlockOption {
  before = 1,
  after = 2,
  allow = 3,
}
export enum PrintingType {
  SingleUnitTicket = 1,
  MultipleUnitTicket = 2,
  OrderSummaryDocument = 3,
}

export enum PriceTypes {
  FitLocal = 1,
  GroupLocal = 2,
}

export function ToOptions(e: any, prefix = '') {
  return Object.entries(e)
    .filter((x) => typeof x[0] === 'string' && isNumber(x[1]))
    .map((x) => {
      return {
        id: x[1],
        name: useI18n().t(prefix + x[0]),
      };
    });
}
