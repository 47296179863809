
import { ProviderRow, ProvidersFilter } from '@/models/Provider';
import api from '@/services/Api';
import store from '@/store';
import swal from 'sweetalert2';
import Q from 'q';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import { Emitter, EventType } from 'mitt';
import useExtensions from '@/useExtensions';

var { formatDate, awaitTask } = useExtensions();

@Options({
  name: 'ProviderTable',
  computed: {
    ...mapState({
      locale: 'lang',
      isMobileView: 'isMobileView',
    }),
  },
  beforeRouteEnter(to, from, next) {
    if (
      (from.name != 'modal-providers-filter' && from.name != 'provider-view' && from.name != 'provider-new') ||
      to.params.newSearch
    ) {
      to.params.newSearch = '1';
    } else {
      to.params.newSearch = '';
    }
    next();
  },
  inject: ['emitter'],
})
export default class Providers extends Vue {
  public emitter!: Emitter<Record<EventType, unknown>>;

  public busy = false;
  public hasNext = false;
  public total = 0;
  public totalFiltered = 0;
  public filter: ProvidersFilter = new ProvidersFilter();
  public rows: ProviderRow[] = [];

  get hasFilter() {
    return (
      this.filter.registrationStartDate ||
      this.filter.registrationEndDate ||
      this.filter.providerIdNoHash ||
      this.filter.email ||
      this.filter.mobile ||
      this.filter.provider ||
      this.filter.providerId ||
      this.filter.providerIdNoHash ||
      this.filter.subscriptionId ||
      this.filter.company ||
      this.filter.companyId ||
      this.filter.username ||
      this.filter.registrationStartDate ||
      this.filter.registrationEndDate ||
      this.filter.active !== null
    );
  }

  mounted() {
    this.$route.params.newSearch = '1';

    this.search();
  }

  activated() {
    this.emitter.on('isBottom', this.ListenerGetNextPage);
    this.filter = JSON.parse(JSON.stringify(store.state.providersFilter));

    const refreshProviderSearch = store.state.refreshProviderSearch;

    if (this.$route.params.newSearch || refreshProviderSearch) {
      if (!this.$route.params.keepFilter) {
        store.commit('saveProvidersFilter', new ProvidersFilter());
        this.filter = new ProvidersFilter();
      }
      this.filter.pageIndex = 0;
      this.rows = [];
      this.search();
    }
  }
  deactivated() {
    this.emitter.off('isBottom', this.ListenerGetNextPage);
  }

  ShowFilter() {
    store.commit('saveProvidersFilter', this.filter);
    this.$router.push({ name: 'modal-providers-filter' });
  }

  goToDetails(providerId: string) {
    this.$router.push({ name: 'provider-view', params: { id: providerId } });
  }

  async ProvidersForToday() {
    const data = new Date(Date.now());
    this.filter.registrationStartDate = formatDate(data, 'api');
    this.filter.registrationEndDate = formatDate(data, 'api');
    store.commit('saveOrdersFilter', this.filter.registrationEndDate);
    await this.search();
  }

  async Export() {
    swal.showLoading();
    if (this.filter && this.filter.active != null) this.filter.active = Boolean(this.filter.active);

    const apiResult = await api.exportProviders(this.filter);
    await Q.delay(400);
    if (apiResult.data?.error || !apiResult.data) {
      swal.fire({
        icon: 'error',
        text: apiResult.data?.error || 'No data',
      });
      return;
    }
    const taskId = apiResult.data.taskId;
    awaitTask(
      () => api.waitExportTask(taskId),
      (url) => {
        swal.fire({
          icon: 'success',
          title: this.$t('alert.exportReady.providers'),
          html: `
              <div class="text-center my-4">
                  <a href="${url}" target="_blank">
                      <button role="button" class="popup-bookit-button my-1 px-4">${this.$t('report.download')}</button>
                  </a>
              </div>`,
          showConfirmButton: false,
          showCloseButton: true,
        });
      },
      this.$t,
    );
  }

  async search(pageIndex?: number) {
    store.commit('setRefreshProviderSearch', false);

    if (this.busy) return;
    if (!pageIndex) pageIndex = 0;

    swal.showLoading();
    this.busy = true;
    this.filter.pageIndex = pageIndex;
    if (this.filter.active != null) this.filter.active = this.filter.active = Boolean(this.filter.active);
    const requestFilter = JSON.parse(JSON.stringify(this.filter));
    if (requestFilter.providerIdNoHash == '') {
        requestFilter.providerIdNoHash = null;
    }
    if (requestFilter.providerId == '') {
        requestFilter.providerId = null
    }
    const apiResult = await api.filterProviders({
      filter: requestFilter,
    });
    await Q.delay(400);
    if (apiResult.data?.error || !apiResult.data) {
      swal.fire({
        icon: 'error',
        text: apiResult.data?.error || 'No data',
      });
      this.busy = false;
      return;
    }

    this.hasNext = apiResult.data.hasNext || false;

    if (this.filter.pageIndex == 0) {
      this.total = apiResult.data.total;
      this.totalFiltered = apiResult.data.totalFiltered;
      this.rows = apiResult.data.rows || [];
    } else {
      this.rows = [...this.rows, ...apiResult.data.rows];
    }
    this.filter.pageIndex = pageIndex;
    this.busy = false;
    swal.close();
  }

  ListenerGetNextPage() {
    if (this.hasNext && !this.busy) {
      this.search(this.filter.pageIndex + 1);
    }
  }

  async showAll() {
    this.filter = new ProvidersFilter();
    this.rows = [];
    await this.search();
  }

  async sortBy(sotrBy: string) {
    if (this.filter.orderBy != sotrBy) {
      this.filter.asc = true;
    } else {
      this.filter.asc = !this.filter.asc;
    }
    this.filter.orderBy = sotrBy;
    await this.search();
  }

  getArrowClass(sort: string) {
    if (sort == this.filter.orderBy) {
      if (this.filter.asc) {
        return 'top-sort-arrow';
      } else {
        return 'bottom-sort-arrow';
      }
    }
    return 'standart-sort-arrow';
  }
}
