
import { defineComponent, computed, ref, onMounted, inject } from 'vue';
import router from '@/router';
import api from '@/services/Api';
import Q from 'q';
import store from '@/store';
import swal from 'sweetalert2';
import { OrdersFilter, MyOrder, RevenueByCurrency, MyOrderCustomer } from '@/models/Order';
import useExtensions from '@/useExtensions';
import { useI18n } from 'vue-i18n';
import { onActivated, onDeactivated } from 'vue';
import { useRoute } from 'vue-router';
import { OrderStatus, OrderStatusColors } from '@/models/Enums';
import OrderQtyReportModal from '@/views/Modals/OrderQtyReportModal.vue';

export default defineComponent({
  components: { OrderQtyReportModal },
  name: 'Orders',
  data() {
    return {
      doSearch: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      (from.name != 'modal-orders-filter' && from.name != 'order' && from.name != 'provider-view') ||
      to.params.newSearch
    ) {
      to.params.newSearch = '1';
    } else {
      to.params.newSearch = '';
    }
    next();
  },
  async setup() {
    const isMobileView = computed(() => store.state.isMobileView);
    const route = useRoute();
    const filter = ref<OrdersFilter>(new OrdersFilter());
    if (store.state.orderFilter) {
      filter.value = store.state.orderFilter;
    }
    /*
    if (filter.value) {
      filter.value.pageIndex = 0;
    }
    */
    const { t } = useI18n();
    const modalQtyReport = ref<InstanceType<typeof OrderQtyReportModal>>();

    const orders = ref<MyOrder[]>([]);
    const busy = ref(false);
    const hasNext = ref(false);
    const total = ref(0);
    const totalFiltered = ref(0);
    const totalTickets = ref(0);
    const revenueByCurrencies = ref<RevenueByCurrency[]>([]);
    const { formatDate, removemillis, awaitTask } = useExtensions();
    const getCurrencySymbol = (id: string) => store.getters.getCurrencySymbol(id);

    const hasFilter = computed<boolean>(() => {
      if (filter.value.providerId) {
        return true;
      }
      if (filter.value.status) {
        return true;
      }
      if (filter.value.productId) {
        return true;
      }
      if (filter.value.orderNumber) {
        return true;
      }
      if (filter.value.startDate) {
        return true;
      }
      if (filter.value.priceTypeId) {
        return true;
      }
      if (filter.value.arrivalStartDate) {
        return true;
      }
      if (filter.value.arrivalEndDate) {
        return true;
      }
      if (filter.value.channelId) {
        return true;
      }
      return false;
    });

    const createNewOrder = () => {
      router.push({ name: 'neworder' });
    };

    const ShowFilter = () => {
      store.commit('saveOrdersFilter', filter.value);
      router.push({ name: 'modal-orders-filter' });
    };

    function goToDetails(orderId: string) {
      router.push({ name: 'order', params: { id: orderId } });
    }

    const getHoursMinutes = (totalMinutes: number): string => {
      const minutes = (totalMinutes % 60).toString();
      const hours = Math.floor(totalMinutes / 60).toString();
      if (hours == '0') {
        return t('orders.duration-minutes', { minutes: minutes });
      }
      if (minutes == '0') {
        return t('orders.duration-hours', { hours: hours });
      }
      return t('orders.duration-hours-minutes', { hours: hours, minutes: minutes });
    };

    async function ValidateFilterData(filterData: OrdersFilter) {
      if (!filterData.providerId) filterData.providerId = 0;
      if (!filterData.orderNumber) filterData.orderNumber = null;
      if (!filterData.status) filterData.status = null;
      if (!filterData.startDate) filterData.startDate = null;
      if (!filterData.endDate) filterData.endDate = null;
      if (!filterData.arrivalStartDate) filterData.arrivalStartDate = null;
      if (!filterData.arrivalEndDate) filterData.arrivalEndDate = null;
    }

    const getMyOrders = async (pageIndex?: number) => {
      swal.showLoading();
      busy.value = true;
      if (!pageIndex) pageIndex = 0;
      const filterData: OrdersFilter = JSON.parse(JSON.stringify(filter.value));
      filterData.pageIndex = pageIndex;
      ValidateFilterData(filterData);
      const apiResult = await api.getOrders(filterData);
      await Q.delay(400);
      if (apiResult.errorMessage || !apiResult.data) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      hasNext.value = apiResult.data.hasNext || false;

      if (filterData.pageIndex == 0) {
        total.value = apiResult.data.total;
        totalFiltered.value = apiResult.data.totalFiltered;
        totalTickets.value = apiResult.data.totalTickets;
        revenueByCurrencies.value = apiResult.data.revenueByCurrencies;
        orders.value = apiResult.data.rows || [];
      } else {
        orders.value = [...orders.value, ...apiResult.data.rows];
      }

      filter.value.pageIndex = pageIndex;
      busy.value = false;
      swal.close();
    };

    async function getOrders() {
      orders.value = [];
      await getMyOrders();
    }

    function ListenerGetMyOrders() {
      if (hasNext.value && !busy.value) {
        getMyOrders(filter.value.pageIndex + 1);
      }
    }

    const onMountedCall = async () => {
      route.params.newSearch = '1';
    };

    async function GetRQOrders() {
      filter.value.status = OrderStatus.RQ;
      store.commit('saveOrdersFilter', filter.value);
      orders.value = [];
      await getMyOrders();
    }

    async function OrdersForToday() {
      const data = new Date(Date.now());
      filter.value.arrivalStartDate = formatDate(data, 'api');
      filter.value.arrivalEndDate = formatDate(data, 'api');
      store.commit('saveOrdersFilter', filter.value);
      orders.value = [];
      await getMyOrders();
    }

    const emitter: any = inject('emitter');
    async function onActivatedAction() {
      emitter.on('isBottom', ListenerGetMyOrders);
      filter.value = store.state.orderFilter;
      if (route.params.newSearch) {
        if (!route.params.keepFilter) {
          store.commit('saveOrdersFilter', new OrdersFilter());
          filter.value = new OrdersFilter();
        }
        orders.value = [];
        await getMyOrders();
      }
    }

    async function onDeactivatedAction() {
      emitter.off('isBottom', ListenerGetMyOrders);
    }

    async function showAll() {
      filter.value = new OrdersFilter();
      orders.value = [];
      await getMyOrders();
    }

    async function sotrBy(sotrBy: string) {
      if (filter.value.orderBy != sotrBy) {
        filter.value.asc = true;
      } else {
        filter.value.asc = !filter.value.asc;
      }
      filter.value.orderBy = sotrBy;
      await getOrders();
    }

    function getArrowClass(sort: string) {
      if (sort == filter.value.orderBy) {
        if (filter.value.asc) {
          return 'top-sort-arrow';
        } else {
          return 'bottom-sort-arrow';
        }
      }
      return 'standart-sort-arrow';
    }

    const exportOrders = async () => {
      swal.showLoading();
      const filterData: OrdersFilter = JSON.parse(JSON.stringify(filter.value));

      const apiResult = await api.exportOrders(filterData);
      await Q.delay(400);
      if (apiResult.data?.error || !apiResult.data) {
        swal.fire({
          icon: 'error',
          text: apiResult.data?.error || 'No data',
        });
        return;
      }
      const taskId = apiResult.data.taskId;
      awaitTask(
        () => api.waitExportTask(taskId),
        (url) => {
          swal.fire({
            icon: 'success',
            title: t('alert.exportReady.orders'),
            html: `
                    <div class="text-center my-4">
                        <a href="${url}" target="_blank">
                            <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                        </a>
                    </div>`,
            showConfirmButton: false,
            showCloseButton: true,
          });
        },
        t,
      );
    };
    function isAnyOrderHasRemarks() {
      return orders.value.some((x) => x.haveRemarks == true);
    }
    function showWarning(index: number) {
      document.getElementById('order-remark-' + index)?.classList.remove('dp-none');
      document.getElementById('order-remark-' + index)?.classList.add('dp-block');
    }
    function hideWarning(index: number) {
      document.getElementById('order-remark-' + index)?.classList.remove('dp-block');
      document.getElementById('order-remark-' + index)?.classList.add('dp-none');
    }
    async function qtyReport() {
      swal.showLoading();
      const filterData: OrdersFilter = JSON.parse(JSON.stringify(filter.value));
      ValidateFilterData(filterData);
      const apiPromise = api.qtyReportGet(filterData);
      await Q.delay(400);
      const apiResult = await apiPromise;
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      if (apiResult.data) {
        modalQtyReport.value?.open(apiResult.data.customers);
      }
      swal.close();
    }
    function displayCustomers(customers: MyOrderCustomer[]) {
      let result = '';
      let index = 0;
      customers.forEach((customer) => {
        if (index != 0) {
          result += ', ';
        }
        result += customer.qty + ' ' + customer.typeName;
        index++;
      });
      return result;
    }
    onMounted(onMountedCall);
    onDeactivated(onDeactivatedAction);
    onActivated(onActivatedAction);

    return {
      createNewOrder,
      ShowFilter,
      hasFilter,
      goToDetails,
      orders,
      total,
      totalFiltered,
      totalTickets,
      getOrders,
      filter,
      GetRQOrders,
      OrdersForToday,
      removemillis,
      OrderStatusColors,
      showAll,
      sotrBy,
      isMobileView,
      getArrowClass,
      revenueByCurrencies,
      getCurrencySymbol,
      exportOrders,
      isAnyOrderHasRemarks,
      showWarning,
      hideWarning,
      getHoursMinutes,
      qtyReport,
      modalQtyReport,
      displayCustomers,
    };
  },
});
