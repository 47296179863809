
import api from '@/services/Api';
import swal from 'sweetalert2';
import { changeLanguage } from '@/services/i18n-utils';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
  computed: {
    ...mapState({
      locale: 'lang',
    }),
  },
})
export default class Login extends Vue {
  public Username = '';
  public Password = '';
  public usernameOrEmail = '';
  public forgotPassword = false;

  async login() {
    swal.showLoading();
    if (!this.Username || !this.Password) {
      swal.fire({
        icon: 'error',
        text: 'Must enter a username and password!',
      });
      return;
    }

    const response = await api.login(this.Username, this.Password);
    if (response.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
      return;
    }
    window.location.assign('');
  }

  async resetPassword() {
    console.log(this.usernameOrEmail);
  }

  async changeLang(lang: string): Promise<void> {
    await changeLanguage(lang);
    this.$i18n.locale = lang;
  }
}
