
import router from '@/router';
import generalStore from '@/store';
import { ref, computed } from 'vue';
import useProduct from '@/components/Product/useProduct';
import { Product } from '@/models/Product';

export default {
    props: {
        productId: {
            type: String,
            required: true
        }
    },
    async setup(props: any) {
        const { getProduct, saveProduct, saveProductRegions } = useProduct();
        if (!generalStore.state.regions.length) await generalStore.dispatch('loadAllRegions');

        const product = computed<Product>(() => generalStore.getters.product);
        if (!product.value || product.value.id != props.productId) await getProduct(props.productId);
        const allRegions = generalStore.state.regions;

        const selectedRegions = ref(product.value.productRegions.map(m => m.regionId));

        const save = async () => {
            if (product.value) {
                product.value.productRegions = [];

                selectedRegions.value?.forEach(id => {
                    product.value.productRegions.push({
                        id: 0,
                        regionId: id,
                        productId: product.value.id
                    });
                });

                await saveProductRegions(product.value.id, selectedRegions.value);
            }
            router.go(-1);
        };

        const close = () => {
            router.go(-1);
        };

        const clearAll = () => {
            selectedRegions.value = selectedRegions.value = [];
        };

        const search = ref('');
        const filteredRegions = computed(() => {
            return allRegions.filter(region => {
                return region.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
            });
        });

        return {
            allRegions,
            selectedRegions,
            save,
            close,
            clearAll,
            search,
            filteredRegions
        };
    }
};
