
import router from '@/router';
import generalStore from '@/store';
import { ref, computed } from 'vue';
import useProduct from '@/components/Product/useProduct';
import { Product } from '@/models/Product';

export default {
    props: {
        tagId: {
            type: String,
            required: true
        },
        productId: {
            type: String,
            required: true
        },
        type: {
            type: String
        }
    },
    async setup(props: any) {
        const { getProduct, saveProduct, saveProductTags } = useProduct();
        if (!generalStore.state.subTags.length) await generalStore.dispatch('loadAllSubTags');
        const allSubTags = generalStore.state.subTags;

        const product = computed<Product>(() => generalStore.getters.product);
        if (!product.value || product.value.id != props.productId) await getProduct(props.productId);
        const currentType = ref(props.type);

        const subTags = allSubTags.filter(t => t.parentTagId == props.tagId);

        if (props.type == "languages") {
            // TODO ROMAN fix it
            const hebrewIndex = subTags.findIndex(x => x.id == 10)
            if (hebrewIndex > -1) {
                const item = subTags[hebrewIndex];
                subTags.splice(hebrewIndex, 1)
                subTags.unshift(item);
            }
        }
        
        const selectedSubTags =
            props.type == 'included'
                ? ref(product.value.productIncludedTags.map(m => m.subTagId))
                : props.type == 'excluded'
                ? ref(product.value.productExcludedTags.map(m => m.subTagId))
                : ref(product.value.productTags.filter(s => s.parentTagId == props.tagId).map(m => m.subTagId));

        const save = async () => {
            if (product.value) {
                let productTags =
                    props.type == 'included'
                        ? product.value.productIncludedTags
                        : props.type == 'excluded'
                        ? product.value.productExcludedTags
                        : product.value.productTags;

                productTags = productTags.filter(
                    p => p.parentTagId != props.tagId || (p.parentTagId == props.tagId && selectedSubTags.value?.includes(p.subTagId))
                );
                selectedSubTags.value?.forEach(id => {
                    if (!productTags.find(t => t.subTagId == id))
                        productTags.push({
                            id: 0,
                            subTagId: id,
                            productId: product.value.id
                        });
                });

                if (props.type == 'included') product.value.productIncludedTags = productTags;
                else if (props.type == 'excluded') product.value.productExcludedTags = productTags;
                else product.value.productTags = productTags;

                const existProductTags = productTags.map(t => t.subTagId);

                await saveProductTags(product.value.id, existProductTags, currentType.value);
            }
            router.go(-1);
        };

        const close = () => {
            router.go(-1);
        };

        const clearAll = () => {
            selectedSubTags.value = selectedSubTags.value = [];
        };

        const search = ref('');
        const filteredTags = computed(() => {
            return subTags.filter(subTag => {
                return subTag.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
            });
        });

        return {
            product,
            subTags,
            selectedSubTags,
            save,
            close,
            clearAll,
            search,
            filteredTags,
            currentType
        };
    }
};
