import moment from 'moment';
import {
  ProductInfoType,
  PromotedType,
  ProductFilterPhoto,
  ProductFilterPrice,
  ProductFilterSlot,
  ProductRegistrationType,
  OrderBlockTypes,
  OrderBlockOption,
  PrintingType,
} from '@/models/Enums';

export class Product {
  id: string;
  providerId: number;
  name: string;
  active: boolean;
  shortDescription: string;
  fullDescription: string;
  fullDescriptionIndex: string;
  arrivalInstructions: string;
  parkingInstructions: string;
  longitude: number;
  latitude: number;
  address: string;
  regionId: string | null;
  recommended: boolean;
  mostPopular: boolean;
  calendarColor: string;
  productRegions: ProductRegion[];
  productWorkingDates: ProductWorkingDate[];
  cityId: string | null;
  productTypeId: string | null;
  productTags: ProductTags[];
  productIncludedTags: ProductTags[];
  productExcludedTags: ProductTags[];
  productPrices: ProductPrice[];
  productImages?: ProductImage[];
  productDocuments?: ProductDocument[];
  productVideos?: ProductVideo[];
  productTranslations: Translation[];
  productSlots: CalendarSlot[];
  providerName: string;
  createdAt?: string;
  deletedAt?: string;
  updatedAt?: string;
  allowMultiplePriceLists: boolean;
  registrationType: ProductRegistrationType;
  averageDurationTime: number | null;
  operationStatus: number;
  receiveNotifications: boolean;
  pricePerPerson: boolean;
  canChangeOrderConfirmation: boolean;
  pmsLink: string;
  blockCreatingNewOrdersBefore: boolean;
  orderBlockMinutes: number | null;
  productFaqs: ProductFAQ[];
  productInfos: ProductMoreInfo[];
  responsiblePersonName: string | null;
  responsiblePersonDescription: string | null;
  responsiblePersonPhotoUrl: string | null;
  extraInfo: string;
  policy: string;
  useCashier: boolean;
  useLocals: boolean;
  useGroups: boolean;
  useB2cChannels: boolean;
  useIndex: boolean;
  printingType: PrintingType | null;
  selectedOrderBlockType: OrderBlockTypes;
  selectedOrderBlockOption: OrderBlockOption;
  ticketImagePath: string | null;
  ticketImagePathSmall: string | null;
  specialId: string | null;
  orderLimitation: number | string | null;
  productsNotificationsRestrictions: UnfinishedNotificationsRestrictions;
  availableProductPriceTypes: string[];
  allowAssignEmployees: boolean;

  constructor() {
    this.id = '';
    this.providerId = 0;
    this.providerName = '';
    this.name = '';
    this.active = true;
    this.shortDescription = '';
    this.fullDescription = '';
    this.fullDescriptionIndex = '';
    this.arrivalInstructions = '';
    this.parkingInstructions = '';
    this.longitude = 0;
    this.latitude = 0;
    this.address = '';
    this.regionId = null;
    this.recommended = false;
    this.mostPopular = false;
    this.calendarColor = '';
    this.productRegions = [];
    this.productWorkingDates = [];
    this.cityId = null;
    this.productTypeId = null;
    this.productTags = [];
    this.productIncludedTags = [];
    this.productExcludedTags = [];
    this.productPrices = [];
    this.productTranslations = [];
    this.productVideos = [];
    this.productSlots = [];
    this.createdAt = undefined;
    this.deletedAt = undefined;
    this.updatedAt = undefined;
    this.allowMultiplePriceLists = false;
    this.registrationType = ProductRegistrationType.RegistrationIsRequired;
    this.averageDurationTime = null;
    this.operationStatus = 0;
    this.receiveNotifications = true;
    this.pricePerPerson = true;
    this.canChangeOrderConfirmation = true;
    this.pmsLink = '';
    this.blockCreatingNewOrdersBefore = false;
    this.orderBlockMinutes = 0;
    this.productFaqs = [];
    this.productInfos = [];
    this.responsiblePersonName = null;
    this.responsiblePersonDescription = null;
    this.responsiblePersonPhotoUrl = null;
    this.extraInfo = '';
    this.policy = '';
    this.useCashier = false;
    this.useLocals = false;
    this.useGroups = false;
    this.useB2cChannels = true;
    this.useIndex = false;
    this.selectedOrderBlockType = OrderBlockTypes.minutes;
    this.selectedOrderBlockOption = OrderBlockOption.allow;
    this.printingType = null;
    this.ticketImagePath = null;
    this.ticketImagePathSmall = null;
    this.specialId = null;
    this.orderLimitation = null;
    this.productsNotificationsRestrictions = new UnfinishedNotificationsRestrictions();
    this.availableProductPriceTypes = [];
    this.allowAssignEmployees = false;
  }
}
export class Language {
  id: number;
  name: string;
  twoLetterIsoLanguageName: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.twoLetterIsoLanguageName = '';
  }
}
export class Translation {
  arrivalInstructions: string;
  parkingInstructions: string;
  fullDescription: string;
  fullDescriptionIndex: string;
  language: Language;
  name: string;
  shortDescription: string;
  responsiblePersonDescription: string;
  policy: string;
  extraInfo: string;

  constructor() {
    this.arrivalInstructions = '';
    this.parkingInstructions = '';
    this.fullDescription = '';
    this.fullDescriptionIndex = '';
    this.language = new Language();
    this.name = '';
    this.shortDescription = '';
    this.responsiblePersonDescription = '';
    this.policy = '';
    this.extraInfo = '';
  }
}
export class ProductRegion {
  id: number;
  productId: string;
  regionId: string;
  regionName?: string;

  constructor() {
    this.id = 0;
    this.productId = '';
    this.regionId = '';
  }
}

export class ProductAllTags {
  productTags: ProductTags[];
  productExcludedTags: ProductTags[];
  productIncludedTags: ProductTags[];
  constructor() {
    this.productTags = [];
    this.productExcludedTags = [];
    this.productIncludedTags = [];
  }
}

export class UnfinishedNotificationsRestrictions {
  productId: number;
  unfinishedProductSlotEmpty: boolean;
  unfinishedProductPriceEmpty: boolean;
  unfinishedProductSlotLess: boolean;
  unfinishedProductPriceLess: boolean;
  unfinishedProductNoPhoto: boolean;

  constructor() {
    this.productId = 0;
    this.unfinishedProductSlotEmpty = false;
    this.unfinishedProductPriceEmpty = false;
    this.unfinishedProductSlotLess = false;
    this.unfinishedProductPriceLess = false;
    this.unfinishedProductNoPhoto = false;
  }
}

export class PriceType {
  id: string;
  text: string;

  constructor() {
    this.id = '';
    this.text = '';
  }
}
export class ProductCustomerType {
  id: 0 | null;
  productId: string;
  name: string;

  constructor() {
    this.id = 0;
    this.productId = '';
    this.name = '';
  }
}
export class ProductPriceLine {
  amount: number | null;
  currencyId: number;
  id: number;
  currencySymbol: string;
  isOfficial: boolean;
  productCustomerType?: ProductCustomerType;
  productCustomerTypeId: string | null;
  productPriceId: number;
  quantity: number | null;
  priority: number | null;

  constructor() {
    this.amount = null;
    this.currencyId = 0;
    this.id = 0;
    this.currencySymbol = '';
    this.isOfficial = false;
    this.productCustomerTypeId = '';
    this.productPriceId = 0;
    this.quantity = null;
    this.priority = null;
  }
}
export class ProductPrice {
  autoConfirm: boolean;
  priceType?: PriceType;
  priceTypeId?: string;
  currencyId: number;
  fromDate: string;
  id: number;
  officialPrice: number;
  productId: string;
  productPriceLines: ProductPriceLine[];
  remarks: string;
  title: string;
  toDate: string;
  deletedAt: string | null;
  channelId: string;

  constructor() {
    this.autoConfirm = false;
    this.currencyId = 0;
    this.fromDate = moment().toDate().toDateString();
    this.toDate = moment(`${moment().year() + 1}-12-31`)
      .toDate()
      .toDateString();
    this.id = 0;
    this.officialPrice = 0;
    this.productId = '';
    this.productPriceLines = [];
    this.remarks = '';
    this.title = '';
    this.deletedAt = null;
    this.channelId = '';
  }
}
export class ProductTags {
  id: number;
  productId: string;
  subTagId: string;
  parentTagId?: string;
  subTagName?: string;

  constructor() {
    this.id = 0;
    this.productId = '';
    this.subTagId = '';
  }
}
export class ProductWorkingDate {
  id = 0;
  productId = '';
  fromDate = moment().toDate().toDateString();
  toDate = moment(`${moment().year() + 1}-12-31`)
    .toDate()
    .toDateString();
  name = '';
  strictHours = false;
  timeSlots: TimeSlot[] = [];
  deletedAt: string | undefined;
  errorCode = 0;
  maxUsedTicketsQuantity = 0;
  showAllTimeSlot = false;
}
export class TimeSlot {
  active: boolean;
  endTime: string;
  dayOfWeek: string;
  duration: string;
  id: number;
  productWorkingDateId: number;
  quantity: number | null;
  startTime: string;
  usedQuantity: number;
  deletedAt?: string | undefined;

  constructor() {
    this.active = true;
    this.endTime = '';
    this.dayOfWeek = '';
    this.duration = '';
    this.id = 0;
    this.productWorkingDateId = 0;
    this.quantity = null;
    this.startTime = '';
    this.usedQuantity = 0;
    this.deletedAt = undefined;
  }
}

export class ProductTranslationItem {
  languageId: number;
  newTranslation: string;
  constructor() {
    this.languageId = 0;
    this.newTranslation = '';
  }
}

export class ProductsFilter {
  name: string | null = null;
  id: string | null = null;
  idNoHash: number | null = null;
  typeId: number | null = null;
  cityId: number | null = null;
  subTypes: number[] = [];
  targetAudiences: number[] = [];
  entrances: number[] = [];
  foodAndDrinks: number[] = [];
  religions: number[] = [];
  languages: number[] = [];
  transportAndParking: number[] = [];
  included: number[] = [];
  excluded: number[] = [];
  regions: number[] = [];
  active: boolean | number | null = 1;
  providerName: string | null = null;
  providerId: string | null = null;
  providerIdNoHash: number | null = null;
  email: string | null = null;
  mobile: string | null = null;
  productCity: number | null = null;
  operationStatus: number | null = null;
  slotFilter = ProductFilterSlot.All;
  priceFilter = ProductFilterPrice.All;
  photoFilter = ProductFilterPhoto.All;

  pageIndex = 0;
  keyword: string | null = null;
  orderBy = 'Id';
  asc = false;
  useExpiredSlots = false;
  filterExpiredSlots: number | null = null;
}

export class ProductRowResult {
  id = '';
  idNoHash = 0;
  providerName = '';
  providerId = '';
  providerIdNoHash = 0;
  name = '';
  active = false;
  productType = '';
  productTypeId = '';
  calendarColor = '';
  hasImages = false;
  hasSlot = false;
  hasPriceList = false;
  onAir = false;
}

export class DateRange {
  fromDate: Date | null = null;
  toDate: Date | null = null;
}

export class CalendarSlot {
  id = '';
  limit: number | null = null;
  startDate: Date | string = new Date();
  duration = '';
  startTime = '';
  endTime = '';
  daysOfWeek: string[] = [];
  repeatMode = 1;
  repeatEndDate: Date | string | null = '';
  repeatCount: number | null = null;
}

export interface ProductMoreInfo {
  id: number;
  title: string;
  description: string;
  type: ProductInfoType;
  translations: TranslationDouble[];
}

export class PromotedInfo {
  promotedProducts: PromotedProduct[] = [];
  promotedCategories: PromotedCategory[] = [];
}

export class PromotedProduct {
  id = 0;
  productId = 0;
  providerId = 0;
  productName = '';
  providerName = '';
  description = '';
  url = '';
  imagePath = '';
  mobileImagePath = '';
  image = '';
  mobileImage = '';
  order = 0;
  price: number | string = '';
  currencyId = 0;
  createDate: string | null = null;
  type: PromotedType | null = null;
  fromDate: string | null = null;
  toDate: string | null = null;
  active = true;
  imageToShow = '';
  mobileImageToShow = '';
}

export class PromotedCategory {
  id = 0;
  name = '';
  imagePath = '';
  image = '';
  filter = '';
  order = 0;
  createDate: string | null = null;
  fromDate: string | null = null;
  toDate: string | null = null;
  productTypeId: number | null = null;
  productTypeName = '';
  promotedCategoryRegions: PromotedCategoryRegion[] = [];
  regionName = '';
  subTagIds: string | null = null;
  subTagNames = '';
  active = true;
  imageToShow = '';
}
export class PromotedCategoryRegion {
  id = '0';
  promotedCategoryId = '0';
  regionId = '';
  regionName = '';
}
export class PromoFilter {
  keyword = '';
  orderBy = 'Order';
  asc = true;

  active: boolean | number | null = null;
  provider = '';
  providerId: string | null = null;
  product = '';
  productId: string | null = null;
  fromDate: string | null = null;
  toDate: string | null = null;
  type: PromotedType | null = null;
}
