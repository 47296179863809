
import store from '@/store';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import swal from 'sweetalert2';
import api from '@/services/Api';
import { ProductsFilter } from '@/models/Product';
import { ProductFilterSlot, ProductFilterPrice, ProductFilterPhoto} from '@/models/Enums'

export default {
  async setup() {
    const { t,locale } = useI18n();

    const greeting = computed(() => {
      const date = new Date();
      const hours = date.getHours();
      const greetingText =
        hours < 12 ? t('home.goodMorning') : hours < 19 ? t('home.goodDay') : t('home.goodEvening');
      return greetingText + ' ' + store.state.user?.fullName;
    });

    const showAlert = await (async () => {
      const resp = await api.getProductsAlert();
      if (resp.error || resp.errorMessage) {
        swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: resp.errorMessage });
        return;
      }
      return resp.data ?? false;
    })();

    function setFilter(){
      const filter = new ProductsFilter();
      filter.slotFilter = ProductFilterSlot.NoSlotsFuture;
      filter.priceFilter = ProductFilterPrice.NoPriceFuture;
      filter.photoFilter = ProductFilterPhoto.NoPhoto;
      store.commit('saveProductsFilter', filter);
    }

    return { greeting, showAlert, setFilter,locale };
  }
};
