
import { defineComponent, ref, reactive } from 'vue';
import Multiselect from '@vueform/multiselect';
import router from '@/router';
import Calendar from 'primevue/calendar';
import useExtensions from '@/useExtensions';
import { DocumentsFilter } from '@/models/Document';
import { DocumentType } from '@/models/Enums';
import generalStore from '@/store';
import { DateRange } from '@/models/Order';
import moment from 'moment';

export default defineComponent({
  components: {
    Multiselect,
    Calendar,
  },
  name: 'OrderFilter',
  data() {
    return {};
  },
  async setup() {
    const calendar = ref();
    const filter = ref<DocumentsFilter>();
    const filterCopy = generalStore.getters.getDocumentsFilter;
    filter.value = JSON.parse(JSON.stringify(filterCopy));

    const documentType = [];
    for (const [propertyKey, propertyValue] of Object.entries(DocumentType)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      documentType.push({ id: propertyValue, name: propertyKey });
    }

    const dateRange = reactive(new DateRange());
    if (filter.value?.createDateStart) {
      dateRange.fromDate = new Date(filter.value?.createDateStart);
    }
    if (filter.value?.createDateEnd) {
      dateRange.toDate = new Date(filter.value?.createDateEnd);
    }

    function ResetFilter() {
      generalStore.commit('saveDocumentsFilter', new DocumentsFilter());
      router.push({ name: 'documents' });
    }

    function Close() {
      router.push({ name: 'documents' });
    }

    function addZero(number: number) {
      const str = number.toString();
      if (str.length == 1) {
        return '0' + str;
      }
      return str;
    }

    function convertDate(data: Date) {
      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      return year + '-' + addZero(month) + '-' + addZero(day);
    }

    function Search() {
      if (filter.value) {
        if (dateRange.fromDate) {
          filter.value.createDateStart = convertDate(dateRange.fromDate);
        }
        if (dateRange.toDate) {
          filter.value.createDateEnd = convertDate(dateRange.toDate);
        }

        filter.value.keyword = '';
      }

      generalStore.commit('saveDocumentsFilter', filter.value);
      router.push({ name: 'documents' });
    }

    function dateRangeValidate(dateRange: DateRange) {
      const isNotValidDates = moment(dateRange.fromDate) > moment(dateRange.toDate);
      if (isNotValidDates) {
        dateRange.toDate = dateRange.fromDate;
      }
    }

    const dateChanged = () => {
      if (!dateRange.fromDate || !dateRange.toDate) {
        return;
      }
      dateRangeValidate(dateRange);
    };

    return {
      filter,
      documentType,
      dateRange,
      ResetFilter,
      Close,
      Search,
      calendar,
      dateChanged,
    };
  },
});
