
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';

export default defineComponent({
    components: {},
    name: 'OrderRemarksEdit',
    data() {
        return {};
    },
    async setup() {
        const route = useRoute();
        const id = route.params.id.toString();
        const remarks = ref<string>('');

        async function getOrderRemarks() {
            swal.showLoading();
            const apiResult = await api.GetOrderRemarks(id);
            await Q.delay(400);
            if (apiResult.data?.error) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error
                });
                swal.close();
                return '';
            }
            swal.close();
            return apiResult.data?.remarks || '';
        }
        remarks.value = await getOrderRemarks();

        async function saveOrderRemarks() {
            swal.showLoading();
            const apiResult = await api.SaveOrderRemarks(id, remarks.value);
            await Q.delay(400);
            if (apiResult.data?.error) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error
                });
                return;
            }
            remarks.value = apiResult.data?.remarks || '';
            router.push({ name: 'order', params: { id: id } });
        }

        function Cancel() {
            router.push({ name: 'order', params: { id: id } });
        }

        function checkDescription() {
            if (remarks.value && remarks.value.length > 1000) {
                remarks.value = remarks.value.substring(0, 1000);
            }
        }
        return { remarks, saveOrderRemarks, Cancel, checkDescription };
    }
});
