
import { defineComponent, watch, ref, PropType } from 'vue';
import { DayOfWeekKendo } from '@/models/Enums';

export default defineComponent({
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    disabledDays: {
      type: Array,
    },
    withCheck: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    function uuidv4() {
      return ('10000000-1000-4000-8000-100000000000').replace(/[018]/g, c =>
        (Number(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number(c) / 4).toString(16),
      );
    }

    const weekDayNames = DayOfWeekKendo;
    const value = ref(props.modelValue);
    const guid = 'day_' + uuidv4() + '_';
    watch(
      () => props.modelValue,
      x => {
        value.value = x;
      },
    );
    return {
      weekDayNames,
      guid,
      value,
    };
  },
});
