
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import * as ApiInterfaces from '@/models/ApiInterfaces';

@Options({
  name: 'CreateNewTag',
  computed: {
    ...mapState(['languages', 'lang']),
  },
})
export default class CreateNewTag extends Vue {
  public tagName = '';
  public mainLang: Language | null = null;
  protected readonly languages!: Language[];
  protected readonly lang!: string;
  async mounted() {
    this.mainLang = this.languages.find((x) => x.name == this.lang) || null;
  }
  async create() {
    if (!this.tagName || this.tagName.length < 2) {
      return;
    }
    swal.showLoading();
    const request: ApiInterfaces.SaveTagRequest = {
      tagId: 0,
      tagName: this.tagName,
      tagType: 'tag',
      translations: [{ id: 0, name: this.tagName, languageId: this.mainLang?.id || 1, entityId: 0 }],
      parentTagId: 0,
    };
    const apiResult = await api.saveTag(request);
    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
      return;
    }
    if (apiResult.data?.subTag?.id) {
      swal.close();
      this.$router.push({ name: 'editTag', params: { tagId: apiResult.data.subTag.id } });
    }
  }

  close() {
    this.$router.push({ name: 'settings' });
  }
}
