
import { defineComponent, PropType, computed, toRef } from 'vue';
import { PromotedProduct, PromoFilter } from '@/models/Product';
import { PromotedType } from '@/models/Enums';
import useExtensions from '@/useExtensions';
import generalStore from '@/store';
import draggable from 'vuedraggable';

export default defineComponent({
  name: 'PromoProducts',
  props: {
    promotedType: {
      type: [] as PropType<PromotedType>,
      required: true,
    },
    filter: {
      type: [] as PropType<PromoFilter>,
      required: true,
    },
    items: {
      type: [] as PropType<PromotedProduct[]>,
      default: () => [],
    },
  },
  components: {
    draggable,
  },
  setup(props, { emit }) {
    const promoted = toRef(props, 'items');
    const filter = toRef(props, 'filter');
    const promotedProducts = computed({
      get() {
        return promoted.value;
      },
      set(newValue) {
        emit('on-order', newValue);
      },
    });

    const { formatLocalDate } = useExtensions();

    const getArrowClass = (sort: string) => {
      if (sort == filter.value.orderBy) {
        if (filter.value.asc) {
          return 'top-sort-arrow';
        } else {
          return 'bottom-sort-arrow';
        }
      }
      return 'standart-sort-arrow';
    };

    const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

    return {
      promotedProducts,
      getCurrencySymbol,
      props,
      getArrowClass,
      formatLocalDate,
      PromotedType
    };
  },
});
