
import { ref, computed, reactive } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import api from '@/services/Api';
import swal from 'sweetalert2';
import { Product, ProductCustomerType, ProductPrice, ProductPriceLine } from '@/models/Product';
import generalStore from '@/store';
import useProduct from '@/components/Product/useProduct';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { DateRange } from '@/models/Order';

export default {
    components: {
        Calendar,
        Multiselect
    },
    async setup() {
        const loaded = ref(false);
        const calendar = ref();
        const showInfo = ref(false);
        const { t } = useI18n();
        const route = useRoute();
        const setb2cPriceStep = ref('one');
        const { getProduct, dateRangeValidate } = useProduct();
        const id = route.params.id.toString();
        const prodPriceId = ref(0);
        const validated = ref(false);
        const channels = await generalStore.dispatch('getChannels');
        if (route.params.priceId && route.params.priceId !== '') prodPriceId.value = Number(route.params.priceId);
        const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
        const currentPricing = computed<ProductPrice>(() => generalStore.getters.currentPricing);
        let productItem: Product = reactive(new Product());
        const priceTypes = computed<PriceType[]>(() => generalStore.getters.priceTypes);
        if (priceTypes.value.length === 0) {
            await generalStore.dispatch('getPriceTypes');
        }
        const apiCustomerTypes = await api.getProductCustomerTypes(id);
        let customerTypes: ProductCustomerType[] = reactive([]);
        if (apiCustomerTypes.data) customerTypes = apiCustomerTypes.data;
        const product = computed<Product>(() => generalStore.getters.product);
        const popoverBool = ref(false);
        if (!product.value || !product.value.id) await getProduct(id);
        if (product.value !== null) {
            productItem = JSON.parse(JSON.stringify(product.value));
        }

        if (prodPriceId.value && currentPricing.value.id === 0) {
            if (route.params.action == 'copy') {
                const found = product.value.productPrices.find(pp => pp.id === prodPriceId.value);
                if (found) {
                    const foundedCopy = JSON.parse(JSON.stringify(found)) as ProductPrice;
                    foundedCopy.id = 0;
                    foundedCopy.fromDate = '';
                    foundedCopy.toDate = '';
                    foundedCopy.productPriceLines.forEach(ppt => {
                        ppt.id = 0;
                        ppt.productCustomerTypeId = null;
                        ppt.productPriceId = 0;
                        if (ppt.productCustomerType) ppt.productCustomerType.id = 0;
                    });
                    generalStore.commit('setCurrentPricing', foundedCopy);
                }
            } else {
                const found = product.value.productPrices.find(pp => pp.id === prodPriceId.value);
                generalStore.commit('setCurrentPricing', found);
            }
        }
        if (currentPricing.value.id == 0) {
          currentPricing.value.channelId = generalStore.getters.getDefaultChannel;
            if (priceTypes.value && priceTypes.value.length > 0) {
                currentPricing.value.priceTypeId = generalStore.getters.getDefaultPriceType;
                const foundSameCurrency = product.value.productPrices.find(pp => pp.priceTypeId === currentPricing.value.priceTypeId)?.currencyId;
                if (foundSameCurrency && product.value.productPrices.length > 0) {
                    currentPricing.value.currencyId = foundSameCurrency;
                } else {
                    const shekelCurr = currencies.value.find(c => c.name === 'NIS')?.id;
                    if (shekelCurr) {
                        currentPricing.value.currencyId = shekelCurr;
                    }
                }
            }
            currentPricing.value.productId = id;
            currentPricing.value.autoConfirm = true;
        } else {
            currentPricing.value.productPriceLines.forEach(p => {
                const find = customerTypes.find(c => c.id == p.productCustomerTypeId);
                if (find) {
                    p.productCustomerType = JSON.parse(JSON.stringify(find));
                }
            });
        }
        if ((currentPricing.value.id !== null || currentPricing.value.id !== '') && product.value.pricePerPerson === false) {
            if (currentPricing.value.productPriceLines.length === 0) {
                const newPriceType = new ProductPriceLine();
                newPriceType.productCustomerTypeId = null;
                newPriceType.isOfficial = true;
                newPriceType.productCustomerType = new ProductCustomerType();
                newPriceType.productCustomerType.productId = id;
                currentPricing.value.productPriceLines.push(newPriceType);
            }
        }
        if (!currentPricing.value.priceTypeId) {
            currentPricing.value.priceTypeId = '';
        }
        loaded.value = true;
        const dateRange = reactive(new DateRange());
        dateRange.fromDate = currentPricing.value.fromDate ? new Date(currentPricing.value.fromDate) : null;
        dateRange.toDate = currentPricing.value.toDate ? new Date(currentPricing.value.toDate) : null;
        const tempValidation = ref(false);
        function clearTempValidation() {
            tempValidation.value = false;
        }
        function onBlurPriceAmount(event: any, item: ProductPriceLine) {
            let itemValue: string = event.target.value;
            const arr = itemValue.split('.');
            if (arr.length > 1) {
                itemValue = arr[0] + '.' + arr[1].slice(0, 2);
            }
            item.amount = Number(itemValue);
        }
        const productSaved = ref(false);
        const priceListDuplicated = computed(() => {
            const doWeHaveDuplicatedPriceList = product.value.productPrices.some(
                    x => x.productId == currentPricing.value.productId                     
                        && x.priceTypeId == currentPricing.value.priceTypeId
                        && x.channelId == currentPricing.value.channelId
                        && x.currencyId == currentPricing.value.currencyId 
                        && moment(x.toDate).format('YYYY-MM-DD') == moment(currentPricing.value.toDate).format('YYYY-MM-DD')
                        && moment(x.fromDate).format('YYYY-MM-DD') == moment(currentPricing.value.fromDate).format('YYYY-MM-DD')
                        && x.id != currentPricing.value.id    
                    );

            return doWeHaveDuplicatedPriceList;
        });
        const priceListDuplicatedPriorities = computed(() => {
            const mappedPriorities = currentPricing.value.productPriceLines
                .filter(x => x.priority)
                .map((item: ProductPriceLine) => item.priority);
            return mappedPriorities.filter((item, index) => mappedPriorities.indexOf(item) != index);
        });
        function addMorePriceLine(event: any) {
            if (currentPricing.value.productPriceLines.length > 0) {
                currentPricing.value.productPriceLines.forEach(priceType => {
                    if (priceType.productCustomerType?.name === '' || priceType.amount === null) {
                        tempValidation.value = true;
                    }
                });
                // event.target.classList.add('was-validated');
            }
            if (priceListDuplicated.value) {
                swal.fire({
                    icon: 'error',
                    text: t('product.priceList.duplicatedPriceList')
                });
                return;
            }
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                if (currentPricing.value.productPriceLines.length === 0) {
                    const newProductPriceLine = new ProductPriceLine();
                    newProductPriceLine.productCustomerType = new ProductCustomerType();
                    newProductPriceLine.productCustomerType.productId = currentPricing.value.productId;
                    newProductPriceLine.priority = null;
                    currentPricing.value.productPriceLines.push(newProductPriceLine);
                    currentPricing.value.productPriceLines[0].isOfficial = currentPricing.value.productPriceLines[0].isOfficial = true;
                    currentPricing.value.productPriceLines[0].currencyId = currentPricing.value.currencyId;
                } else {
                    const newProductPriceLine = new ProductPriceLine();
                    newProductPriceLine.productCustomerType = new ProductCustomerType();
                    newProductPriceLine.productCustomerType.productId = currentPricing.value.productId;
                    newProductPriceLine.currencyId = currentPricing.value.currencyId;
                    newProductPriceLine.productPriceId = currentPricing.value.id;
                    newProductPriceLine.productCustomerTypeId = null;
                    newProductPriceLine.priority = null;
                    currentPricing.value.productPriceLines.push(newProductPriceLine);
                }
            }
        }
        function removeCustomerType(index: number) {
            currentPricing.value.productPriceLines.splice(index, 1);
            clearTempValidation();
        }
        function watchChanges(index: number) {
            currentPricing.value.productPriceLines.forEach(cp => {
                cp.isOfficial = false;
            });
            currentPricing.value.productPriceLines[index].isOfficial = true;
        }

        async function saveProductPricing() {
            currentPricing.value.fromDate = new Date(
                moment(dateRange.fromDate)
                    .utcOffset(0, true)
                    .format()
            ).toUTCString();
            currentPricing.value.toDate = new Date(
                moment(dateRange.toDate)
                    .utcOffset(0, true)
                    .format()
            ).toUTCString();
            const isRequiredFieldsFilled =
                currentPricing.value.title === '' ||
                currentPricing.value.priceTypeId === '' ||
                currentPricing.value.currencyId === 0 ||
                currentPricing.value.productPriceLines.length === 0;
            if (isRequiredFieldsFilled) {
                swal.fire({
                    icon: 'error',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                validated.value = true;
                return;
            }
            
            if (currentPricing.value.title == "" || currentPricing.value.title ==  null) {
                swal.fire({
                    icon: 'error',
                    text: t('product.priceList.nameAlert')
                });
                return;
            }
            if (product.value.productPrices && product.value.productPrices.length > 0) {
                if (currentPricing.value.productPriceLines.some(x => x.productCustomerType?.name === '' || x.amount === null)) {
                    tempValidation.value = true;
                    return;
                }
            }

            currentPricing.value.productPriceLines.forEach(price => {
                if (!price.productCustomerType?.name || price.amount === null) {
                    tempValidation.value = true;
                    return;
                }
                if (price.quantity != null && (price.quantity < 0 || price.quantity.toString() === '')) {
                    price.quantity = null;
                }
            });
            if (currentPricing.value.productPriceLines.some(price => price.amount === null || price.amount < 0 || price.amount.toString() === '')) {
                swal.fire({
                    icon: 'error',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                tempValidation.value = true;
                return;
            }
            if (tempValidation.value) {
                swal.fire({
                    icon: 'error',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                return;
            }
            if (currentPricing.value.productPriceLines.some(price => price.amount == 0)) {
                const swalResult = await swal.fire({
                    title: t('alert.areYouSure'),
                    text: t('productEditPage.pricing.alert.amountsAreZero'),
                    icon: 'question',
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('button.yes'),
                    cancelButtonText: t('button.no')
                });
                if (!swalResult.isConfirmed) {
                    return;
                }
            }
            if (priceListDuplicated.value) {
                swal.fire({
                    icon: 'error',
                    text: t('product.priceList.duplicatedPriceList')
                });
                return;
            }
            if (priceListDuplicatedPriorities.value.length > 0) {
                swal.fire({
                    icon: 'error',
                    text: t('product.priceList.duplicatedPriorityAlert')
                });
                return;
            }
            const existDeletedAtField = currentPricing.value.deletedAt && currentPricing.value.deletedAt !== null;
            if (existDeletedAtField) {
                const newResult = await swal.fire({
                    icon: 'info',
                    title: t('alert.productPrice.beforeSave'),
                    customClass: {
                        confirmButton: 'green-button popup-bookit-button my-1 px-4',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('button.productPrice.restore'),
                    cancelButtonText: t('button.cancel')
                });

                if (!newResult.isConfirmed) {
                    return;
                }

                swal.showLoading();
                const response = await api.removeOrRestoreProductPrice(currentPricing.value.productId,currentPricing.value.id);
                if (response.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                    return;
                }
                currentPricing.value.deletedAt = null;
            }
            if (currentPricing.value.productPriceLines.length > 0) {
                const higherInt = currentPricing.value.productPriceLines.length >= 3 ? 3 : currentPricing.value.productPriceLines.length;
                for (let index = 1; index <= higherInt; index++) {
                    const found = currentPricing.value.productPriceLines.find(x => x.priority == index);
                    if (!found) {
                        swal.fire({
                            icon: 'error',
                            text: t('product.wizzard.noPriority')
                        });
                        if (setb2cPriceStep.value === 'one') {
                            document.getElementById('setb2cPricebtn')?.click();
                            setb2cPriceStep.value = 'two';
                        }
                        return;
                    }
                }
            }
            const savingPricing = JSON.parse(JSON.stringify(currentPricing.value));
            if (savingPricing.productPriceLines.every((x: ProductPriceLine) => x.isOfficial === false)) {
                swal.fire({
                    icon: 'error',
                    text: t('product.wizzard.noOfficialPrice')
                });
                return;
            }
            if (savingPricing.productPriceLines.some((x: ProductPriceLine) => x.amount === null)) {
                tempValidation.value = true;
                swal.fire({
                    icon: 'error',
                    text: t('product.PriceList.NullAmount')
                });
                return;
            }
            savingPricing.productPriceLines.forEach((priceLine: ProductPriceLine) => {
                priceLine.productCustomerTypeId = null;
                priceLine.currencyId = currentPricing.value.currencyId;
            });

            swal.showLoading();
            const response = await api.saveProductPrice(savingPricing);
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            swal.close();
            productSaved.value = true;
            document.getElementById('close-modal')?.click();
            const foundIndexPL = product.value.productPrices.findIndex(pp => pp.id === response.data?.productPrice.id);
            if (foundIndexPL > -1) {
                product.value.productPrices[foundIndexPL] = response.data!.productPrice;
            } else {
                product.value.productPrices.push(response.data!.productPrice);
            }

            const message = savingPricing.id === '' ? t('alert.priceCreated') : t('alert.priceUpdated');
            const swalAlert = await swal.fire({
                icon: 'success',
                text: message,
                showConfirmButton: true
            });
            if (swalAlert.isConfirmed) {
                router.push({ name: 'edit-product', params: { id: id } });
                generalStore.commit('setCurrentPricing', new ProductPrice());
            }
        }
        const priceTypeChanged = (bool: boolean) => {
            currentPricing.value.productPriceLines = [];
            if (bool == false) {
                currentPricing.value.productPriceLines.push(new ProductPriceLine());
                currentPricing.value.productPriceLines[0].isOfficial = true;
                currentPricing.value.productPriceLines[0].productCustomerType = new ProductCustomerType();
                currentPricing.value.productPriceLines[0].productCustomerType.id = null;
                currentPricing.value.productPriceLines[0].productCustomerTypeId = null;
                currentPricing.value.productPriceLines[0].productCustomerType.productId = id;
            }
        };
        const goBack = () => {
            loaded.value = false;
            product.value.productPrices = productItem.productPrices;
            generalStore.commit('setCurrentPricing', new ProductPrice());
            router.go(-1);
        };
        const availableCustomers = (item: ProductCustomerType) => {
            const selectedTypes: string[] = currentPricing.value.productPriceLines
                .map(x => x.productCustomerType?.name || '')
                .filter(name => name && name != item.name);
            return customerTypes.filter(x => !selectedTypes.includes(x.name));
        };
        const customerMultiSelect = {
            value: 0,
            placeholder: t('select.customer.placeholder'),
            trackBy: 'name',
            valueProp: 'name',
            label: 'name',
            required: true,
            searchable: true,
            options: customerTypes,
            closeOnSelect: true,
            canDeselect: false
        };
        async function activateOrDisactivate(evt: Event) {
            evt.preventDefault();
            const isPriceListDeleted = currentPricing.value.deletedAt !== null;
            const deleteAlert = await swal.fire({
                icon: 'info',
                title: isPriceListDeleted ? t('alert.productPrice.beforeRestore') : t('alert.productPrice.beforeDelete'),
                customClass: {
                    confirmButton: isPriceListDeleted
                        ? 'green-button popup-bookit-button my-1 px-4'
                        : 'stop-sale-button popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: isPriceListDeleted ? t('button.productPrice.restore') : t('alert.yesDeactivate'),
                cancelButtonText: t('button.cancel')
            });
            if (deleteAlert.isConfirmed) {
                swal.showLoading();
                const response = await api.removeOrRestoreProductPrice(currentPricing.value.productId, currentPricing.value.id);
                if (response.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                    return;
                }
                if (!isPriceListDeleted) {
                    currentPricing.value.deletedAt = Date.now().toString();
                    const isPriceListDisactivated = await swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: t('alert.priceList.deleted'),
                        showConfirmButton: true,
                        confirmButtonText: t('button.backToProduct')
                    });
                    if (isPriceListDisactivated.isConfirmed) {
                        router.push({ name: 'edit-product', params: { id: id } });
                    }
                } else {
                    currentPricing.value.deletedAt = null;
                }
                const found = product.value.productPrices.find(pp => pp.id === currentPricing.value.id);
                if (found) found.deletedAt = currentPricing.value.deletedAt;
            }
        }

        function dateChanged() {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
            currentPricing.value.fromDate = new Date(
                moment(dateRange.fromDate)
                    .utcOffset(0, true)
                    .format()
            ).toUTCString();
            currentPricing.value.toDate = new Date(
                moment(dateRange.toDate)
                    .utcOffset(0, true)
                    .format()
            ).toUTCString();
        }

        function filterKey(event: any) {
            if (event.key.trim() === '' || isNaN(event.key)) {
                event.preventDefault();
            }
        }

        function changePricingQuantity(pricing: ProductPriceLine) {
            const index = currentPricing.value.productPriceLines.findIndex(x => x.id == pricing.id);
            if (index >= 0) {
                currentPricing.value.productPriceLines[index].quantity = (Number)(currentPricing.value.productPriceLines[index].quantity);
            }
        }
        async function submitUpdates() {
            await saveProductPricing();
        }
        function getOfficialPrice() {
            let result = '';
            if (currentPricing.value.productPriceLines.length > 0) {
                const found = currentPricing.value.productPriceLines.find(x => x.isOfficial)?.amount;
                result = currentPricing.value.productPriceLines[0].currencySymbol + (found ? found : 0);
            }
            return result;
        }
        function prioritizedLines() {
            const filteredPriceLines = currentPricing.value.productPriceLines.filter(x => x.priority != null);
            return filteredPriceLines.filter(x => x.productCustomerType?.name).sort((a,b) => (a.priority! > b.priority!) ? 1 : -1);
        }
        function watchSelection(selectedOpt: number, index: number) {
            const found = currentPricing.value.productPriceLines.find(x => x.priority == selectedOpt);
            if (found) {
                found.priority = null;
            }
            currentPricing.value.productPriceLines[index].priority = selectedOpt;
            prioritizedLines();
        }
        async function mainSaveProductPrice() {
            setb2cPriceStep.value = 'one';
            await saveProductPricing();
        }
        function openModal() {
            currentPricing.value.productPriceLines = currentPricing.value.productPriceLines.filter(x => x.amount || (x.productCustomerType && x.productCustomerType.name));
            setb2cPriceStep.value = 'one';
        }
        function openModalAvailable() {
            if (currentPricing.value.productPriceLines.some(x => x.productCustomerType?.name === '' || x.amount === null)) {
                tempValidation.value = true;
                return;
            } else if (priceListDuplicated.value) {
                swal.fire({
                    icon: 'error',
                    text: t('product.priceList.duplicatedPriceList')
                });
                return;
            } else {
                document.getElementById('setb2cPricebtn')?.click();
            }
        }
        function checkTitleLength() {
            if (currentPricing.value.title && currentPricing.value.title.length > 30) {
                currentPricing.value.title = currentPricing.value.title.substring(0, 30);
            }
        }
        return {
            tempValidation,
            clearTempValidation,
            validated,
            popoverBool,
            availableCustomers,
            customerMultiSelect,
            activateOrDisactivate,
            priceTypeChanged,
            removeCustomerType,
            dateRange,
            saveProductPricing,
            priceListDuplicated,
            watchChanges,
            addMorePriceLine,
            prodPriceId,
            customerTypes,
            priceTypes,
            currencies,
            currentPricing,
            id,
            product,
            goBack,
            showInfo,
            dateChanged,
            calendar,
            filterKey,
            changePricingQuantity,
            channels,
            loaded,
            setb2cPriceStep,
            submitUpdates,
            getOfficialPrice,
            watchSelection,
            mainSaveProductPrice,
            prioritizedLines,
            openModal,
            priceListDuplicatedPriorities,
            checkTitleLength,
            openModalAvailable,
            onBlurPriceAmount
        };
    }
};
