
import { defineComponent, ref, reactive } from 'vue';
import swal from 'sweetalert2';
import Q from 'q';
import Multiselect from '@vueform/multiselect';
import { DateRange, OrdersFilter } from '@/models/Order';
import store from '@/store';
import router from '@/router';
import { OrderStatus } from '@/models/Enums';
import Calendar from 'primevue/calendar';
import useExtensions from '@/useExtensions';

export default defineComponent({
  components: { Multiselect, Calendar },
  name: 'OrdersFilterModal',
  data() {
    return {};
  },
  async setup() {
    const calendar = ref();
    const { dateRangeValidate, formatDate } = useExtensions();
    const filter = ref<OrdersFilter>();
    const sloterFilter = store.state.orderFilter;
    filter.value = JSON.parse(JSON.stringify(sloterFilter));
    const priceTypes = await store.dispatch('getPriceTypes');
    const channels = await store.dispatch('getChannels');
    const products = await store.dispatch('getProducts');
    const providers = await store.dispatch('getProviders');
    
    const providersOption = {
        value: 0,
        valueProp: 'id',
        trackBy: 'name',
        label: 'name',
        required: true,
        searchable: true,
        options: providers,
        'close-on-select': true
    };
    const productsOption = {
        value: 0,
        valueProp: 'id',
        trackBy: 'name',
        label: 'name',
        required: true,
        searchable: true,
        options: products,
        'close-on-select': true
    };
    const reservationDateRange = reactive(new DateRange());
    const arrivalDateRange = reactive(new DateRange());

    if (filter.value?.startDate) {
      reservationDateRange.fromDate = new Date(filter.value?.startDate);
    }
    if (filter.value?.endDate) {
      reservationDateRange.toDate = new Date(filter.value?.endDate);
    }
    if (filter.value?.arrivalStartDate) {
      arrivalDateRange.fromDate = new Date(filter.value?.arrivalStartDate);
    }
    if (filter.value?.arrivalEndDate) {
      arrivalDateRange.toDate = new Date(filter.value?.arrivalEndDate);
    }

    const orderStatus = [];
    for (const [propertyKey, propertyValue] of Object.entries(OrderStatus)) {
      if (!Number.isNaN(Number(propertyKey))) continue;
      orderStatus.push({ id: propertyValue, name: propertyKey });
    }

    await Q.delay(400);
    swal.close();

    //functions
    function ResetFilter() {
      router.push({ name: 'orders', params: { newSearch: '1' } });
    }

    function Close() {
      router.push({ name: 'orders' });
    }

    function Search() {
      if (filter.value) {
        if (reservationDateRange.fromDate) {
          filter.value.startDate = formatDate(reservationDateRange.fromDate, 'api');
        }
        if (reservationDateRange.toDate) {
          filter.value.endDate = formatDate(reservationDateRange.toDate, 'api');
        }
        if (arrivalDateRange.fromDate) {
          filter.value.arrivalStartDate = formatDate(arrivalDateRange.fromDate, 'api');
        }
        if (arrivalDateRange.toDate) {
          filter.value.arrivalEndDate = formatDate(arrivalDateRange.toDate, 'api');
        }
        filter.value.pageIndex = 0;
      }

      store.commit('saveOrdersFilter', filter.value);
      router.push({ name: 'orders', params: { newSearch: '1', keepFilter: '1' } });
    }

    const dateChanged = (dateRange: DateRange) => {
      if (!dateRange.fromDate || !dateRange.toDate) return;
      dateRangeValidate(dateRange);
    };

    return {
      filter,
      ResetFilter,
      Close,
      Search,
      providers,
      priceTypes,
      products,
      orderStatus,
      reservationDateRange,
      calendar,
      dateChanged,
      arrivalDateRange,
      channels,
      providersOption,
      productsOption
    };
  },
});
