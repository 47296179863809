
import { defineComponent, ref, onMounted } from 'vue';
import { ChartData } from 'chart.js';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import _ from 'lodash';
import moment from 'moment';

export interface ChartDataType {
    value: number;
    title: string;
    tooltip: string;
}

export default defineComponent({
    components: {},
    name: 'TimelineBlock',
    data() {
        return {};
    },
    props: {
        id: [Number, String],
        name: String,
        zoomed: String,
        labelsY: Function,
        data: Object,
        showLegend: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: 'DD/MM'
        },
        tooltipFormat: {
            type: String,
            default: 'DD/MM/YYYY'
        }
    },
    watch: {
        data: function() {
            this.drawChart();
        }
    },
    async setup(props) {
        const el = ref<HTMLElement | null>(null);

        let instanseChart: Chart | null = null;
        function formatNumbers(label: string | number) {
            const val = Number(label);
            if (val < 1000) return label;
            if (val < 1000000) return val / 1000 + 'k';
            else return val / 1000 + 'm';
        }

        function drawChart() {
            const ctx = (el.value as any).getContext('2d');

            const data = _.cloneDeep(props.data) as ChartData<'line'>;

            data.datasets.forEach(x => {
                if (!x.backgroundColor) return;
                x.borderColor = x.backgroundColor;
                x.backgroundColor += '20';
                x.borderWidth = 2;
            });
            if (instanseChart) {
                instanseChart.data = data;
                instanseChart.update();
                return;
            }
            instanseChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: {
                    maintainAspectRatio: false,
                    spanGaps: false,
                    elements: {
                        line: {
                            tension: 0.4
                        },
                        point: {
                            radius: 0.1
                        }
                    },
                    plugins: {
                        legend: {
                            display: props.showLegend,
                            labels: {
                                boxWidth: 23,
                                boxHeight: 7,
                                usePointStyle: false,
                                font: {
                                    size: 16,
                                },
                                color: '#7F8FA4',
                                padding: 40,
                            },
                        },
                        filler: {
                            propagate: false
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                title: tooltipItems => {
                                    return moment(tooltipItems[0].label).format(props.tooltipFormat);
                                },
                                label: options => {
                                    const raw = options.raw as { [key: string]: string | number };
                                    return (raw.l || Number(raw.y).toLocaleString('he')) + '';
                                }
                            }
                        }
                    },
                    scales: {
                        xAxes: {
                            type: 'time',
                            grid: {
                                drawOnChartArea: false,
                                color: '#ccd3da33',
                                drawBorder: false
                            },
                            time: {
                                displayFormats: {
                                    millisecond: props.format,
                                    second: props.format,
                                    minute: props.format,
                                    hour: props.format,
                                    day: props.format,
                                    week: props.format,
                                    month: props.format,
                                    quarter: props.format,
                                    year: props.format
                                }
                            },
                            ticks: {
                                autoSkip: true,
                                maxRotation: 180
                            }
                        },
                        yAxes: {
                            display: true,
                            grid: {
                                color: '#ccd3da33',
                                drawBorder: false
                            },
                            suggestedMin: 0,
                            beginAtZero: true,
                            ticks: {
                                callback: function(label, index, labels) {
                                    const val = formatNumbers(label);
                                    if (!props.labelsY) return val;
                                    return props.labelsY(val);
                                }
                            }
                        }
                    }
                }
            });
        }

        onMounted(async () => {
            drawChart();
        });
        return { el, drawChart };
    }
});
