
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { Product, TimeSlot } from '@/models/Product';
import generalStore from '@/store';
import useProduct from '@/components/Product/useProduct';
import moment from 'moment';
import InputMask from 'primevue/inputmask';
import api from '@/services/Api';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

export default {
    props: {
        productId: {
            type: String,
            required: true
        }
    },
    components: {
        InputMask
    },
    async setup(props: any) {
        const { t } = useI18n();
        const showHelp = ref(true);
        const route = useRoute();

        const { getProduct } = useProduct();

        const product = computed<Product>(() => generalStore.getters.product);
        if (!product.value || product.value.id != props.productId) await getProduct(props.productId);

        const timeSlot = ref(new TimeSlot());

        const close = () => {
            generalStore.commit('saveSelectedWeekDays', route.params.selectedWeekDays);
            router.go(-1);
        };

        if (!route.params.selectedWeekDays || !route.params.workingDateId) {
            router.go(-1);
        }

        let currentStartTime = '';
        if (route.params.timeSlotObject) {
            timeSlot.value = JSON.parse(route.params.timeSlotObject.toString());
            currentStartTime = timeSlot.value.startTime;
        }

        const workingDateId = parseInt(route.params.workingDateId.toString());
        const isActiveDay = (weekname: string) => {
            let isActive = true;
            if (product.value.productWorkingDates.find(wd => wd.id == workingDateId)!.timeSlots.some(t => t.dayOfWeek == weekname)) {
                isActive = product.value.productWorkingDates
                    .find(wd => wd.id == workingDateId)!
                    .timeSlots.some(t => t.dayOfWeek == weekname && t.active);
            }
            return isActive;
        };
        const submitForm = async (event: any) => {
            const duration = moment.duration(timeSlot.value.startTime).add(timeSlot.value.duration);
            timeSlot.value.endTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
            let newSlots = [];

            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                (route.params.selectedWeekDays as string[]).forEach(day => {
                    if (!timeSlot.value.id) {
                        const newTimeSlot = new TimeSlot();
                        newTimeSlot.active = isActiveDay(day);
                        newTimeSlot.duration = timeSlot.value.duration;
                        newTimeSlot.dayOfWeek = day;
                        newTimeSlot.startTime = moment(timeSlot.value.startTime, 'HH:mm').format('HH:mm:ss');
                        newTimeSlot.endTime = moment(timeSlot.value.endTime, 'HH:mm').format('HH:mm:ss');
                        newTimeSlot.quantity = timeSlot.value.quantity;

                        product.value.productWorkingDates.find(wd => wd.id == workingDateId)?.timeSlots.push(newTimeSlot);
                        newSlots.push(newTimeSlot);
                    } else {
                        const currentTimeSlot = product.value.productWorkingDates
                            .find(wd => wd.id == workingDateId)
                            ?.timeSlots.find(ts => ts.startTime == currentStartTime && ts.dayOfWeek == day);
                        if (currentTimeSlot) {
                            currentTimeSlot.startTime = moment(timeSlot.value.startTime, 'HH:mm').format('HH:mm:ss');
                            currentTimeSlot.endTime = moment(timeSlot.value.endTime, 'HH:mm').format('HH:mm:ss');
                            currentTimeSlot.duration = timeSlot.value.duration;
                            currentTimeSlot.quantity = timeSlot.value.quantity;

                            const workingDate = product.value.productWorkingDates.find(wd => wd.id == workingDateId);
                            if(workingDate && workingDate.timeSlots) {
                            const currentTimeSlotIndex = workingDate.timeSlots.findIndex(ts => ts.startTime == currentStartTime && ts.dayOfWeek == day)!;
                                if (~currentTimeSlotIndex) {
                                    product.value.productWorkingDates
                                        .find(wd => wd.id == workingDateId)
                                        ?.timeSlots.splice(currentTimeSlotIndex, 1, currentTimeSlot);
                                }
                            }

                            newSlots.push(currentTimeSlot);
                        }
                    }
                });
                const fWorkDay = product.value.productWorkingDates.find(w => w.id == workingDateId);
                if (fWorkDay) {
                    const response = await api.saveProductWorkingDate(fWorkDay, false);
                    if (response.data?.error) {
                        swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.data?.error });
                        fWorkDay.timeSlots = fWorkDay?.timeSlots.filter(t => t.id);
                        newSlots = [];
                    } else if (response.data?.errorCode == 1003) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            text: 'Please take in account that there are already sold tickets for this slot.'
                        });
                    } else if (response.data?.errorCode == 1002) {
                        const title = `In some dates, the qty of already sold tickets which relevant to this slot start time and dates is ${response.data?.maxUsedTicketsQuantity} and it is more than the new slot qty you just set (${timeSlot.value.quantity}). Do you want to increase the new slot qty?`;
                        const oneValue = response.data?.maxUsedTicketsQuantity;
                        const twoValue = response.data?.maxUsedTicketsQuantity - (timeSlot.value.quantity || 0);
                        const inputOptions = {
                            1: `Add another ${oneValue}. The availability will remain ${timeSlot.value.quantity} after increasing.`,
                            2: `Add only ${twoValue}, so there will be no available tickets for the new slot on those dates`
                        };
                        const { value: formValues } = await swal.fire({
                            title: title,
                            customClass: {
                                title: 'creating-slot-pop-up-title',
                                input: 'creating-slot-pop-up-input-label'
                            },
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: `Increase`,
                            denyButtonText: `Don't increase`,
                            focusConfirm: false,
                            input: 'radio',
                            inputOptions: inputOptions,
                            inputValidator: value => {
                                return new Promise(resolve => {
                                    if (!value) {
                                        resolve('You need to choose something!');
                                    } else {
                                        resolve('');
                                    }
                                });
                            }
                        });
                        if (formValues) {
                            let newQuantity = 0;
                            if (formValues == 1) {
                                newQuantity = Number(timeSlot.value.quantity) + oneValue;
                            } else {
                                newQuantity = Number(timeSlot.value.quantity) + twoValue;
                            }
                            product.value.productWorkingDates.find(
                                wd => wd.id == workingDateId
                            )!.timeSlots = product.value.productWorkingDates
                                .find(wd => wd.id == workingDateId)!
                                .timeSlots.filter(t => t.id);
                            timeSlot.value.quantity = newQuantity;
                            submitForm(event);
                        }
                    } else {
                        const indexWD = product.value.productWorkingDates.findIndex(w => w.id == fWorkDay.id);
                        if (indexWD > -1) {
                            product.value.productWorkingDates[indexWD] = response.data!.productWorkingDate;
                            generalStore.commit('setProduct', product.value);
                        }
                        router.go(-1);
                    }
                }
            }
        };
        const validateDate = (timeType: string) => {
            const splitter = ':';
            let timeArray = [];

            if (timeType === 'start') {
                timeArray = timeSlot.value.startTime.split(splitter);
                const parsedHours = parseInt(timeArray[0]);
                const parsedMins = parseInt(timeArray[1]);

                if (parsedHours > 23) {
                    if (parsedMins >= 0) {
                        timeSlot.value.startTime = '23' + ':' + parsedMins.toString();
                        return;
                    }
                    timeSlot.value.startTime = '';
                    return;
                }
                if (parsedMins > 59) timeSlot.value.startTime = parsedHours.toString() + ':' + '59';
                return;
            }

            if (timeType === 'duration') {
                timeArray = timeSlot.value.duration.split(splitter);
                const parsedHours = parseInt(timeArray[0]);
                const parsedMins = parseInt(timeArray[1]);

                if (parsedHours > 23) {
                    if (parsedMins >= 0) {
                        timeSlot.value.duration = '23' + ':' + parsedMins.toString();
                        return;
                    }
                    timeSlot.value.duration = '';
                    return;
                }
                if (parsedMins > 59) timeSlot.value.duration = parsedHours.toString() + ':' + '59';
            }
        };
        return {
            validateDate,
            submitForm,
            close,
            timeSlot,
            showHelp
        };
    },
    methods: {}
};
