
import { Options, Vue } from 'vue-class-component';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { mapState, Store, mapGetters } from 'vuex';
import EditLangItem from '@/components/EditLangItem.vue';
import { Emitter, EventType } from 'mitt';
import * as ApiInterfaces from '@/models/ApiInterfaces';

@Options({
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentLang: 'getCurrentLangId',
      enLang: 'getEnLangId',
    }),
  },
  components: {
    EditLangItem,
  },
  name: 'Locations',
  inject: ['emitter'],
})
export default class Locations extends Vue {
  public pageIndex = 0;
  public filter = '';
  public loaded = false;
  public hasNext = false;
  public busy = false;
  public items: ItemWithTranslation[] = [];
  public itemToEdit: ItemWithTranslation | null = null;
  public emitter!: Emitter<Record<EventType, unknown>>;
  public debounce = this.createDebounce();
  protected readonly type!: string;
  protected readonly currentLang!: number;
  protected readonly enLang!: number;

  SearchLocations() {
    this.pageIndex = 0;
    this.items = [];
    this.getItemsByPage();
  }

  createDebounce() {
    let timeout: any = null;
    return function (fnc: any, delayMs: any) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fnc();
      }, delayMs || 1200);
    };
  }

  ListenerGetNextPage() {
    if (this.hasNext && !this.busy) {
      this.getItemsByPage();
    }
  }
  beforeUnmount() {
    this.emitter.off('isBottom', this.ListenerGetNextPage);
  }

  async deleteItem(itemId: number) {
    swal.showLoading();
    let locationItemIsUsedResponse;
    if (this.type == 'regions') {
      locationItemIsUsedResponse = await api.regionIsUsed(itemId);
    } else {
      locationItemIsUsedResponse = await api.cityIsUsed(itemId);
    }
    await Q.delay(400);
    if (locationItemIsUsedResponse.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: locationItemIsUsedResponse.errorMessage });
      return;
    }

    if (!locationItemIsUsedResponse.data) {
      swal.close();
      return;
    }

    const itemUsed = locationItemIsUsedResponse.data.used;
    const swalResult = await swal.fire({
      icon: 'info',
      title: this.$t('alert.confirm-remove-location-item', [
        this.type == 'regions' ? this.$t('region.text') : this.$t('city.text'),
      ]),
      text: itemUsed
        ? this.$t('alert.product-type-used')
        : '',
      customClass: {
        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
      },
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: this.$t('alert.yesDeleteIt'),
      cancelButtonText: this.$t('button.cancel'),
    });
    if (!swalResult.isConfirmed) {
      return;
    }
    await Q.delay(200);
    swal.showLoading();
    let deleteSubTagResponse;
    if (this.type == 'regions') {
      deleteSubTagResponse = await api.deleteRegionItem(itemId);
    } else if (this.type == 'cities') {
      deleteSubTagResponse = await api.deleteCityItem(itemId);
    } else {
      return;
    }
    await Q.delay(400);
    if (deleteSubTagResponse.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: deleteSubTagResponse.errorMessage });
      return;
    }

    if (!deleteSubTagResponse.data || !deleteSubTagResponse.data.deleted) {
      return;
    }

    var indexToDelete = this.items.findIndex((x) => x.id == itemId);
    if (indexToDelete >= 0) {
      this.items.splice(indexToDelete, 1);
    }
    swal.close();
  }

  editItem(item: ItemWithTranslation) {
    this.items = this.items.filter((x) => x.id);
    this.itemToEdit = JSON.parse(JSON.stringify(item));
  }

  rejectChanges(itemInxed: number) {
    if (this.itemToEdit && this.itemToEdit.id == 0) {
      this.items.splice(itemInxed, 1);
    }
    this.itemToEdit = null;
  }

  selTranslateFromMain(item: ItemWithTranslation) {
    var lang = item.translations.find((x) => x.languageId == item.mainLang?.languageId);
    if (lang && item.mainLang) {
      lang.name = item.mainLang.name;
    }
  }

  async applyChanges() {
    if (!this.itemToEdit || !this.itemToEdit.mainLang) {
      return;
    }

    var index = this.items.findIndex((x) => x.id == this.itemToEdit?.id);
    if (index < 0) {
      return;
    }

    swal.showLoading();
    let request: ApiInterfaces.SaveItemWithTranslationRequest | null = null;

    if (this.itemToEdit.id) {
      if (this.itemToEdit.translations.length && this.currentLang == this.itemToEdit.mainLang.languageId) {
        this.selTranslateFromMain(this.itemToEdit);
      } else {
        this.itemToEdit.translations.push({
          id: 0,
          entityId: 0,
          languageId: this.currentLang,
          name: this.itemToEdit.mainLang.name || ''
        });
      }

      request = {
        id: this.itemToEdit.id,
        name: this.itemToEdit.name || '',
        translations: this.itemToEdit.translations,
      };
      let apiResponse;
      if (this.type == 'regions') {
        apiResponse = await api.saveRegionTranslation(request);
      } else {
        apiResponse = await api.saveCityTranslation(request);
      }
      if (apiResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: apiResponse.errorMessage });
        return;
      }
      if (apiResponse.data && apiResponse.data.item) {
        var editIndex = this.items.findIndex((x) => x.id == this.itemToEdit?.id);
        if (editIndex >= 0) {
          let subTagToChange: ItemWithTranslation = JSON.parse(JSON.stringify(apiResponse.data.item));
          const mainLang = subTagToChange.translations.find((x) => x.languageId == this.currentLang);
          if (mainLang) {
            subTagToChange.mainLang = mainLang;
          }
          this.items[index] = subTagToChange;
        }
      }
    } else {
      //Add New
      this.selTranslateFromMain(this.itemToEdit);
      request = {
        id: this.itemToEdit.id,
        name: this.itemToEdit.mainLang.name || '',
        translations: this.itemToEdit.translations,
      };
      let apiResponse;
      if (this.type == 'regions') {
        apiResponse = await api.saveRegionTranslation(request);
      } else {
        apiResponse = await api.saveCityTranslation(request);
      }
      if (apiResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: apiResponse.errorMessage });
        return;
      }

      if (apiResponse.data && apiResponse.data.item) {
        this.items = this.items.filter((x) => x.id);
        var itemToPush: ItemWithTranslation = JSON.parse(JSON.stringify(apiResponse.data.item));
        var mainLang = itemToPush.translations.find((x) => x.languageId == this.currentLang);
        itemToPush.mainLang = JSON.parse(JSON.stringify(mainLang));
        this.items.unshift(itemToPush);
      }
    }
    swal.close();
    this.itemToEdit = null;
  }
  addItem() {
    const item: ItemWithTranslation = {
      id: 0,
      name: '',
      mainLang: {
        name: '',
        languageId: this.currentLang,
        id: 0,
        entityId: 0
      },
      translations: [
        {
          name: '',
          languageId: this.currentLang,
          id: 0,
          entityId: 0
        },
      ],
    };
    this.items.unshift(JSON.parse(JSON.stringify(item)));
    this.itemToEdit = item;
  }

  async getItemsByPage(): Promise<boolean> {
    if (this.busy) return false;
    swal.showLoading();
    this.itemToEdit = null;
    this.busy = true;
    let apiResult;
    if (this.type == 'regions') {
      apiResult = await api.getRegions(this.pageIndex, this.filter);
    } else if (this.type == 'cities') {
      apiResult = await api.getCities(this.pageIndex, this.filter);
    } else {
      return false;
    }

    await Q.delay(400);
    if (apiResult.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
      return false;
    }
    if (apiResult.data) {
      this.hasNext = apiResult.data.hasNext;
      var newItems = apiResult.data.items;
      newItems.forEach((element) => {
        if (!element.translations.length) {
          element.mainLang = {
            id: 0,
            name: element.name,
            entityId: 0,
            languageId: this.currentLang
          };
          return true;
        }

        var lang = element.translations.find((x) => x.languageId == this.currentLang);
        if (lang) {
          element.mainLang = lang;
          return true;
        }

        lang = element.translations.find((x) => x.languageId == this.enLang);
        if (lang) {
          element.mainLang = lang;
          return true;
        }

        var anyLang = element.translations[0];
        element.mainLang = anyLang;
        return true;
      });
      if (this.items && this.items.length) {
        this.items = [...this.items, ...newItems];
      } else {
        this.items = newItems;
      }

      this.busy = false;
      this.pageIndex++;
    }
    swal.close();
    return false;
  }

  editLangs(id: number) {
    this.$router.push({ name: 'EditTranslations', params: { id } });
  }

  changeFilterValue() {
    this.pageIndex = 0;
    this.items = [];
    this.getItemsByPage();
  }

  goBack() {
    this.$router.push({ name: 'settings' });
  }

  async mounted() {
    this.emitter.on('isBottom', this.ListenerGetNextPage);
    this.getItemsByPage();

    this.loaded = true;
  }
}
