
import { computed, reactive, ref } from 'vue';
import { Product } from '@/models/Product';
import useProduct from '@/components/Product/useProduct';
import { useRoute } from 'vue-router';
import generalStore from '@/store';
import Multiselect from '@vueform/multiselect';
import api from '@/services/Api';
import router from '@/router';
import swal from 'sweetalert2';

export default {
    props: {
        type: {
            type: String
        }
    },
    components: {
        Multiselect
    },
    async setup() {
        const route = useRoute();
        const { getProduct } = useProduct();

        if (!route.params.fileId) {
            router.push({ name: 'edit-product', params: { id: route.params.id } });
            return;
        }
        const showImgKeywordsInfo = ref(false);
        const id = route.params.id.toString();
        const fileId = route.params.fileId.toString();
        const fileType = route.params.type;

        const selectedOption = ref();
        const imageKeywords = ref();
        const prodDescription = ref();
        let productItem: Product = reactive(new Product());
        const currentLanguage = computed(() => generalStore.getters.currentLanguage);
        const langs = computed<Language[]>(() => generalStore.getters.languages);
        const product = computed<Product>(() => generalStore.getters.product);
        const currentLangId = ref();

        if (langs.value) {
            selectedOption.value = langs.value.find(l => l.name == currentLanguage.value)?.id;
            currentLangId.value = langs.value.find(l => l.name == currentLanguage.value)?.id;
        }
        if (!product.value || !product.value.id) {
            if (route.params.type) {
                await getProduct(id);
            }
        }
        if (product.value !== null) {
            productItem = product.value;
        }

        const currFile: ProductImage | ProductDocument | undefined =
            fileType == 'image'
                ? productItem.productImages?.find(i => i.id == fileId)
                : productItem.productDocuments?.find(i => i.id == fileId);
        const temCurrFile = JSON.stringify(currFile);
        const currentFile: ProductImage | ProductDocument | undefined = JSON.parse(temCurrFile);
        if (currentFile && fileType == 'image') {
            imageKeywords.value = (currentFile as ProductImage).keywords;
        }
        
        // FOLLOWING FUNCTION WATCHING IS EXIST CURRENT LANGUAGE TRANSLATION IN OBJECT. WILL ADD IF DOESN'T EXIST
        function addCurrentDefaultTranslation() {
            if (!currentFile) {
                router.push({ name: 'edit-product', params: { id: route.params.id } });
                return;
            }
            if (currentFile.translations == null) {
                currentFile.translations = [];
            }
            const foundTranslation = currentFile?.translations.find(t => t.languageId == selectedOption.value);
            if (fileType && fileType == 'image') {
                if (!foundTranslation) {
                    currentFile?.translations.push({
                        name: '',
                        description: '',
                        languageId: selectedOption.value,
                        entityId: fileId,
                        id: 0
                    });
                }
                prodDescription.value = currentFile?.translations.find(t => t.languageId == selectedOption.value)?.description;
            } else {
                if (!foundTranslation) {
                    currentFile?.translations.push({
                        name: '',
                        description: '',
                        languageId: selectedOption.value,
                        entityId: fileId,
                        id: 0
                    });
                }
                prodDescription.value = currentFile?.translations.find(t => t.languageId == selectedOption.value)?.name;
            }
        }
        if (productItem) {
            addCurrentDefaultTranslation();
        }
        function goBack() {
            router.push({ name: 'edit-product', params: { id: id } });
        }
        function selectLanguage() {
            if (!currentFile) {
                router.push({ name: 'edit-product', params: { id: route.params.id } });
                return;
            }
            addCurrentDefaultTranslation();
        }
        async function addNewTranslation() {
            swal.showLoading();
            let newLocalizationFile = currentFile?.translations as FileTranslation[];
            if (fileType == 'image') {
                newLocalizationFile = newLocalizationFile.filter(l => l.description !== '');
                const response = await api.saveProductImage(fileId, newLocalizationFile, imageKeywords.value);
                swal.close();
                if (response.data) {
                    const image = productItem.productImages?.find(i => i.id == fileId);
                    if (image) image.translations = response.data;
                }
            } else {
                newLocalizationFile = newLocalizationFile.filter(l => l.name !== '');
                const response = await api.saveProductDocumentTranslations(fileId, newLocalizationFile);
                swal.close();
                if (response.data) {
                    const document = productItem.productDocuments?.find(i => i.id == fileId);
                    if (document) {
                        document.translations = response.data;
                        const currLang = response.data.find(t => t.languageId == currentLangId.value);
                        document.name = currLang?.name as string;
                    }
                }
            }
            router.push({ name: 'edit-product', params: { id: id } });
        }
        function watchLength(data: string) {
            prodDescription.value = data;
        }
        function clearTranslationField() {
            const translation = currentFile?.translations.find(t => t.languageId == selectedOption.value);
            if (translation) {
                if (fileType == 'image') {
                    translation.description = '';
                } else translation.name = '';
            }
            prodDescription.value = '';
        }
        return {
            currentLangId,
            clearTranslationField,
            watchLength,
            currentLanguage,
            fileType,
            fileId,
            currentFile,
            addNewTranslation,
            selectLanguage,
            langs,
            productItem,
            goBack,
            prodDescription,
            selectedOption,
            showImgKeywordsInfo,
            imageKeywords
        };
    }
};
