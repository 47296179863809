
import { computed, defineComponent, PropType, toRef } from 'vue';
import { PaymentSystem, VatType } from '@/models/Enums';
import { useI18n } from 'vue-i18n';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: 'ProviderAccounting',
  components: {
    Multiselect
  },
  props: {
    settings: {
      type: Object as PropType<ProviderSetting[]>,
      required: true,
    },
    commission: {
      type: Number,
      default: 0,
    },
    vatType: {
     type: Number as PropType<VatType>,
     default: 0,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  async setup(props, { emit }) {
    const receiptSequenceNumber = computed<ProviderSetting | undefined>(() =>
      props.settings.find((s: ProviderSetting) => s.key == 'receipt_sequence_start_number'),
    );
    const invoiceSequenceNumber = computed<ProviderSetting | undefined>(() =>
      props.settings.find((s: ProviderSetting) => s.key == 'invoice_sequence_start_number'),
    );
    const creditInvoiceSequenceNumber = computed<ProviderSetting | undefined>(() =>
      props.settings.find((s: ProviderSetting) => s.key == 'credit_invoice_sequence_start_number'),
    );
    const invoiceReceiptSequenceNumber = computed<ProviderSetting | undefined>(() =>
      props.settings.find((s: ProviderSetting) => s.key == 'invoice_receipt_sequence_start_number'),
    );
    const perfomaInvoiceSequenceNumber = computed<ProviderSetting | undefined>(() =>
      props.settings.find((s: ProviderSetting) => s.key == 'perfoma_invoice_sequence_start_number'),
    );

    const { t } = useI18n();
    const paymentSystem = PaymentSystem;
    const providerPaymentSystems: IdName[] = [];
    for (const enumMember in paymentSystem) {
      const parsedValue = parseInt(enumMember, 10);
      if (parsedValue >= 0) {
        providerPaymentSystems.push({ id: parsedValue.toString(), name: paymentSystem[enumMember] });
      }
    }

    const vatTypeList = [
      { value: 0, name: t('provider.vatType.includeVat') },
      {
        value: 1,
        name: t('provider.vatType.noVat'),
        },
    ];

    const commissionPercent = toRef(props, 'commission');
    const providerVatType = toRef(props, 'vatType');
    function filterKey(event: any) {
      if (event.key.trim() === '' || isNaN(event.key)) {
        event.preventDefault();
      }
    }
    async function validateCommission(event: any) {
      const value = Number(event.target.value.replace(/[^0-9.]/g, ''));
      let valueToChage = 0;
      if (value > 100) {
        valueToChage = 100;
      } else if (value < 0) {
        valueToChage = 0;
      } else {
        valueToChage = value;
      }
      emit('update-commission', valueToChage);
      event.target.value = valueToChage;
    }

    async function updateVatType(value: any) {
        emit('update-vat-type', value);
    }
    return {
      receiptSequenceNumber,
      invoiceSequenceNumber,
      creditInvoiceSequenceNumber,
      perfomaInvoiceSequenceNumber,
      invoiceReceiptSequenceNumber,
      commissionPercent,
      providerVatType,
      validateCommission,
      filterKey,
      vatTypeList,
      updateVatType,
    };
  },
});
