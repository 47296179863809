
import { defineComponent, ref, computed } from 'vue';
import { PromotedInfo, PromoFilter, PromotedProduct, PromotedCategory } from '@/models/Product';
import store from '@/store';
import { PromotedType } from '@/models/Enums';
import { useRoute } from 'vue-router';
import router from '@/router';
import swal from 'sweetalert2';
import api from '@/services/Api';
import { useI18n } from 'vue-i18n';
import Q from 'q';
import PromoProducts from '@/components/Index/PromoProducts.vue';
import PromoCategories from '@/components/Index/PromoCategories.vue';

export default defineComponent({
  name: 'Promo',
  components: {
    PromoProducts,
    PromoCategories,
  },
  async setup() {
    const route = useRoute();
    var { t } = useI18n();
    const busy = ref(false);
    const total = ref(0);
    const totalFiltered = ref(0);
    const promo = ref(new PromotedInfo());

    const filter = ref<PromoFilter>(new PromoFilter());
    filter.value = store.getters.getPromoFilter;

    const currentPromotedType = ref(PromotedType[route.params.type as keyof typeof PromotedType]);
    if (currentPromotedType.value == undefined) {
      currentPromotedType.value = PromotedType.UpperBanner;
    }

    const search = async () => {
      if (busy.value) return;

      swal.showLoading();
      busy.value = true;
      if (filter.value.active != null) filter.value.active = filter.value.active = Boolean(filter.value.active);
      filter.value.type = currentPromotedType.value;

      const apiResult = await api.GetPromotedInfo({
        filter: filter.value,
      });
      await Q.delay(400);
      if (apiResult.data?.error || !apiResult.data) {
        swal.fire({
          icon: 'error',
          text: apiResult.data?.error || 'No data',
        });
        busy.value = false;
        return;
      }

      total.value = apiResult.data.total;
      totalFiltered.value = apiResult.data.totalFiltered;
      if (promo.value) {
        promo.value.promotedProducts = apiResult.data.promotedProducts || [];
        promo.value.promotedCategories = apiResult.data.promotedCategories || [];
      }

      busy.value = false;
      swal.close();
    };

    const showAll = async () => {
      filter.value = new PromoFilter();
      if (promo.value) {
        promo.value.promotedCategories = [];
        promo.value.promotedProducts = [];
      }

      await search();
    };

    const sortBy = async (sortBy: string) => {
      if (filter.value.orderBy != sortBy) {
        filter.value.asc = true;
      } else {
        filter.value.asc = !filter.value.asc;
      }
      filter.value.orderBy = sortBy;
      await search();
    };

    const orderPromoProducts = async (promotedProducts: PromotedProduct[]) => {
      if (promotedProducts) {
        promotedProducts.forEach((product, index) => {
          product.order = index + 1;
        });
        promo.value.promotedProducts = promotedProducts;
        const result = await api.OrderPromotedProducts(promo.value.promotedProducts);
        if (!result.data) {
          swal.fire({
            icon: 'error',
            text: 'Error in order process',
          });
          return;
        }
      }
    };

    const orderPromoCategories = async (promotedCategories: PromotedCategory[]) => {
      if (promotedCategories) {
        promotedCategories.forEach((category, index) => {
          category.order = index + 1;
        });
        promo.value.promotedCategories = promotedCategories;
        const result = await api.OrderPromotedCategories(promo.value.promotedCategories);
        if (!result.data) {
          swal.fire({
            icon: 'error',
            text: 'Error in order process',
          });
          return;
        }
      }
    };

    search();

    const areYouSureDeleteModal = async () => {
      return swal.fire({
        title: t('alert.areYouSure'),
        text: t('alert.youWontBeAbleToRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        confirmButtonText: t('alert.yesDeleteIt'),
        cancelButtonText: t('button.cancel'),
      });
    };

    const deletePromoProduct = async (id: number) => {
      areYouSureDeleteModal().then(async (result) => {
        if (result.isConfirmed) {
          const response = await api.DeletePromotedProduct(id);
          if (!response || response.error) {
            swal.fire({
              icon: 'error',
              text: response.errorMessage || 'Delete error',
            });
          } else {
            if (promo.value) {
              promo.value.promotedProducts = promo.value.promotedProducts.filter((p) => p.id != id);
            }
          }
        }
      });
    };

    const deletePromoCategory = async (id: number) => {
      areYouSureDeleteModal().then(async (result) => {
        if (result.isConfirmed) {
          const response = await api.DeletePromotedCategory(id);
          if (!response || response.error) {
            swal.fire({
              icon: 'error',
              text: response.errorMessage || 'Delete error',
            });
          } else {
            if (promo.value) {
              promo.value.promotedCategories = promo.value.promotedCategories.filter((p) => p.id != id);
            }
          }
        }
      });
    };

    const addRouteParams = (type: PromotedType) => {
      router.push({ name: 'PromoManagement', params: { type: PromotedType[type] } });
      currentPromotedType.value = type;
      search();
    };

    const showFilter = () => {
      store.commit('savePromoFilter', filter);
      router.push({ name: 'modal-promo-filter' });
    };

    const hasFilter = computed<boolean>(() => {
      if (filter.value.providerId) {
        return true;
      }
      if (filter.value.productId) {
        return true;
      }
      if (filter.value.fromDate) {
        return true;
      }
      if (filter.value.toDate) {
        return true;
      }
      if (filter.value.active) {
        return true;
      }
      return false;
    });

    return {
      promo,
      deletePromoProduct,
      deletePromoCategory,
      PromotedType,
      currentPromotedType,
      addRouteParams,
      filter,
      search,
      totalFiltered,
      total,
      showAll,
      sortBy,
      orderPromoProducts,
      orderPromoCategories,
      showFilter,
      hasFilter,
    };
  },
});
