
import { computed, defineComponent, ref } from 'vue';
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { Permissions } from '@/models/Enums';
import router from '@/router';
import generalStore from '@/store';
import InputNumber from 'primevue/inputnumber';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Profile',
  props: {
    id: {
      type: Number,
      required: true,
    },
    providerId: {
      type: Number,
      required: true,
    },
  },
  components: { InputNumber },
  async setup(props: any) {
    const { t } = useI18n();
    const wrongCashierMaximumDiscount = ref(false);
    const loaded = ref(false);
    const wrongCashierMaximumChecks = ref(false);
    const showCashierMaxChecks = ref(false);
    const permissions = Permissions;
    const defaultProfile: ProviderProfile = {
      id: 0,
      active: true,
      description: '',
      createdAt: '',
      permissions: [],
      providerId: props.providerId,
      priceTypes: [],
      products: [],
      cashierMaxDicountPercent: null,
      cashierMaxChecks: null,
    };
    const profile = ref<ProviderProfile>(defaultProfile);
    if (props.id > 0) {
      swal.showLoading();
      const apiResult = await api.getUserProfileData(props.id, props.providerId);
      await Q.delay(400);
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
      }
      if (apiResult.data) {
        profile.value = apiResult.data;
        if (profile.value.cashierMaxChecks != null) {
          showCashierMaxChecks.value = true;
        }
      }
      swal.close();
    }
    const priceTypes = await generalStore.dispatch('getPriceTypes');
    const products = ref<IdText[]>([]);
    const apiResult = await api.getProviderProducts(props.providerId);
    if (apiResult.errorMessage) {
      swal.fire({
        icon: 'error',
        text: apiResult.errorMessage,
      });
      return;
    }
    if (apiResult.data) {
      products.value = apiResult.data;
    }
    loaded.value = true;
    async function cancel() {
      router.go(-1);
    }
    async function save() {
      let wasError = false;
      wrongCashierMaximumDiscount.value = false;
      wrongCashierMaximumChecks.value = false;
      if (
        profile.value.permissions.includes(permissions.CashierChangeDiscount) &&
        profile.value.cashierMaxDicountPercent == null
      ) {
        swal.fire({
          icon: 'error',
          text: t('alert.permissions.maximumDiscountRequired'),
        });
        wrongCashierMaximumDiscount.value = true;
        wasError = true;
      }
      if (showCashierMaxChecks.value && profile.value.cashierMaxChecks == null) {
        if (!wasError) {
          swal.fire({
            icon: 'error',
            text: t('alert.permissions.maximumCashierChecksRequired'),
          });
        }
        wrongCashierMaximumChecks.value = true;
        wasError = true;
      }
      if (wasError) {
        return;
      }
      swal.showLoading();
      const profileClone = JSON.parse(JSON.stringify(profile.value)) as ProviderProfile;
      profileClone.permissions = [];
      if (profile.value != undefined && profile.value.permissions) {
        profile.value.permissions.forEach((element) => {
          profileClone.permissions.push(parseInt(element.toString()));
        });
      }
      const apiResult = await api.saveUserProfileData(profileClone);
      await Q.delay(400);
      if (apiResult.errorMessage) {
        swal.fire({
          icon: 'error',
          text: apiResult.errorMessage,
        });
        return;
      }
      swal.close();
      router.go(-1);
    }
    function changeCashierMaxDicountPercent(e: any) {
      if (!e.target.checked) {
        profile.value.cashierMaxDicountPercent = null;
      }
    }
    function changeCashierMaxChecks(e: any) {
      if (!e.target.checked) {
        profile.value.cashierMaxChecks = null;
      }
    }
    return {
      cancel,
      save,
      loaded,
      profile,
      permissions,
      priceTypes,
      products,
      wrongCashierMaximumDiscount,
      wrongCashierMaximumChecks,
      changeCashierMaxDicountPercent,
      showCashierMaxChecks,
      changeCashierMaxChecks,
    };
  },
});
