
import { computed, defineComponent, PropType, ref } from 'vue';
import api from '@/services/Api';
import swal from 'sweetalert2';
import { UnfinishedNotificationsRestrictions } from '@/models/Product';

export default defineComponent({
    props: {
        productId: {
            type: Number,
            required: true,
        },
        restrictions: {
            type: UnfinishedNotificationsRestrictions,
            required: true,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any) {
        console.log(props)
        const notificationsRestrictions = ref<UnfinishedNotificationsRestrictions>(props.restrictions);

        const unfinishedProductsNotificationsToggle = computed(() => {
            if (
                notificationsRestrictions.value.unfinishedProductSlotEmpty == true ||
                notificationsRestrictions.value.unfinishedProductPriceEmpty == true ||
                notificationsRestrictions.value.unfinishedProductSlotLess == true ||
                notificationsRestrictions.value.unfinishedProductPriceLess == true ||
                notificationsRestrictions.value.unfinishedProductNoPhoto == true
            ) {
                return true;
            }
            return false;
        });

        const activateDeactivateUnfinishedGroupNotifications = async (status: boolean) => {
            notificationsRestrictions.value.unfinishedProductSlotEmpty = status;
            notificationsRestrictions.value.unfinishedProductPriceEmpty = status;
            notificationsRestrictions.value.unfinishedProductSlotLess = status;
            notificationsRestrictions.value.unfinishedProductPriceLess = status;
            notificationsRestrictions.value.unfinishedProductNoPhoto = status;

            await activateDeactivateNotificationRestrictions();
        }

        const activateDeactivateNotificationRestrictions = async () => {
            console.log(notificationsRestrictions.value)
            swal.showLoading();
            setTimeout(async () => {
                const response = await api.activateDeactivateProductNotificationRestriction(notificationsRestrictions.value);

                if (response.error) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                }
            } ,500);
            swal.close();
        }

        return {
            unfinishedProductsNotificationsToggle,
            notificationsRestrictions,
            activateDeactivateNotificationRestrictions,
            activateDeactivateUnfinishedGroupNotifications
        }
    }
});
