
import api from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import { mapState, Store, mapGetters } from 'vuex';
import * as ApiInterfaces from '@/models/ApiInterfaces';

@Options({
  name: 'EditTagTranslation',
  props: {
    tagType: {
      type: String,
      required: true,
    },
    tagId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState(['languages', 'lang']),
  },
})
export default class EditTagTranslation extends Vue {
  protected readonly tagType!: string;
  protected readonly tagId!: number;
  public tagItem: EditTagLangs | null = null;
  public loaded = false;
  public mainLang: Language | null = null;
  public selectedLang = 0;

  protected readonly languages!: Language[];
  protected readonly lang!: string;

  async mounted() {
    swal.showLoading();
    this.mainLang = this.languages.find((x) => x.name == this.lang) || null;
    if (this.mainLang) {
      this.selectedLang = this.mainLang.id;
    }
    const getTagLangsResponse = await api.getTagLangs(this.tagId, this.tagType);
    await Q.delay(400);
    if (getTagLangsResponse.errorMessage) {
      swal.fire({ icon: 'error', title: 'Oops...', text: getTagLangsResponse.errorMessage });
      return;
    }
    if (getTagLangsResponse.data) {
      this.tagItem = {
        id: getTagLangsResponse.data.item.id,
        name: getTagLangsResponse.data.item.name,
        language: getTagLangsResponse.data.item.translations,
      };
      this.languages.forEach((language) => {
        var lang = this.tagItem?.language.find((x) => x.languageId == language.id);
        if (!lang) {
          this.tagItem?.language.push({
            entityId: this.tagId,
            id: 0,
            languageId: language.id,
            name: ''
          });
        }
      });
      this.loaded = true;
    }
    swal.close();
  }

  cancel() {
    this.$router.go(-1);
  }
  async save() {
    if (!this.tagItem) {
      return;
    }
    swal.showLoading();
    let toSave: EditTagLangs = JSON.parse(JSON.stringify(this.tagItem));
    toSave.language = toSave.language.filter((x) => x.name);
    let request: ApiInterfaces.SaveTagRequest = {
      tagId: toSave.id,
      tagName: toSave.name,
      translations: toSave.language,
      parentTagId: 0,
      tagType: this.tagType,
    };
    
    const saveTagResponsePromise = this.tagType == "productType" ? api.saveProductType(request) : api.saveTag(request);
    await Q.delay(400);
    const saveTagResponse = await saveTagResponsePromise;
    if (saveTagResponse.errorMessage) {
      if (saveTagResponse.errorMessage) {
        swal.fire({ icon: 'error', title: 'Oops...', text: saveTagResponse.errorMessage });
        return;
      }
    }
    swal.close();
    this.cancel();
  }
}
