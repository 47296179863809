
import { ref, computed } from 'vue';
import router from '@/router';
import { WeekNames } from '@/models/Enums';
import api from '@/services/Api';
import swal from 'sweetalert2';
import { Product, ProductWorkingDate } from '@/models/Product';
import generalStore from '@/store';
import useProduct from '@/components/Product/useProduct';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default {
    props: {
        workingDayId: {
            type: String,
            required: true
        }
    },
    async setup(props: any) {
        const { t } = useI18n();

        const close = () => {
            router.go(-1);
        };

        const { getProduct } = useProduct();

        const product = computed<Product>(() => generalStore.getters.product);
        const workingDate = ref<ProductWorkingDate>();
        const untouchedWorkingDate = ref<ProductWorkingDate>();
        if (product.value) {
            const date = product.value.productWorkingDates?.find(d => d.id == props.workingDayId);
            if (date) {
                workingDate.value = JSON.parse(JSON.stringify(date));
                untouchedWorkingDate.value = JSON.parse(JSON.stringify(date));
            }
        } else {
            const productWorkingDateResponse = await api.getProductWorkingDate(props.workingDayId);
            if (productWorkingDateResponse.errorMessage)
                swal.fire({ icon: 'error', title: 'Oops...', text: productWorkingDateResponse.errorMessage });
            if (productWorkingDateResponse.data) {
                workingDate.value = JSON.parse(JSON.stringify(productWorkingDateResponse.data));
                untouchedWorkingDate.value = JSON.parse(JSON.stringify(productWorkingDateResponse.data));
            }
        }

        if (!workingDate.value) {
            close();
        }

        const weekNames = WeekNames;
        const checkedDays = ref(workingDate.value?.timeSlots.map(m => m.dayOfWeek).filter((x, i, a) => a.indexOf(x) === i));

        const findCheckedWeekDay = (weekEnum: WeekNames) => {
            let newwBool = false;
            checkedDays.value?.forEach(week => {
                if (weekEnum === week) {
                    newwBool = true;
                }
            });
            return newwBool;
        };
        const findActiveWeekDay = (weekEnum: WeekNames) => {
            let newwBool = false;
            workingDate.value?.timeSlots.some(t => {
                if (weekEnum === t.dayOfWeek && t.active == true) {
                    newwBool = true;
                }
            });
            return newwBool;
        };

        const hasAnyActiveTimeSlot = ref(workingDate.value?.timeSlots.some(t => t.active != true));

        const switchDay = (weekEnum: string, $event: any) => {
            $event.target.classList.add('was-validated');
            if ($event.target.checkValidity() === false) {
                $event.preventDefault();
                $event.stopPropagation();
            } else {
                const slots = workingDate.value?.timeSlots.filter(t => t.dayOfWeek == weekEnum);
                const target = $event?.target as HTMLInputElement;
                if (slots) {
                    slots?.forEach(s => (s.active = target.checked));
                }
                hasAnyActiveTimeSlot.value = workingDate.value?.timeSlots.some(t => t.active != true);
            }
        };

        const switchAllDays = ($event: InputEvent) => {
            const target = $event?.target as HTMLInputElement;
            target.classList.add('was-validated');
            if (target.checkValidity() === false) {
                $event.preventDefault();
                $event.stopPropagation();
            } else {
                if (workingDate.value) {
                    workingDate.value?.timeSlots.forEach(s => (s.active = target.checked));
                }
                hasAnyActiveTimeSlot.value = workingDate.value?.timeSlots.some(t => t.active != true);
            }
        };

        const save = async () => {
            if (workingDate.value) {
                swal.showLoading();
                workingDate.value.timeSlots.forEach(s => {
                    if (s.startTime.length == 5) {
                        s.startTime += ':00';
                    }
                    if (s.endTime.length == 5) {
                        s.endTime += ':00';
                    }
                });
                const saveResponse = await api.saveProductWorkingDate(workingDate.value, false);
                if (saveResponse.data?.error && saveResponse.data?.errorCode == 0) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: saveResponse.data?.error });
                    workingDate.value = JSON.parse(JSON.stringify(untouchedWorkingDate.value));
                    hasAnyActiveTimeSlot.value = false;
                    return;
                }
                if (product.value && saveResponse.data && saveResponse.data?.errorCode != 101) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: saveResponse.data?.error });
                    const index = product.value.productWorkingDates.findIndex(d => d.id == workingDate.value?.id);
                    if (index > -1) product.value.productWorkingDates.splice(index, 1, saveResponse.data?.productWorkingDate);
                    else product.value.productWorkingDates.push(saveResponse.data?.productWorkingDate);
                }
                if (saveResponse.data?.errorCode && saveResponse.data?.errorCode == 101) {
                    const newResult = await swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: saveResponse.data?.error,
                        customClass: {
                            confirmButton: 'green-button popup-bookit-button my-1 px-4 fixed-size',
                            cancelButton: 'stop-sale-button popup-bookit-button my-1 px-4 fixed-size'
                        },
                        buttonsStyling: false,
                        showCancelButton: true,
                        confirmButtonText: t('button.yes'),
                        cancelButtonText: t('button.no')
                    });
                    if (newResult.isConfirmed) {
                        const saveAnywayResponse = await api.saveProductWorkingDate(workingDate.value, true);
                        if (product.value && saveAnywayResponse.data) {
                            const found = product.value.productWorkingDates?.findIndex(d => d.id == props.workingDayId);
                            if (found > -1) {
                                product.value.productWorkingDates[found].timeSlots = saveAnywayResponse.data.productWorkingDate.timeSlots;
                            }
                        }
                    } else return;
                }
                swal.close();
            }
            close();
        };

        const deleteWorkingDate = () => {
            if (workingDate.value) {
                swal.fire({
                    title: t('alert.areYouSure'),
                    text: t('alert.youWontBeAbleToRevertThis'),
                    icon: 'warning',
                    showCancelButton: true,
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    confirmButtonText: t('alert.yesDeleteIt'),
                    cancelButtonText: t('button.cancel')
                }).then(async result => {
                    if (result.isConfirmed) {
                        if (workingDate.value?.id) {
                            if (!product.value || product.value.productWorkingDates.length == 0) {
                                await getProduct(workingDate.value.productId);
                            }
                            const index = product.value.productWorkingDates.findIndex(d => d.id == workingDate.value?.id);
                            if (index > -1) {
                                const timeNow = moment().toISOString();
                                product.value.productWorkingDates[index].deletedAt = timeNow;
                                product.value.productWorkingDates[index].timeSlots.forEach(s => {
                                    if (s.startTime.length == 5) {
                                        s.startTime = moment(s.startTime, 'HH:mm').format('HH:mm:ss');
                                    }
                                    if (s.endTime.length == 5) {
                                        s.endTime = moment(s.startTime, 'HH:mm').format('HH:mm:ss');
                                    }
                                    s.deletedAt = timeNow;
                                });
                            }
                            const response = await api.deleteProductWorkingDate(product.value.productWorkingDates[index]);
                            if (response.errorMessage) {
                                swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
                            } else {
                                product.value.productWorkingDates.splice(index, 1);
                                swal.fire({
                                    text: t('editProductPage.deleteCalendar.alertTitle'),
                                    icon: 'success',
                                    showCancelButton: false,
                                    customClass: {
                                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4'
                                    },
                                    confirmButtonText: t('button.backToProduct')
                                }).then(() => {
                                    router.push({ name: 'edit-product', params: { id: product.value.id } });
                                });
                            }
                        }
                    }
                });
            }
        };

        return {
            weekNames,
            findCheckedWeekDay,
            findActiveWeekDay,
            workingDate,
            checkedDays,
            save,
            switchDay,
            switchAllDays,
            hasAnyActiveTimeSlot,
            untouchedWorkingDate,
            deleteWorkingDate,
            close
        };
    }
};
